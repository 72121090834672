import { gql, useMutation } from '@apollo/client';

import { ApiKeyMutation, ApiKeyMutationVariables } from '../gqlTypes';

const MUTATION = gql`
  mutation ApiKey($apiKey: APIKeyInput!) {
    updateApiKey(apiKey: $apiKey)
  }
`;

export const useUpdateApiKey = () =>
  useMutation<ApiKeyMutation, ApiKeyMutationVariables>(MUTATION, {
    refetchQueries: ['ApiKeys'],
  });
