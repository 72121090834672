import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { VideoIdResultQuery, VideoIdResultQueryVariables } from '../gqlTypes';

export const VIDEO_ID_RESULT_QUERY = gql`
  query VideoIdResult($input: IdVerificationResultInput!) {
    idVerificationResult(input: $input) {
      status
    }
  }
`;

export const useVideoIdResult = (
  params: VideoIdResultQueryVariables,
  options?: QueryHookOptions<VideoIdResultQuery, VideoIdResultQueryVariables>,
) =>
  useQuery<VideoIdResultQuery, VideoIdResultQueryVariables>(VIDEO_ID_RESULT_QUERY, {
    variables: params,
    ...options,
  });
