export const useShowIntercom = () => {
  const getIntercom = () => {
    return document.getElementsByClassName('intercom-lightweight-app')?.[0] as HTMLElement;
  };

  const hide = () => {
    const intercomNode = getIntercom();
    if (intercomNode) {
      intercomNode.style.display = 'none';
    }
  };

  const show = () => {
    const intercomNode = getIntercom();
    if (intercomNode) {
      intercomNode.style.display = 'block';
    }
  };

  return {
    hide,
    show,
  };
};
