import * as React from 'react';
import { SVGProps } from 'react';
const SvgTicket = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.502 8.5H10.502V6.5H12.502V8.5ZM12.502 13H10.502V11H12.502V13ZM12.502 17.5H10.502V15.5H12.502V17.5ZM21.502 10V6C21.502 4.89 20.602 4 19.502 4H3.50195C2.97152 4 2.46281 4.21071 2.08774 4.58579C1.71267 4.96086 1.50195 5.46957 1.50195 6V10C2.61195 10 3.50195 10.9 3.50195 12C3.50195 12.5304 3.29124 13.0391 2.91617 13.4142C2.54109 13.7893 2.03239 14 1.50195 14V18C1.50195 18.5304 1.71267 19.0391 2.08774 19.4142C2.46281 19.7893 2.97152 20 3.50195 20H19.502C20.0324 20 20.5411 19.7893 20.9162 19.4142C21.2912 19.0391 21.502 18.5304 21.502 18V14C20.9715 14 20.4628 13.7893 20.0877 13.4142C19.7127 13.0391 19.502 12.5304 19.502 12C19.502 11.4696 19.7127 10.9609 20.0877 10.5858C20.4628 10.2107 20.9715 10 21.502 10Z"
      fill="white"
    />
  </svg>
);
export default SvgTicket;
