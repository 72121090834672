import * as React from 'react';
import { SVGProps } from 'react';
const SvgAffiliateCommission = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M12 10.5C14.481 10.5 16.5 8.481 16.5 6C16.5 3.519 14.481 1.5 12 1.5C9.519 1.5 7.5 3.519 7.5 6C7.5 8.481 9.519 10.5 12 10.5ZM12 3C13.6545 3 15 4.3455 15 6C15 7.6545 13.6545 9 12 9C10.3455 9 9 7.6545 9 6C9 4.3455 10.3455 3 12 3Z" />
    <path d="M4.5 16.5C2.8455 16.5 1.5 17.8455 1.5 19.5C1.5 21.1545 2.8455 22.5 4.5 22.5C6.1545 22.5 7.5 21.1545 7.5 19.5C7.5 17.8455 6.1545 16.5 4.5 16.5ZM4.5 21C3.67275 21 3 20.3273 3 19.5C3 18.6727 3.67275 18 4.5 18C5.32725 18 6 18.6727 6 19.5C6 20.3273 5.32725 21 4.5 21Z" />
    <path d="M12 16.5C10.3455 16.5 9 17.8455 9 19.5C9 21.1545 10.3455 22.5 12 22.5C13.6545 22.5 15 21.1545 15 19.5C15 17.8455 13.6545 16.5 12 16.5ZM12 21C11.1727 21 10.5 20.3273 10.5 19.5C10.5 18.6727 11.1727 18 12 18C12.8273 18 13.5 18.6727 13.5 19.5C13.5 20.3273 12.8273 21 12 21Z" />
    <path d="M19.5 16.5C17.8455 16.5 16.5 17.8455 16.5 19.5C16.5 21.1545 17.8455 22.5 19.5 22.5C21.1545 22.5 22.5 21.1545 22.5 19.5C22.5 17.8455 21.1545 16.5 19.5 16.5ZM19.5 21C18.6727 21 18 20.3273 18 19.5C18 18.6727 18.6727 18 19.5 18C20.3273 18 21 18.6727 21 19.5C21 20.3273 20.3273 21 19.5 21Z" />
    <path d="M5.25 13.5H11.25V15H12.75V13.5H18.75V15H20.25V13.5C20.25 12.6728 19.5773 12 18.75 12H5.25C4.42275 12 3.75 12.6728 3.75 13.5V15H5.25V13.5Z" />
  </svg>
);
export default SvgAffiliateCommission;
