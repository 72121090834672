import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { AydenPaymentStatusQuery, AydenPaymentStatusQueryVariables } from './../gqlTypes';

const query = gql`
  query AydenPaymentStatus($merchantReference: String!) {
    payment {
      aydenPaymentStatus(merchantReference: $merchantReference)
    }
  }
`;

export const useAydenPaymentStatus = (
  options?: QueryHookOptions<AydenPaymentStatusQuery, AydenPaymentStatusQueryVariables>,
) => {
  return useQuery<AydenPaymentStatusQuery, AydenPaymentStatusQueryVariables>(query, {
    ...options,
  });
};
