import { gql, QueryOptions, useQuery } from '@apollo/client';

import { MarketOrdersQuery, MarketOrdersQueryVariables, Symbol } from './../gqlTypes';

const query = gql`
  query MarketOrders($symbol: Symbol) {
    orders(currency: $symbol) {
      id
      market {
        symbol
      }
      amount
      price
      remaining
      createdAt
      type
    }
  }
`;

export const useMarketOrders = (currency?: Symbol, options?: Omit<QueryOptions, 'query'>) => {
  return useQuery<MarketOrdersQuery, MarketOrdersQueryVariables>(query, {
    ...options,
    variables: { symbol: currency },
  });
};
