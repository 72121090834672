import * as React from 'react';
import { SVGProps } from 'react';
const SvgFraud = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12 12.75C9.105 12.75 6.75 10.395 6.75 7.5C6.75 4.605 9.105 2.25 12 2.25C14.895 2.25 17.25 4.605 17.25 7.5C17.25 10.395 14.895 12.75 12 12.75ZM12 3.75C9.93225 3.75 8.25 5.43225 8.25 7.5C8.25 9.56775 9.93225 11.25 12 11.25C14.0678 11.25 15.75 9.56775 15.75 7.5C15.75 5.43225 14.0678 3.75 12 3.75Z" />
    <path d="M21.75 22H20.25V20.4444C20.25 18.3001 18.5678 16.5556 16.5 16.5556H6.75C5.0955 16.5556 3.75 17.9509 3.75 19.6667V22H2.25V19.6667C2.25 17.0938 4.269 15 6.75 15H16.5C19.395 15 21.75 17.4422 21.75 20.4444V22Z" />
    <rect x={5} y={6} width={14} height={2} rx={1} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7H16Z"
    />
    <path d="M3 20C3 17.7909 4.79086 16 7 16H17C19.2091 16 21 17.7909 21 20V22H3V20Z" />
  </svg>
);
export default SvgFraud;
