import * as React from 'react';
import { SVGProps } from 'react';
const SvgDoubleCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.86334 15.5015L21 4.03161L19.9984 3L8.8617 14.4699C8.7695 14.5648 8.62002 14.5648 8.52782 14.4699L4.00164 9.80826L3 10.8399L7.52618 15.5015C8.17157 16.1662 9.21795 16.1662 9.86334 15.5015ZM9.86334 20.5015L21 9.03161L19.9984 8L8.8617 19.4699C8.7695 19.5648 8.62002 19.5648 8.52782 19.4699L4.00164 14.8083L3 15.8399L7.52618 20.5015C8.17157 21.1662 9.21795 21.1662 9.86334 20.5015Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgDoubleCheck;
