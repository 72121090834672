import { gql, useQuery, WatchQueryFetchPolicy } from '@apollo/client';

import { PnlBalanceQuery, PnlBalanceQueryVariables, PnlInput } from './../gqlTypes';

export const PNL_BALANCE = gql`
  query PNLBalance($input: PNLInput!) {
    pnl {
      balance(input: $input) {
        fiatCurrency
        total {
          fiatAmount
          percent
        }
        currencies {
          cryptoCurrency
          fiatAmount
          percent
        }
      }
    }
  }
`;

export const usePNLBalance = (
  params: PnlInput,
  apolloOpts: {
    fetchPolicy?: WatchQueryFetchPolicy;
    nextFetchPolicy?: WatchQueryFetchPolicy;
    skip?: boolean;
  },
) => {
  return useQuery<PnlBalanceQuery, PnlBalanceQueryVariables>(PNL_BALANCE, {
    variables: {
      input: {
        period: params.period,
        accountGroupId: params.accountGroupId,
      },
    },
    fetchPolicy: apolloOpts.fetchPolicy || 'cache-first',
    nextFetchPolicy: apolloOpts.nextFetchPolicy,
    skip: apolloOpts.skip,
  });
};
