import imageUrlBuilder from '@sanity/image-url';
import {
  SanityAsset,
  SanityImageObject,
  SanityImageSource,
  SanityReference,
} from '@sanity/image-url/lib/types/types';
import { sanityConfig } from 'lib/sanity/config';
import { SanityImageAssets } from 'types/sanity';

const builder = imageUrlBuilder(sanityConfig);

/**
 *
 * @param newHeight
 * @param ratio
 * @returns width
 */
export const getWidthByRatio = (newHeight: number, ratio: number) => newHeight * ratio;

/**
 *
 * @param newWidth
 * @param ratio
 * @returns height
 */
export const getHeightByRatio = (newWidth: number, ratio: number) => newWidth / ratio;

/**
 *
 * @param source
 * @returns image builder with options
 */
export function urlFor(source: SanityImageAssets) {
  return builder.image(source);
}

function getSanityRefId(image: SanityImageSource): string {
  if (typeof image === 'string') {
    return image;
  }

  const obj = image as SanityImageObject;
  const ref = image as SanityReference;
  const img = image as SanityAsset;

  if (typeof image === 'string') {
    return image;
  }

  if (obj.asset) {
    return obj.asset._ref || (obj.asset as SanityAsset)._id;
  }

  return ref._ref || img._id || '';
}

export function getSanityImageDimensions(source: SanityImageAssets) {
  const id = getSanityRefId(source);
  const { width: customWidth, height: customHeight } = source;

  const dimensions = id.split('-')[2];

  let [width, height] = dimensions.split('x').map((num: string) => parseInt(num, 10));

  const aspectRatio = width / height;

  if (customWidth || customHeight) {
    width = customWidth || getWidthByRatio(customHeight ?? 0, aspectRatio);
    height = customHeight || getHeightByRatio(customWidth ?? 0, aspectRatio);
  }

  return { width, height, aspectRatio };
}
