import { gql, useMutation } from '@apollo/client';

import { DeleteProfilePictureMutation } from './../gqlTypes';

const mutation = gql`
  mutation DeleteProfilePicture {
    deleteProfilePicture {
      success
    }
  }
`;

export const useDeleteProfilePicture = () =>
  useMutation<DeleteProfilePictureMutation>(mutation, {
    refetchQueries: ['ProfilePicture'],
  });
