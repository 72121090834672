import { gql } from '@apollo/client';

export const TradeFragment = gql`
  fragment TradeFragment on Trade {
    id
    tradeType
    createdAt
    price
    priceCurrency
    buyAmount
    buyCurrency
    sellAmount
    sellCurrency
    feeAmount
    feeCurrency
    accountGroupId
    depositDetails {
      depositType
      amount
      fee
      totalAmount
    }
  }
`;
