import { gql, useQuery } from '@apollo/client';

import { CryptoCurrency, PriceStatsQuery, PriceStatsQueryVariables } from '../gqlTypes';

const QUERY = gql`
  query PriceStats($currency: CryptoCurrency!, $accountGroupId: String) {
    pnl {
      priceStats(currency: $currency, accountGroupId: $accountGroupId) {
        id
        maxPrice
        minPrice
        avgPrice
      }
    }
  }
`;

export const usePriceStats = (params: { currency: CryptoCurrency; accountGroupId?: string }) =>
  useQuery<PriceStatsQuery, PriceStatsQueryVariables>(QUERY, {
    variables: { currency: params.currency, accountGroupId: params.accountGroupId },
  });
