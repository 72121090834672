import { IllustrationName } from '@firi/illustrations/illustrations/IllustrationNameType';
import { t } from '@lingui/macro';

import { AuthRedirectError } from './types';

// To create newline in lingui
const BR = '\n\n';

export const authRedirectErrorToTextMapper: Record<
  AuthRedirectError | 'default',
  { title: string; content: string; illustration: IllustrationName }
> = {
  hidden: {
    title: t`Oh no..`,
    content: t`To become a customer with us, all information must be correct and verifiable. Therefore, it is not possible to have a secret name or use pseudonyms. We require full legal names to ensure compliance with anti-money laundering regulations and protect against fraud.\n\nThis practice creates a safe and secure experience for all our customers. Thank you for your understanding, and we look forward to welcoming you.`,
    illustration: 'screen-thumbs-down',
  },
  mismatch: {
    title: t`Something went wrong`,
    content: t`We are sorry, something went wrong.`,
    illustration: 'hammer',
  },
  underAge: {
    title: t`We have an 18-year age limit`,
    content: t`There is an age limit of 18 to create an account with Firi. We warmly welcome you back when you turn 18!`,
    illustration: 'clock',
  },
  default: {
    title: t`Something went wrong`,
    content: t`We are sorry, something went wrong.`,
    illustration: 'hammer',
  },
};
