import create from 'zustand';

import { AccountGroup, Symbol } from './../gql/gqlTypes';

type AccountType = Pick<AccountGroup, 'name' | 'type' | 'accounts' | 'id'>;

interface State {
  from?: AccountType;
  setFrom: (from?: AccountType) => void;
  to?: AccountType;
  setTo: (to?: AccountType) => void;
  amount?: string;
  currency?: Symbol;
  setCurrency: (currency?: Symbol) => void;
  setValues: (v: {
    from?: AccountType;
    to?: AccountType;
    amount?: string;
    currency?: Symbol;
  }) => void;
}
export const useInternalTransferStore = create<State>((set) => ({
  from: undefined,
  to: undefined,
  setFrom: (from) => set({ from }),
  setTo: (to) => set({ to }),
  setCurrency: (currency) => set({ currency }),
  setValues: (v) => set({ ...v }),
  amount: '',
  currency: undefined,
}));
