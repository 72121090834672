import * as React from 'react';
import { SVGProps } from 'react';
const SvgSortMax = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 15 15" fill="#12123B" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9375 2.50006L10.9375 12.5L11.875 12.5L11.875 2.50006L10.9375 2.50006Z"
      fill="#474AEE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.43751 5.00004L8.43751 12.5L9.375 12.5L9.375 5.00004L8.43751 5.00004Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.93751 7.50003L5.93751 12.5L6.875 12.5L6.875 7.50003L5.93751 7.50003Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.43751 10L3.43751 12.5L4.375 12.5L4.375 10L3.43751 10Z"
    />
  </svg>
);
export default SvgSortMax;
