import * as React from 'react';
import { SVGProps } from 'react';
const SvgQuestionMarkCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 32 32" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M16 30C8.28 30 2 23.72 2 16C2 8.28 8.28 2 16 2C23.72 2 30 8.28 30 16C30 23.72 23.72 30 16 30ZM16 4C9.383 4 4 9.383 4 16C4 22.617 9.383 28 16 28C22.617 28 28 22.617 28 16C28 9.383 22.617 4 16 4Z" />
    <path d="M16 24C16.5523 24 17 23.5523 17 23C17 22.4477 16.5523 22 16 22C15.4477 22 15 22.4477 15 23C15 23.5523 15.4477 24 16 24Z" />
    <path d="M17 20H15.2C15.2 18.235 19 17.235 19 14C19 12.346 17.654 11 16 11C14.346 11 13 12.346 13 14H11C11 11.243 13.243 9 16 9C18.757 9 21 11.243 21 14C21 17.365 17.34 19.45 17 20Z" />
  </svg>
);
export default SvgQuestionMarkCircle;
