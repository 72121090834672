import { gql, useMutation } from '@apollo/client';

import { WithdrawKycConsentMutation } from './../gqlTypes';

const mutation = gql`
  mutation WithdrawKycConsent {
    withdrawKycConsent {
      success
      message
    }
  }
`;

export const useWithdrawKycConsent = () => useMutation<WithdrawKycConsentMutation>(mutation);
