import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import {
  PaymentDirectOrderStatusQuery,
  PaymentDirectOrderStatusQueryVariables,
} from './../gqlTypes';

const query = gql`
  query PaymentDirectOrderStatus($merchantReference: String!) {
    payment {
      paymentDirectOrderStatus(merchantReference: $merchantReference) {
        paymentStatus
        depositFee
        order {
          fee
          exchangeRate
          assetMatched
          fiatMatched
          status
        }
      }
    }
  }
`;

export const usePaymentDirectOrderStatus = (
  options?: QueryHookOptions<PaymentDirectOrderStatusQuery, PaymentDirectOrderStatusQueryVariables>,
) => {
  return useQuery<PaymentDirectOrderStatusQuery, PaymentDirectOrderStatusQueryVariables>(
    query,
    options,
  );
};
