import * as React from 'react';
import { SVGProps } from 'react';
const SvgLockFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.52 10.25C4.68053 10.25 4 10.9305 4 11.77V20.73C4 21.5695 4.68053 22.25 5.52 22.25H18.48C19.3195 22.25 20 21.5695 20 20.73V11.77C20 10.9305 19.3195 10.25 18.48 10.25H5.52ZM12.75 14.25C12.75 13.8358 12.4142 13.5 12 13.5C11.5858 13.5 11.25 13.8358 11.25 14.25V18.25C11.25 18.6642 11.5858 19 12 19C12.4142 19 12.75 18.6642 12.75 18.25V14.25Z"
      fill="#DA2D43"
    />
    <path
      d="M12 3.5C10.2051 3.5 8.75 4.95507 8.75 6.75V10.5C8.75 10.9142 8.41421 11.25 8 11.25C7.58579 11.25 7.25 10.9142 7.25 10.5V6.75C7.25 4.12665 9.37665 2 12 2C14.6234 2 16.75 4.12665 16.75 6.75V10.5C16.75 10.9142 16.4142 11.25 16 11.25C15.5858 11.25 15.25 10.9142 15.25 10.5V6.75C15.25 4.95507 13.7949 3.5 12 3.5Z"
      fill="#DA2D43"
    />
  </svg>
);
export default SvgLockFilled;
