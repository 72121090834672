import { gql, useQuery } from '@apollo/client';

import { ChristmasCalendarAnswersQuery } from '../gqlTypes';

const query = gql`
  query ChristmasCalendarAnswers {
    calendar {
      answers {
        correct
        day
      }
    }
  }
`;

export const useChristmasCalendarAnswers = () => {
  return useQuery<ChristmasCalendarAnswersQuery>(query);
};
