import * as React from 'react';
import { SVGProps } from 'react';
const SvgGraphFilledRound = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9969 12.25C21.999 12.1669 22 12.0836 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 12.0836 2.00103 12.1669 2.00306 12.25H4.48025L5.45502 9.65062C5.81277 8.69662 7.10032 8.5484 7.66549 9.39615L8.33316 10.3977L10.1865 5.7644C10.6466 4.61401 12.3201 4.76207 12.5711 5.97538L14.6067 15.8138L15.9852 10.8512C16.2715 9.82038 17.6362 9.6022 18.2296 10.4924L19.4014 12.25H21.9969ZM21.8474 13.75H19.2676C18.8497 13.75 18.4594 13.5411 18.2275 13.1934L17.2843 11.7786L15.7755 17.2104C15.4245 18.4739 13.6127 18.4133 13.347 17.1291L11.2706 7.09296L9.54937 11.396C9.17558 12.3305 7.907 12.4626 7.34871 11.6251L6.68814 10.6343L5.82391 12.9389C5.64096 13.4268 5.17456 13.75 4.6535 13.75H2.15261C2.98014 18.4385 7.07415 22 12 22C16.9259 22 21.0199 18.4385 21.8474 13.75Z"
    />
  </svg>
);
export default SvgGraphFilledRound;
