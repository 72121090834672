import { FetchPolicy, gql, QueryHookOptions, useQuery } from '@apollo/client';

import { useFiatMarket } from '../../hooks/useFiatMarket';
import { BuyOrdersQuery, BuyOrdersQueryVariables, CryptoCurrency, MarketType } from '../gqlTypes';

const query = gql`
  query BuyOrders($symbol: ID!, $marketType: MarketType) {
    market: market_v2(symbol: $symbol, marketType: $marketType) {
      symbol
      lastPrice
      buyOrders
      marketType
    }
    priceIndex(symbol: $symbol) {
      rate
    }
  }
`;

type Params = {
  fetchPolicy?: FetchPolicy;
  marketType?: MarketType;
} & ({ currency: CryptoCurrency } | { market?: string });

export const useBuyOrders = (
  params: Params,
  options?: QueryHookOptions<BuyOrdersQuery, BuyOrdersQueryVariables>,
) => {
  const fiatMarket = useFiatMarket();
  return useQuery<BuyOrdersQuery, BuyOrdersQueryVariables>(query, {
    ...options,
    variables: {
      symbol:
        'market' in params
          ? params.market || ''
          : 'currency' in params
          ? params.currency + fiatMarket.symbol
          : '',
      marketType: params.marketType,
    },
    skip:
      ('market' in params && !params.market) || (!('currency' in params) && !('market' in params)),
    // set to no-cache, since typePolicies is defined as merge in cache.ts. This means that cache rule is to merge incoming with existing and cause a requery loop
    fetchPolicy: params.fetchPolicy || 'no-cache',
  });
};
