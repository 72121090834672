import { gql, useMutation } from '@apollo/client';

import { UpdateSavingAgreementMutation, UpdateSavingAgreementMutationVariables } from '../gqlTypes';

const MUTATION = gql`
  mutation UpdateSavingAgreement($input: CreateSavingAgreement!) {
    updateSavingAgreement(input: $input) {
      success
      message
    }
  }
`;

export const useUpdateSavingAgreement = () => {
  return useMutation<UpdateSavingAgreementMutation, UpdateSavingAgreementMutationVariables>(
    MUTATION,
    { refetchQueries: ['Accounts'] },
  );
};
