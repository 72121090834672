import { useCallback, useEffect, useState } from 'react';

export const useScroll = () => {
  const [position, setPosition] = useState<number>(0);
  const [direction, setDirection] = useState<'up' | 'down' | null>(null);

  const handleScroll = useCallback(() => {
    const scrollFromTop = window.pageYOffset || document.documentElement.scrollTop;
    setDirection(scrollFromTop < position ? 'up' : 'down');
    setPosition(scrollFromTop <= 0 ? 0 : scrollFromTop);
  }, [position]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return {
    direction,
    position,
  };
};
