import { gql, useMutation } from '@apollo/client';

import {
  UpdateSavingAgreementDetailsMutation,
  UpdateSavingAgreementDetailsMutationVariables,
} from '../gqlTypes';

const MUTATION = gql`
  mutation UpdateSavingAgreementDetails($input: UpdateSavingAgreementDetails!) {
    updateSavingAgreementDetails(input: $input) {
      success
      message
    }
  }
`;

export const useUpdateSavingAgreementDetails = () => {
  return useMutation<
    UpdateSavingAgreementDetailsMutation,
    UpdateSavingAgreementDetailsMutationVariables
  >(MUTATION, { refetchQueries: ['Accounts'] });
};
