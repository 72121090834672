import { gql, useQuery } from '@apollo/client';

import { FeatureFlagsQuery } from '../gqlTypes';

const FEATURE_FLAGS_QUERY = gql`
  query FeatureFlags {
    featureFlags {
      travelRuleEnabled
      surveyGoogleForm
    }
  }
`;

export const useFeatureFlags = () => useQuery<FeatureFlagsQuery>(FEATURE_FLAGS_QUERY);
