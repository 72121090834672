import { gql, useQuery } from '@apollo/client';

import { ActiveLightningInvoicesQuery } from '../gqlTypes';

const query = gql`
  query ActiveLightningInvoices {
    activeLightningInvoices
  }
`;

export const useActiveLightningInvoices = () => useQuery<ActiveLightningInvoicesQuery>(query);
