import * as React from 'react';
import { SVGProps } from 'react';
const SvgShield = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M6.90039 12.5059L9.0718 14.6771C9.40047 14.9831 9.83424 15.1509 10.2832 15.1457C10.7331 15.1557 11.1686 14.9873 11.4947 14.6771L17.0002 9.1488L15.7887 8.00594L10.3175 13.5L8.1461 11.3288L6.90039 12.5059Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C9.33333 20.6667 4 17.5 4 14.5V5C6.5 5 8.5 4 12 2C15.5 4 18 5 20 5V14.5C20 17.7 14.6667 20.8333 12 22ZM5.5 14.5V6.39079C7.59854 6.08073 9.51743 5.1258 12.0037 3.72315C14.4845 5.09561 16.6084 6.02533 18.5 6.36099V14.5C18.5 14.8798 18.339 15.3746 17.9024 15.9887C17.4697 16.5974 16.8339 17.2263 16.0647 17.8398C14.7696 18.8727 13.2339 19.7533 12.0365 20.3317C10.8087 19.6815 9.24935 18.7535 7.93704 17.7037C7.16049 17.0825 6.52004 16.4575 6.08515 15.8637C5.64208 15.2587 5.5 14.8071 5.5 14.5Z"
    />
  </svg>
);
export default SvgShield;
