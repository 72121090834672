import { gql, useMutation } from '@apollo/client';

import { DeleteContactMutation, DeleteContactMutationVariables } from './../gqlTypes';

const mutation = gql`
  mutation DeleteContact($input: DeleteContactInput!) {
    deleteContact(input: $input) {
      success
    }
  }
`;

export const useDeleteContact = () =>
  useMutation<DeleteContactMutation, DeleteContactMutationVariables>(mutation, {
    refetchQueries: ['Contacts'],
  });
