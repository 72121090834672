import { gql, useLazyQuery } from '@apollo/client';

import { OpenBankingPaymentQuery, OpenBankingPaymentQueryVariables } from '../gqlTypes';

const QUERY = gql`
  query OpenBankingPayment($input: OpenBankingTransferInput!) {
    openBankingDomesticTransfer(input: $input) {
      url
      status
      errorMsg
      paymentId
    }
  }
`;

export const useOpenBankingPayment = () =>
  useLazyQuery<OpenBankingPaymentQuery, OpenBankingPaymentQueryVariables>(QUERY);
