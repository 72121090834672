import { gql } from '@apollo/client';

export const TransactionMiscFragment = gql`
  fragment TransactionMiscFragment on TransactionMisc {
    id
    accountGroupId
    createdAt
    amount
    type
    currency
    fromAccountGroupId
    toAccountGroupId
    fiatValue
  }
`;
