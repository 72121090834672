import * as React from 'react';
import { SVGProps } from 'react';
const SvgEmailAwait = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 13C20.7614 13 23 10.7614 23 8C23 5.23858 20.7614 3 18 3C15.2386 3 13 5.23858 13 8C13 10.7614 15.2386 13 18 13ZM17.5 4.5V8V8.20711L17.6464 8.35355L19.6464 10.3536L20.3536 9.64645L18.5 7.79289V4.5H17.5Z"
      fill="#474AEE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 13.1973L21 18.5C21 19.3273 20.3272 20 19.5 20H3.5C2.67275 20 2 19.3272 2 18.5V5.5C2 4.67275 2.67275 4 3.5 4H13.5278C13.1293 4.4452 12.7964 4.95025 12.544 5.5L4.86448 5.5L11.5 11.0499L12.4413 10.2626C12.6315 10.7294 12.8788 11.1669 13.1745 11.5664L11.5 12.9501L3.5 6.33903L3.5 18.5H19.5L19.5 13.811C20.0322 13.674 20.5357 13.4659 21 13.1973Z"
      fill="#12123B"
    />
  </svg>
);
export default SvgEmailAwait;
