import * as React from 'react';
import { SVGProps } from 'react';
const SvgMultiIdentity = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.7501 10.7812C10.8719 10.7812 11.7813 9.87185 11.7813 8.75005V10.375H12.5652C12.0033 11.3464 10.953 12 9.7501 12C7.95523 12 6.5002 10.5449 6.5002 8.75005C6.5002 6.95518 7.95523 5.50015 9.7501 5.50015C10.953 5.50015 12.0033 6.15371 12.5652 7.1251H11.7813V8.75005C11.7813 7.62826 10.8719 6.71887 9.7501 6.71887C8.62831 6.71887 7.71891 7.62826 7.71891 8.75005C7.71891 9.87185 8.62831 10.7812 9.7501 10.7812ZM8.12515 14.2191L13 14.2188V13L8.12515 13.0003C5.88156 13.0003 4.06277 14.8191 4.06277 17.0627V18.6877H5.28149V17.0627C5.28149 15.4922 6.55464 14.2191 8.12515 14.2191Z"
      fill="#828299"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 8C17.5 9.38071 16.3807 10.5 15 10.5C13.6193 10.5 12.5 9.38071 12.5 8C12.5 6.61929 13.6193 5.5 15 5.5C16.3807 5.5 17.5 6.61929 17.5 8ZM19 8C19 10.2091 17.2091 12 15 12C12.7909 12 11 10.2091 11 8C11 5.79086 12.7909 4 15 4C17.2091 4 19 5.79086 19 8ZM9.5 18C9.5 16.067 11.067 14.5 13 14.5H17C18.933 14.5 20.5 16.067 20.5 18V20H22V18C22 15.2386 19.7614 13 17 13H13C10.2386 13 8 15.2386 8 18V20H9.5V18Z"
    />
  </svg>
);
export default SvgMultiIdentity;
