import { t } from '@lingui/macro';

type Path = {
  title: string;
  path: {
    [locale: string]: string;
  };
};
interface HeaderItem extends Path {
  subItems: Path[];
}

export const headerItems: HeaderItem[] = [
  {
    title: t`Cryptocurrency`,
    path: {
      en: '/cryptocurrency',
      no: '/kryptovaluta',
      dk: '/cryptocurrency',
    },
    subItems: [
      {
        title: t`Market and prices`,
        path: {
          en: '/cryptocurrency',
          no: '/kryptovaluta',
          dk: '/cryptocurrency',
        },
      },
      {
        title: t`Advanced trade`,
        path: {
          en: 'https://platform.firi.com/advanced/BTCNOK',
          no: 'https://platform.firi.com/advanced/BTCNOK',
          dk: 'https://platform.firi.com/advanced/BTCDKK',
        },
      },
      {
        title: t`Staking`,
        path: {
          no: 'https://firi.com/no/staking',
          dk: 'https://firi.com/dk/staking',
        },
      },
      {
        title: t`OTC Trading`,
        path: {
          no: 'https://firi.com/no/om-oss/otc-handel',
        },
      },
    ],
  },
  {
    title: t`Learning`,
    path: {
      en: '/articles',
      no: '/artikler',
      dk: '/articles',
    },
    subItems: [
      {
        title: t`Start with crypto`,
        path: {
          en: '/articles/how-to-buy-cryptocurrency',
          no: '/artikler/hvordan-kjope-kryptovaluta',
          dk: '/articles/hvordan-kober-man-kryptovaluta',
        },
      },
      {
        title: t`Understand crypto`,
        path: {
          en: '/articles/cryptocurrency-for-dummies',
          no: '/artikler/kryptovaluta-for-dummies',
          dk: '/articles/kryptovaluta-for-dummies-guide',
        },
      },
      {
        title: t`Savings & Investments`,
        path: {
          en: '/crypto-investing-savings',
          no: '/sparing-investering',
          dk: '/crypto-investing-savings',
        },
      },

      {
        title: t`Crypto currency tax`,
        path: {
          en: '/cryptocurrency-tax',
          no: '/cryptocurrency-tax',
          dk: '/cryptocurrency-tax',
        },
      },
      {
        title: t`All articles`,
        path: {
          en: '/articles',
          no: '/artikler',
          dk: '/articles',
        },
      },
    ],
  },
  {
    title: t`About Firi`,
    path: {
      en: '/about',
      no: '/om-oss',
      dk: '/about',
    },
    subItems: [
      {
        title: t`About Firi`,
        path: {
          en: '/about',
          no: '/om-oss',
          dk: '/about',
        },
      },
      {
        title: t`Firi Reward`,
        path: {
          en: '/about/firi-reward',
          no: '/om-oss/firi-fordel',
          dk: '/about/firi-fordel-dk',
        },
      },
      {
        title: t`FAQ`,
        path: {
          en: '/about/faq-en',
          no: '/om-oss/faq',
          dk: '/about/faq',
        },
      },
      {
        title: t`Contact`,
        path: {
          en: '/about/contact',
          no: '/om-oss/kontakt-oss',
          dk: '/about/kontakt-os',
        },
      },
      {
        title: t`News`,
        path: {
          en: '/press/press-release',
          no: '/presse/pressemeldinger',
          dk: '/press/press-release',
        },
      },
      {
        title: t`Security`,
        path: {
          en: '/about/security',
          no: '/about/sikkerhet',
          dk: '/about/sikkerhed',
        },
      },
      {
        title: 'Firi Analytics',
        path: {
          no: 'https://firi.com/no/om-oss/firi-analytics',
        },
      },
    ],
  },
];
