import { IconName } from '@firi/icons/web';
import { PaymentMethodType } from '@firi/shared/gql/gqlTypes';
import { FontColors as WebFontColors } from '@firi/ui-web';
import { t } from '@lingui/macro';

interface BuyMethod {
  icon: { name: IconName; color: WebFontColors; background: string };
  paymentText: string;
  subText: string;
  feeText?: string;
  fastest?: boolean;
}

export type BuyMethodInfo = {
  [key in PaymentMethodType]?: BuyMethod;
};
export function usePaymentInfo(
  paymentMethod: PaymentMethodType | null,
  type: 'trade',
): BuyMethod | null | undefined;

export function usePaymentInfo(
  paymentMethod: PaymentMethodType | null,
  type: 'deposit',
  bank?: string,
): BuyMethod | null | undefined;

export function usePaymentInfo(
  paymentMethod: PaymentMethodType | null,
  type: 'trade' | 'deposit',
  bank?: string,
): BuyMethod | null | undefined {
  if (type === 'trade') {
    return paymentMethod ? basicBuyPaymentInfo[paymentMethod] : null;
  }

  const depositPaymentInfo: BuyMethodInfo = {
    openbanking: {
      icon: { name: 'bank', color: 'positive', background: 'bg-green-200' },
      paymentText: bank || t`Transfer via online banking`,
      subText: t`1-2 banking days`,
      feeText: t`No fees`,
    },
    scheme: {
      icon: { name: 'card', color: 'blue', background: 'bg-blue-200' },
      paymentText: t`Card`,
      subText: t`Direct deposit`,
      feeText: t`Fee will be added`,
    },
    vipps: {
      icon: { name: 'vipps', color: 'white', background: 'bg-vipps' },
      paymentText: t`Vipps`,
      subText: t`Direct deposit`,
      feeText: t`Fee will be added`,
    },
    mobilepay: {
      icon: { name: 'mobile-pay', color: 'white', background: 'bg-[#5179FF]' },
      paymentText: t`MobilePay`,
      subText: t`Direct deposit`,
      feeText: t`Fee will be added`,
    },
    applepay: {
      icon: { name: 'apple-pay', color: 'white', background: 'bg-[#5179FF]' },
      paymentText: t`Apple Pay`,
      subText: t`Direct deposit`,
      feeText: t`Fee will be added`,
    },
    paywithgoogle: {
      icon: { name: 'google-pay', color: 'white', background: 'bg-[#5179FF]' },
      paymentText: t`Google Pay`,
      subText: t`Direct deposit`,
      feeText: t`Fee will be added`,
    },
    googlepay: {
      icon: { name: 'google-pay', color: 'white', background: 'bg-[#5179FF]' },
      paymentText: t`Google Pay`,
      subText: t`Direct deposit`,
      feeText: t`Fee will be added`,
    },
    manual: {
      icon: { name: 'bank', color: 'positive', background: 'bg-green-200' },
      paymentText: t`Manual transfer`,
      subText: t`1-2 banking days`,
      feeText: '',
    },
  };

  return paymentMethod ? depositPaymentInfo[paymentMethod] : null;
}

export const basicBuyPaymentInfo: BuyMethodInfo = {
  scheme: {
    icon: { name: 'card', color: 'blue', background: 'bg-blue-200' },
    paymentText: t`Card`,
    subText: t`Direct purchase`,
    feeText: t`Card fee`,
  },
  vipps: {
    icon: { name: 'vipps', color: 'white', background: 'bg-vipps' },
    paymentText: t`Vipps`,
    subText: t`Direct purchase`,
    feeText: t`Vipps fee`,
    fastest: true,
  },
  mobilepay: {
    icon: { name: 'mobile-pay', color: 'white', background: 'bg-[#5179FF]' },
    paymentText: t`MobilePay`,
    subText: t`Direct purchase`,
    feeText: t`MobilePay fee`,
  },
  applepay: {
    icon: { name: 'apple-pay', color: 'white', background: 'bg-[#5179FF]' },
    paymentText: t`Apple Pay`,
    subText: t`Direct purchase`,
    feeText: t`Apple Pay fee`,
  },
  paywithgoogle: {
    icon: { name: 'google-pay', color: 'white', background: 'bg-[#5179FF]' },
    paymentText: t`Google Pay`,
    subText: t`Direct purchase`,
    feeText: t`Google Pay fee`,
  },
  googlepay: {
    icon: { name: 'google-pay', color: 'white', background: 'bg-[#5179FF]' },
    paymentText: t`Google Pay`,
    subText: t`Direct purchase`,
    feeText: t`Google Pay fee`,
  },
  manual: {
    icon: { name: 'bank', color: 'positive', background: 'bg-green-200' },
    paymentText: t`My balance`,
    subText: t`My balance`,
  },
};
