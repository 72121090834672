import { gql, useMutation } from '@apollo/client';

import { ToggleFavoriteMutation, ToggleFavoriteMutationVariables } from './../gqlTypes';

const mutation = gql`
  mutation ToggleFavorite($input: ToggleFavoriteInput!) {
    toggleFavorite(input: $input) {
      success
    }
  }
`;

export const useToggleFavorite = () =>
  useMutation<ToggleFavoriteMutation, ToggleFavoriteMutationVariables>(mutation, {
    refetchQueries: ['Metadata'],
  });
