import * as React from 'react';
import { SVGProps } from 'react';
const SvgFemale = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.9997 14.8572C15.3528 14.8572 18.0711 12.1389 18.0711 8.78572C18.0711 5.43257 15.3528 2.71429 11.9997 2.71429C8.64649 2.71429 5.92822 5.43257 5.92822 8.78572C5.92822 12.1389 8.64649 14.8572 11.9997 14.8572Z"
      stroke="black"
      strokeWidth={1.42857}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 14.8571V21.2857"
      stroke="black"
      strokeWidth={1.42857}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.14258 18.4285H14.8569"
      stroke="black"
      strokeWidth={1.42857}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgFemale;
