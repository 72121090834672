import { gql } from '@apollo/client';

export const UserFragment = gql`
  fragment UserFragment on Profile {
    id
    address
    city
    country
    email
    type
    tag
    uuid
    postalArea
    firstName
    lastName
    twoFactor
    nationality
    securityLevel
    hasVippsLinked
    features
    dateOfBirth
    transactionFee
    corporate {
      name
    }
    affiliateCode
    onboardingStatus
    hasVerifiedEmail
    mustVerifyEmail
    fiatMarket {
      id
      symbol
    }
    createdAt
    actions
  }
`;
