import { gql, useQuery } from '@apollo/client';

import {
  CryptoCurrency,
  CryptoWalletAddressQuery,
  CryptoWalletAddressQueryVariables,
  Symbol,
} from '../gqlTypes';

const QUERY = gql`
  query CryptoWalletAddress($symbol: Symbol!, $skip: Boolean) {
    currency: currency_v2(symbol: $symbol) {
      symbol
      hasTag
      walletAddress: walletAddress_v4(skip: $skip) {
        address
        destinationTag
      }
    }
  }
`;

export const useCryptoWalletAddress = (params: {
  symbol?: Symbol | CryptoCurrency;
  skip?: boolean;
}) => {
  return useQuery<CryptoWalletAddressQuery, CryptoWalletAddressQueryVariables>(QUERY, {
    fetchPolicy: 'cache-first',
    skip: !params.symbol,
    variables: {
      symbol: (params.symbol || Symbol.Nok) as Symbol, // Should not happend because we skip. Typescript not smart enough
      skip: params.skip,
    },
  });
};
