import { gql, useMutation } from '@apollo/client';

import { GenerateBankIdSessionMutation, GenerateBankIdSessionMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation GenerateBankIdSession($input: GenerateBankIdSessionInput!) {
    generateBankIdSession(input: $input) {
      url
      success
      message
      id
    }
  }
`;

export const useGenerateBankIdSession = () => {
  return useMutation<GenerateBankIdSessionMutation, GenerateBankIdSessionMutationVariables>(
    mutation,
  );
};
