import { gql, useQuery } from '@apollo/client';

import { TopMoversQuery } from '../gqlTypes';

const TOP_MOVERS_QUERY = gql`
  query TopMovers($includeOtc: Boolean) {
    markets(includeOtc: $includeOtc) {
      symbol
      lastPrice
      change
      baseCurrency {
        symbol
        name
      }
      quoteCurrency {
        symbol
        name
      }
    }
  }
`;

// TODO: remove param when everything has support fot OTC
export const useTopMovers = (includeOtc?: boolean) => {
  const { data, loading, ...rest } = useQuery<TopMoversQuery>(TOP_MOVERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      includeOtc: !!includeOtc,
    },
  });

  if (loading || !data) return { data, loading, ...rest };

  const sortByPriceChange = [...data.markets]?.sort(function (a, b) {
    if (!a?.change) return 0;
    if (!b?.change) return 0;

    return Math.abs(parseFloat(b.change)) - Math.abs(parseFloat(a.change));
  });

  return { data: { markets: sortByPriceChange }, loading, ...rest };
};
