// We have this just to have support in the future
export const languageCodes = [
  {
    sanity: 'en_US',
    locale: 'en',
    text: 'English',
  },
  {
    sanity: 'da',
    locale: 'dk',
    text: 'Dansk',
  },
  {
    sanity: 'nb_NO',
    locale: 'no',
    text: 'Norsk',
  },
];
