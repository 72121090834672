import * as React from 'react';
import { SVGProps } from 'react';
const SvgSparebank1SrbankenDark = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={20} cy={20} r={20} fill="white" />
    <path
      d="M4.46248 20.3818C3.77174 20.3818 3.22314 20.2226 3.12223 20.1899C3.06964 20.1686 3.04264 20.1259 3.04264 20.0776V20.0506L3.11228 19.6996C3.12223 19.647 3.1606 19.6299 3.20324 19.6299C3.21319 19.6299 3.22456 19.6299 3.24019 19.6356C3.35247 19.6569 3.89397 19.8118 4.4042 19.8118C4.84053 19.8118 5.22853 19.6839 5.22853 19.232C5.22853 18.78 4.82916 18.7274 4.31324 18.6521C3.73905 18.5668 3 18.4133 3 17.508C3 16.6026 3.69073 16.3354 4.5108 16.3354C5.13331 16.3354 5.64923 16.4847 5.76577 16.5159C5.82973 16.5316 5.861 16.5742 5.861 16.6282V16.6552L5.78709 17.0006C5.7814 17.0376 5.74445 17.0745 5.70181 17.0745C5.69613 17.0745 5.69187 17.0688 5.68618 17.0688C5.43035 17.0162 5.03666 16.9096 4.56907 16.9096C4.17538 16.9096 3.76037 16.9835 3.76037 17.4312C3.76037 17.8789 4.20665 17.9358 4.67993 18.0054C5.30244 18.1007 6.00881 18.1916 6.00881 19.1808C6.00881 20.1117 5.25412 20.3832 4.46105 20.3832L4.46248 20.3818Z"
      fill="#002776"
    />
    <path
      d="M11.2192 20.3193C11.033 20.3619 10.7771 20.3932 10.4801 20.3932C9.90591 20.3932 9.19812 20.3349 9.19812 19.4892C9.19812 18.8028 9.62876 18.7289 10.0537 18.6592L11.0003 18.5043V18.3338C11.0003 18.1845 10.9846 18.0779 10.851 18.0197C10.7658 17.9827 10.6379 17.9671 10.4474 17.9671C10.0864 17.9671 9.69272 18.0737 9.53212 18.1163C9.51648 18.1163 9.50511 18.122 9.49516 18.122C9.46816 18.122 9.44258 18.112 9.43689 18.0737L9.36299 17.7439V17.7226C9.36299 17.6743 9.39994 17.653 9.42694 17.643C9.55486 17.5947 10.0438 17.4625 10.5867 17.4625C10.9647 17.4625 11.2192 17.5151 11.3897 17.6118C11.6612 17.761 11.7137 18.0111 11.7137 18.2982V19.7664C11.7137 20.1658 11.5802 20.234 11.2192 20.3193ZM11.0017 18.9691L10.3422 19.0757C10.1248 19.1069 9.92154 19.1396 9.92154 19.485C9.92154 19.9 10.257 19.9313 10.5114 19.9313C10.7189 19.9313 10.8894 19.8943 11.0003 19.8573V18.9691H11.0017Z"
      fill="#002776"
    />
    <path
      d="M13.6111 18.0111C13.2871 18.0111 13.0099 18.0423 12.8607 18.0807V20.2453C12.8607 20.2936 12.8294 20.3249 12.7811 20.3249H12.2382C12.2069 20.3249 12.1472 20.2936 12.1472 20.2453V17.9471C12.1472 17.7396 12.2694 17.6756 12.5253 17.6017C12.7598 17.5278 13.1364 17.4738 13.4406 17.4681H13.5315C13.5808 17.4681 13.6182 17.4734 13.6438 17.4838C13.6651 17.4951 13.6921 17.515 13.6921 17.569V17.9144C13.6921 17.967 13.6864 18.0096 13.6125 18.0096L13.6111 18.0111Z"
      fill="#002776"
    />
    <path
      d="M16.3953 19.1325C16.3953 19.1908 16.3641 19.2547 16.283 19.2547H14.5022C14.5022 19.4992 14.5718 19.6328 14.7154 19.7436C14.8533 19.8559 15.0551 19.9028 15.3436 19.9028C15.6733 19.9028 15.9235 19.8232 16.0883 19.7749C16.0983 19.7692 16.104 19.7692 16.1153 19.7692C16.1466 19.7692 16.1736 19.7905 16.1892 19.8431L16.2589 20.1146C16.2589 20.1146 16.2646 20.1302 16.2646 20.1416C16.2646 20.1842 16.2376 20.2056 16.2006 20.2212C16.0201 20.2951 15.6577 20.3917 15.2384 20.3917C14.1966 20.3917 13.7859 19.9824 13.7859 18.9406C13.7859 17.8989 14.063 17.4625 15.0991 17.4625C15.6364 17.4625 15.9661 17.6004 16.158 17.8562C16.3385 18.095 16.3967 18.4361 16.3967 18.8767V19.1325H16.3953ZM15.6449 18.3394C15.5753 18.0467 15.4161 17.9514 15.102 17.9514C14.7452 17.9514 14.5974 18.058 14.5377 18.3238C14.5008 18.473 14.5008 18.6265 14.4951 18.8028L15.6861 18.7971C15.6861 18.6265 15.6804 18.483 15.6435 18.3394H15.6449Z"
      fill="#002776"
    />
    <path
      d="M22.4457 20.3193C22.2595 20.3619 22.0037 20.3932 21.7067 20.3932C21.1325 20.3932 20.4247 20.3349 20.4247 19.4892C20.4247 18.8028 20.8553 18.7289 21.2803 18.6592L22.2268 18.5043V18.3338C22.2268 18.1845 22.2112 18.0779 22.0776 18.0197C21.9923 17.9827 21.8644 17.9671 21.674 17.9671C21.3115 17.9671 20.9193 18.0737 20.7587 18.1163C20.743 18.1163 20.7317 18.122 20.7217 18.122C20.6947 18.122 20.6691 18.112 20.6635 18.0737L20.5895 17.7439V17.7226C20.5895 17.6743 20.6265 17.653 20.6535 17.643C20.7814 17.5947 21.2703 17.4625 21.8133 17.4625C22.1913 17.4625 22.4457 17.5151 22.6163 17.6118C22.8877 17.761 22.9403 18.0111 22.9403 18.2982V19.7664C22.9403 20.1658 22.8067 20.234 22.4457 20.3193V20.3193ZM22.2283 18.9691L21.5688 19.0757C21.3513 19.1069 21.1481 19.1396 21.1481 19.485C21.1481 19.9 21.4835 19.9313 21.7379 19.9313C21.9454 19.9313 22.116 19.8943 22.2268 19.8573V18.9691H22.2283Z"
      fill="#002776"
    />
    <path
      d="M25.7842 20.3235H25.2371C25.1887 20.3235 25.1518 20.2923 25.1518 20.2439V18.3664C25.1518 18.058 25.0352 17.9514 24.7524 17.9514C24.434 17.9514 24.0873 18.0424 24.0873 18.0424V20.2439C24.0873 20.2923 24.056 20.3235 24.0077 20.3235H23.4647C23.4122 20.3235 23.3738 20.2923 23.3738 20.2439V17.9514C23.3738 17.7496 23.4747 17.68 23.7732 17.6004C24.0446 17.5265 24.4909 17.4625 24.832 17.4625C25.4971 17.4625 25.8638 17.6331 25.8638 18.2712V20.2439C25.8638 20.2923 25.8326 20.3235 25.7842 20.3235V20.3235Z"
      fill="#002776"
    />
    <path
      d="M28.8669 20.2766C28.8669 20.2979 28.8456 20.3249 28.8143 20.3249H28.1023C28.0753 20.3249 28.0497 20.3249 28.0227 20.2937L27.1983 19.1709H27.0178V20.2454C27.0178 20.2937 26.9908 20.3249 26.9382 20.3249H26.3911C26.3427 20.3249 26.3058 20.2937 26.3058 20.2454V16.5273C26.3058 16.4634 26.3328 16.4321 26.3967 16.4151L26.9183 16.3241H26.9397C26.988 16.3241 27.0193 16.3554 27.0193 16.4151V18.6905H27.1998L28.0397 17.5691C28.061 17.5379 28.0824 17.5322 28.1094 17.5322L28.7575 17.5478C28.7944 17.5478 28.81 17.5748 28.81 17.5961C28.81 17.6061 28.8044 17.6118 28.8001 17.6231L27.7953 18.8895L28.8584 20.251C28.8584 20.251 28.8683 20.2667 28.8683 20.278L28.8669 20.2766Z"
      fill="#002776"
    />
    <path
      d="M7.81522 17.4625C7.45849 17.4625 7.04917 17.5208 6.7308 17.633C6.49203 17.7069 6.39539 17.7865 6.39539 18.0637V21.1194C6.39539 21.172 6.4366 21.216 6.51335 21.2018C6.5901 21.1876 7.0179 21.1094 7.0179 21.1094C7.08185 21.0995 7.10886 21.0668 7.10886 21.0028V20.3107C7.29789 20.3562 7.53524 20.3917 7.79959 20.3917C8.78879 20.3917 8.97497 19.7692 8.97497 18.9292C8.97497 18.0893 8.84137 17.4611 7.81522 17.4611V17.4625ZM7.61341 19.9028C7.32631 19.9028 7.10886 19.8388 7.10886 19.8388V18.048C7.10886 18.048 7.42296 17.9628 7.66741 17.9628C8.07105 17.9628 8.24729 18.0537 8.24729 18.915C8.24729 19.7237 8.09805 19.9042 7.61483 19.9042L7.61341 19.9028Z"
      fill="#002776"
    />
    <path
      d="M18.732 20.3235H16.9611C16.9128 20.3235 16.8701 20.2865 16.8701 20.2382V16.479C16.8701 16.4306 16.9128 16.3937 16.9611 16.3937H18.7377C19.6373 16.3937 20.004 16.7461 20.004 17.3843C20.004 17.9485 19.6913 18.1504 19.2067 18.2882C19.7453 18.3763 20.1376 18.6691 20.1376 19.2959C20.1376 20.0079 19.6373 20.322 18.7334 20.322L18.732 20.3235ZM18.6623 16.9409H17.6362V18.0793L18.6254 18.0367C19.029 18.021 19.2365 17.8775 19.2365 17.4724C19.2365 17.1 19.056 16.9409 18.6623 16.9409V16.9409ZM18.6353 18.5952L17.6362 18.5639V19.7763H18.6353C19.1726 19.7763 19.3474 19.5418 19.3474 19.2177C19.3474 18.7814 19.2038 18.6108 18.6353 18.5952Z"
      fill="#002776"
    />
    <path
      d="M19.8647 23.4374H19.5946C19.5691 23.4374 19.5534 23.4261 19.5534 23.4033L19.5293 23.004C19.5179 22.8064 19.3644 22.7581 19.1526 22.751L18.7774 22.741V23.4005C18.7774 23.4218 18.7604 23.436 18.7362 23.436H18.4718C18.4505 23.436 18.4335 23.4218 18.4335 23.4005V21.7106C18.4335 21.6893 18.4519 21.6722 18.4747 21.6722H19.282C19.6444 21.6722 19.8675 21.8044 19.8675 22.1739C19.8675 22.4383 19.6828 22.5648 19.4681 22.616C19.6956 22.6543 19.8675 22.7254 19.8832 23.0693L19.8974 23.3991C19.8974 23.4247 19.8888 23.4374 19.8647 23.4374V23.4374ZM19.2265 21.9195H18.7774V22.5236L19.1612 22.5065C19.3786 22.4966 19.5165 22.4468 19.5165 22.218C19.5165 22.0148 19.4397 21.9195 19.2251 21.9195H19.2265Z"
      fill="#002776"
    />
    <path
      d="M22.3475 23.4374H21.5531C21.5531 23.4147 21.5346 23.3962 21.5118 23.3962V21.7106C21.5332 21.7106 21.5502 21.6936 21.5502 21.6722H22.3504C22.754 21.6722 22.9189 21.83 22.9189 22.1171C22.9189 22.3701 22.7782 22.461 22.5607 22.5236C22.8023 22.5634 22.9786 22.6941 22.9786 22.9755C22.9786 23.2953 22.754 23.436 22.349 23.436L22.3475 23.4374ZM22.3163 21.9195H21.8558V22.4298L22.2992 22.4113C22.4811 22.4042 22.5735 22.3402 22.5735 22.1583C22.5735 21.9906 22.4925 21.9195 22.3163 21.9195V21.9195ZM22.3049 22.6614L21.8558 22.6472V23.1916H22.3049C22.5465 23.1916 22.6247 23.0864 22.6247 22.9414C22.6247 22.7453 22.5607 22.6685 22.3049 22.6614Z"
      fill="#002776"
    />
    <path
      d="M17.2452 23.463C16.9354 23.463 16.6895 23.3933 16.6441 23.3777C16.6185 23.3678 16.6014 23.3493 16.6085 23.3152L16.6398 23.1574C16.6441 23.129 16.6682 23.1233 16.6966 23.129C16.7464 23.1389 16.9908 23.2071 17.2197 23.2071C17.4158 23.2071 17.5892 23.1503 17.5892 22.9471C17.5892 22.7438 17.4101 22.7197 17.1784 22.687C16.9212 22.6486 16.5886 22.579 16.5886 22.1739C16.5886 21.7688 16.8985 21.6466 17.2666 21.6466C17.5466 21.6466 17.7782 21.7106 17.8294 21.7276C17.8578 21.7376 17.8791 21.7589 17.872 21.7902L17.8379 21.9451C17.8308 21.9763 17.8123 21.9806 17.7853 21.9763C17.6702 21.9522 17.5011 21.9053 17.2907 21.9053C17.1145 21.9053 16.9283 21.938 16.9283 22.1398C16.9283 22.3416 17.1287 22.3672 17.3419 22.397C17.6219 22.4397 17.9388 22.4809 17.9388 22.9243C17.9388 23.3422 17.5991 23.4644 17.2438 23.4644L17.2452 23.463Z"
      fill="#002776"
    />
    <path
      d="M20.9931 22.6728H20.3536C20.3323 22.6728 20.3038 22.6586 20.3038 22.6301V22.4823C20.3038 22.4582 20.3252 22.4397 20.3536 22.4397H20.9931C21.0216 22.4397 21.0415 22.4582 21.0415 22.4823V22.6301C21.0415 22.6571 21.0173 22.6728 20.9931 22.6728Z"
      fill="#002776"
    />
    <path
      d="M24.9996 23.4091C24.9996 23.4275 24.9883 23.4375 24.9684 23.4375H24.687C24.6656 23.4375 24.6514 23.4261 24.6443 23.4034L24.4468 22.778H23.8143L23.6182 23.4091C23.6139 23.4261 23.594 23.4375 23.577 23.4375H23.2956C23.2785 23.4375 23.2643 23.4275 23.2643 23.4119C23.2643 23.4091 23.2643 23.4048 23.2671 23.3949L23.8385 21.705C23.8427 21.6879 23.8641 21.6737 23.8811 21.6737H24.3928C24.4098 21.6737 24.4311 21.6879 24.4354 21.705L24.9982 23.3934C25.0011 23.4005 25.0011 23.4076 25.0011 23.4105L24.9996 23.4091ZM24.1782 21.9139H24.0758L23.8854 22.525H24.3743L24.1782 21.9139Z"
      fill="#002776"
    />
    <path
      d="M26.9695 23.4375H26.4223C26.401 23.4375 26.384 23.419 26.3769 23.3991L25.7899 21.9352H25.7117L25.733 23.402C25.733 23.4233 25.716 23.4375 25.6918 23.4375H25.4289C25.4076 23.4375 25.3905 23.4233 25.3905 23.402V21.7092C25.3905 21.6908 25.409 21.6737 25.4317 21.6737H26.0045C26.0258 21.6737 26.0457 21.6879 26.0528 21.7078L26.6526 23.2314H26.6867L26.6725 21.7135C26.6725 21.6822 26.6938 21.6751 26.7208 21.6751H26.9638C26.9923 21.6751 27.0122 21.6851 27.0122 21.7135V23.4034C27.0122 23.4247 26.9951 23.4389 26.9709 23.4389L26.9695 23.4375Z"
      fill="#002776"
    />
    <path
      d="M29.0417 23.3877C29.0417 23.3877 29.0459 23.399 29.0459 23.4047C29.0459 23.4218 29.0317 23.4388 29.0204 23.4388H28.665C28.648 23.4388 28.6295 23.4289 28.6224 23.4204L28.0368 22.6799H27.9317V23.4033C27.9317 23.4246 27.9146 23.4388 27.8905 23.4388H27.6247C27.6034 23.4388 27.5863 23.4246 27.5863 23.4033V21.7106C27.5863 21.6893 27.6034 21.675 27.6247 21.675H27.8905C27.9146 21.675 27.9317 21.6893 27.9317 21.7106V22.4155H28.0368L28.5997 21.6949C28.6068 21.685 28.6181 21.6765 28.638 21.6765L28.9649 21.6807C28.9791 21.6807 28.9933 21.6978 28.9933 21.7148C28.9933 21.7191 28.9905 21.7262 28.9862 21.7319L28.3225 22.5392L29.0417 23.3919V23.3877Z"
      fill="#002776"
    />
    <path
      d="M33.7646 16C33.3481 16 32.9459 16.0654 32.5693 16.1848C34.1568 15.8408 35.7302 16.4946 36.2972 17.8334C36.9524 19.3812 36.0144 21.2587 34.2023 22.0247C32.5764 22.7126 30.8012 22.2734 29.9797 21.0583C30.4601 22.6956 31.9723 23.8908 33.7646 23.8908C35.9433 23.8908 37.71 22.1242 37.71 19.9454C37.71 17.7666 35.9433 16 33.7646 16Z"
      fill="#E60000"
    />
    <path
      d="M34.2009 22.0247C36.013 21.2586 36.9511 19.3811 36.2959 17.8334C35.7288 16.4945 34.1554 15.8408 32.5679 16.1847C30.9732 16.6907 29.8177 18.183 29.8177 19.9454C29.8177 20.3319 29.8746 20.7057 29.9784 21.0582C30.7998 22.2734 32.5764 22.7126 34.2009 22.0247V22.0247Z"
      fill="#AF0000"
    />
    <path
      d="M34.7751 21.729V17.677C34.7751 17.596 34.7097 17.5306 34.6287 17.5306H33.712C33.6466 17.5306 33.6096 17.5449 33.5514 17.5676L32.3675 18.1773C32.295 18.2072 32.2651 18.2498 32.2651 18.3237V18.9874C32.2651 19.0656 32.3291 19.1281 32.4058 19.1281H33.1634V22.3132C33.5087 22.2649 33.8584 22.1696 34.2009 22.0247C34.4027 21.9394 34.5946 21.8399 34.7751 21.7276V21.729Z"
      fill="white"
    />
  </svg>
);
export default SvgSparebank1SrbankenDark;
