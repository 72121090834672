import { gql, useQuery } from '@apollo/client';

import { ChristmasCalendarTicketsQuery } from '../gqlTypes';

const query = gql`
  query ChristmasCalendarTickets {
    calendar {
      ticketsAccumulated
    }
  }
`;

export const useChristmasCalendarTickets = () => {
  return useQuery<ChristmasCalendarTicketsQuery>(query);
};
