import { gql, useMutation } from '@apollo/client';

import { SubmitTranslationMutation, SubmitTranslationMutationVariables } from './../gqlTypes';

const MUTATION = gql`
  mutation SubmitTranslation($input: TranslationInput!) {
    submitTranslation(input: $input) {
      success
      message
    }
  }
`;

export const useSubmitTranslation = () => {
  return useMutation<SubmitTranslationMutation, SubmitTranslationMutationVariables>(MUTATION);
};
