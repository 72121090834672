import { gql, useMutation } from '@apollo/client';

import { AdyenSubmitPaymentMutation, AdyenSubmitPaymentMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation AdyenSubmitPayment($input: AdyenSubmitPaymentInput!) {
    adyenSubmitPayment(input: $input) {
      success
      url
      message
    }
  }
`;

export const useAdyenSubmitPayment = () =>
  useMutation<AdyenSubmitPaymentMutation, AdyenSubmitPaymentMutationVariables>(mutation);
