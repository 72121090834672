import * as React from 'react';
import { SVGProps } from 'react';
const SvgNoTrading = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3 3L21 21" stroke="#DA2D43" strokeWidth={1.5} strokeLinecap="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0633 20.5633L16.9862 19.4862C15.6621 20.372 14.092 20.9164 12.4035 20.9909C11.2065 21.0434 10.0583 20.8604 9 20.4854V22.0627C10.0673 22.3814 11.205 22.5359 12.3833 22.4932C14.4926 22.4171 16.4464 21.7128 18.0633 20.5633ZM19.4861 16.9861C20.3817 15.6475 20.9281 14.0579 20.9933 12.3502C21.0855 9.93668 20.2208 7.72268 18.75 6.05468V8.99993H17.25V4.49993C17.25 4.08593 17.586 3.74993 18 3.74993H22.5V5.25068H20.0355C21.6293 7.14368 22.5675 9.60593 22.4963 12.2849C22.4391 14.4298 21.7312 16.4199 20.5631 18.0631L19.4861 16.9861ZM16.1762 13.6762L14.9642 12.4642C14.9878 12.3128 15 12.1578 15 11.9999C15 10.3454 13.6545 8.99993 12 8.99993C11.8421 8.99993 11.6871 9.01218 11.5358 9.03578L10.3238 7.82377C10.8421 7.61493 11.4079 7.49993 12 7.49993C14.481 7.49993 16.5 9.51893 16.5 11.9999C16.5 12.592 16.385 13.1578 16.1762 13.6762ZM7.82379 10.3238C7.61498 10.8421 7.5 11.4079 7.5 11.9999C7.5 14.4809 9.519 16.4999 12 16.4999C12.5921 16.4999 13.1578 16.3849 13.6761 16.1761L12.4641 14.9641C12.3128 14.9877 12.1578 14.9999 12 14.9999C10.3455 14.9999 9 13.6544 9 11.9999C9 11.8421 9.01224 11.6871 9.03583 11.5358L7.82379 10.3238ZM7.01362 4.51362C8.3377 3.62782 9.90791 3.08344 11.5965 3.00891C12.7935 2.95641 13.9418 3.13941 15 3.51441V1.95591H15.0623C13.9928 1.62891 12.8513 1.46841 11.6678 1.50516C9.53962 1.57153 7.56714 2.27783 5.93689 3.43689L7.01362 4.51362ZM3.43698 5.93698L4.51367 7.01367C3.61826 8.3522 3.07193 9.94178 3.00675 11.6497C2.9145 14.0632 3.77925 16.2772 5.25 17.9452V14.9999H6.75V19.4999C6.75 19.9139 6.414 20.2499 6 20.2499H5.5845V20.3062C5.5605 20.2874 5.53725 20.2687 5.514 20.2499H1.5V18.7499H3.96525C2.37 16.8539 1.43025 14.3879 1.5045 11.7052C1.56344 9.56353 2.27096 7.57724 3.43698 5.93698Z"
      fill="#12123B"
    />
  </svg>
);
export default SvgNoTrading;
