import * as React from 'react';
import { SVGProps } from 'react';
const SvgSparebank1Nordnorge = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={20} cy={20} r={20} fill="#002776" />
    <path
      d="M4.46248 20.3818C3.77174 20.3818 3.22314 20.2226 3.12223 20.1899C3.06964 20.1686 3.04264 20.1259 3.04264 20.0776V20.0506L3.11228 19.6996C3.12223 19.647 3.1606 19.6299 3.20324 19.6299C3.21319 19.6299 3.22456 19.6299 3.24019 19.6356C3.35247 19.6569 3.89397 19.8118 4.4042 19.8118C4.84053 19.8118 5.22853 19.6839 5.22853 19.232C5.22853 18.78 4.82916 18.7274 4.31324 18.6521C3.73905 18.5668 3 18.4133 3 17.508C3 16.6026 3.69073 16.3354 4.5108 16.3354C5.13331 16.3354 5.64923 16.4847 5.76577 16.5159C5.82973 16.5316 5.861 16.5742 5.861 16.6282V16.6552L5.78709 17.0006C5.7814 17.0376 5.74445 17.0745 5.70181 17.0745C5.69613 17.0745 5.69187 17.0688 5.68618 17.0688C5.43035 17.0162 5.03666 16.9096 4.56907 16.9096C4.17538 16.9096 3.76037 16.9835 3.76037 17.4312C3.76037 17.8789 4.20665 17.9358 4.67993 18.0054C5.30244 18.1007 6.00881 18.1916 6.00881 19.1808C6.00881 20.1117 5.25412 20.3832 4.46105 20.3832L4.46248 20.3818Z"
      fill="white"
    />
    <path
      d="M11.2192 20.3193C11.033 20.3619 10.7771 20.3932 10.4801 20.3932C9.90591 20.3932 9.19812 20.3349 9.19812 19.4892C9.19812 18.8028 9.62876 18.7289 10.0537 18.6592L11.0003 18.5043V18.3338C11.0003 18.1845 10.9846 18.0779 10.851 18.0197C10.7658 17.9827 10.6379 17.9671 10.4474 17.9671C10.0864 17.9671 9.69272 18.0737 9.53212 18.1163C9.51648 18.1163 9.50511 18.122 9.49516 18.122C9.46816 18.122 9.44258 18.112 9.43689 18.0737L9.36299 17.7439V17.7226C9.36299 17.6743 9.39994 17.653 9.42694 17.643C9.55486 17.5947 10.0438 17.4625 10.5867 17.4625C10.9647 17.4625 11.2192 17.5151 11.3897 17.6118C11.6612 17.761 11.7137 18.0111 11.7137 18.2982V19.7664C11.7137 20.1658 11.5802 20.234 11.2192 20.3193ZM11.0017 18.9691L10.3422 19.0757C10.1248 19.1069 9.92154 19.1396 9.92154 19.485C9.92154 19.9 10.257 19.9313 10.5114 19.9313C10.7189 19.9313 10.8894 19.8943 11.0003 19.8573V18.9691H11.0017Z"
      fill="white"
    />
    <path
      d="M13.6111 18.0111C13.2871 18.0111 13.0099 18.0423 12.8607 18.0807V20.2453C12.8607 20.2936 12.8294 20.3249 12.7811 20.3249H12.2382C12.2069 20.3249 12.1472 20.2936 12.1472 20.2453V17.9471C12.1472 17.7396 12.2694 17.6756 12.5253 17.6017C12.7598 17.5278 13.1364 17.4738 13.4406 17.4681H13.5315C13.5808 17.4681 13.6182 17.4734 13.6438 17.4838C13.6651 17.4951 13.6921 17.515 13.6921 17.569V17.9144C13.6921 17.967 13.6864 18.0096 13.6125 18.0096L13.6111 18.0111Z"
      fill="white"
    />
    <path
      d="M16.3953 19.1325C16.3953 19.1908 16.3641 19.2547 16.283 19.2547H14.5022C14.5022 19.4992 14.5718 19.6328 14.7154 19.7436C14.8533 19.8559 15.0551 19.9028 15.3436 19.9028C15.6733 19.9028 15.9235 19.8232 16.0883 19.7749C16.0983 19.7692 16.104 19.7692 16.1153 19.7692C16.1466 19.7692 16.1736 19.7905 16.1892 19.8431L16.2589 20.1146C16.2589 20.1146 16.2646 20.1302 16.2646 20.1416C16.2646 20.1842 16.2376 20.2056 16.2006 20.2212C16.0201 20.2951 15.6577 20.3917 15.2384 20.3917C14.1966 20.3917 13.7859 19.9824 13.7859 18.9406C13.7859 17.8989 14.063 17.4625 15.0991 17.4625C15.6364 17.4625 15.9661 17.6004 16.158 17.8562C16.3385 18.095 16.3967 18.4361 16.3967 18.8767V19.1325H16.3953ZM15.6449 18.3394C15.5753 18.0467 15.4161 17.9514 15.102 17.9514C14.7452 17.9514 14.5974 18.058 14.5377 18.3238C14.5008 18.473 14.5008 18.6265 14.4951 18.8028L15.6861 18.7971C15.6861 18.6265 15.6804 18.483 15.6435 18.3394H15.6449Z"
      fill="white"
    />
    <path
      d="M22.4457 20.3193C22.2595 20.3619 22.0037 20.3932 21.7067 20.3932C21.1325 20.3932 20.4247 20.3349 20.4247 19.4892C20.4247 18.8028 20.8553 18.7289 21.2803 18.6592L22.2268 18.5043V18.3338C22.2268 18.1845 22.2112 18.0779 22.0776 18.0197C21.9923 17.9827 21.8644 17.9671 21.674 17.9671C21.3115 17.9671 20.9193 18.0737 20.7587 18.1163C20.743 18.1163 20.7317 18.122 20.7217 18.122C20.6947 18.122 20.6691 18.112 20.6635 18.0737L20.5895 17.7439V17.7226C20.5895 17.6743 20.6265 17.653 20.6535 17.643C20.7814 17.5947 21.2703 17.4625 21.8133 17.4625C22.1913 17.4625 22.4457 17.5151 22.6163 17.6118C22.8877 17.761 22.9403 18.0111 22.9403 18.2982V19.7664C22.9403 20.1658 22.8067 20.234 22.4457 20.3193ZM22.2283 18.9691L21.5688 19.0757C21.3513 19.1069 21.1481 19.1396 21.1481 19.485C21.1481 19.9 21.4835 19.9313 21.7379 19.9313C21.9454 19.9313 22.116 19.8943 22.2268 19.8573V18.9691H22.2283Z"
      fill="white"
    />
    <path
      d="M25.7842 20.3235H25.2371C25.1887 20.3235 25.1518 20.2923 25.1518 20.2439V18.3664C25.1518 18.058 25.0352 17.9514 24.7524 17.9514C24.434 17.9514 24.0872 18.0424 24.0872 18.0424V20.2439C24.0872 20.2923 24.056 20.3235 24.0077 20.3235H23.4647C23.4122 20.3235 23.3738 20.2923 23.3738 20.2439V17.9514C23.3738 17.7496 23.4747 17.68 23.7732 17.6004C24.0446 17.5265 24.4909 17.4625 24.832 17.4625C25.4971 17.4625 25.8638 17.6331 25.8638 18.2712V20.2439C25.8638 20.2923 25.8326 20.3235 25.7842 20.3235Z"
      fill="white"
    />
    <path
      d="M28.8669 20.2766C28.8669 20.2979 28.8456 20.3249 28.8143 20.3249H28.1023C28.0753 20.3249 28.0497 20.3249 28.0227 20.2937L27.1983 19.1709H27.0178V20.2454C27.0178 20.2937 26.9908 20.3249 26.9382 20.3249H26.3911C26.3427 20.3249 26.3058 20.2937 26.3058 20.2454V16.5273C26.3058 16.4634 26.3328 16.4321 26.3967 16.4151L26.9183 16.3241H26.9397C26.988 16.3241 27.0193 16.3554 27.0193 16.4151V18.6905H27.1998L28.0397 17.5691C28.061 17.5379 28.0824 17.5322 28.1094 17.5322L28.7575 17.5478C28.7944 17.5478 28.81 17.5748 28.81 17.5961C28.81 17.6061 28.8044 17.6118 28.8001 17.6231L27.7953 18.8895L28.8584 20.251C28.8584 20.251 28.8683 20.2667 28.8683 20.278L28.8669 20.2766Z"
      fill="white"
    />
    <path
      d="M7.81522 17.4625C7.45849 17.4625 7.04917 17.5208 6.7308 17.633C6.49203 17.7069 6.39539 17.7865 6.39539 18.0637V21.1194C6.39539 21.172 6.4366 21.216 6.51335 21.2018C6.5901 21.1876 7.0179 21.1094 7.0179 21.1094C7.08185 21.0995 7.10886 21.0668 7.10886 21.0028V20.3107C7.29789 20.3562 7.53524 20.3917 7.79959 20.3917C8.78879 20.3917 8.97497 19.7692 8.97497 18.9292C8.97497 18.0893 8.84137 17.4611 7.81522 17.4611V17.4625ZM7.61341 19.9028C7.32631 19.9028 7.10886 19.8388 7.10886 19.8388V18.048C7.10886 18.048 7.42296 17.9628 7.66741 17.9628C8.07105 17.9628 8.24729 18.0537 8.24729 18.915C8.24729 19.7237 8.09805 19.9042 7.61483 19.9042L7.61341 19.9028Z"
      fill="white"
    />
    <path
      d="M18.732 20.3235H16.9611C16.9128 20.3235 16.8701 20.2865 16.8701 20.2382V16.479C16.8701 16.4306 16.9128 16.3937 16.9611 16.3937H18.7377C19.6373 16.3937 20.004 16.7461 20.004 17.3843C20.004 17.9485 19.6913 18.1504 19.2067 18.2882C19.7453 18.3763 20.1376 18.6691 20.1376 19.2959C20.1376 20.0079 19.6373 20.322 18.7334 20.322L18.732 20.3235ZM18.6623 16.9409H17.6362V18.0793L18.6254 18.0367C19.029 18.021 19.2365 17.8775 19.2365 17.4724C19.2365 17.1 19.056 16.9409 18.6623 16.9409ZM18.6353 18.5952L17.6362 18.5639V19.7763H18.6353C19.1726 19.7763 19.3474 19.5418 19.3474 19.2177C19.3474 18.7814 19.2038 18.6108 18.6353 18.5952Z"
      fill="white"
    />
    <path
      d="M33.7646 16C33.3481 16 32.9459 16.0654 32.5693 16.1848C34.1568 15.8408 35.7302 16.4946 36.2972 17.8334C36.9524 19.3812 36.0144 21.2587 34.2023 22.0247C32.5764 22.7126 30.8012 22.2734 29.9797 21.0583C30.4601 22.6956 31.9723 23.8908 33.7646 23.8908C35.9433 23.8908 37.71 22.1242 37.71 19.9454C37.71 17.7666 35.9433 16 33.7646 16Z"
      fill="#E60000"
    />
    <path
      d="M34.2009 22.0247C36.013 21.2586 36.9511 19.3811 36.2959 17.8334C35.7288 16.4945 34.1554 15.8408 32.5679 16.1847C30.9732 16.6907 29.8177 18.183 29.8177 19.9454C29.8177 20.3319 29.8746 20.7057 29.9784 21.0582C30.7998 22.2734 32.5764 22.7126 34.2009 22.0247Z"
      fill="#AF0000"
    />
    <path
      d="M34.7751 21.729V17.677C34.7751 17.596 34.7097 17.5306 34.6287 17.5306H33.712C33.6466 17.5306 33.6096 17.5449 33.5514 17.5676L32.3675 18.1773C32.295 18.2072 32.2651 18.2498 32.2651 18.3237V18.9874C32.2651 19.0656 32.3291 19.1281 32.4058 19.1281H33.1634V22.3132C33.5087 22.2649 33.8584 22.1696 34.2009 22.0247C34.4027 21.9394 34.5946 21.8399 34.7751 21.7276V21.729Z"
      fill="white"
    />
    <path
      d="M15.8155 23.48H15.5454C15.5199 23.48 15.5042 23.4687 15.5042 23.4459L15.4801 23.0466C15.4687 22.849 15.3152 22.8007 15.1034 22.7936L14.7282 22.7836V23.4431C14.7282 23.4644 14.7112 23.4786 14.687 23.4786H14.4227C14.4013 23.4786 14.3843 23.4644 14.3843 23.4431V21.7532C14.3843 21.7319 14.4028 21.7148 14.4255 21.7148H15.2328C15.5952 21.7148 15.8183 21.847 15.8183 22.2165C15.8183 22.4809 15.6336 22.6074 15.419 22.6586C15.6464 22.6969 15.8183 22.768 15.834 23.1119L15.8482 23.4417C15.8482 23.4673 15.8396 23.48 15.8155 23.48ZM15.1773 21.9621H14.7282V22.5662L15.112 22.5491C15.3294 22.5392 15.4673 22.4894 15.4673 22.2606C15.4673 22.0574 15.3905 21.9621 15.1759 21.9621H15.1773Z"
      fill="white"
    />
    <path
      d="M25.1503 23.48H24.8803C24.8547 23.48 24.8391 23.4687 24.8391 23.4459L24.8149 23.0466C24.8035 22.849 24.65 22.8007 24.4383 22.7936L24.0631 22.7836V23.4431C24.0631 23.4644 24.046 23.4786 24.0218 23.4786H23.7575C23.7362 23.4786 23.7191 23.4644 23.7191 23.4431V21.7532C23.7191 21.7319 23.7376 21.7148 23.7603 21.7148H24.5676C24.93 21.7148 25.1532 21.847 25.1532 22.2165C25.1532 22.4809 24.9684 22.6074 24.7538 22.6586C24.9812 22.6969 25.1532 22.768 25.1688 23.1119L25.183 23.4417C25.183 23.4673 25.1745 23.48 25.1503 23.48ZM24.5122 21.9621H24.0631V22.5662L24.4468 22.5491C24.6643 22.5392 24.8021 22.4894 24.8021 22.2606C24.8021 22.0574 24.7254 21.9621 24.5108 21.9621H24.5122Z"
      fill="white"
    />
    <path
      d="M11.6853 23.4801H11.1382C11.1168 23.4801 11.0998 23.4616 11.0927 23.4417L10.5057 21.9778H10.4275L10.4488 23.4446C10.4488 23.4659 10.4318 23.4801 10.4076 23.4801H10.1447C10.1234 23.4801 10.1063 23.4659 10.1063 23.4446V21.7518C10.1063 21.7334 10.1248 21.7163 10.1475 21.7163H10.7203C10.7416 21.7163 10.7615 21.7305 10.7686 21.7504L11.3684 23.274H11.4025L11.3883 21.7561C11.3883 21.7248 11.4096 21.7177 11.4366 21.7177H11.6797C11.7081 21.7177 11.728 21.7277 11.728 21.7561V23.446C11.728 23.4673 11.7109 23.4815 11.6868 23.4815L11.6853 23.4801Z"
      fill="white"
    />
    <path
      d="M13.0554 23.5114C12.3633 23.5114 12.2197 23.1461 12.2197 22.5989C12.2197 22.0517 12.3633 21.6851 13.0554 21.6851C13.7476 21.6851 13.8911 22.0503 13.8911 22.5989C13.8911 23.1475 13.7476 23.5114 13.0554 23.5114ZM13.0554 21.9352C12.6205 21.9352 12.5736 22.164 12.5736 22.5961C12.5736 23.0281 12.6191 23.2598 13.054 23.2598C13.4889 23.2598 13.5387 23.0281 13.5387 22.5961C13.5387 22.164 13.4903 21.9352 13.0568 21.9352H13.0554Z"
      fill="white"
    />
    <path
      d="M17.1174 23.48H16.401C16.3797 23.48 16.3627 23.463 16.3627 23.4417V21.7532C16.3627 21.7319 16.3797 21.7148 16.401 21.7148H17.1174C17.7029 21.7148 17.9275 21.9394 17.9275 22.596C17.9275 23.2526 17.7029 23.48 17.1174 23.48ZM17.0761 21.9621H16.7066V23.2327H17.0761C17.4343 23.2327 17.575 23.1304 17.575 22.5946C17.575 22.0588 17.4343 21.9621 17.0761 21.9621Z"
      fill="white"
    />
    <path
      d="M19.0034 22.7155H18.3638C18.3425 22.7155 18.3141 22.7013 18.3141 22.6729V22.5251C18.3141 22.5009 18.3354 22.4824 18.3638 22.4824H19.0034C19.0318 22.4824 19.0517 22.5009 19.0517 22.5251V22.6729C19.0517 22.6999 19.0276 22.7155 19.0034 22.7155Z"
      fill="white"
    />
    <path
      d="M21.0187 23.4801H20.4715C20.4502 23.4801 20.4332 23.4616 20.4261 23.4417L19.8391 21.9778H19.7609L19.7822 23.4446C19.7822 23.4659 19.7652 23.4801 19.741 23.4801H19.4781C19.4568 23.4801 19.4397 23.4659 19.4397 23.4446V21.7518C19.4397 21.7334 19.4582 21.7163 19.4809 21.7163H20.0537C20.075 21.7163 20.0949 21.7305 20.102 21.7504L20.7018 23.274H20.7359L20.7217 21.7561C20.7217 21.7248 20.743 21.7177 20.77 21.7177H21.013C21.0415 21.7177 21.0614 21.7277 21.0614 21.7561V23.446C21.0614 23.4673 21.0443 23.4815 21.0201 23.4815L21.0187 23.4801Z"
      fill="white"
    />
    <path
      d="M22.3888 23.5114C21.6966 23.5114 21.5531 23.1461 21.5531 22.5989C21.5531 22.0517 21.6966 21.6851 22.3888 21.6851C23.081 21.6851 23.2245 22.0503 23.2245 22.5989C23.2245 23.1475 23.081 23.5114 22.3888 23.5114ZM22.3888 21.9352C21.9539 21.9352 21.907 22.164 21.907 22.5961C21.907 23.0281 21.9525 23.2598 22.3874 23.2598C22.8223 23.2598 22.872 23.0281 22.872 22.5961C22.872 22.164 22.8237 21.9352 22.3902 21.9352H22.3888Z"
      fill="white"
    />
    <path
      d="M27.1359 21.9977C27.1316 22.019 27.1174 22.0261 27.1004 22.0261C27.0933 22.0261 27.0862 22.0261 27.0762 22.0232C26.9995 22.0048 26.8062 21.9451 26.5489 21.9451C26.141 21.9451 25.9875 22.0886 25.9875 22.606C25.9875 23.1233 26.1339 23.2626 26.5461 23.2626C26.6768 23.2626 26.7991 23.2384 26.8261 23.2342V22.7282L26.539 22.6927C26.5205 22.6898 26.5063 22.6784 26.5063 22.6642V22.4809C26.5063 22.4638 26.5205 22.4525 26.539 22.4525H27.126C27.1473 22.4525 27.1615 22.4638 27.1615 22.4866V23.2285C27.1615 23.3578 27.1444 23.4175 27.0236 23.453C26.9071 23.4886 26.6797 23.5127 26.5347 23.5127C25.8539 23.5127 25.6365 23.2697 25.6365 22.6003C25.6365 21.9309 25.8582 21.6864 26.4907 21.6864C26.8374 21.6864 27.0876 21.7703 27.1302 21.7845C27.1586 21.7958 27.1714 21.8058 27.1714 21.8299C27.1714 21.8342 27.1714 21.8413 27.1686 21.8513L27.1345 21.9991L27.1359 21.9977Z"
      fill="white"
    />
    <path
      d="M28.8954 23.48H27.7513C27.73 23.48 27.7101 23.463 27.7101 23.4417V21.7532C27.7101 21.7319 27.7286 21.7148 27.7513 21.7148H28.8926C28.9167 21.7148 28.9309 21.7319 28.9309 21.7504V21.9366C28.9309 21.9579 28.9167 21.9749 28.8926 21.9749H28.0526V22.4383H28.857C28.8784 22.4383 28.8954 22.4553 28.8954 22.4766V22.6657C28.8954 22.6841 28.8784 22.7012 28.857 22.7012H28.0526V23.2214H28.8954C28.9167 23.2214 28.9338 23.2384 28.9338 23.2569V23.4403C28.9338 23.4616 28.9167 23.4786 28.8954 23.4786V23.48Z"
      fill="white"
    />
  </svg>
);
export default SvgSparebank1Nordnorge;
