import * as React from 'react';
import { SVGProps } from 'react';
const SvgCallAwait = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 13.917V20C15 20.5523 14.5523 21 14 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H12.6822C12.0774 3.40215 11.5497 3.91113 11.126 4.5H4.5V16H13.5V13.456C13.9704 13.6719 14.4736 13.8287 15 13.917ZM10 18.4307C10 18.983 9.55228 19.4307 9 19.4307C8.44772 19.4307 8 18.983 8 18.4307C8 17.8784 8.44772 17.4307 9 17.4307C9.55228 17.4307 10 17.8784 10 18.4307Z"
      fill="#12123B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 13C18.7614 13 21 10.7614 21 8C21 5.23858 18.7614 3 16 3C13.2386 3 11 5.23858 11 8C11 10.7614 13.2386 13 16 13ZM15.5 4.5V8V8.20711L15.6464 8.35355L17.6464 10.3536L18.3536 9.64645L16.5 7.79289V4.5H15.5Z"
      fill="#474AEE"
    />
  </svg>
);
export default SvgCallAwait;
