import { gql, useMutation } from '@apollo/client';

import { ChangeUnverifiedEmailMutation, ChangeUnverifiedEmailMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation ChangeUnverifiedEmail($email: String!) {
    changeUnverifiedEmail(email: $email) {
      success
      message
    }
  }
`;

export const useChangeUnverifiedEmail = () =>
  useMutation<ChangeUnverifiedEmailMutation, ChangeUnverifiedEmailMutationVariables>(mutation);
