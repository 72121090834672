import * as React from 'react';
import { SVGProps } from 'react';
const SvgPresentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="#12123B" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.087 7H20a1 1 0 011 1v3a1 1 0 01-1 1v9a1 1 0 01-1 1H5a1 1 0 01-1-1v-9a1 1 0 01-1-1V8a1 1 0 011-1h2.656a7.108 7.108 0 01-.132-.091C5.186 5.964 4.157 4.536 4 2.815A.75.75 0 014.68 2c1.646-.15 3.355.361 4.69 1.305 1.233.871 2.204 2.152 2.472 3.695h.144c.406-1.592 1.449-3.024 2.772-3.968C16.09 2.08 17.777 1.58 19.454 2a.75.75 0 01.546.91c-.401 1.603-1.448 3.047-2.78 3.997a6.826 6.826 0 01-.133.093zm-.738-1.314c-.815.582-1.734.91-2.638.902a5.812 5.812 0 011.917-2.335c.816-.582 1.735-.91 2.638-.902a5.812 5.812 0 01-1.917 2.335zM5.668 3.491c.29.855.903 1.615 1.721 2.193a5.812 5.812 0 002.837 1.04c-.291-.856-.904-1.616-1.722-2.194a5.812 5.812 0 00-2.836-1.039zM4.5 8.5h5v2h-5v-2zm6.5 2v-2h2v2h-2zm2 1.5h-2v8.5h2V12zm1.5 8.5V12h4v8.5h-4zm0-10v-2h5v2h-5zm-9 1.5h4v8.5h-4V12z"
    />
  </svg>
);
export default SvgPresentIcon;
