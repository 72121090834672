import * as React from 'react';
import { SVGProps } from 'react';
const SvgTask = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8.75 7H20" stroke="#12123B" strokeWidth={1.5} strokeLinecap="round" />
    <path d="M8.75 12H16.2539" stroke="#12123B" strokeWidth={1.5} strokeLinecap="round" />
    <path d="M8.75 17H20" stroke="#12123B" strokeWidth={1.5} strokeLinecap="round" />
    <circle cx={5} cy={17} r={1} fill="#12123B" />
    <circle cx={5} cy={12} r={1} fill="#12123B" />
    <circle cx={5} cy={7} r={1} fill="#12123B" />
  </svg>
);
export default SvgTask;
