import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import {
  CryptoCurrency,
  FiatCurrency,
  OtcPricesQuery,
  OtcPricesQueryVariables,
} from './../gqlTypes';

const QUERY = gql`
  query OtcPrices($base: CryptoCurrency!, $quote: FiatCurrency!) {
    otc {
      prices(base: $base, quote: $quote) {
        ask
        bid
        maxBidVolume
        maxAskVolume
      }
    }
  }
`;

export const useOtcPrices = (
  params: { base: CryptoCurrency; quote: FiatCurrency },
  options?: QueryHookOptions<OtcPricesQuery, OtcPricesQueryVariables>,
) =>
  useQuery<OtcPricesQuery, OtcPricesQueryVariables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    ...options,
    variables: {
      base: params.base,
      quote: params.quote,
    },
  });
