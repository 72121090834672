import { gql, QueryOptions, useQuery } from '@apollo/client';

import { MarketQuery, MarketQueryVariables } from '../gqlTypes';

const query = gql`
  query Market($symbol: ID!) {
    market: market_v2(symbol: $symbol) {
      symbol
      lastPrice
      change
      bidPriceHighest
      askPriceLowest
      volume
      baseCurrency {
        symbol
        name
      }
      quoteCurrency {
        symbol
      }
      marketType
      priceHistory(period: day) {
        change24h: change
        data {
          price
        }
      }
      enabled {
        buy
        sell
      }
    }
  }
`;

export const useMarket = (
  currency?: string,
  skip?: boolean,
  options?: Omit<QueryOptions, 'query'>,
) => {
  const result = useQuery<MarketQuery, MarketQueryVariables>(query, {
    ...options,
    skip: !currency || skip,
    variables: { symbol: currency ?? '' },
  });

  if (!result.data || !result.data.market) return result;

  const market = {
    ...result.data.market,
    lastPrice: [...(result.data.market?.priceHistory.data || [])].pop()?.price,
    change: result.data.market?.priceHistory.change24h,
  };

  const data: MarketQuery = { ...result.data, market: market };

  return { ...result, data };
};
