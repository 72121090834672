import { AccountType, CryptoCurrency, Symbol } from '../gql/gqlTypes';

export const getAccountGroupWithHighestBalance = (
  accounts?: {
    id: string;
    type: AccountType;
    accounts?: { available: string; currency: Symbol }[] | null;
  }[],
  currency?: CryptoCurrency,
) => {
  if (!accounts) return null;

  const accountWithHighestBalance = accounts
    ?.filter((a) => a.type !== AccountType.Staking)
    .reduce((high, current) => {
      const acc = current?.accounts?.find((a) => a.currency === (currency as unknown as Symbol));

      if (
        acc &&
        parseFloat(
          high?.accounts?.find((a) => a.currency === (currency as unknown as Symbol))?.available ||
            '0',
        ) < parseFloat(acc?.available || '0')
      ) {
        return current;
      } else {
        return high;
      }
    }, accounts[0]);

  return accountWithHighestBalance;
};
