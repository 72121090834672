import { Button as ButtonComponent, ButtonProps } from '@firi/ui-web';
import clsx from 'clsx';
import { sendGTMEvent } from 'helpers/sendGTMEvent';

interface Props extends ButtonProps {
  'aria-label': string;
  eventAction?: string;
  eventCategory?: 'Interactions' | 'Identification';
  eventValue?: string;
}

export const Button = ({
  children,
  eventAction,
  eventCategory,
  eventValue,
  onClick,
  size,
  className,
  ...props
}: Props) => {
  const handleClick = () => {
    if (eventAction) {
      const ariaLabel = props['aria-label'];
      sendGTMEvent({
        event_action: `${eventAction} click`,
        event_label: ariaLabel.charAt(0).toUpperCase() + ariaLabel.slice(1),
        event_category: eventCategory || 'Interactions',
        event_value: eventValue,
      });
    }
  };

  return (
    <ButtonComponent
      {...props}
      onClick={(event) => {
        handleClick();
        if (onClick) {
          onClick(event);
        }
      }}
      size={size}
      className={clsx(
        { '!py-4 !px-5 !h-[42px] !gap-3 !rs-landing-sm !font-medium': !size },
        className,
      )}
    >
      {children}
    </ButtonComponent>
  );
};
