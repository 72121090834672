import { languageCodes } from '../constants/languages';

/**
 * @param locale
 * @returns Sanity language code
 */
export const findSanityLanguageByLocale = (locale: string) =>
  languageCodes.find((l) => l.locale === locale)?.sanity || '';

/**
 * @param language
 * @returns Next.config locale
 */
export const findLocaleBySanityLanguage = (language: string) =>
  languageCodes.find((l) => l.sanity === language)?.locale || '';

export const getLanguageFields = (fields: any, language: string) => fields[language] ?? {};

/**
 * @param language
 * @returns Full text
 */
export const findLangTextByLocale = (language: string) =>
  languageCodes.find((l) => l.locale === language)?.text || '';
