import * as React from 'react';
import { SVGProps } from 'react';
const SvgChainalysis = (props: SVGProps<SVGSVGElement>) => (
  <svg
    baseProfile="basic"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 100 99.9"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill="#FF5300"
      d="M50,0c1.1,0,2.4,0.1,2.4,0.1c-3.8,4.5-6,10.1-6.5,15.9C31.4,18.4,20.1,30,18,44.6c-6.5,0.7-13.8,3.7-18,7.9 C-1,23.5,21.3,0,50,0z M57.2,87.2c-1.3,0.2-2.7,0.3-4,0.3c-2.3,0-4.6-0.2-6.9-0.6c-1.8,4.3-4.1,8.3-7,12c-18.3-4-32.8-18-37.6-36.1 c4.5-5.3,14.9-8.5,21.4-8.5c13.8,0,21.1,7.3,23.7,18.7c-8.3-2.2-13.2-6.9-16.5-13.8c-1.9-0.7-3.9-1-5.9-1c-1.8,0-3.6,0.3-5.4,0.8 c3.7,14,17.2,24.4,33.7,24.4c15.2,0,21.7-7.7,21.7-7.7V63.9c0,0-7.5,7.8-16.8,9.3c-2.1-14.9-13.9-26.5-28.7-28.6 c2.4-11.8,11.4-18.5,23.6-18.5S74.6,35,74.6,35V23.4c0,0-6.3-5.9-17.6-7.2c0.3-5.2,3.7-11.7,7.3-14.1c15.3,4.8,30.1,17.8,34.3,36.6 c0,0-6.8,6.8-19.4,6.8c-10.4,0-17-5.2-20.7-14.4c-1.9-0.7-4-1-6-1c-1.9,0-3.8,0.3-5.6,1c4.1,14.7,17,24.5,31.4,24.5 c14.1,0,21.7-6.5,21.7-6.5c0,29.3-22.4,50.3-49,50.8C54.1,97,56.5,90.4,57.2,87.2z"
    />
  </svg>
);
export default SvgChainalysis;
