import * as React from 'react';
import { SVGProps } from 'react';
const SvgBonusFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#474AEE" {...props}>
    <path d="M12.75 14.25H11.25C11.25 11.355 8.895 9 6 9V7.5C8.895 7.5 11.25 5.145 11.25 2.25H12.75C12.75 5.145 15.105 7.5 18 7.5V9C15.105 9 12.75 11.355 12.75 14.25ZM9.09075 8.25C10.3358 8.89425 11.3565 9.91425 12 11.1593C12.6443 9.91425 13.6642 8.8935 14.9093 8.25C13.6642 7.60575 12.6435 6.58575 12 5.34075C11.3558 6.58575 10.3358 7.60575 9.09075 8.25Z" />
    <path d="M11 6L12 4L13.5 6.5L16.5 8.5L14 9.5L12 13L11 10.5L9 9L8 8L9 7.5L11 6Z" />
    <path d="M17 15.5L18 14L20.5 17.5L18 20L15 17L17 15.5Z" />
    <path d="M3 17.5L6 14.5L8.5 17.5L6 20L3 17.5Z" />
    <path d="M6.75 12.75H5.25C5.25 14.8177 3.56775 16.5 1.5 16.5V18C3.56775 18 5.25 19.6823 5.25 21.75H6.75C6.75 19.6823 8.43225 18 10.5 18V16.5C8.43225 16.5 6.75 14.8177 6.75 12.75ZM6 19.0493C5.556 18.3128 4.93725 17.694 4.20075 17.25C4.93725 16.806 5.556 16.1872 6 15.4507C6.444 16.1872 7.06275 16.806 7.79925 17.25C7.06275 17.694 6.444 18.3128 6 19.0493Z" />
    <path d="M18.75 12.75H17.25C17.25 14.8177 15.5677 16.5 13.5 16.5V18C15.5677 18 17.25 19.6823 17.25 21.75H18.75C18.75 19.6823 20.4323 18 22.5 18V16.5C20.4323 16.5 18.75 14.8177 18.75 12.75ZM18 19.0493C17.556 18.3128 16.9372 17.694 16.2007 17.25C16.9372 16.806 17.556 16.1872 18 15.4507C18.444 16.1872 19.0627 16.806 19.7992 17.25C19.0627 17.694 18.444 18.3128 18 19.0493Z" />
  </svg>
);
export default SvgBonusFilled;
