import React from 'react';
import { useDropzone } from 'react-dropzone';

import { Icon } from '../../01-atoms/icon/Icon';
import { WebTypography } from '../../01-atoms/typography/WebTypography';

export type FileData = File & {
  id: string;
  created_at?: Date;
  type: string;
  name: string;
  size: number;
};

export interface UploadDropzoneProps {
  onDrop: (files: Array<FileData>) => void;
  upperText?: string;
  lowerText?: string;
  errorText: string;
  footer?: React.ReactNode;
}

export const UploadDropzone = (props: UploadDropzoneProps) => {
  const onDrop = (acceptedFiles: any) => {
    props.onDrop(acceptedFiles);
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    maxFiles: 5,
    maxSize: 31457280, // 30 MB
    accept: {
      // TODO: Always update constants ACCEPTED_UPLOAD_MIME_TYPES and ACCEPTED_FILE_TYPES
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'text/csv': ['.csv'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
      'application/pdf': ['.pdf'],
      'application/vnd.oasis.opendocument.text': ['.odt'],
      'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
    },
  });

  return (
    <div className="flex flex-col space-y-2">
      <div
        {...getRootProps()}
        className="flex flex-col items-center text-center border border-dashed rounded-lg p-4 cursor-pointer border-firi-blue hover:bg-card-light-2 hover:dark:bg-card-dark-2"
      >
        <>
          <input {...getInputProps()} />
          <Icon icon="cloud" color="blue" />
          <WebTypography className="flex flex-col">
            {props.upperText}{' '}
            <WebTypography color="blue">{props.lowerText}</WebTypography>
          </WebTypography>

          {fileRejections.length > 0 ? (
            <div className="flex flex-col mt-4 space-y-2">
              <WebTypography color="negative">{props.errorText}:</WebTypography>
              <div className="flex flex-col">
                {fileRejections.map((reject) => {
                  return (
                    <WebTypography color="negative">
                      {reject.file.name}
                    </WebTypography>
                  );
                })}
              </div>
            </div>
          ) : null}
        </>
      </div>
      {props.footer}
    </div>
  );
};
