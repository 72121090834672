import {
  gql,
  LazyQueryHookOptions,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from '@apollo/client';

import { AppVersionsQuery } from './../gqlTypes';

const query = gql`
  query AppVersions {
    appVersion {
      versions {
        ios
        play
        ota
      }
    }
  }
`;

export const useAppVersions = (options?: QueryHookOptions<AppVersionsQuery>) =>
  useQuery<AppVersionsQuery>(query, options);
