import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { PaymentMethodsQuery } from './../gqlTypes';

const QUERY = gql`
  query PaymentMethods {
    payment {
      paymentMethods {
        type
        hasFee
        instantSettlement
        fastest
      }
    }
  }
`;

export const usePaymentMethods = (options?: QueryHookOptions) =>
  useQuery<PaymentMethodsQuery>(QUERY, options);
