import { gql, QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';

import { TaxReportQuery, TaxReportQueryVariables, TaxYear } from './../gqlTypes';

const query = gql`
  query TaxReport($year: TaxYear!) {
    tax {
      report(year: $year) {
        shouldRebuild
        year
        fees
        data {
          type
          fortune
          profit
          loss
          income
          amount
        }
        missingPrices {
          id
          time
          amount
          currency
          fiatValue
          processed
          accountId
          income
          fee
        }
      }
    }
  }
`;

export const useTaxReport = (
  year?: string,
  options?: QueryHookOptions<TaxReportQuery, TaxReportQueryVariables>,
) =>
  useQuery<TaxReportQuery, TaxReportQueryVariables>(query, {
    variables: {
      year: ('Y_' + year) as TaxYear,
    },
    skip: !year,
    ...options,
  });

export const useLazyTaxReport = (year?: string) =>
  useLazyQuery<TaxReportQuery, TaxReportQueryVariables>(query, {
    variables: {
      year: ('Y_' + year) as TaxYear,
    },
  });
