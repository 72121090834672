import * as React from 'react';
import { SVGProps } from 'react';
const SvgNoWithdraw = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3 3L21 21" stroke="#DA2D43" strokeWidth={1.5} strokeLinecap="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.75 23.25L19.25 21.75L3 21.75L3 17.25H1.5V21.75C1.5 22.5772 2.17275 23.25 3 23.25L20.75 23.25ZM21 18.5V17.25H22.5V20L21 18.5ZM18.8415 16.3415L17.7774 15.2774C18.8529 13.9791 19.5 12.3137 19.5 10.5C19.5 6.3645 16.1355 3 12 3C10.1863 3 8.52093 3.64712 7.2226 4.7226L6.15847 3.65847C7.73165 2.31327 9.77261 1.5 12 1.5C16.9628 1.5 21 5.53725 21 10.5C21 12.7274 20.1867 14.7683 18.8415 16.3415ZM3.95912 6.45912C3.3457 7.67485 3 9.0478 3 10.5C3 15.4627 7.03725 19.5 12 19.5C13.4522 19.5 14.8251 19.1543 16.0409 18.5409L14.9117 17.4117C14.016 17.7905 13.032 18 12 18C7.8645 18 4.5 14.6355 4.5 10.5C4.5 9.46798 4.70953 8.48397 5.08828 7.58828L3.95912 6.45912ZM11.25 13.75V15.75H12.75V15.25L11.25 13.75ZM12.75 10.25V7.81045L15.9698 11.0302L17.0303 9.96975L13.0606 6C12.7681 5.7075 12.3841 5.56125 12.0001 5.56125C11.6161 5.56125 11.2321 5.7075 10.9396 6L9.71977 7.21977L10.7803 8.28027L11.25 7.81055V8.75L12.75 10.25Z"
    />
  </svg>
);
export default SvgNoWithdraw;
