import { gql } from '@apollo/client';

export const CalendarMerchPrizeFragment = gql`
  fragment CalendarMerchPrizeFragment on CalendarMerchPrize {
    type
  }
`;

export const CalendarCryptoPrizeFragment = gql`
  fragment CalendarCryptoPrizeFragment on CalendarCryptoPrize {
    type
    currency
    fiatCurrency
    amount
  }
`;
