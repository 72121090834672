import create from 'zustand';
import { persist } from 'zustand/middleware';

type Fields = {
  stateUuid: string;
  email: string;
  address: string;
  postalArea: string;
  city: string;
  country: string;
  affiliate: string;
  nin: string;
  referralName: string;
};

export type RegistrationField = keyof Fields;

const initialState = {
  stateUuid: '',
  email: '',
  address: '',
  postalArea: '',
  city: '',
  country: '',
  affiliate: '',
  nin: '',
  referralName: '',
};

const initialTouchedState = {
  stateUuid: false,
  email: false,
  address: false,
  postalArea: false,
  city: false,
  country: false,
  affiliate: false,
  nin: false,
  referralName: false,
};

type RegistrationStore = {
  values: Fields;
  adTractionClickId: string | null;
  errors: Record<RegistrationField, string>;
  touched: Record<RegistrationField, boolean>;
  setValue: (key: RegistrationField, value: any) => void;
  setError: (key: RegistrationField, value: any) => void;
  setTouched: (key: RegistrationField, value: boolean) => void;
  setAdTractionClickId: (value: string) => void;
  reset: () => void;
};

// Add persist middleware if needed
export const usePersistedRegistrationStore = create<RegistrationStore>()(
  persist(
    (set, get) => ({
      values: initialState,
      errors: initialState,
      adTractionClickId: null,
      touched: initialTouchedState,
      setValue: (key, value) => {
        set({
          values: {
            ...get().values,
            [key]: value,
          },
        });
      },
      setError: (key, value) => {
        set({
          errors: {
            ...get().errors,
            [key]: value,
          },
        });
      },
      setTouched: (key, value) => {
        set({
          touched: {
            ...get().touched,
            [key]: value,
          },
        });
      },
      setAdTractionClickId: (value) => {
        set({
          adTractionClickId: value,
        });
      },
      reset: () => {
        set({
          values: {
            ...initialState,
            affiliate: get().values.affiliate,
          },
          errors: initialState,
          touched: initialTouchedState,
        });
      },
    }),
    {
      name: '@firi_registration_store',
      getStorage: () => sessionStorage,
    },
  ),
);
