import { firiMerge } from '@firi/shared/styles';
import React from 'react';
import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
  PolymorphicPropsWithRef,
} from 'react-polymorphic-types';

export const CardDefaultElement = 'div';

export type CardSkin =
  | 'blue'
  | 'blue-100'
  | 'blue-10'
  | 'green'
  | 'yellow'
  | 'black'
  | 'gray'
  | 'red'
  | 'white'
  | 'transparent';

export interface CardProps {
  border?: boolean;
  className?: string;
  noPadding?: boolean;
  skin?: CardSkin;
}

export type CardComponentProps<
  T extends React.ElementType = typeof CardDefaultElement
> = PolymorphicPropsWithRef<CardProps, T>;

export const Card: PolymorphicForwardRefExoticComponent<
  CardProps,
  typeof CardDefaultElement
> = React.forwardRef(function Card<
  T extends React.ElementType = typeof CardDefaultElement
>(
  {
    as,
    border,
    className,
    children,
    skin = 'white',
    noPadding,
    ...restProps
  }: PolymorphicPropsWithoutRef<CardProps, T>,
  ref: React.ForwardedRef<Element>
) {
  const Element: React.ElementType = as || CardDefaultElement;

  return (
    <Element
      ref={ref}
      className={firiMerge(
        `rounded-xl`,
        { 'p-4': noPadding !== true },
        { border: border },

        {
          'bg-blue-10 dark:bg-card-dark-2': skin === 'blue-10',
          'bg-blue-50 border-blue-700 dark:bg-card-dark-3 dark:border-blue-500':
            skin === 'blue',
          'bg-blue-100 dark:bg-card-dark-3': skin === 'blue-100',
          'bg-green-200 border-green dark:bg-card-dark-3 dark:border-green-600':
            skin === 'green',
          'bg-yellow-200 border-yellow dark:bg-card-dark-3': skin === 'yellow',
          'bg-black-200 border-black dark:bg-card-dark-3 dark:border-[#A2A8BC]':
            skin === 'black',
          'bg-black-100 border-black dark:bg-card-dark-3 dark:border-[#A2A8BC]':
            skin === 'gray',
          'bg-red-200 border-red dark:bg-card-dark-3 dark:border-red-600':
            skin === 'red',
          'bg-white border-black-150 dark:bg-card-dark-1 dark:border-text-dark-4':
            skin === 'white',
          'border-black-300 dark:bg-card-dark-1 dark:border-text-dark-4':
            skin === 'transparent',
        },
        className
      )}
      {...restProps}
    >
      {children}
    </Element>
  );
});
