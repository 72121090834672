import clsx, { ClassValue } from 'clsx';
import React from 'react';

import { FontColors } from '../typography/WebTypography';

interface Props {
  className?: ClassValue;
  thickness?: '1' | '2' | '4' | '8';
  color?: FontColors;
}
export const Divider = (props: Props) => {
  const thickness = props.thickness || '1';
  const color = props.color || '4';
  return (
    <div
      className={clsx(
        'w-full rounded-full my-2',
        thickness === '1' && 'border-t',
        thickness === '2' && 'border-t-2',
        thickness === '4' && 'border-t-4',
        thickness === '8' && 'border-t-8',
        props.className ?? '',
        {
          'border-text-light-1 dark:border-white': color === '1',
          'border-text-light-2 dark:border-text-dark-2': color === '2',
          'border-text-light-3 dark:border-text-dark-3': color === '3',
          'border-[#E7E7E7] dark:border-text-dark-4': color === '4',
          'border-text-light-blue dark:border-text-dark-blue': color === 'blue',
          'border-white': color === 'white',
          'border-text-light-red dark:border-text-dark-red':
            color === 'negative',
          'border-text-light-green dark:border-text-dark-green':
            color === 'positive',
        }
      )}
    />
  );
};
