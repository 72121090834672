import * as React from 'react';
import { SVGProps } from 'react';
const SvgYoutubeRound = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM17.1104 9.53051C16.9878 9.05926 16.6263 8.68816 16.1673 8.56222C15.3356 8.33333 12 8.33333 12 8.33333C12 8.33333 8.66442 8.33333 7.83261 8.56222C7.37364 8.68816 7.01218 9.05926 6.88951 9.53051C6.66667 10.3846 6.66667 12.1667 6.66667 12.1667C6.66667 12.1667 6.66667 13.9487 6.88951 14.8028C7.01218 15.2741 7.37364 15.6452 7.83261 15.7712C8.66442 16 12 16 12 16C12 16 15.3356 16 16.1673 15.7712C16.6263 15.6452 16.9878 15.2741 17.1104 14.8028C17.3333 13.9487 17.3333 12.1667 17.3333 12.1667C17.3333 12.1667 17.3333 10.3846 17.1104 9.53051Z"
      fill="#12123B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8 14.4V10.4L14 12.4001L10.8 14.4Z"
      fill="#12123B"
    />
  </svg>
);
export default SvgYoutubeRound;
