import { firiMerge, FiriStyleClass } from '@firi/shared/styles';
import clsx from 'clsx';
import React from 'react';
import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
  PolymorphicPropsWithRef,
} from 'react-polymorphic-types';

const DefaultElement = 'span';

// TODO: Nothing. Remove when somebody sees this
export type FontColors =
  | '1'
  | '2'
  | '3'
  | '4'
  | 'blue'
  | 'white'
  | 'negative'
  | 'positive'
  | 'black'
  | 'pinkle';

export type FontSize = '3xl' | '2xl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs' | '2xs';

export type WebTypographyOwnProps = {
  size?: FontSize;
  weight?: 'regular' | 'medium' | 'bold';
  color?: FontColors;
  children: React.ReactNode;
  className?: FiriStyleClass;
};

export type WebTypographyProps<
  T extends React.ElementType = typeof DefaultElement
> = PolymorphicPropsWithRef<WebTypographyOwnProps, T>;

export const WebTypography: PolymorphicForwardRefExoticComponent<
  WebTypographyOwnProps,
  typeof DefaultElement
> = React.forwardRef(function WebTypography<
  T extends React.ElementType = typeof DefaultElement
>(
  {
    as,
    size = 'sm',
    weight = 'regular',
    color = '1',
    children,
    className,
    ...restProps
  }: PolymorphicPropsWithoutRef<WebTypographyOwnProps, T>,
  ref: React.ForwardedRef<Element>
) {
  const Component: React.ElementType = as || DefaultElement;

  return (
    <Component
      ref={ref}
      className={clsx(
        {
          'text-web-mobile-2xs md:text-web-2xs': size === '2xs',
          'text-web-mobile-xs md:text-web-xs': size === 'xs',
          'text-web-mobile-sm md:text-web-sm': size === 'sm',
          'text-web-mobile-md md:text-web-md': size === 'md',
          'text-web-mobile-lg md:text-web-lg': size === 'lg',
          'text-web-mobile-xl md:text-web-xl': size === 'xl',
          'text-web-mobile-2xl md:text-web-2xl': size === '2xl',
          'text-web-mobile-3xl md:text-web-3xl': size === '3xl',
          'font-semibold md:font-medium': weight === 'medium',
          'font-bold md:font-bold': weight === 'bold',
        },
        {
          'text-text-light-1 dark:text-white': color === '1',
          'text-text-light-2 dark:text-text-dark-2': color === '2',
          'text-text-light-3 dark:text-text-dark-3': color === '3',
          'text-text-light-4 dark:text-text-dark-4': color === '4',
          'text-text-light-blue dark:text-text-dark-blue': color === 'blue',
          'text-white': color === 'white',
          'text-text-light-red dark:text-text-dark-red': color === 'negative',
          'text-text-light-green dark:text-text-dark-green':
            color === 'positive',
          'text-pinkle': color === 'pinkle',
        },
        className
      )}
      {...restProps}
    >
      {children}
    </Component>
  );
});
