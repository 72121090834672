import * as React from 'react';
import { SVGProps } from 'react';
const SvgManaSand = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 72 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_968_449)">
      <path
        d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
        fill="url(#paint0_linear_968_449)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7124 16.2495V34.9995H33.3374L17.7124 16.2495Z"
        fill="url(#paint1_linear_968_449)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.0874 34.9995H17.7124V16.2495L2.0874 34.9995Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 40.0005C6.425 41.888 8.1125 43.5755 10 45.0005H40C41.8875 43.5755 43.575 41.888 45 40.0005H5Z"
        fill="#FC9965"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0001 45C14.1751 48.1375 19.3751 50 25.0001 50C30.6251 50 35.8251 48.1375 40.0001 45H10.0001Z"
        fill="#FF2D55"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5376 26.25V40H45.0001L33.5376 26.25Z"
        fill="url(#paint2_linear_968_449)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5374 34.9995H2.0874C2.8624 36.787 3.8499 38.462 4.9999 39.9995H33.5499V34.9995H33.5374Z"
        fill="#FFBC5B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0876 40H33.5376V26.25L22.0876 40Z"
        fill="white"
      />
      <path
        d="M33.5376 22.4995C36.9894 22.4995 39.7876 19.7013 39.7876 16.2495C39.7876 12.7977 36.9894 9.99951 33.5376 9.99951C30.0858 9.99951 27.2876 12.7977 27.2876 16.2495C27.2876 19.7013 30.0858 22.4995 33.5376 22.4995Z"
        fill="#FFC95B"
      />
      <path
        d="M17.7124 12.5C19.4383 12.5 20.8374 11.1009 20.8374 9.375C20.8374 7.64911 19.4383 6.25 17.7124 6.25C15.9865 6.25 14.5874 7.64911 14.5874 9.375C14.5874 11.1009 15.9865 12.5 17.7124 12.5Z"
        fill="#FFC95B"
      />
    </g>
    <g clipPath="url(#clip1_968_449)">
      <path
        d="M47 50C60.8071 50 72 38.8071 72 25C72 11.1929 60.8071 0 47 0C33.1929 0 22 11.1929 22 25C22 38.8071 33.1929 50 47 50Z"
        fill="url(#paint3_linear_968_449)"
      />
      <path
        d="M38.6 11.8002L35.9 14.5002V25.2002L38.6 27.9002H49.3V33.3002H44V30.6002H35.9V36.0002L38.6 38.7002H54.7L57.4 36.0002V25.2002L54.7 22.5002H44V17.1002H49.4V19.8002H57.5V14.4002L54.8 11.7002H38.6V11.8002Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_968_449"
        x1={4267.75}
        y1={732.25}
        x2={732.25}
        y2={4267.75}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF2D55" />
        <stop offset={1} stopColor="#FFBC5B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_968_449"
        x1={798.431}
        y1={16.2495}
        x2={798.431}
        y2={1891.25}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A524B3" />
        <stop offset={1} stopColor="#FF2D55" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_968_449"
        x1={606.273}
        y1={26.25}
        x2={606.273}
        y2={1401.25}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A524B3" />
        <stop offset={1} stopColor="#FF2D55" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_968_449"
        x1={32.72}
        y1={8.885}
        x2={63.87}
        y2={44.035}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00ADEF" />
        <stop offset={1} stopColor="#0084FF" />
      </linearGradient>
      <clipPath id="clip0_968_449">
        <rect width={50} height={50} fill="white" />
      </clipPath>
      <clipPath id="clip1_968_449">
        <rect width={50} height={50} fill="white" transform="translate(22)" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgManaSand;
