import * as React from 'react';
import { SVGProps } from 'react';
const SvgPendingSuspension = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2548 17.6461C21.3558 16.0393 22 14.0949 22 12C22 6.47715 17.5228 2 12 2C9.9051 2 7.96065 2.64417 6.35394 3.74524L7.4364 4.82771C8.7548 3.98709 10.3205 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 13.6795 20.0129 15.2452 19.1723 16.5636L20.2548 17.6461ZM10.3956 13.0043L11.4627 14.0714H10.5714C9.19072 14.0714 8.07143 15.1907 8.07143 16.5714V18H7V16.5714C7 14.658 8.50481 13.0959 10.3956 13.0043ZM4.82771 7.4364C3.98709 8.7548 3.5 10.3205 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C13.6795 20.5 15.2452 20.0129 16.5636 19.1723L17.6461 20.2548C16.0393 21.3558 14.0949 22 12 22C6.47715 22 2 17.5228 2 12C2 9.9051 2.64417 7.96065 3.74524 6.35394L4.82771 7.4364ZM13.9167 11.308C14.5786 10.7577 15 9.92806 15 9C15 7.34315 13.6569 6 12 6C11.0719 6 10.2423 6.42141 9.69201 7.08332L10.493 7.88426C10.8346 7.42358 11.3824 7.125 12 7.125C13.0355 7.125 13.875 7.96447 13.875 9C13.875 9.61758 13.5764 10.1654 13.1157 10.507L13.9167 11.308Z"
      fill="#12123B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.2253 2.2253C2.52571 1.9249 3.01276 1.9249 3.31316 2.2253L4.46701 3.37915C4.76741 3.67955 4.76741 4.1666 4.46701 4.46701C4.1666 4.76741 3.67955 4.76741 3.37915 4.46701L2.2253 3.31316C1.9249 3.01276 1.9249 2.52571 2.2253 2.2253ZM5.68684 5.68684C5.98724 5.38644 6.47429 5.38644 6.7747 5.68684L9.08239 7.99453C9.38279 8.29494 9.38279 8.78199 9.08239 9.08239C8.78199 9.38279 8.29494 9.38279 7.99453 9.08239L5.68684 6.7747C5.38644 6.47429 5.38644 5.98724 5.68684 5.68684ZM10.3022 10.3022C10.6026 10.0018 11.0897 10.0018 11.3901 10.3022L13.6978 12.6099C13.9982 12.9103 13.9982 13.3974 13.6978 13.6978C13.3974 13.9982 12.9103 13.9982 12.6099 13.6978L10.3022 11.3901C10.0018 11.0897 10.0018 10.6026 10.3022 10.3022ZM14.9176 14.9176C15.218 14.6172 15.7051 14.6172 16.0055 14.9176L18.3132 17.2253C18.6136 17.5257 18.6136 18.0128 18.3132 18.3132C18.0128 18.6136 17.5257 18.6136 17.2253 18.3132L14.9176 16.0055C14.6172 15.7051 14.6172 15.218 14.9176 14.9176ZM19.533 19.533C19.8334 19.2326 20.3204 19.2326 20.6209 19.533L21.7747 20.6868C22.0751 20.9872 22.0751 21.4743 21.7747 21.7747C21.4743 22.0751 20.9872 22.0751 20.6868 21.7747L19.533 20.6209C19.2326 20.3204 19.2326 19.8334 19.533 19.533Z"
      fill="#DA2D43"
    />
  </svg>
);
export default SvgPendingSuspension;
