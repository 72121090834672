import { gql, useMutation } from '@apollo/client';

import { SubmitFeedbackMutation, SubmitFeedbackMutationVariables } from './../gqlTypes';

const mutation = gql`
  mutation SubmitFeedback($input: SubmitFeedbackInput!) {
    submitFeedback(input: $input) {
      success
    }
  }
`;

export const useSubmitFeedback = () =>
  useMutation<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>(mutation);
