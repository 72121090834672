import * as React from 'react';
import { SVGProps } from 'react';
const SvgCsv = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M8.48743 19.5C8.05274 19.5 7.68585 19.4095 7.38678 19.2286C7.08771 19.0477 6.86515 18.8065 6.71909 18.505C6.57303 18.2002 6.5 17.8635 6.5 17.495C6.5 17.1265 6.57303 16.7915 6.71909 16.49C6.86515 16.1884 7.08597 15.9489 7.38156 15.7714C7.68064 15.5905 8.04926 15.5 8.48743 15.5C8.99864 15.5 9.40725 15.624 9.71328 15.8719C10.0193 16.1198 10.1984 16.4548 10.2506 16.8769H9.22816C9.20034 16.6859 9.11687 16.5436 8.97777 16.4497C8.84215 16.3526 8.67174 16.304 8.46657 16.304C8.15359 16.304 7.91189 16.4112 7.74149 16.6256C7.57109 16.8367 7.48589 17.1265 7.48589 17.495C7.48589 17.8635 7.57109 18.1566 7.74149 18.3744C7.91189 18.5888 8.15359 18.696 8.46657 18.696C8.67174 18.696 8.84215 18.6474 8.97777 18.5503C9.1134 18.4531 9.19686 18.3074 9.22816 18.1131H10.2506C10.1984 18.5385 10.0193 18.8769 9.71328 19.1281C9.40725 19.376 8.99864 19.5 8.48743 19.5Z" />
    <path d="M12.2813 19.5C11.9996 19.5 11.7249 19.4564 11.4571 19.3693C11.1893 19.2822 10.9615 19.1348 10.7737 18.9271C10.586 18.7194 10.4799 18.4481 10.4555 18.1131H11.4206C11.5353 18.5352 11.8205 18.7462 12.2761 18.7462C12.4499 18.7462 12.596 18.7111 12.7142 18.6407C12.8359 18.567 12.8968 18.4598 12.8968 18.3191C12.8968 18.2253 12.8586 18.1482 12.782 18.0879C12.709 18.0276 12.6151 17.9807 12.5004 17.9472C12.3891 17.9137 12.2309 17.8752 12.0257 17.8317C11.7162 17.7647 11.464 17.696 11.2693 17.6256C11.078 17.5553 10.9111 17.4447 10.7685 17.294C10.6294 17.1399 10.5599 16.9322 10.5599 16.6709C10.5599 16.4162 10.6242 16.2018 10.7529 16.0276C10.885 15.8501 11.0624 15.7178 11.285 15.6307C11.5075 15.5436 11.7579 15.5 12.0361 15.5C12.3213 15.5 12.5856 15.5486 12.829 15.6457C13.0724 15.7395 13.2741 15.8836 13.4341 16.0779C13.5941 16.2688 13.6949 16.5017 13.7366 16.7764H12.7716C12.7229 16.6122 12.6325 16.4849 12.5004 16.3945C12.3717 16.3007 12.21 16.2538 12.0152 16.2538C11.8692 16.2538 11.744 16.2822 11.6397 16.3392C11.5388 16.3961 11.4884 16.4816 11.4884 16.5955C11.4884 16.6826 11.5232 16.7546 11.5927 16.8116C11.6657 16.8652 11.7562 16.9087 11.864 16.9422C11.9752 16.9757 12.13 17.0142 12.3282 17.0578C12.6377 17.1248 12.8933 17.1951 13.095 17.2688C13.2967 17.3425 13.4706 17.4598 13.6167 17.6206C13.7627 17.7814 13.8358 17.9992 13.8358 18.2739C13.8358 18.5251 13.7732 18.7429 13.648 18.9271C13.5228 19.1114 13.3437 19.2538 13.1107 19.3543C12.8777 19.4514 12.6012 19.5 12.2813 19.5Z" />
    <path d="M16.1385 19.4146H15.1266L13.7651 15.5804H14.7562L15.6325 18.4045L16.5089 15.5804H17.5L16.1385 19.4146Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 3V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V3C4 2.44771 4.44772 2 5 2H19C19.5523 2 20 2.44771 20 3ZM18.5 3.5L5.5 3.5L5.5 20.5H18.5L18.5 3.5Z"
    />
  </svg>
);
export default SvgCsv;
