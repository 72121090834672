import * as React from 'react';
import { SVGProps } from 'react';
const SvgBuilding = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8437 3V4.125H14.5312V5.93525C15.3595 6.28321 16.0951 6.67962 16.7432 7.24368C17.4247 7.83684 17.9698 8.5799 18.4469 9.5625H19.5937V11.25H18.7514L18.76 13.4625H20.4375V15.15H19.5937V19.3125H21V21H3V19.3125H4.40625V15.15H3.5625V13.4625H5.25V11.25H4.40625V9.5625H5.55132C6.02861 8.57972 6.57416 7.83663 7.25611 7.24349C7.90454 6.67952 8.64048 6.28316 9.46875 5.93523V4.125H11.1562V3H12.8437ZM12.8437 5.8125H11.1562V7.0864L10.6145 7.29409C9.62437 7.6737 8.92724 8.02649 8.36356 8.51677C8.04694 8.79214 7.75528 9.12618 7.4774 9.5625H16.521C16.2432 9.12605 15.9517 8.79197 15.6353 8.51658C15.0721 8.02641 14.3755 7.67367 13.3854 7.29409L12.8437 7.0864V5.8125ZM17.0639 11.25H14.8174V13.4625H17.0724L17.0639 11.25ZM17.9062 15.15H16.2187V19.3125H17.9062V15.15ZM14.5312 19.3125V15.15H12.8437V19.3125H14.5312ZM11.1562 19.3125V15.15H9.46875V19.3125H11.1562ZM7.78125 19.3125V15.15H6.09375V19.3125H7.78125ZM6.9375 13.4625H9.1842V11.25H6.9375L6.9375 13.4625ZM10.8717 11.25V13.4625H13.1299V11.25H10.8717Z"
      fill="#12123B"
    />
  </svg>
);
export default SvgBuilding;
