import * as React from 'react';
import { SVGProps } from 'react';
const SvgMana = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_924_7294)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="url(#paint0_linear_924_7294)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.50195 7.7998V16.7998H16.002L8.50195 7.7998Z"
        fill="url(#paint1_linear_924_7294)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.00195 16.7998H8.50195V7.7998L1.00195 16.7998Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.40002 19.2002C3.08402 20.1062 3.89402 20.9162 4.80002 21.6002H19.2C20.106 20.9162 20.916 20.1062 21.6 19.2002H2.40002Z"
        fill="#FC9965"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.80005 21.6001C6.80405 23.1061 9.30005 24.0001 12 24.0001C14.7 24.0001 17.196 23.1061 19.2 21.6001H4.80005Z"
        fill="#FF2D55"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.098 12.6001V19.2001H21.6L16.098 12.6001Z"
        fill="url(#paint2_linear_924_7294)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.098 16.7998H1.00195C1.37395 17.6578 1.84795 18.4618 2.39995 19.1998H16.104V16.7998H16.098Z"
        fill="#FFBC5B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6021 19.2001H16.0981V12.6001L10.6021 19.2001Z"
        fill="white"
      />
      <path
        d="M16.098 10.7998C17.7549 10.7998 19.098 9.45666 19.098 7.7998C19.098 6.14295 17.7549 4.7998 16.098 4.7998C14.4412 4.7998 13.098 6.14295 13.098 7.7998C13.098 9.45666 14.4412 10.7998 16.098 10.7998Z"
        fill="#FFC95B"
      />
      <path
        d="M8.50195 6C9.33038 6 10.002 5.32843 10.002 4.5C10.002 3.67157 9.33038 3 8.50195 3C7.67353 3 7.00195 3.67157 7.00195 4.5C7.00195 5.32843 7.67353 6 8.50195 6Z"
        fill="#FFC95B"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_924_7294"
        x1={2048.52}
        y1={351.48}
        x2={351.48}
        y2={2048.52}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF2D55" />
        <stop offset={1} stopColor="#FFBC5B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_924_7294"
        x1={383.247}
        y1={7.7998}
        x2={383.247}
        y2={907.8}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A524B3" />
        <stop offset={1} stopColor="#FF2D55" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_924_7294"
        x1={291.011}
        y1={12.6001}
        x2={291.011}
        y2={672.6}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A524B3" />
        <stop offset={1} stopColor="#FF2D55" />
      </linearGradient>
      <clipPath id="clip0_924_7294">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMana;
