import { gql, useMutation } from '@apollo/client';

import { ClaimRewardMutation } from './../gqlTypes';

const MUTATION = gql`
  mutation ClaimReward($uuid: String!) {
    claimReward(uuid: $uuid) {
      uuid
      success
      message
    }
  }
`;

export const useClaimReward = () =>
  useMutation<ClaimRewardMutation>(MUTATION, {
    refetchQueries: ['Transaction', 'PNLBalance', 'Accounts', 'Rewards'],
  });
