import { gql, QueryOptions, useQuery } from '@apollo/client';

import { MarketHistoryQuery, MarketHistoryQueryVariables } from '../gqlTypes';

const query = gql`
  query MarketHistory($symbol: ID!) {
    market: market_v2(symbol: $symbol) {
      symbol
      history {
        createdAt
        amount
        type
        price
      }
    }
  }
`;

export const useMarketHistory = (market?: string, options?: Omit<QueryOptions, 'query'>) => {
  return useQuery<MarketHistoryQuery, MarketHistoryQueryVariables>(query, {
    ...options,
    variables: { symbol: market || '' },
    skip: !market,
  });
};
