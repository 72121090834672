import { gql, useMutation } from '@apollo/client';

import { GenerateApiKeyMutation, GenerateApiKeyMutationVariables } from '../gqlTypes';

const MUTATION = gql`
  mutation GenerateApiKey($input: KeygenInput!) {
    generateApiKey(input: $input) {
      apiKey
      clientId
      secretKey
    }
  }
`;

export const useGenerateApiKey = () =>
  useMutation<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>(MUTATION, {
    refetchQueries: ['ApiKeys'],
  });
