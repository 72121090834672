import { useBreakpoint } from '@firi/shared';
import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { twMerge } from 'tailwind-merge';

import {
  BottomSheet,
  FontColors,
  Modal,
  ModalPosition,
  ModalSize,
  SheetHeight,
} from '../../index';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  children: any;
  size?: ModalSize;
  position?: ModalPosition;
  sheetHeight?: SheetHeight;
  closeIconColor?: FontColors;
  bottomSheetClassName?: string;
  disableClose?: boolean;
  onCloseCompleted?: () => void;
  /**
   * tw class
   */
  minHeight?: string; // TODO: Rename to "className"
  className?: string;
};

export const ModalWrapper: FC<Props> = (props) => {
  const isMobile = useBreakpoint('md');
  const root =
    document.getElementById('headlessui-portal-root') || document.body;

  if (!isMobile) {
    return (
      <Modal
        open={props.isOpen}
        onClose={props.onClose}
        size={props.size}
        position={props.position}
        closeIconColor={props.closeIconColor}
        className={twMerge(props.minHeight, props.className)}
        hideCloseBtn={props.disableClose}
        onCloseCompleted={props.onCloseCompleted}
      >
        {props.children}
      </Modal>
    );
  }

  return ReactDOM.createPortal(
    <BottomSheet
      onCloseCompleted={props.onCloseCompleted}
      isOpen={props.isOpen}
      onClose={props.onClose}
      height={props.sheetHeight ?? 'auto'}
      scrollable={!!props.sheetHeight}
      className={props.bottomSheetClassName}
      closeIconColor={props.closeIconColor}
      disableClose={props.disableClose}
    >
      {props.children}
    </BottomSheet>,
    root
  );
};
