import * as React from 'react';
import { SVGProps } from 'react';
const SvgInterest = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="black" {...props}>
    <path d="M20.4696 2.46946L3.21973 19.7194L4.28038 20.78L21.5302 3.53012L20.4696 2.46946Z" />
    <path d="M22.5 10.5H21V3.1665C21 3.07425 20.925 3 20.8335 3H13.5V1.5H20.8335C21.7523 1.5 22.5 2.24775 22.5 3.1665V10.5Z" />
    <path d="M6.375 12C4.10025 12 2.25 10.1498 2.25 7.875C2.25 5.60025 4.10025 3.75 6.375 3.75C8.64975 3.75 10.5 5.60025 10.5 7.875C10.5 10.1498 8.64975 12 6.375 12ZM6.375 5.25C4.9275 5.25 3.75 6.4275 3.75 7.875C3.75 9.3225 4.9275 10.5 6.375 10.5C7.8225 10.5 9 9.3225 9 7.875C9 6.4275 7.8225 5.25 6.375 5.25Z" />
    <path d="M16.125 21.75C13.8503 21.75 12 19.8998 12 17.625C12 15.3503 13.8503 13.5 16.125 13.5C18.3998 13.5 20.25 15.3503 20.25 17.625C20.25 19.8998 18.3998 21.75 16.125 21.75ZM16.125 15C14.6775 15 13.5 16.1775 13.5 17.625C13.5 19.0725 14.6775 20.25 16.125 20.25C17.5725 20.25 18.75 19.0725 18.75 17.625C18.75 16.1775 17.5725 15 16.125 15Z" />
  </svg>
);
export default SvgInterest;
