import * as React from 'react';
import { SVGProps } from 'react';
const SvgTips = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M15.75 18H8.25V19.5H15.75V18Z" />
    <path d="M14.25 21H9.75V22.5H14.25V21Z" />
    <path d="M18.7501 8.24996C18.7501 6.41696 18.0279 4.70171 16.7161 3.42071C15.4051 2.13971 13.6734 1.45721 11.8374 1.50221C8.18411 1.58846 5.22911 4.63346 5.25011 8.28971C5.26286 10.5307 6.38411 12.6127 8.25011 13.8615V15.75C8.25011 16.164 8.58611 16.5 9.00011 16.5H15.0001C15.4141 16.5 15.7501 16.164 15.7501 15.75L15.7456 13.8637C17.6274 12.6067 18.7501 10.5082 18.7501 8.24996ZM14.9124 12.6172C14.4976 12.8947 14.2501 13.3597 14.2501 13.8607V15H12.7501V9.74996H15.0001V8.24996H9.00011V9.74996H11.2501V15H9.75011V13.8607C9.75011 13.359 9.50336 12.894 9.09011 12.618C7.63511 11.6467 6.76061 10.0252 6.75086 8.28071C6.73436 5.43671 9.03236 3.06821 11.8734 3.00146C13.3014 2.96846 14.6491 3.49796 15.6691 4.49396C16.6884 5.48996 17.2501 6.82421 17.2501 8.24996C17.2501 10.0065 16.3764 11.6385 14.9124 12.6172Z" />
  </svg>
);
export default SvgTips;
