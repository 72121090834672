import * as React from 'react';
import { SVGProps } from 'react';
const SvgAdyen = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 27" {...props}>
    <g>
      <g id="svg_2">
        <path
          id="svg_3"
          fill="#35B454"
          d="m18.50001,4l-14,0l0,4.5l9.2,0c0.6,0 1,0.5 1,1l0,7.9l-2,0c-0.6,0 -1,-0.5 -1,-1l0,-5.7l-3.9,0c-2,0 -3.6,1.6 -3.6,3.6l0,4c0,2 1.6,3.6 3.6,3.6l14.3,0l0,-14.3c0,-2 -1.6,-3.6 -3.6,-3.6z"
          className="st0"
        />
      </g>
    </g>
  </svg>
);
export default SvgAdyen;
