import * as React from 'react';
import { SVGProps } from 'react';
const SvgAdvancedTrade = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.25 4.5V5.5H10.5C9.67275 5.5 9 6.17275 9 7V13C9 13.8284 9.67157 14.5 10.5 14.5H11.25V17C11.25 17.2761 11.4739 17.5 11.75 17.5H12.25C12.5261 17.5 12.75 17.2761 12.75 17V14.5H13.5C14.3284 14.5 15 13.8284 15 13V7C15 6.17275 14.3273 5.5 13.5 5.5H12.75V4.5C12.75 4.22386 12.5261 4 12.25 4H11.75C11.4739 4 11.25 4.22386 11.25 4.5ZM10.5 13H13.5V7H10.5V13Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.75 6.5C19.75 6.22386 19.5261 6 19.25 6H18.75C18.4739 6 18.25 6.22386 18.25 6.5L18.25 9.5H17.5C16.6716 9.5 16 10.1716 16 11V17C16 17.8272 16.6727 18.5 17.5 18.5H18.25L18.25 19.5C18.25 19.7761 18.4739 20 18.75 20H19.25C19.5261 20 19.75 19.7761 19.75 19.5L19.75 18.5H20.5C21.3273 18.5 22 17.8272 22 17L22 11C22 10.1716 21.3284 9.5 20.5 9.5H19.75L19.75 6.5ZM20.5 11L17.5 11L17.5 17H20.5L20.5 11Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 8H5.75V4.5C5.75 4.22386 5.52614 4 5.25 4H4.75C4.47386 4 4.25 4.22386 4.25 4.5V8H3.5C2.67275 8 2 8.67275 2 9.5V15.5C2 16.3284 2.67157 17 3.5 17H4.25V18.5C4.25 18.7761 4.47386 19 4.75 19H5.25C5.52614 19 5.75 18.7761 5.75 18.5V17H6.5C7.32843 17 8 16.3284 8 15.5V9.5C8 8.67275 7.32725 8 6.5 8ZM3.5 15.5H6.5V9.5H3.5V15.5Z"
    />
  </svg>
);
export default SvgAdvancedTrade;
