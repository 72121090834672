import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = PropsWithChildren<{ className?: string }>;

export const GridItem = ({ children, className }: Props) => (
  <div className={clsx('col-span-full', className)}>{children}</div>
);

export const Grid = ({ children, className }: Props) => (
  <div
    className={twMerge(
      'grid gap-4 grid-cols-2 md:grid-cols-6 lg:grid-cols-12 lg:gap-10',
      className,
    )}
  >
    {children}
  </div>
);
