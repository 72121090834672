import * as React from 'react';
import { SVGProps } from 'react';
const SvgTransactions = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M19.5 6H4.5V7.5H19.5V6Z" />
    <path d="M8.5821 12.3427L4.04985 7.81048C3.76635 7.52698 3.61035 7.15048 3.61035 6.74998C3.61035 6.34948 3.76635 5.97298 4.04985 5.68948L8.5821 1.15723L9.6426 2.21773L5.11035 6.74998L9.6426 11.2822L8.5821 12.3427Z" />
    <path d="M19.5 16.5H4.5V18H19.5V16.5Z" />
    <path d="M15.4179 22.8427L14.3574 21.7822L18.8897 17.25L14.3574 12.7177L15.4179 11.6572L19.9502 16.1895C20.2337 16.473 20.3897 16.8495 20.3897 17.25C20.3897 17.6505 20.2337 18.0277 19.9502 18.3105L15.4179 22.8427Z" />
  </svg>
);
export default SvgTransactions;
