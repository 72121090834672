import * as React from 'react';
import { SVGProps } from 'react';
const SvgObservation = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.23807 18.1269C8.64763 19.0687 10.3048 19.5714 12.0001 19.5714C14.2734 19.5714 16.4536 18.6684 18.061 17.0609C18.2993 16.8227 18.5221 16.5718 18.7287 16.3099C18.8796 16.1187 19.1066 16 19.3502 16C19.9325 16 20.2874 16.6266 19.9329 17.0886C19.271 17.9509 18.4694 18.7043 17.5558 19.3147C15.9113 20.4135 13.9779 21 12.0001 21C9.34871 20.9975 6.80663 19.9431 4.93182 18.0683C4.62177 17.7582 4.33417 17.4299 4.07005 17.0859C3.71632 16.6252 4.07029 16 4.65114 16C4.89407 16 5.12038 16.1184 5.27084 16.3091C5.82431 17.0106 6.48705 17.6251 7.23807 18.1269Z"
      fill="#12123B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7661 5.87312C15.3565 4.93128 13.6993 4.42857 12.0041 4.42857C9.73077 4.42857 7.55059 5.33163 5.94314 6.93908C5.70488 7.17734 5.48209 7.42819 5.27543 7.69005C5.12451 7.88128 4.89757 8 4.65397 8C4.07167 8 3.7167 7.37337 4.07124 6.91144C4.73312 6.04908 5.53476 5.29575 6.44835 4.6853C8.09284 3.58649 10.0262 3 12.0041 3C14.6554 3.00252 17.1975 4.0569 19.0723 5.93171C19.3824 6.24176 19.67 6.57005 19.9341 6.91407C20.2878 7.37479 19.9339 8 19.353 8C19.1101 8 18.8838 7.88161 18.7333 7.6909C18.1798 6.98939 17.5171 6.37493 16.7661 5.87312Z"
      fill="#12123B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 16C14.2091 16 16 14.2091 16 12C16 11.59 15.9383 11.1945 15.8238 10.8221C15.5102 11.5166 14.8115 12 14 12C12.8954 12 12 11.1046 12 10C12 9.18846 12.4834 8.48982 13.1779 8.17624C12.8055 8.06167 12.41 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
      fill="#12123B"
    />
  </svg>
);
export default SvgObservation;
