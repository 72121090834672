import { gql, useQuery } from '@apollo/client';

import { ReferralStatsQuery } from '../gqlTypes';

const query = gql`
  query ReferralStats {
    affiliateStats {
      bonus {
        currency
        type
        last
        pending
        total
      }
      commission {
        currency
        type
        last
        pending
        total
      }
      feebackBonus {
        currency
        type
        last
        pending
        total
      }
    }
    referralCount {
      last
      pending
      total
    }
  }
`;

export const useReferralStats = () => {
  return useQuery<ReferralStatsQuery>(query);
};
