import * as React from 'react';
import { SVGProps } from 'react';
const SvgDonations = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <g clipPath="url(#clip0_914:15571)">
      <path d="M8.32999 2.49996C8.74825 2.50008 9.16136 2.59226 9.53999 2.76996C10.08 3.0287 10.5169 3.46208 10.78 3.99996L12.12 6.81996L13.48 3.99996C13.7044 3.55516 14.0514 3.18388 14.48 2.92996C14.9186 2.65915 15.4245 2.51708 15.94 2.51996C16.6587 2.5175 17.3504 2.79347 17.87 3.28996C18.1142 3.52167 18.3085 3.80087 18.4409 4.11041C18.5734 4.41995 18.6411 4.75329 18.64 5.08996C18.64 7.37996 14.96 10.97 12.15 13.09C9.58999 11.09 5.64999 7.40996 5.63999 5.08996C5.64317 4.50744 5.84415 3.94328 6.20999 3.48996C6.60397 3.02029 7.14167 2.69345 7.73999 2.55996C7.93737 2.51923 8.13845 2.49913 8.33999 2.49996H8.32999ZM8.33999 0.999956C8.03051 0.998029 7.72183 1.03158 7.41999 1.09996C6.47045 1.28403 5.61473 1.79322 4.99999 2.53996C4.40253 3.26156 4.08325 4.17327 4.09999 5.10996C4.09999 9.37996 12.1 15 12.1 15C12.1 15 20.1 9.37996 20.1 5.10996C20.1031 4.574 19.9985 4.04287 19.7924 3.54813C19.5862 3.05339 19.2828 2.60513 18.9 2.22996C18.0948 1.45518 17.0174 1.02781 15.9 1.03996C15.1128 1.04039 14.341 1.25842 13.67 1.66996C12.9987 2.07648 12.4575 2.66628 12.11 3.36996C11.6949 2.50386 10.9863 1.81294 10.11 1.41996C9.55428 1.15214 8.94681 1.0088 8.32999 0.999956H8.33999Z" />
      <path d="M22.21 10.3L19.68 12.83V21.25H4.59V12.83L2.06 10.3L1 11.36L3.09 13.45V21.24C3.08868 21.4379 3.12651 21.634 3.2013 21.8171C3.27609 22.0003 3.38637 22.1668 3.52579 22.3072C3.66521 22.4475 3.83101 22.5589 4.01366 22.6349C4.1963 22.7109 4.39217 22.7501 4.59 22.75H19.68C19.8778 22.7501 20.0737 22.7109 20.2563 22.6349C20.439 22.5589 20.6048 22.4475 20.7442 22.3072C20.8836 22.1668 20.9939 22.0003 21.0687 21.8171C21.1435 21.634 21.1813 21.4379 21.18 21.24V13.45L23.27 11.36L22.21 10.3Z" />
    </g>
    <defs>
      <clipPath id="clip0_914:15571">
        <rect width={22.27} height={21.75} fill="white" transform="translate(1 1)" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDonations;
