import { gql, useMutation } from '@apollo/client';

import { CancelOrderMutation, CancelOrderMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation CancelOrder($input: CancelOrderInput!) {
    cancelOrder(input: $input) {
      success
    }
  }
`;

export const useCancelOrder = () =>
  useMutation<CancelOrderMutation, CancelOrderMutationVariables>(mutation, {
    refetchQueries: ['MarketOrders', 'Accounts', 'BuyOrders', 'SellOrders'],
  });
