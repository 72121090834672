import * as React from 'react';
import { SVGProps } from 'react';
const SvgLock = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.75 6.75C8.75 4.95507 10.2051 3.5 12 3.5C13.7949 3.5 15.25 4.95507 15.25 6.75V10H8.75V6.75ZM7.25 10V6.75C7.25 4.12665 9.37665 2 12 2C14.6234 2 16.75 4.12665 16.75 6.75V10H18.48C19.3195 10 20 10.6805 20 11.52V20.48C20 21.3195 19.3195 22 18.48 22H5.52C4.68053 22 4 21.3195 4 20.48V11.52C4 10.6805 4.68053 10 5.52 10H7.25ZM18.4845 11.5002C18.4835 11.5001 18.4821 11.5 18.48 11.5H5.52C5.51665 11.5 5.51494 11.5003 5.51438 11.5004L5.51268 11.501C5.51137 11.5015 5.50876 11.503 5.50586 11.5059C5.50296 11.5088 5.50151 11.5114 5.50096 11.5127L5.50043 11.5144C5.50031 11.5149 5.5 11.5167 5.5 11.52V20.48C5.5 20.4834 5.50031 20.4851 5.50043 20.4856L5.50096 20.4873C5.50151 20.4886 5.50296 20.4912 5.50586 20.4941C5.50876 20.497 5.51137 20.4985 5.51268 20.499L5.51438 20.4996C5.51494 20.4997 5.51665 20.5 5.52 20.5H18.48L18.4833 20.4999L18.4856 20.4996L18.4873 20.499C18.4886 20.4985 18.4912 20.497 18.4941 20.4941C18.4956 20.4927 18.4967 20.4913 18.4975 20.4902C18.4983 20.489 18.4988 20.488 18.499 20.4873L18.4996 20.4856C18.4997 20.4851 18.5 20.4833 18.5 20.48V11.52C18.5 11.5166 18.4997 11.5149 18.4996 11.5144L18.499 11.5127C18.4985 11.5114 18.497 11.5088 18.4941 11.5059C18.4912 11.503 18.4886 11.5015 18.4873 11.501L18.4856 11.5004L18.4845 11.5002ZM12 13.25C12.4142 13.25 12.75 13.5858 12.75 14V18C12.75 18.4142 12.4142 18.75 12 18.75C11.5858 18.75 11.25 18.4142 11.25 18V14C11.25 13.5858 11.5858 13.25 12 13.25Z"
      fill="#DA2D43"
    />
  </svg>
);
export default SvgLock;
