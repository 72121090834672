import * as React from 'react';
import { SVGProps } from 'react';
const SvgSortAvg = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="#12123B" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6665 2.66676L11.6665 13.3335L12.6665 13.3335L12.6665 2.66676L11.6665 2.66676Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99999 5.33344L8.99999 13.3335L10 13.3335L10 5.33344L8.99999 5.33344Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.33325 8.00013L6.33325 13.3335L7.33325 13.3335L7.33325 8.00013L6.33325 8.00013Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.66674 10.6668L3.66674 13.3335L4.66675 13.3335L4.66675 10.6668L3.66674 10.6668Z"
    />
    <line x1={2.80688} y1={9.60538} x2={14.0351} y2={9.60538} stroke="#474AEE" />
  </svg>
);
export default SvgSortAvg;
