import { gql, useMutation } from '@apollo/client';

import { CancelLightningInvoicesMutation } from './../gqlTypes';

const mutation = gql`
  mutation CancelLightningInvoices {
    cancelLightningInvoices
  }
`;

export const useCancelLightningInvoice = () => {
  return useMutation<CancelLightningInvoicesMutation>(mutation);
};
