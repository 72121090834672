import { gql, useQuery } from '@apollo/client';

import { OpenBanksQuery } from './../gqlTypes';

const QUERY = gql`
  query OpenBanks {
    openBankingBanks {
      countryCode
      bankingGroupName
      personalIdentificationRequired
      id
      bankDisplayName
      supportedServices
      bic
      bankOfficialName
      status
    }
  }
`;

export const useOpenBanks = () =>
  useQuery<OpenBanksQuery>(QUERY, { fetchPolicy: 'cache-and-network' });
