import * as React from 'react';
import { SVGProps } from 'react';
const SvgBellFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 23 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.14437 25.8721C8.1443 25.8797 8.1438 25.8872 8.1438 25.8948C8.1438 27.7938 9.68321 29.3332 11.5822 29.3332C13.4811 29.3332 15.0205 27.7938 15.0205 25.8948C15.0205 25.8872 15.02 25.8797 15.02 25.8721H8.14437Z"
      fill="currentColor"
    />
    <path
      d="M22.5601 21.5741L19.4655 17.0283C19.4655 15.6368 19.4655 12.2365 19.4655 11.357C19.4655 7.53221 16.7417 4.34405 13.128 3.62558V1.87916C13.128 1.02546 12.4359 0.333313 11.5821 0.333313C10.7284 0.333313 10.0363 1.02546 10.0363 1.87916V3.62565C6.42267 4.34418 3.6988 7.53234 3.6988 11.357C3.6988 12.8543 3.6988 16.1605 3.6988 17.0284L0.604265 21.5742C0.278302 22.053 0.243831 22.6728 0.514677 23.1848C0.785524 23.6968 1.31724 24.017 1.8965 24.017H21.2678C21.847 24.017 22.3788 23.6968 22.6496 23.1848C22.9205 22.6727 22.886 22.0529 22.5601 21.5741Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgBellFilled;
