import * as React from 'react';
import { SVGProps } from 'react';
const SvgFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3965 4H20V5.5H18.3965C18.07 6.65425 17.0088 7.5 15.75 7.5C14.4912 7.5 13.43 6.65425 13.1035 5.5H4V4H13.1035C13.43 2.84575 14.4912 2 15.75 2C17.0088 2 18.07 2.84575 18.3965 4ZM16.9999 4.75C16.9999 5.44036 16.4403 6 15.7499 6C15.0596 6 14.4999 5.44036 14.4999 4.75C14.4999 4.05964 15.0596 3.5 15.7499 3.5C16.4403 3.5 16.9999 4.05964 16.9999 4.75Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 11H12.3965C12.07 9.84575 11.0088 9 9.75 9C8.49122 9 7.42998 9.84575 7.10352 11H4V12.5H7.10352C7.42998 13.6543 8.49122 14.5 9.75 14.5C11.0088 14.5 12.07 13.6543 12.3965 12.5H20V11ZM10.9834 11.7963C10.9834 12.4866 10.4238 13.0463 9.7334 13.0463C9.04304 13.0463 8.4834 12.4866 8.4834 11.7963C8.4834 11.1059 9.04304 10.5463 9.7334 10.5463C10.4238 10.5463 10.9834 11.1059 10.9834 11.7963Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 20H11.1035C11.43 21.1543 12.4912 22 13.75 22C15.0088 22 16.07 21.1543 16.3965 20H20V18.5H16.3965C16.07 17.3457 15.0088 16.5 13.75 16.5C12.4912 16.5 11.43 17.3457 11.1035 18.5H4V20ZM15.0646 19.25C15.0646 19.9404 14.505 20.5 13.8146 20.5C13.1243 20.5 12.5646 19.9404 12.5646 19.25C12.5646 18.5596 13.1243 18 13.8146 18C14.505 18 15.0646 18.5596 15.0646 19.25Z"
    />
  </svg>
);
export default SvgFilter;
