import * as React from 'react';
import { SVGProps } from 'react';
const SvgEth = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={22} cy={22} r={22} fill="#627EEA" />
    <path d="M21.3638 11V19.0636L28.1792 22.1091L21.3638 11Z" fill="white" fillOpacity={0.602} />
    <path d="M21.8164 11L15 22.1091L21.8164 19.0636V11Z" fill="white" />
    <path
      d="M21.3638 27.6837V33.1628L28.1838 23.7273L21.3638 27.6837Z"
      fill="white"
      fillOpacity={0.602}
    />
    <path d="M21.8164 33.1628V27.6827L15 23.7273L21.8164 33.1628Z" fill="white" />
    <path
      d="M21.3638 26.1827L28.1792 22.2255L21.3638 19.1818V26.1827Z"
      fill="white"
      fillOpacity={0.2}
    />
    <path d="M15 22.2255L21.8164 26.1827V19.1818L15 22.2255Z" fill="white" fillOpacity={0.602} />
  </svg>
);
export default SvgEth;
