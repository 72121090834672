import { gql, useQuery } from '@apollo/client';

import { ActivitiesQuery } from '../gqlTypes';

const query = gql`
  query Activities {
    activities {
      id
      type
      created
      details {
        detailsId
        amount
        aon
        market
        price
        type
        original
        total
        from
        currency
        reference
        accountNumber
        iban
        to
      }
    }
  }
`;

export const useActivities = () => useQuery<ActivitiesQuery>(query);
