import * as React from 'react';
import { SVGProps } from 'react';
const SvgUserId = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.66665 5.33333C3.37998 5.33333 2.33331 4.28667 2.33331 3C2.33331 1.71333 3.37998 0.666668 4.66665 0.666668C5.95331 0.666668 6.99998 1.71333 6.99998 3C6.99998 4.28667 5.95331 5.33333 4.66665 5.33333ZM4.66665 1.33333C3.74765 1.33333 2.99998 2.081 2.99998 3C2.99998 3.919 3.74765 4.66667 4.66665 4.66667C5.58565 4.66667 6.33331 3.919 6.33331 3C6.33331 2.081 5.58565 1.33333 4.66665 1.33333ZM8.99998 8.66667V9.33333H8.33331V8.66667C8.33331 7.74767 7.58565 7 6.66665 7H2.33331C1.59798 7 0.99998 7.598 0.99998 8.33333V9.33333H0.333313V8.33333C0.333313 7.23067 1.23065 6.33333 2.33331 6.33333H6.66665C7.95331 6.33333 8.99998 7.38 8.99998 8.66667ZM8.66665 4.048H12.6666V3.66667H8.66665V4.048ZM10.764 5.19199H12.6666V4.81066H8.66665V5.19199H10.764ZM12.6666 6.33599H8.66665V5.95466H12.6666V6.33599Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgUserId;
