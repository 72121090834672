import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';

type Breakpoints = 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
const breakpoints: { [key in Breakpoints]: number } = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
};

export const useBreakpoint = (breakpoint: Breakpoints): boolean => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const throttledHandleResize = throttle(() => {
      setWidth(window.innerWidth);
    }, 100);

    window.addEventListener('resize', throttledHandleResize);
    return () => {
      window.removeEventListener('resize', throttledHandleResize);
      throttledHandleResize.cancel();
    };
  }, []);

  return width < breakpoints[breakpoint];
};
