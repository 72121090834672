import * as React from 'react';

import { Button, ButtonProps, ButtonSkins } from '../button/Button';
import { useCopyToClipboard } from './useCopyToClipboard';

export interface CopyButtonProps extends ButtonProps {
  value: string;
  text?: React.ReactNode;
  postCopyText?: React.ReactNode;
  children?: React.ReactNode;
  copiedSkin?: ButtonSkins;
  onCopy?: () => void;
}

// trigger

export const CopyButton = React.forwardRef<HTMLButtonElement, CopyButtonProps>(
  (props, ref) => {
    const { copy } = useCopyToClipboard();
    const [copied, setCopied] = React.useState(false);

    const { postCopyText, copiedSkin, text, value, skin, icon, ...btnProps } =
      props;

    React.useEffect(() => {
      if (copied) {
        setTimeout(() => {
          setCopied(false);
        }, 5000);
      }
    }, [copied]);

    function handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
      e.preventDefault();
      if (!copied) {
        copy(props.value);
        setCopied(true);
        props.onCopy?.();
      }
    }

    return (
      <Button
        {...btnProps}
        skin={copied ? copiedSkin || 'success' : skin}
        icon={copied ? 'check-circle' : icon}
        ref={ref}
        onClick={handleClick}
        aria-label={`Copy ${value} to clipboard`}
        aria-pressed={copied}
      >
        {copied && postCopyText ? postCopyText : text || props.children}
      </Button>
    );
  }
);
