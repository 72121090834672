import { gql, useMutation } from '@apollo/client';

import { SubmitSurveyMutation, SubmitSurveyMutationVariables } from './../gqlTypes';

const mutation = gql`
  mutation SubmitSurvey($input: SubmitSurveyInput!) {
    submitSurvey(input: $input) {
      success
    }
  }
`;

export const useSubmitSurvey = () =>
  useMutation<SubmitSurveyMutation, SubmitSurveyMutationVariables>(mutation);
