import * as React from 'react';
import { SVGProps } from 'react';
const SvgCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_4:667)">
      <path d="M14.35 10.0801V8.58008H6.41V10.0801H14.35Z" />
      <path d="M14.35 14.13V12.63H6.41V14.13H14.35Z" />
      <path d="M14.35 18.17V16.67H6.41V18.17H14.35Z" />
      <path d="M16.26 4H4.5C4.10218 4 3.72064 4.15804 3.43934 4.43934C3.15804 4.72064 3 5.10218 3 5.5V21.25C3 21.6478 3.15804 22.0294 3.43934 22.3107C3.72064 22.592 4.10218 22.75 4.5 22.75H16.26C16.6578 22.75 17.0394 22.592 17.3207 22.3107C17.602 22.0294 17.76 21.6478 17.76 21.25V5.5C17.76 5.10218 17.602 4.72064 17.3207 4.43934C17.0394 4.15804 16.6578 4 16.26 4ZM16.26 21.25H4.5V5.5H16.26V21.25Z" />
      <path d="M19.46 1.00005H7.7C7.30218 1.00005 6.92065 1.15808 6.63934 1.43938C6.35804 1.72069 6.2 2.10222 6.2 2.50005H19.46V20.5C19.6603 20.5054 19.8597 20.4705 20.0463 20.3976C20.2329 20.3246 20.4031 20.2149 20.5466 20.0751C20.6902 19.9353 20.8042 19.7681 20.8821 19.5835C20.96 19.3988 21.0001 19.2004 21 19V2.50005C21.0001 2.29965 20.96 2.10127 20.8821 1.91663C20.8042 1.73198 20.6902 1.56481 20.5466 1.42499C20.4031 1.28516 20.2329 1.17552 20.0463 1.10254C19.8597 1.02955 19.6603 0.994701 19.46 1.00005Z" />
    </g>
    <defs>
      <clipPath id="clip0_4:667">
        <rect width={17.96} height={21.75} fill="white" transform="translate(3 1)" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCopy;
