import * as React from 'react';
import { SVGProps } from 'react';
const SvgTiktokRound = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.6455 15.728C10.4133 15.7281 10.1845 15.6681 9.97906 15.5532C9.80248 15.2949 9.70704 14.9839 9.70702 14.6649C9.70739 14.261 9.85885 13.8738 10.1282 13.5882C10.3975 13.3026 10.7626 13.1419 11.1435 13.1414C11.2915 13.1414 11.4334 13.1674 11.5674 13.212V11.362C11.427 11.3409 11.2853 11.33 11.1435 11.3293C11.1285 11.3293 11.1137 11.3298 11.0989 11.3304L11.095 11.3305C11.0864 11.3308 11.0779 11.3311 11.0694 11.3313V10.9022C10.929 10.8811 10.7874 10.8702 10.6455 10.8695C8.91108 10.8695 7.5 12.366 7.5 14.2051C7.5 15.3333 8.03238 16.3324 8.84353 16.9363C9.13711 17.2718 9.4928 17.5394 9.88841 17.7225C10.2843 17.9056 10.7116 18.0001 11.1435 18C12.878 18 14.2891 16.5037 14.2891 14.6649V10.9823C14.9817 11.5105 15.8135 11.7941 16.6667 11.7931V9.98066C16.4956 9.98066 16.3293 9.9612 16.1684 9.92467V9.52111C15.7088 9.52157 15.2586 9.37927 14.8738 9.11273C14.4966 8.65508 14.2888 8.06783 14.289 7.46003L12.58 7.45978H13.8312C13.8045 7.30816 13.791 7.15425 13.7908 7H12.082L12.0792 14.2623C12.0507 15.0755 11.4191 15.728 10.6455 15.728ZM8.20548 15.8549C8.35147 16.2582 8.56933 16.6241 8.84219 16.935C8.56378 16.6191 8.34879 16.2517 8.20548 15.8549Z"
      fill="#12123B"
    />
  </svg>
);
export default SvgTiktokRound;
