import { firiMerge } from '@firi/shared/styles';
import { motion } from 'framer-motion';
import React from 'react';

import { Card, CardProps } from '../card/Card';

export type ButtonCardProps = CardProps & {
  onClick?: () => void;
  children?: React.ReactNode;
  variant?: 'scale' | 'border';
  as?: 'button' | 'div';
};

export const ButtonCard = (props: ButtonCardProps) => {
  const variant = props.variant || 'scale';

  if (variant === 'border') {
    return (
      <motion.div
        whileHover={{
          scale: 0.98,
          transition: { duration: 0.1 },
        }}
        whileTap={{
          scale: 0.98,
          transition: { duration: 0.1 },
        }}
        className="flex flex-1"
      >
        <Card
          {...props}
          border
          className={firiMerge(
            'w-full text-left hover:border-blue',
            props.as === 'div' ? 'cursor-pointer' : '',
            props.className
          )}
          tabIndex={props.as === 'div' ? 0 : undefined}
          as={props.as || 'button'}
        >
          {props.children}
        </Card>
      </motion.div>
    );
  }
  return (
    <motion.div
      whileHover={{
        scale: 0.98,
        transition: { duration: 0.1 },
      }}
      whileTap={{
        scale: 0.98,
        transition: { duration: 0.1 },
      }}
      className="flex flex-1"
    >
      <Card
        {...props}
        className={firiMerge(
          'w-full text-left',
          props.as === 'div' ? 'cursor-pointer' : '',
          props.className
        )}
        tabIndex={props.as === 'div' ? 0 : undefined}
        as={props.as || 'button'}
      >
        {props.children}
      </Card>
    </motion.div>
  );
};
