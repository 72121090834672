import * as React from 'react';
import { SVGProps } from 'react';
const SvgUni = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1541_5622)">
      <circle cx={12} cy={12} r={12} fill="#FF007A" />
      <g clipPath="url(#clip1_1541_5622)">
        <path
          d="M9.78931 6.86843C9.6051 6.84212 9.59633 6.83335 9.68405 6.82457C9.85071 6.79826 10.2367 6.83335 10.5086 6.89475C11.1402 7.04387 11.7104 7.42984 12.3156 8.10528L12.4735 8.28949L12.7016 8.2544C13.6753 8.09651 14.6753 8.21931 15.5086 8.60528C15.7367 8.71054 16.0963 8.92107 16.1402 8.9737C16.1577 8.99124 16.184 9.10528 16.2016 9.21931C16.263 9.63159 16.2367 9.93861 16.1051 10.1755C16.0349 10.307 16.0349 10.3421 16.0788 10.4562C16.1139 10.5439 16.2191 10.6053 16.3156 10.6053C16.5262 10.6053 16.7455 10.2719 16.8507 9.80703L16.8946 9.62282L16.9735 9.71054C17.4209 10.2105 17.7718 10.9035 17.8244 11.3948L17.8419 11.5263L17.763 11.4123C17.6314 11.2105 17.5086 11.079 17.3419 10.9649C17.0437 10.7632 16.7279 10.7018 15.8946 10.6579C15.1402 10.614 14.7104 10.5526 14.2893 10.4123C13.57 10.1755 13.2016 9.86843 12.3507 8.73686C11.9735 8.23686 11.7367 7.96493 11.4998 7.73686C10.9823 7.23686 10.4647 6.9737 9.78931 6.86843Z"
          fill="white"
        />
        <path
          d="M16.3246 7.98249C16.3422 7.64915 16.386 7.42986 16.4825 7.2281C16.5176 7.14916 16.5527 7.07898 16.5615 7.07898C16.5702 7.07898 16.5527 7.14038 16.5264 7.21056C16.4562 7.40354 16.4474 7.67547 16.4913 7.98249C16.5527 8.37723 16.579 8.42986 17.0001 8.85968C17.193 9.06144 17.4211 9.31582 17.5088 9.42108L17.6579 9.61407L17.5088 9.47372C17.3246 9.29828 16.9036 8.96494 16.8071 8.92108C16.7457 8.886 16.7369 8.886 16.693 8.92986C16.6579 8.96494 16.6492 9.01758 16.6492 9.27196C16.6404 9.6667 16.5878 9.91231 16.4562 10.1667C16.386 10.2983 16.3772 10.272 16.4386 10.1228C16.4825 10.0088 16.4913 9.95617 16.4913 9.57898C16.4913 8.81582 16.4036 8.63161 15.8685 8.32459C15.7369 8.24565 15.5088 8.13161 15.3772 8.07021C15.2369 8.0088 15.1316 7.95617 15.1404 7.95617C15.1579 7.93863 15.6755 8.08775 15.8772 8.17547C16.1843 8.29828 16.2369 8.30705 16.272 8.29828C16.2983 8.27196 16.3158 8.20179 16.3246 7.98249Z"
          fill="white"
        />
        <path
          d="M10.149 9.28947C9.78062 8.7807 9.54378 7.99123 9.59641 7.40351L9.61396 7.2193L9.70167 7.23684C9.85957 7.26316 10.1315 7.36842 10.2631 7.44737C10.614 7.6579 10.7719 7.94737 10.921 8.66667C10.9648 8.87719 11.0262 9.12281 11.0526 9.20176C11.0964 9.33333 11.2631 9.64035 11.4034 9.83333C11.4999 9.97369 11.4385 10.0439 11.2192 10.0263C10.8859 9.99123 10.4385 9.68421 10.149 9.28947Z"
          fill="white"
        />
        <path
          d="M15.877 13.1053C14.1402 12.4035 13.5261 11.7982 13.5261 10.7719C13.5261 10.6228 13.5349 10.5 13.5349 10.5C13.5437 10.5 13.6051 10.5526 13.684 10.614C14.0349 10.8947 14.4296 11.0175 15.5261 11.1754C16.1665 11.2719 16.5349 11.3421 16.8682 11.4561C17.9296 11.807 18.5875 12.5263 18.7454 13.5C18.7893 13.7807 18.763 14.3158 18.6928 14.5965C18.6314 14.8158 18.4559 15.2193 18.4121 15.2281C18.4033 15.2281 18.3858 15.1842 18.3858 15.114C18.3682 14.7456 18.184 14.3947 17.877 14.1228C17.5086 13.807 17.0349 13.5702 15.877 13.1053Z"
          fill="white"
        />
        <path
          d="M14.6493 13.3948C14.6317 13.2632 14.5879 13.0965 14.5616 13.0264L14.5177 12.8948L14.5967 12.9913C14.7107 13.1228 14.7984 13.2807 14.8774 13.5C14.9388 13.6667 14.9388 13.7193 14.9388 13.9913C14.9388 14.2544 14.93 14.3158 14.8774 14.465C14.7896 14.7018 14.6844 14.8685 14.5089 15.0527C14.1931 15.3772 13.7809 15.5527 13.1931 15.6316C13.0879 15.6404 12.7896 15.6667 12.5265 15.6842C11.8686 15.7193 11.43 15.7895 11.0353 15.9299C10.9826 15.9474 10.93 15.965 10.9212 15.9562C10.9037 15.9386 11.1756 15.7807 11.3949 15.6755C11.7019 15.5264 12.0177 15.4474 12.7107 15.3246C13.0528 15.272 13.4037 15.2018 13.4914 15.1667C14.3598 14.8948 14.7896 14.2193 14.6493 13.3948Z"
          fill="white"
        />
        <path
          d="M15.4472 14.807C15.2192 14.307 15.1665 13.8333 15.2893 13.3859C15.3069 13.3421 15.3244 13.2982 15.342 13.2982C15.3595 13.2982 15.4121 13.3245 15.4648 13.3596C15.57 13.4298 15.7893 13.5526 16.3507 13.8596C17.0613 14.2456 17.4648 14.5438 17.7455 14.8859C17.9911 15.1842 18.1402 15.5263 18.2104 15.9473C18.2542 16.1842 18.2279 16.7544 18.1665 16.9912C17.9735 17.7368 17.5349 18.3333 16.8946 18.6754C16.7981 18.728 16.7192 18.7631 16.7104 18.7631C16.7016 18.7631 16.7367 18.6754 16.7893 18.5701C16.9999 18.1228 17.0262 17.693 16.8683 17.2105C16.7718 16.9123 16.57 16.5526 16.1665 15.9473C15.6841 15.2456 15.57 15.0614 15.4472 14.807Z"
          fill="white"
        />
        <path
          d="M8.91248 17.4912C9.5616 16.9473 10.3599 16.5613 11.0967 16.4385C11.4125 16.3859 11.9388 16.4034 12.2283 16.4824C12.6932 16.6052 13.1142 16.8684 13.3335 17.1929C13.5441 17.5087 13.6406 17.7806 13.737 18.3859C13.7721 18.6227 13.816 18.8684 13.8248 18.921C13.8949 19.2368 14.0353 19.4824 14.2107 19.614C14.4827 19.8157 14.9563 19.8245 15.4213 19.6491C15.5002 19.6227 15.5704 19.5964 15.5704 19.6052C15.5879 19.6227 15.3511 19.7806 15.1932 19.8596C14.9739 19.9736 14.7984 20.0087 14.5616 20.0087C14.1406 20.0087 13.7809 19.7894 13.4914 19.3508C13.43 19.2631 13.3072 19.0087 13.202 18.7719C12.8949 18.0613 12.737 17.8508 12.3774 17.614C12.0616 17.4122 11.6581 17.3684 11.3511 17.5175C10.9476 17.7105 10.8423 18.228 11.123 18.5438C11.237 18.6754 11.4476 18.7806 11.623 18.807C11.9476 18.8508 12.2283 18.5964 12.2283 18.2719C12.2283 18.0613 12.1493 17.9385 11.9388 17.842C11.6581 17.7192 11.3511 17.8596 11.3599 18.1315C11.3599 18.2455 11.4125 18.3157 11.5265 18.3684C11.5967 18.4034 11.5967 18.4034 11.5441 18.3947C11.2897 18.342 11.2283 18.0263 11.43 17.8245C11.6756 17.5789 12.1932 17.6841 12.3686 18.0263C12.4388 18.1666 12.4476 18.4473 12.3862 18.6227C12.237 19.0087 11.816 19.2105 11.3862 19.0964C11.0967 19.0175 10.9739 18.9385 10.623 18.5789C10.009 17.9473 9.77213 17.8245 8.89494 17.6929L8.72827 17.6666L8.91248 17.4912Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.29766 3.37719C6.34152 5.85965 9.49064 9.7193 9.64854 9.92982C9.78012 10.1053 9.72748 10.2719 9.50818 10.3947C9.38538 10.4649 9.13099 10.5351 9.00818 10.5351C8.86783 10.5351 8.70117 10.4649 8.58713 10.3509C8.50818 10.2719 8.16608 9.77193 7.39415 8.57018C6.80643 7.64912 6.30643 6.88597 6.29766 6.87719C6.26257 6.85965 6.26257 6.85965 7.33275 8.77193C8.00819 9.97368 8.22748 10.4035 8.22748 10.4561C8.22748 10.5702 8.1924 10.6316 8.05204 10.7895C7.8152 11.0526 7.70994 11.3509 7.63099 11.9737C7.54327 12.6667 7.30643 13.1579 6.63099 13.9912C6.23626 14.4825 6.17485 14.5702 6.07836 14.7719C5.95555 15.0175 5.92047 15.1579 5.90292 15.4737C5.88538 15.807 5.92047 16.0175 6.01696 16.3333C6.10468 16.614 6.20117 16.7982 6.43801 17.1579C6.63976 17.4737 6.76257 17.7105 6.76257 17.7982C6.76257 17.8684 6.78012 17.8684 7.0959 17.7982C7.85029 17.6228 8.4731 17.3246 8.8152 16.9561C9.02573 16.7281 9.07836 16.6053 9.07836 16.2895C9.07836 16.0877 9.06959 16.0439 9.01696 15.9211C8.92924 15.7281 8.76257 15.5702 8.40292 15.3246C7.92924 15 7.72748 14.7368 7.67485 14.386C7.63099 14.0877 7.68362 13.886 7.94678 13.3333C8.21871 12.7632 8.28889 12.5263 8.33275 11.9474C8.35906 11.5789 8.40292 11.4298 8.50819 11.3158C8.62222 11.193 8.71871 11.1491 8.99064 11.114C9.43801 11.0526 9.72748 10.9386 9.95555 10.7193C10.1573 10.5351 10.245 10.3509 10.2538 10.0789L10.2626 9.87719L10.1485 9.75439C9.73626 9.2807 4.02573 3 3.99941 3C3.99064 3 4.13099 3.16667 4.29766 3.37719ZM6.99064 15.8509C7.08713 15.6842 7.0345 15.4737 6.87661 15.3684C6.72748 15.2719 6.49941 15.3158 6.49941 15.4474C6.49941 15.4825 6.51696 15.5175 6.56959 15.5351C6.64854 15.5789 6.65731 15.6228 6.5959 15.7193C6.5345 15.8158 6.5345 15.9035 6.61345 15.9649C6.73626 16.0614 6.90292 16.0088 6.99064 15.8509Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5438 11.2368C10.3333 11.2982 10.1315 11.5263 10.0701 11.7544C10.035 11.8947 10.0526 12.1491 10.114 12.2281C10.2105 12.3509 10.2982 12.386 10.5438 12.386C11.0263 12.386 11.4386 12.1754 11.4824 11.921C11.5263 11.7105 11.3421 11.421 11.0877 11.2895C10.9561 11.2193 10.6842 11.193 10.5438 11.2368ZM11.1052 11.6754C11.1754 11.5702 11.1491 11.4561 11.0175 11.3772C10.7807 11.2281 10.421 11.3509 10.421 11.5789C10.421 11.693 10.6052 11.8158 10.7807 11.8158C10.8947 11.8158 11.0526 11.7456 11.1052 11.6754Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1541_5622">
        <rect width={24} height={24} fill="white" />
      </clipPath>
      <clipPath id="clip1_1541_5622">
        <rect width={14.7632} height={17} fill="white" transform="translate(4 3)" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgUni;
