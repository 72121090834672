import { gql, useMutation } from '@apollo/client';

import {
  RegisterCustomerIoTokenMutation,
  RegisterCustomerIoTokenMutationVariables,
} from '../gqlTypes';

const mutation = gql`
  mutation RegisterCustomerIoToken($input: CustomerIoRegisterInput!) {
    registerCustomerIoPushToken(input: $input) {
      success
      message
    }
  }
`;

export const useRegisterCustomerIoToken = () =>
  useMutation<RegisterCustomerIoTokenMutation, RegisterCustomerIoTokenMutationVariables>(mutation);
