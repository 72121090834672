import { firiMerge } from '@firi/shared/styles';
import { ClassValue } from 'clsx';
import { ReactNode } from 'react';

interface ContentWrapperProps {
  children: ReactNode;
  className?: ClassValue;
  constrained?: boolean;
}

const ContentWrapper = ({ children, className, constrained }: ContentWrapperProps) => (
  <div className="flex justify-center">
    <div
      className={firiMerge(
        'px-4 w-full lg:px-9',
        { 'max-w-[1440px]': !constrained, 'max-w-[1208px]': constrained },
        className,
      )}
    >
      {children}
    </div>
  </div>
);

export default ContentWrapper;
