import * as React from 'react';
import { SVGProps } from 'react';
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" fill="#12123B" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.75 15.75V7.32246L17.25 5.82246V16.5C17.25 16.9142 16.9142 17.25 16.5 17.25H1.5C1.08579 17.25 0.75 16.9142 0.75 16.5V1.5C0.75 1.08579 1.08579 0.75 1.5 0.75H12.1775L10.6775 2.25H2.25V15.75H15.75ZM15 1C15.1326 1 15.2598 1.05268 15.3536 1.14645L16.8536 2.64645C17.0488 2.84171 17.0488 3.15829 16.8536 3.35355L15.3536 4.85355C15.2598 4.94732 15.1326 5 15 5C14.8674 5 14.7402 4.94732 14.6465 4.85355L13.1465 3.35355C12.9512 3.15829 12.9512 2.84171 13.1465 2.64645L14.6465 1.14645C14.7402 1.05268 14.8674 1 15 1ZM12 4C12.1326 4 12.2598 4.05268 12.3536 4.14645L13.8536 5.64645C14.0488 5.84171 14.0488 6.15829 13.8536 6.35355L7.35356 12.8536C7.28948 12.9176 7.20919 12.9631 7.12128 12.9851L5.12128 13.4851C4.95089 13.5277 4.77065 13.4777 4.64646 13.3536C4.52227 13.2294 4.47234 13.0491 4.51494 12.8787L5.01494 10.8787C5.03692 10.7908 5.08238 10.7105 5.14646 10.6464L11.6465 4.14645C11.7402 4.05268 11.8674 4 12 4Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEdit;
