import * as React from 'react';
import { SVGProps } from 'react';
const SvgMore = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="black" {...props}>
    <path d="M4.125 14.625C2.6775 14.625 1.5 13.4475 1.5 12C1.5 10.5525 2.6775 9.375 4.125 9.375C5.5725 9.375 6.75 10.5525 6.75 12C6.75 13.4475 5.5725 14.625 4.125 14.625ZM4.125 10.875C3.50475 10.875 3 11.3798 3 12C3 12.6203 3.50475 13.125 4.125 13.125C4.74525 13.125 5.25 12.6203 5.25 12C5.25 11.3798 4.74525 10.875 4.125 10.875Z" />
    <path d="M12 14.625C10.5525 14.625 9.375 13.4475 9.375 12C9.375 10.5525 10.5525 9.375 12 9.375C13.4475 9.375 14.625 10.5525 14.625 12C14.625 13.4475 13.4475 14.625 12 14.625ZM12 10.875C11.3798 10.875 10.875 11.3798 10.875 12C10.875 12.6203 11.3798 13.125 12 13.125C12.6203 13.125 13.125 12.6203 13.125 12C13.125 11.3798 12.6203 10.875 12 10.875Z" />
    <path d="M19.875 14.625C18.4275 14.625 17.25 13.4475 17.25 12C17.25 10.5525 18.4275 9.375 19.875 9.375C21.3225 9.375 22.5 10.5525 22.5 12C22.5 13.4475 21.3225 14.625 19.875 14.625ZM19.875 10.875C19.2548 10.875 18.75 11.3798 18.75 12C18.75 12.6203 19.2548 13.125 19.875 13.125C20.4953 13.125 21 12.6203 21 12C21 11.3798 20.4953 10.875 19.875 10.875Z" />
  </svg>
);
export default SvgMore;
