import { gql, useMutation } from '@apollo/client';

import { UnRegisterPushTokenMutation, UnRegisterPushTokenMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation UnRegisterPushToken($input: PushNotificationInput!) {
    unRegisterPushToken(input: $input) {
      success
      message
    }
  }
`;

export const useUnRegisterPushToken = () =>
  useMutation<UnRegisterPushTokenMutation, UnRegisterPushTokenMutationVariables>(mutation);
