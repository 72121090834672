import * as React from 'react';
import { SVGProps } from 'react';
const SvgTax = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 2C4.44772 2 4 2.44772 4 3V21C4 21.5523 4.44772 22 5 22H19C19.5523 22 20 21.5523 20 21V9.41421C20 9.149 19.8946 8.89464 19.7071 8.70711L13.2929 2.29289C13.1054 2.10536 12.851 2 12.5858 2H5ZM18.5 10.25V20.5H5.5V3.5H11.75V9.25C11.75 9.80228 12.1977 10.25 12.75 10.25H18.5ZM17.6287 8.75L13.25 4.37132V8.75H17.6287Z"
    />
    <path d="M9.36812 18.5H8.00012V11.633H9.36812V15.251L11.0961 13.424H12.8061L10.6371 15.62L12.9411 18.5H11.2941L9.36812 16.025V18.5Z" />
    <path d="M13.4933 18.5V13.424H14.8613V14C15.0353 13.748 15.2303 13.571 15.4463 13.469C15.6683 13.367 15.9293 13.316 16.2293 13.316H16.4183V14.621H16.1303C15.7463 14.621 15.4523 14.717 15.2483 14.909C15.1103 15.041 15.0113 15.215 14.9513 15.431C14.8913 15.641 14.8613 15.917 14.8613 16.259V18.5H13.4933Z" />
    <path d="M7 6.75H10.5V5.25H7V6.75Z" />
    <path d="M7 9.75H10.5V8.25H7V9.75Z" />
  </svg>
);
export default SvgTax;
