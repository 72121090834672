import { gql } from '@apollo/client';

export const SavingAgreementFragment = gql`
  fragment SavingAgreementFragment on SavingAgreement {
    id
    accountGroupId
    amount
    recurring
    savingDetails {
      id
      currency
      distribution
    }
  }
`;
