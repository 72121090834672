import { useScrollBlock, useShowIntercom } from '@firi/shared/hooks';
import clsx from 'clsx';
import { AnimatePresence, motion, PanInfo, useAnimation } from 'framer-motion';
import * as React from 'react';
import { PropsWithChildren, useEffect, useRef } from 'react';

import { FontColors, Icon } from '../../01-atoms';
import { BottomSheetHandle } from './BottomSheetHandle';

export type SheetHeight = 'full' | '5/6' | 'auto';

type Props = PropsWithChildren & {
  isOpen: boolean;
  onClose: () => void;
  scrollable?: boolean;
  backdropScrollable?: boolean;
  height?: SheetHeight;
  className?: string;
  closeIconColor?: FontColors;
  disableClose?: boolean;
  onCloseCompleted?: () => void;
};

export const BottomSheet = ({
  isOpen,
  onClose,
  children,
  height = '5/6',
  scrollable,
  backdropScrollable,
  className,
  closeIconColor,
  disableClose,
  onCloseCompleted,
}: Props) => {
  const { allowScroll, blockScroll } = useScrollBlock();
  const { hide, show } = useShowIntercom();
  const contentContainerRef = useRef<HTMLDivElement>(null);

  const heights = {
    full: 'h-full',
    '5/6': 'h-5/6',
    auto: 'h-auto min-h-[300px] max-h-full',
  };

  const prevIsOpen = usePrevious(isOpen);
  const controls = useAnimation();

  function onDragEnd(
    _event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ) {
    if (disableClose) return;

    const shouldClose =
      info.velocity.y > 20 &&
      (contentContainerRef.current?.scrollTop || 0) <= 0;
    if (shouldClose) {
      controls.start('hidden');
      onClose();
    } else {
      controls.start('visible');
    }
  }

  useEffect(() => {
    if (prevIsOpen && !isOpen) {
      controls.start('hidden');
    } else if (!prevIsOpen && isOpen) {
      controls.start('visible');
    }
  }, [controls, isOpen, prevIsOpen]);

  useEffect(() => {
    if (isOpen) {
      if (!backdropScrollable) {
        blockScroll();
      }
      hide();
    } else if (!isOpen) {
      allowScroll();
      show();
    }

    return () => {
      allowScroll();
    };
  }, [isOpen, backdropScrollable]);

  return (
    <div>
      <AnimatePresence onExitComplete={onCloseCompleted}>
        {isOpen && (
          <motion.div
            key="backdrop"
            className="fixed top-0 left-0 w-full h-[100vh] z-10 bg-card-dark-2"
            onClick={onClose}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            key="bottom-sheet"
            exit={{ y: '100vh' }}
            drag={disableClose ? undefined : 'y'}
            onDragEnd={onDragEnd}
            initial="hidden"
            dragDirectionLock={true}
            animate={controls}
            transition={{
              type: 'spring',
              damping: 40,
              stiffness: 400,
            }}
            variants={{
              visible: { y: 20 },
              hidden: { y: '100vh' },
            }}
            dragConstraints={{ top: 20 }}
            dragElastic={0}
            className={clsx(
              'flex flex-col w-full z-10 fixed left-0 bottom-0 rounded-xl',
              heights[height],
              !className?.includes('bg-')
                ? 'bg-card-light-1 dark:bg-background-dark-1 '
                : '',
              className
            )}
          >
            {disableClose ? null : (
              <div className="flex pt-3 justify-center items-center">
                <BottomSheetHandle />
              </div>
            )}
            {disableClose ? null : (
              <div className="absolute right-0 m-4">
                <button onClick={onClose}>
                  <Icon icon="close" color={closeIconColor ?? '3'} size="lg" />
                </button>
              </div>
            )}
            <div
              className={clsx(
                'flex flex-col flex-1 px-4',
                scrollable && 'h-full overflow-y-auto',
                'py-10'
              )}
              ref={contentContainerRef}
            >
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const usePrevious = (value: boolean) => {
  const previousValueRef = useRef<boolean>();

  useEffect(() => {
    previousValueRef.current = value;
  }, [value]);

  return previousValueRef.current;
};
