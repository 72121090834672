import * as React from 'react';
import { SVGProps } from 'react';
const SvgTiktok = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.59752 17.5513C8.97849 17.7603 9.40279 17.8693 9.8335 17.8692C11.2682 17.8692 12.4394 16.6828 12.4923 15.2042L12.4976 2H15.6666C15.6669 2.28045 15.692 2.56028 15.7415 2.83597H13.4211V2.83643H16.5906C16.5901 3.94151 16.9754 5.00923 17.675 5.84133L17.6759 5.84239C18.3895 6.327 19.2236 6.58467 20.076 6.58383V7.31758C20.3743 7.384 20.6828 7.41937 21 7.41937V10.7147C19.4178 10.7166 17.8752 10.2009 16.5906 9.24062V15.9362C16.5906 19.2795 13.9737 22 10.7571 22C9.95606 22.0002 9.16362 21.8284 8.42942 21.4954C7.69574 21.1626 7.03609 20.676 6.49164 20.066L6.49045 20.0651C4.98613 18.9672 4 17.1514 4 15.1002C4 11.7563 6.61691 9.03542 9.8335 9.03542C10.0965 9.03671 10.3592 9.05662 10.6196 9.09496V9.87504C10.6379 9.87467 10.6561 9.874 10.6744 9.87337C10.7018 9.87237 10.7293 9.87138 10.7571 9.87138C11.0201 9.87267 11.2827 9.89258 11.5431 9.93092V13.2945C11.2946 13.2134 11.0316 13.1662 10.7571 13.1662C10.0507 13.1671 9.37349 13.4592 8.87404 13.9785C8.37459 14.4978 8.0937 15.2018 8.09302 15.9361C8.09306 16.5161 8.26938 17.0813 8.59684 17.5509L8.59752 17.5513ZM5.30835 18.0999C5.57909 18.8332 5.98312 19.4984 6.48916 20.0637C5.97282 19.4893 5.57412 18.8213 5.30835 18.0999Z"
      fill="#12123B"
    />
  </svg>
);
export default SvgTiktok;
