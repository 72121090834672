import { gql, useMutation } from '@apollo/client';

import { DonateMutation, DonateMutationVariables } from '../gqlTypes';

const MUTATION = gql`
  mutation Donate($input: DonateInput!) {
    donate(input: $input) {
      success
      message
    }
  }
`;

export const useDonate = () => {
  return useMutation<DonateMutation, DonateMutationVariables>(MUTATION, {
    refetchQueries: ['Donations'],
  });
};
