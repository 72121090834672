import { gql, useQuery } from '@apollo/client';

import { CryptoCurrency, StakedAssetQuery, StakedAssetQueryVariables } from './../gqlTypes';

const query = gql`
  query StakedAsset($currency: CryptoCurrency!) {
    staking {
      stakedCurrency(currency: $currency) {
        currency
        fiatCurrency
        name
        type
        minimumStaked
        lockPeriod
        rewardDelay
        epochLength
        apy
        price
        stats {
          active
          amount
          fiatValue
          earnings {
            amount
            fiatValue
            yield
          }
        }
        accounts {
          accountGroupId
          stakedAmount
        }
      }
    }
  }
`;

export const useStakedAsset = (currency?: CryptoCurrency, options?: { skip?: boolean }) =>
  useQuery<StakedAssetQuery, StakedAssetQueryVariables>(query, {
    variables: { currency: currency || CryptoCurrency.Eth },
    skip: !currency || options?.skip,
  });
