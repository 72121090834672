import { gql, useMutation } from '@apollo/client';

import { SubmitCalendarAnswerMutation, SubmitCalendarAnswerMutationVariables } from './../gqlTypes';

const mutation = gql`
  mutation SubmitCalendarAnswer($input: SubmitCalendarAnswerInput!) {
    submitCalendarAnswer(input: $input) {
      success
      correct
    }
  }
`;

export const useSubmitCalendarAnswer = () =>
  useMutation<SubmitCalendarAnswerMutation, SubmitCalendarAnswerMutationVariables>(mutation, {
    refetchQueries: ['ChristmasCalendarAnswers', 'ChristmasCalendarTickets'],
  });
