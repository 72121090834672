import { gql, useMutation } from '@apollo/client';

import { AlterConsentMutation, AlterConsentMutationVariables } from '../gqlTypes';

const MUTATION = gql`
  mutation AlterConsent($input: ConsentInput!) {
    alterConsent(input: $input)
  }
`;

export const useUpdateConsent = () =>
  useMutation<AlterConsentMutation, AlterConsentMutationVariables>(MUTATION);
