import * as React from 'react';
import { SVGProps } from 'react';
const SvgSwap = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M18 19.5L18 4.5L16.5 4.5L16.5 19.5L18 19.5Z" />
    <path d="M11.6573 8.58234L16.1895 4.0501C16.473 3.7666 16.8495 3.6106 17.25 3.6106C17.6505 3.6106 18.027 3.7666 18.3105 4.0501L22.8428 8.58235L21.7823 9.64285L17.25 5.1106L12.7178 9.64285L11.6573 8.58234Z" />
    <path d="M7.5 19.5L7.5 4.5L6 4.5L6 19.5L7.5 19.5Z" />
    <path d="M1.15727 15.4177L2.21777 14.3572L6.75002 18.8894L11.2823 14.3572L12.3428 15.4177L7.81052 19.9499C7.52702 20.2334 7.15052 20.3894 6.75002 20.3894C6.34952 20.3894 5.97227 20.2334 5.68952 19.9499L1.15727 15.4177Z" />
  </svg>
);
export default SvgSwap;
