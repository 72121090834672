import { gql, useMutation } from '@apollo/client';

import { SetAirdropAddressMutation, SetAirdropAddressMutationVariables } from '../gqlTypes';

const MUTATION = gql`
  mutation SetAirdropAddress($input: AirdropAddressInput!) {
    setAirdropAddress(input: $input) {
      success
    }
  }
`;

export const useSetAirdropAddress = () =>
  useMutation<SetAirdropAddressMutation, SetAirdropAddressMutationVariables>(MUTATION);
