import * as React from 'react';
import { SVGProps } from 'react';
const SvgMaticDark = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_106_143)">
      <circle cx={12} cy={12} r={12} fill="white" />
      <g clipPath="url(#clip1_106_143)">
        <path
          d="M16.0833 9.26273C15.7917 9.09557 15.4167 9.09557 15.0833 9.26273L12.75 10.6418L11.1667 11.5194L8.875 12.8986C8.58333 13.0657 8.20833 13.0657 7.875 12.8986L6.08333 11.812C5.79167 11.6448 5.58333 11.3105 5.58333 10.9344V8.84482C5.58333 8.51049 5.75 8.17616 6.08333 7.96721L7.875 6.92243C8.16667 6.75527 8.54167 6.75527 8.875 6.92243L10.6667 8.009C10.9583 8.17616 11.1667 8.51049 11.1667 8.88661V10.2657L12.75 9.34631V7.92542C12.75 7.59109 12.5833 7.25676 12.25 7.0478L8.91667 5.08363C8.625 4.91646 8.25 4.91646 7.91667 5.08363L4.5 7.0896C4.16667 7.25676 4 7.59109 4 7.92542V11.8538C4 12.1881 4.16667 12.5224 4.5 12.7314L7.875 14.6956C8.16667 14.8627 8.54167 14.8627 8.875 14.6956L11.1667 13.3583L12.75 12.4388L15.0417 11.1015C15.3333 10.9344 15.7083 10.9344 16.0417 11.1015L17.8333 12.1463C18.125 12.3135 18.3333 12.6478 18.3333 13.0239V15.1135C18.3333 15.4478 18.1667 15.7821 17.8333 15.9911L16.0833 17.0359C15.7917 17.203 15.4167 17.203 15.0833 17.0359L13.2917 15.9911C13 15.8239 12.7917 15.4896 12.7917 15.1135V13.7762L11.2083 14.6956V16.0747C11.2083 16.409 11.375 16.7433 11.7083 16.9523L15.0833 18.9165C15.375 19.0836 15.75 19.0836 16.0833 18.9165L19.4583 16.9523C19.75 16.7851 19.9583 16.4508 19.9583 16.0747V12.1045C19.9583 11.7702 19.7917 11.4359 19.4583 11.2269L16.0833 9.26273Z"
          fill="#8247E5"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_106_143">
        <rect width={24} height={24} fill="white" />
      </clipPath>
      <clipPath id="clip1_106_143">
        <rect width={16} height={14} fill="white" transform="translate(4 5)" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMaticDark;
