import { gql, useMutation } from '@apollo/client';

import { KycFormFragment } from '../fragments';
import { KycSingleAnswerMutation, KycSingleAnswerMutationVariables } from './../gqlTypes';

const mutation = gql`
  mutation KycSingleAnswer($input: KycAnswerInput!) {
    submitKYCAnswer(input: $input) {
      ...KycFormFragment
    }
  }
  ${KycFormFragment}
`;

export const useKycSingleAnswer = () =>
  useMutation<KycSingleAnswerMutation, KycSingleAnswerMutationVariables>(mutation, {
    refetchQueries: ['KycForm'],
  });
