import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';

import {
  CryptoCurrency,
  SanityBannerArea,
  SanityBannerQuery,
  SanityBannerQueryVariables,
} from '../gqlTypes';

const query = gql`
  query SanityBanner($input: SanityBannerInput!) {
    sanityBanner(input: $input) {
      id
      area
      color
      title
      icon
      description
      removable
      uniqueNumber
    }
  }
`;

type Params = {
  area: SanityBannerArea;
  options?: QueryHookOptions;
  currency?: CryptoCurrency; //filter banners by currency
};

export const useSanityBanner = (params: Params) => {
  const { i18n } = useLingui();
  return useQuery<SanityBannerQuery, SanityBannerQueryVariables>(query, {
    variables: {
      input: { area: params.area, currency: params.currency, language: i18n.locale },
    },
  });
};
