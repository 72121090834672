import * as React from 'react';
import { SVGProps } from 'react';
const SvgXmasTree = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#ffffff" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6578 2.11961C12.3177 1.70394 11.6822 1.70395 11.3421 2.11961L7.0531 7.36169C6.59902 7.91668 6.99389 8.74994 7.71097 8.74994H8.49994L5.01994 13.3899C4.59968 13.9503 4.9995 14.7499 5.69994 14.7499H6.91726L3.59303 18.8129C3.09094 19.4266 3.62821 20.3327 4.40754 20.1866L11.25 18.9036V21.5C11.25 21.7761 11.4739 22 11.75 22H12.25C12.5261 22 12.75 21.7761 12.75 21.5V18.9036L19.5924 20.1866C20.3717 20.3327 20.909 19.4266 20.4069 18.8129L17.0826 14.7499H18.2999C19.0004 14.7499 19.4002 13.9503 18.9799 13.3899L15.4999 8.74994H16.2889C17.006 8.74994 17.4009 7.91668 16.9468 7.36169L12.6578 2.11961ZM9.08263 7.24994L11.9999 3.68433L14.9173 7.24994H14.1999C13.4995 7.24994 13.0997 8.04959 13.5199 8.60994L16.9999 13.2499H15.711C14.9939 13.2499 14.599 14.0832 15.0531 14.6382L18.1178 18.384L12.1566 17.2662C12.0531 17.2468 11.9468 17.2468 11.8433 17.2662L5.88205 18.384L8.94678 14.6382C9.40087 14.0832 9.006 13.2499 8.28892 13.2499H6.99994L10.4799 8.60994C10.9002 8.04959 10.5004 7.24994 9.79994 7.24994H9.08263Z"
    />
  </svg>
);
export default SvgXmasTree;
