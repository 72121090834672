import * as React from 'react';
import { SVGProps } from 'react';
const SvgNoTradingFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3 3L21 21" stroke="#DA2D43" strokeWidth={1.5} strokeLinecap="round" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1364 20.6364L17.0611 19.5611C15.7215 20.4767 14.1239 21.0405 12.4035 21.1165C11.2065 21.169 10.0582 20.986 8.99998 20.611V22.1882C10.0672 22.507 11.205 22.6615 12.3832 22.6187C14.5245 22.5415 16.5055 21.8169 18.1364 20.6364ZM19.5361 17.0361C20.402 15.7134 20.9293 14.1514 20.9932 12.4757C21.0855 10.0622 20.2207 7.84823 18.75 6.18023V9.12548H17.25V4.62548C17.25 4.21148 17.586 3.87548 18 3.87548H22.5V5.37623H20.0355C21.6293 7.26923 22.5675 9.73148 22.4963 12.4105C22.4399 14.5234 21.7522 16.4861 20.615 18.115L19.5361 17.0361ZM16.1762 13.6762L10.3238 7.82379C10.8421 7.61496 11.4079 7.49997 12 7.49997C14.481 7.49997 16.5 9.51897 16.5 12C16.5 12.5921 16.385 13.1579 16.1762 13.6762ZM7.8238 10.3238L13.6762 16.1762C13.1579 16.385 12.5921 16.5 12 16.5C9.519 16.5 7.5 14.481 7.5 12C7.5 11.4079 7.61499 10.8421 7.8238 10.3238ZM7.01053 4.51053C8.33526 3.62351 9.9066 3.07834 11.5965 3.00375C12.7935 2.95125 13.9418 3.13425 15 3.50925V1.95075H15.0623C13.9928 1.62375 12.8513 1.46325 11.6678 1.5C9.53831 1.56641 7.5647 2.27355 5.93388 3.43388L7.01053 4.51053ZM3.43484 5.93484L4.51161 7.01161C3.61742 8.34949 3.07188 9.93794 3.00675 11.6445C2.9145 14.058 3.77925 16.272 5.25 17.94V14.9948H6.75V19.4947C6.75 19.9087 6.414 20.2447 6 20.2447H5.5845V20.301C5.5605 20.2822 5.53725 20.2635 5.514 20.2447H1.5V18.7447H3.96525C2.37 16.8488 1.43025 14.3828 1.5045 11.7C1.56341 9.55968 2.27009 7.57451 3.43484 5.93484Z"
      fill="#12123B"
    />
  </svg>
);
export default SvgNoTradingFilled;
