import { gql, useMutation } from '@apollo/client';

import { TinkUpdatePaymentMutation, TinkUpdatePaymentMutationVariables } from '../gqlTypes';

const MUTATION = gql`
  mutation TinkUpdatePayment($input: TinkUpdatePaymentInput!) {
    tinkUpdatePayment(input: $input) {
      success
      message
      status
      paymentId
    }
  }
`;

export const useTinkUpdatePayment = () =>
  useMutation<TinkUpdatePaymentMutation, TinkUpdatePaymentMutationVariables>(MUTATION);
