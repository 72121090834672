import { gql, useMutation } from '@apollo/client';

import { UpdateAccountGroupMutation, UpdateAccountGroupMutationVariables } from '../gqlTypes';

const MUTATION = gql`
  mutation UpdateAccountGroup($input: UpdateAccountGroup!) {
    updateAccountGroup(input: $input) {
      success
      message
    }
  }
`;

export const useUpdateAccountGroup = () => {
  return useMutation<UpdateAccountGroupMutation, UpdateAccountGroupMutationVariables>(MUTATION, {
    refetchQueries: ['Accounts'],
  });
};
