import { gql, useMutation } from '@apollo/client';

import { ResetPasswordMutation, ResetPasswordMutationVariables } from '../gqlTypes';

const MUTATION = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword: resetPassword_v2(input: $input) {
      success
      message
    }
  }
`;

export const usePasswordReset = () =>
  useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(MUTATION);
