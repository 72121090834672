import { gql, useLazyQuery, useQuery } from '@apollo/client';

import {
  InitiateOpenBankingSessionQuery,
  InitiateOpenBankingSessionQueryVariables,
} from '../gqlTypes';

export const QUERY = gql`
  query InitiateOpenBankingSession($input: OpenBankingSessionInput!) {
    openBankingInitiateSession(input: $input) {
      sessionID
      deviceId
      url
    }
  }
`;

export const useInitiateOpenBankingSession = (params: {
  bankId?: string;
  ssn?: string;
  redirectUrl: string;
  skip?: boolean;
}) =>
  useQuery<InitiateOpenBankingSessionQuery, InitiateOpenBankingSessionQueryVariables>(QUERY, {
    skip: !params.bankId,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        bankId: params.bankId || '',
        ssn: params.ssn,
        redirectUrl: params.redirectUrl,
      },
    },
  });

export const useLazyInitiateOpenBankingSession = (params: {
  bankId?: string;
  ssn?: string;
  redirectUrl: string;
}) =>
  useLazyQuery<InitiateOpenBankingSessionQuery, InitiateOpenBankingSessionQueryVariables>(QUERY, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        bankId: params.bankId || '',
        ssn: params.ssn,
        redirectUrl: params.redirectUrl,
      },
    },
  });
