import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { CalendarCryptoPrizeFragment, CalendarMerchPrizeFragment } from '../fragments';
import { CalendarQuestionQuery, CalendarQuestionQueryVariables } from '../gqlTypes';

const query = gql`
  query CalendarQuestion($day: Int!) {
    calendar {
      question(day: $day) {
        id
        day
        question {
          da
          en
          no
        }
        answers {
          text {
            da
            en
            no
          }
          correct
          id
        }
        prize {
          ... on CalendarMerchPrize {
            ...CalendarMerchPrizeFragment
          }
          ... on CalendarCryptoPrize {
            ...CalendarCryptoPrizeFragment
          }
        }
      }
    }
  }
  ${CalendarMerchPrizeFragment}
  ${CalendarCryptoPrizeFragment}
`;

export const useCalendarQuestion = (
  day: number,
  options?: QueryHookOptions<CalendarQuestionQuery>,
) =>
  useQuery<CalendarQuestionQuery, CalendarQuestionQueryVariables>(query, {
    variables: { day: day },
    skip: options?.skip,
  });
