import * as React from 'react';
import { SVGProps } from 'react';
const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M12 22.5C6.21 22.5 1.5 17.79 1.5 12C1.5 6.21 6.21 1.5 12 1.5C17.79 1.5 22.5 6.21 22.5 12C22.5 17.79 17.79 22.5 12 22.5ZM12 3C7.03725 3 3 7.03725 3 12C3 16.9628 7.03725 21 12 21C16.9628 21 21 16.9628 21 12C21 7.03725 16.9628 3 12 3Z" />
    <path d="M12 10.5C12.414 10.5 12.75 10.836 12.75 11.25V15.75C12.75 16.164 12.414 16.5 12 16.5C11.586 16.5 11.25 16.164 11.25 15.75V11.25C11.25 10.836 11.586 10.5 12 10.5Z" />
    <path d="M12 9C12.4142 9 12.75 8.66421 12.75 8.25C12.75 7.83579 12.4142 7.5 12 7.5C11.5858 7.5 11.25 7.83579 11.25 8.25C11.25 8.66421 11.5858 9 12 9Z" />
  </svg>
);
export default SvgInfo;
