import * as React from 'react';
import { SVGProps } from 'react';
const SvgCorporate = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7144 0H9.00005C8.65916 0.000340378 8.33233 0.135908 8.09129 0.376953C7.85024 0.617997 7.71467 0.944826 7.71433 1.28571V7.71429H1.28572C0.944832 7.71463 0.618001 7.85019 0.376955 8.09124C0.135909 8.33228 0.00034038 8.65911 0 9V18H18.0001V1.28571C17.9998 0.944826 17.8642 0.617997 17.6232 0.376953C17.3821 0.135908 17.0553 0.000340378 16.7144 0ZM4.50003 16.7143V12.2143H7.07147V16.7143H4.50003ZM16.7144 16.7143H8.35719V11.5714C8.35719 11.4009 8.28946 11.2374 8.1689 11.1169C8.04834 10.9963 7.88483 10.9286 7.71433 10.9286H3.85717C3.68667 10.9286 3.52315 10.9963 3.40259 11.1169C3.28203 11.2374 3.2143 11.4009 3.2143 11.5714V16.7143H1.28572V9H9.00005V1.28571H16.7144V16.7143ZM10.2858 3.85713H11.5715V5.14284H10.2858V3.85713ZM15.4287 3.85713H14.1429V5.14284H15.4287V3.85713ZM10.2858 7.71428H11.5715V9H10.2858V7.71428ZM15.4287 7.71428H14.1429V9H15.4287V7.71428ZM10.2858 11.5714H11.5715V12.8571H10.2858V11.5714ZM15.4287 11.5714H14.1429V12.8571H15.4287V11.5714Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCorporate;
