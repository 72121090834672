import { getLocalizedUrl, prefixLocale, stripTrailingSlash } from 'helpers/url';
import Head from 'next/head';
import { useRouter } from 'next/router';

type Schema = { '@context': string };

type MetaProps = {
  title?: string;
  description?: string;
  image?: string;
  canonical?: string;
  keywords?: string;
  robots?: string;
  locale?: {
    current: string;
    alternate?: string[];
  };
  opengraph?: {
    type: string;
    url: string;
  };
  noIndex?: boolean;
};

export const Meta = ({
  title,
  description,
  image,
  canonical,
  keywords,
  robots,
  locale,
  opengraph,
  noIndex,
}: MetaProps) => {
  const router = useRouter();
  const simpleCanonical =
    'https://firi.com' +
    prefixLocale(router.locale, router.defaultLocale) +
    getLocalizedUrl(router.pathname, router.locale, router.query);

  return (
    <Head>
      {noIndex && <meta name="robots" content="noindex,nofollow" />}

      {title && (
        <>
          <title>
            {title}
            {!title.includes('|') ? ' | Firi' : null}
          </title>
          <meta property="og:title" content={title} key="og:title" />
          <meta name="twitter:title" content={title} key="twitter:title" />
        </>
      )}
      {description && (
        <>
          <meta name="description" content={description} key="description" />
          <meta itemProp="description" content={description} key="itemDescription" />
          <meta property="og:description" content={description} key="og:description" />
          <meta name="twitter:description" content={description} key="twitter:description" />
        </>
      )}
      {image && (
        <>
          <meta itemProp="image" content={image} key="itemImage" />
          <meta property="og:image" content={image} key="og:image" />
          <meta name="twitter:image" content={image} key="twitter:image" />
        </>
      )}
      {(canonical || (simpleCanonical && simpleCanonical !== router.asPath)) && (
        <>
          <meta
            name="canonical"
            content={stripTrailingSlash(canonical || simpleCanonical)}
            key="canonical"
          />
          <link rel="canonical" href={stripTrailingSlash(canonical || simpleCanonical)} />
        </>
      )}
      {keywords && <meta name="keywords" content={keywords} key="keywords" />}
      {robots && <meta name="robots" content={robots} key="robots" />}

      <meta name="twitter:card" content="summary_large_image" key="twitter:card" />
      <meta name="twitter:creator" content="@firiapp" key="twitter:creator" />

      {opengraph?.type && <meta property="og:type" content={opengraph.type} key="og:type" />}
      {opengraph?.url && (
        <meta property="og:url" content={stripTrailingSlash(opengraph.url)} key="og:url" />
      )}

      {locale?.current && <meta property="og:locale" content={locale.current} key="og:locale" />}
      {locale?.alternate &&
        locale.alternate.map((alternate) => (
          <meta
            key={alternate}
            property="og:locale:alternate"
            content={stripTrailingSlash(alternate)}
          />
        ))}

      <meta name="theme-color" content="#474AEE" key="theme-color" />
      <link rel="icon" type="image/png" href="/favicon.png" key="icon" />
    </Head>
  );
};
