import * as React from 'react';
import { SVGProps } from 'react';
const SvgAlphabetical = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1889 12.2315C11.8061 12.2315 11.4764 12.1642 11.1999 12.0295C10.9305 11.8877 10.6681 11.6466 10.4129 11.3063V12.1145H9.15796V4H10.4129V6.97782C10.6681 6.63749 10.934 6.39998 11.2105 6.26527C11.487 6.13056 11.8132 6.0632 12.1889 6.0632C12.7065 6.0632 13.1603 6.19437 13.5502 6.4567C13.9473 6.71194 14.2557 7.07353 14.4755 7.54147C14.6953 8.00942 14.8052 8.54471 14.8052 9.14737C14.8052 9.75002 14.6953 10.2853 14.4755 10.7533C14.2557 11.2212 13.9473 11.5863 13.5502 11.8487C13.1603 12.1039 12.7065 12.2315 12.1889 12.2315ZM11.9337 11.1468C12.4229 11.1468 12.8129 10.9589 13.1036 10.5831C13.3943 10.2073 13.5396 9.72875 13.5396 9.14737C13.5396 8.56598 13.3943 8.08741 13.1036 7.71163C12.8129 7.33586 12.4229 7.14798 11.9337 7.14798C11.6147 7.14798 11.3311 7.23306 11.0829 7.40322C10.8348 7.57338 10.6398 7.81089 10.498 8.11577C10.3562 8.42064 10.2853 8.76451 10.2853 9.14737C10.2853 9.53023 10.3562 9.8741 10.498 10.179C10.6398 10.4838 10.8348 10.7214 11.0829 10.8915C11.3311 11.0617 11.6147 11.1468 11.9337 11.1468Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.53175 11.7743C3.88625 12.0791 4.3542 12.2316 4.93558 12.2316C5.51696 12.2316 5.97427 12.1217 6.3075 11.9019C6.54856 11.7459 6.74708 11.5332 6.90307 11.2638V12.1146H8.158V8.89216C8.158 8.39585 8.11901 7.96691 8.04102 7.60532C7.97012 7.24372 7.78932 6.92822 7.49863 6.6588C7.27884 6.46027 7.01296 6.31138 6.701 6.21212C6.39613 6.11286 6.06644 6.06323 5.71194 6.06323C5.09511 6.06323 4.54917 6.23339 4.07414 6.57372C3.6062 6.91404 3.3226 7.40325 3.22334 8.04135H4.47827C4.53499 7.74357 4.67679 7.5096 4.90368 7.33944C5.13056 7.16219 5.39643 7.07356 5.7013 7.07356C6.07708 7.07356 6.37486 7.18346 6.59465 7.40325C6.80026 7.61595 6.90661 7.92082 6.9137 8.31786L5.0313 8.66882C4.42155 8.78935 3.9607 8.96306 3.64874 9.18994C3.21625 9.50899 3 9.94503 3 10.4981C3 11.044 3.17725 11.4694 3.53175 11.7743ZM6.03099 11.0298C5.77575 11.1503 5.50278 11.2106 5.21209 11.2106C4.9214 11.2106 4.68743 11.1468 4.51018 11.0192C4.34002 10.8845 4.25494 10.7001 4.25494 10.4661C4.25494 10.2534 4.32584 10.0762 4.46764 9.93439C4.6378 9.77841 4.92849 9.65788 5.33971 9.5728L6.92434 9.26438C6.92434 9.71815 6.85698 10.0833 6.72227 10.3598C6.59465 10.6363 6.36422 10.8596 6.03099 11.0298Z"
    />
    <path d="M21.6704 15.9205L19.75 14.0002L18.9546 14.7955L20.3125 16.1534L2 16.1534V17.2784L20.3125 17.2784L18.9546 18.6363L19.75 19.4316L21.6704 17.5113C21.883 17.2987 22 17.0163 22 16.7159C22 16.4155 21.883 16.1332 21.6704 15.9205Z" />
    <path d="M16.7034 11.8381C17.1501 12.1004 17.6641 12.2316 18.2455 12.2316C18.9545 12.2316 19.5572 12.0472 20.0535 11.6785C20.5498 11.3099 20.8653 10.7994 21 10.1471H19.6387C19.5607 10.4661 19.3977 10.7178 19.1495 10.9022C18.9084 11.0865 18.6071 11.1787 18.2455 11.1787C17.9194 11.1787 17.6287 11.0936 17.3734 10.9235C17.1182 10.7533 16.9197 10.5122 16.7779 10.2003C16.6361 9.88831 16.5652 9.52672 16.5652 9.11549C16.5652 8.71845 16.6361 8.36749 16.7779 8.06262C16.9197 7.75775 17.1182 7.52378 17.3734 7.36071C17.6287 7.19055 17.9194 7.10547 18.2455 7.10547C18.5788 7.10547 18.8624 7.187 19.0963 7.35007C19.3374 7.51314 19.5075 7.74357 19.6068 8.04135H20.9681C20.8263 7.43161 20.5108 6.94949 20.0216 6.59498C19.5395 6.24048 18.9474 6.06323 18.2455 6.06323C17.6641 6.06323 17.1501 6.1944 16.7034 6.45673C16.2568 6.71197 15.9094 7.07356 15.6612 7.54151C15.4131 8.00236 15.289 8.52702 15.289 9.11549C15.289 9.72524 15.4131 10.2676 15.6612 10.7427C15.9094 11.2106 16.2568 11.5757 16.7034 11.8381Z" />
  </svg>
);
export default SvgAlphabetical;
