import { gql, useQuery } from '@apollo/client';

import { ApiKeysQuery } from '../gqlTypes';

const query = gql`
  query ApiKeys {
    apiKeys {
      apiKey
      write
      read
      withdraw
    }
  }
`;

export const useApiKeys = () => useQuery<ApiKeysQuery>(query);
