import { gql, useQuery } from '@apollo/client';

import { TinkAccountsQuery, TinkAccountsQueryVariables } from '../gqlTypes';

const QUERY = gql`
  query TinkAccounts($code: String, $sessionId: String) {
    payment {
      tinkAccounts(code: $code, sessionId: $sessionId) {
        currency
        balance
        balanceType
        bban
        iban
        accountName
        accountType
        id
      }
    }
  }
`;

export const useTinkAccounts = (params: TinkAccountsQueryVariables) =>
  useQuery<TinkAccountsQuery, TinkAccountsQueryVariables>(QUERY, {
    skip: !params.code,
    variables: {
      code: params.code,
      sessionId: params.sessionId,
    },
  });
