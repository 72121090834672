import create from 'zustand';
import { persist } from 'zustand/middleware';

interface State {
  deviceId?: string;
  setDeviceId: (id?: string) => void;
}

export const useWebStore = create<State>()(
  persist(
    (set, get) => ({
      deviceId: undefined,
      setDeviceId: (id) => set({ deviceId: id }),
    }),
    { name: '@firi-landing-web-store' },
  ),
);
