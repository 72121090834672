import { useEffect, useState } from 'react';

/**
 * Hook for input field
 * @param initialValue
 * @returns
 */
export function useInputField<T>(initialValue: T) {
  const [value, setValue] = useState(initialValue);
  const [touched, setTouched] = useState(false);
  const [errorText, setErrorText] = useState('');

  useEffect(() => {
    if (value !== initialValue) {
      setTouched(true);
    }
  }, [value]);

  const resetField = () => {
    setValue(initialValue);
    setTouched(false);
    setErrorText('');
  };

  return {
    value,
    setValue,
    touched,
    setTouched,
    errorText,
    setErrorText,
    resetField,
  };
}
