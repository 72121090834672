type AnalyticsInfo = {
  socialMetaTagInfo: {
    socialTitle: string;
    socialDescription: string;
    socialImageLink: string;
  };
  googlePlayAnalytics?: {
    utmSource?: string;
    utmMedium?: string;
    utmCampaign?: string;
    utmTerm?: string;
    utmContent?: string;
  };
  itunesConnectAnalytics?: {
    at?: string;
    ct?: string;
    mt?: string;
    pt?: string;
  };
};

const deviceInfo = {
  androidInfo: {
    androidPackageName: 'com.mirai.miraiex',
  },
  iosInfo: {
    iosBundleId: 'com.mirai.miraiex',
    iosCustomScheme: 'firi',
    iosAppStoreId: '1542108249',
  },
};

export async function generateAffiliateLink(referral: string, analyticsInfo?: AnalyticsInfo) {
  const response = await fetch(
    `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCNYfO0agCldrVXUEWGOHK_8eBE9ihzxtE`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dynamicLinkInfo: {
          ...deviceInfo,
          domainUriPrefix: 'https://app-link.firi.com/qrinvites',
          link: `https://app-link.firi.com/qrinvites/?referral=${referral}`,
          desktopInfo: {
            desktopFallbackLink: `https://firi.com/affiliate?referral=${referral}`,
          },
          ...analyticsInfo,
        },
      }),
    },
  );
  const data = await response.json();
  return data.shortLink;
}

export async function generateAtGdLink(
  analyticsInfo: AnalyticsInfo,
  at_gd: string,
): Promise<string> {
  const link = `https://app-link.firi.com/landing/?at_gd=${at_gd}`;
  const response = await fetch(
    `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCNYfO0agCldrVXUEWGOHK_8eBE9ihzxtE`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dynamicLinkInfo: {
          ...deviceInfo,
          link: link,
          domainUriPrefix: 'https://app-link.firi.com/landing',
          desktopInfo: {
            desktopFallbackLink: `https://platform.firi.com`,
          },
          ...analyticsInfo,
        },
      }),
    },
  );
  const data = await response.json();
  return data.shortLink;
}
