import * as React from 'react';
import { SVGProps } from 'react';
const SvgWithdraw = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M12 1.5C16.9628 1.5 21 5.53725 21 10.5C21 15.4627 16.9628 19.5 12 19.5C7.03725 19.5 3 15.4627 3 10.5C3 5.53725 7.03725 1.5 12 1.5ZM12 18C16.1355 18 19.5 14.6355 19.5 10.5C19.5 6.3645 16.1355 3 12 3C7.8645 3 4.5 6.3645 4.5 10.5C4.5 14.6355 7.8645 18 12 18Z" />
    <path d="M11.25 15.75L12.75 15.75L12.75 6L11.25 6L11.25 15.75Z" />
    <path d="M12.0001 5.56125C12.3841 5.56125 12.7681 5.7075 13.0606 6L17.0303 9.96975L15.9698 11.0302L12.0001 7.0605L8.0303 11.0302L6.9698 9.96975L10.9396 6C11.2321 5.7075 11.6161 5.56125 12.0001 5.56125Z" />
    <path d="M1.5 17.25L3 17.25L3 21.75L21 21.75L21 17.25L22.5 17.25L22.5 21.75C22.5 22.5773 21.8273 23.25 21 23.25L3 23.25C2.17275 23.25 1.5 22.5772 1.5 21.75L1.5 17.25Z" />
  </svg>
);
export default SvgWithdraw;
