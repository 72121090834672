import * as React from 'react';
import { SVGProps } from 'react';
const SvgUnlock = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.86296 4.9555C10.5741 3.30746 12.4866 2.54797 14.1346 3.25913C15.7827 3.97028 16.5422 5.88278 15.831 7.53081L14.7656 9.99992H5.52C4.68053 9.99992 4 10.6804 4 11.5199V20.4799C4 21.3194 4.68053 21.9999 5.52 21.9999H18.48C19.3195 21.9999 20 21.3194 20 20.4799V11.5199C20 10.6804 19.3195 9.99992 18.48 9.99992H16.3993L17.2083 8.12512C18.2476 5.71645 17.1376 2.92126 14.729 1.88188C12.3203 0.842502 9.52509 1.95253 8.48571 4.36119L7.79236 5.96798C7.62824 6.34829 7.80351 6.78964 8.18383 6.95375C8.56414 7.11786 9.00549 6.9426 9.1696 6.56228L9.86296 4.9555ZM18.48 11.4999H5.52C5.51665 11.4999 5.51494 11.5002 5.51438 11.5003L5.51268 11.5009C5.51137 11.5014 5.50876 11.5029 5.50586 11.5058C5.50296 11.5087 5.50151 11.5113 5.50096 11.5126L5.50043 11.5143C5.50031 11.5149 5.5 11.5166 5.5 11.5199V20.4799C5.5 20.4833 5.50031 20.485 5.50043 20.4855L5.50096 20.4872C5.50151 20.4885 5.50296 20.4912 5.50586 20.4941C5.50876 20.497 5.51137 20.4984 5.51268 20.499L5.51438 20.4995C5.51494 20.4996 5.51665 20.4999 5.52 20.4999H18.48C18.4834 20.4999 18.4851 20.4996 18.4856 20.4995L18.4873 20.499C18.4886 20.4984 18.4912 20.497 18.4941 20.4941C18.497 20.4912 18.4985 20.4885 18.499 20.4872L18.4996 20.4855L18.4998 20.4838L18.5 20.4799V11.5199L18.4999 11.5165L18.4996 11.5143L18.499 11.5126C18.4985 11.5113 18.497 11.5087 18.4941 11.5058C18.4912 11.5029 18.4886 11.5014 18.4873 11.5009L18.4856 11.5003C18.4851 11.5002 18.4833 11.4999 18.48 11.4999ZM14.75 15.9999C14.75 16.4141 14.4142 16.7499 14 16.7499H10C9.58579 16.7499 9.25 16.4141 9.25 15.9999C9.25 15.5857 9.58579 15.2499 10 15.2499H14C14.4142 15.2499 14.75 15.5857 14.75 15.9999Z"
      fill="#DA2D43"
    />
  </svg>
);
export default SvgUnlock;
