import * as React from 'react';
import { SVGProps } from 'react';
const SvgVipps = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#EC6638" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5345 8.7038C16.8055 8.7038 17.8948 7.73886 17.8948 6.3519H17.8951C17.8951 4.96461 16.8055 4 15.5345 4C14.2638 4 13.1748 4.96461 13.1748 6.3519C13.1748 7.73886 14.2638 8.7038 15.5345 8.7038ZM18.6208 12.5039C17.0471 14.5539 15.3829 15.9713 12.4477 15.9713H12.4481C9.45337 15.9713 7.12299 14.162 5.30766 11.5085C4.58123 10.4228 3.46169 10.1817 2.64462 10.7546C1.88828 11.2975 1.70719 12.4434 2.40269 13.4387C4.91389 17.2684 8.39336 19.4995 12.4477 19.4995C16.1698 19.4995 19.0747 17.6905 21.3437 14.6748C22.1907 13.5593 22.1605 12.4134 21.3437 11.78C20.587 11.1763 19.4675 11.3884 18.6208 12.5039Z"
    />
  </svg>
);
export default SvgVipps;
