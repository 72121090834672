import * as React from 'react';
import { SVGProps } from 'react';
const SvgLinkedinRound = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#ffffff" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM9.06684 10.2825H6.80062V17.0915H9.06684V10.2825ZM9.21623 8.17612C9.20152 7.5085 8.7241 7 7.94884 7C7.17359 7 6.66675 7.5085 6.66675 8.17612C6.66675 8.82992 7.15861 9.35306 7.91943 9.35306H7.93391C8.7241 9.35306 9.21623 8.82992 9.21623 8.17612ZM17.2398 13.1874C17.2398 11.096 16.1218 10.1226 14.6305 10.1226C13.4273 10.1226 12.8886 10.7834 12.5878 11.2471V10.2826H10.3213C10.3512 10.9216 10.3213 17.0917 10.3213 17.0917H12.5878V13.2889C12.5878 13.0854 12.6025 12.8824 12.6624 12.7367C12.8262 12.3302 13.1992 11.9093 13.8252 11.9093C14.6456 11.9093 14.9736 12.5336 14.9736 13.4487V17.0915H17.2397L17.2398 13.1874Z"
    />
  </svg>
);
export default SvgLinkedinRound;
