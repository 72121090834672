import * as React from 'react';
import { SVGProps } from 'react';
const SvgTrophyFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 2H6V4H2V4.75C2 5.84561 2.26876 7.48901 3.10688 8.88587C3.80592 10.0509 4.90848 11.0462 6.53158 11.3803C7.4837 13.5159 9.51788 15.1684 10.9897 15.7619C10.883 17.1153 9.9703 18 9.5 18H9C8.44772 18 8 18.4477 8 19C7.44772 19 7 19.4477 7 20V22H17V20C17 19.4477 16.5523 19 16 19C16 18.4477 15.5523 18 15 18H14.5C14.0297 18 13.117 17.1153 13.0103 15.7619C14.4821 15.1684 16.5163 13.5159 17.4684 11.3803C19.0915 11.0462 20.1941 10.0509 20.8931 8.88587C21.7312 7.48901 22 5.84561 22 4.75V4H18V2ZM6.04028 9.65166C6.01384 9.4371 6 9.21973 6 9V5.5H3.54623C3.64211 6.31522 3.89629 7.28608 4.39312 8.11413C4.77958 8.75824 5.30786 9.31363 6.04028 9.65166ZM19.6069 8.11413C19.2204 8.75824 18.6921 9.31363 17.9597 9.65166C17.9862 9.4371 18 9.21973 18 9V5.5H20.4538C20.3579 6.31522 20.1037 7.28608 19.6069 8.11413ZM12.1902 4.58541C12.1303 4.40115 11.8697 4.40115 11.8098 4.58541L11.1468 6.62574C11.1201 6.70814 11.0433 6.76393 10.9566 6.76393H8.81131C8.61757 6.76393 8.53701 7.01185 8.69375 7.12574L10.4294 8.38673C10.4995 8.43765 10.5288 8.52793 10.502 8.61033L9.83907 10.6507C9.7792 10.8349 9.9901 10.9881 10.1468 10.8743L11.8824 9.61327C11.9525 9.56235 12.0475 9.56235 12.1176 9.61327L13.8532 10.8743C14.0099 10.9881 14.2208 10.8349 14.1609 10.6507L13.498 8.61033C13.4712 8.52793 13.5005 8.43765 13.5706 8.38673L15.3062 7.12574C15.463 7.01186 15.3824 6.76393 15.1887 6.76393H13.0434C12.9567 6.76393 12.8799 6.70814 12.8532 6.62574L12.1902 4.58541Z"
    />
  </svg>
);
export default SvgTrophyFilled;
