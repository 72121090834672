import * as React from 'react';
import { SVGProps } from 'react';
const SvgInstagramRound = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#ffffff" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12.0005 6.66667C10.552 6.66667 10.3703 6.673 9.80137 6.69889C9.23358 6.72489 8.84602 6.81478 8.50691 6.94667C8.15613 7.08289 7.85857 7.26512 7.56212 7.56168C7.26545 7.85812 7.08323 8.15568 6.94656 8.50635C6.81433 8.84558 6.72433 9.23325 6.69878 9.80081C6.67333 10.3697 6.66667 10.5516 6.66667 12.0001C6.66667 13.4485 6.67311 13.6297 6.69889 14.1986C6.725 14.7664 6.81489 15.154 6.94667 15.4931C7.083 15.8439 7.26523 16.1414 7.56179 16.4379C7.85812 16.7345 8.15568 16.9172 8.50624 17.0534C8.84558 17.1853 9.23325 17.2752 9.80092 17.3012C10.3698 17.3271 10.5515 17.3334 11.9998 17.3334C13.4484 17.3334 13.6296 17.3271 14.1985 17.3012C14.7663 17.2752 15.1543 17.1853 15.4936 17.0534C15.8443 16.9172 16.1414 16.7345 16.4378 16.4379C16.7344 16.1414 16.9167 15.8439 17.0533 15.4932C17.1844 15.154 17.2744 14.7663 17.3011 14.1987C17.3267 13.6299 17.3333 13.4485 17.3333 12.0001C17.3333 10.5516 17.3267 10.3698 17.3011 9.80092C17.2744 9.23314 17.1844 8.84558 17.0533 8.50646C16.9167 8.15568 16.7344 7.85812 16.4378 7.56168C16.1411 7.26501 15.8444 7.08278 15.4933 6.94667C15.1533 6.81478 14.7655 6.72489 14.1977 6.69889C13.6289 6.673 13.4477 6.66667 11.9988 6.66667H12.0005Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.522 7.62779C11.6641 7.62757 11.8225 7.62779 12.0005 7.62779C13.4245 7.62779 13.5933 7.6329 14.1556 7.65845C14.6756 7.68223 14.9579 7.76912 15.1459 7.84212C15.3948 7.93879 15.5722 8.05435 15.7588 8.24102C15.9454 8.42768 16.061 8.60546 16.1579 8.85436C16.2309 9.04214 16.3179 9.32436 16.3415 9.84437C16.3671 10.4066 16.3727 10.5755 16.3727 11.9988C16.3727 13.4222 16.3671 13.5911 16.3415 14.1533C16.3178 14.6733 16.2309 14.9555 16.1579 15.1433C16.0612 15.3922 15.9454 15.5694 15.7588 15.756C15.5721 15.9427 15.3949 16.0582 15.1459 16.1549C14.9581 16.2282 14.6756 16.3149 14.1556 16.3387C13.5934 16.3642 13.4245 16.3698 12.0005 16.3698C10.5764 16.3698 10.4076 16.3642 9.84537 16.3387C9.32536 16.3147 9.04314 16.2278 8.85502 16.1548C8.60613 16.0581 8.42835 15.9425 8.24168 15.7559C8.05501 15.5692 7.93946 15.3919 7.84257 15.1429C7.76957 14.9551 7.68257 14.6729 7.6589 14.1529C7.63334 13.5906 7.62823 13.4217 7.62823 11.9975C7.62823 10.5733 7.63334 10.4053 7.6589 9.84303C7.68268 9.32303 7.76957 9.0408 7.84257 8.8528C7.93924 8.60391 8.05501 8.42613 8.24168 8.23946C8.42835 8.05279 8.60613 7.93724 8.85502 7.84035C9.04303 7.76701 9.32536 7.68034 9.84537 7.65645C10.3374 7.63423 10.528 7.62757 11.522 7.62645V7.62779ZM14.8474 8.51335C14.4941 8.51335 14.2074 8.79969 14.2074 9.15314C14.2074 9.50647 14.4941 9.79314 14.8474 9.79314C15.2008 9.79314 15.4874 9.50647 15.4874 9.15314C15.4874 8.7998 15.2008 8.51335 14.8474 8.51335ZM12.0005 9.26114C10.4879 9.26114 9.26158 10.4875 9.26158 12.0001C9.26158 13.5126 10.4879 14.7384 12.0005 14.7384C13.5131 14.7384 14.739 13.5126 14.739 12.0001C14.739 10.4875 13.5131 9.26114 12.0005 9.26114Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0005 10.2223C12.9823 10.2223 13.7783 11.0182 13.7783 12.0001C13.7783 12.9818 12.9823 13.7779 12.0005 13.7779C11.0186 13.7779 10.2227 12.9818 10.2227 12.0001C10.2227 11.0182 11.0186 10.2223 12.0005 10.2223Z"
    />
  </svg>
);
export default SvgInstagramRound;
