import * as React from 'react';
import { SVGProps } from 'react';
const SvgPieChart = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M10.75 12.5V13.25H11.5H20.2183C19.8382 17.7311 16.0798 21.25 11.5 21.25C6.66751 21.25 2.75 17.3325 2.75 12.5C2.75 7.92018 6.26891 4.16178 10.75 3.78166V12.5Z"
      strokeWidth={1.5}
    />
    <path
      d="M13.75 10.25V2.78359C17.7075 3.13997 20.86 6.29248 21.2164 10.25H13.75Z"
      strokeWidth={1.5}
    />
  </svg>
);
export default SvgPieChart;
