import create from 'zustand';

import { CryptoCurrency } from '../gql/gqlTypes';

type AmountType = 'crypto' | 'fiat';
export interface State {
  fiatAmount: string;
  setFiatAmount: (fiatAmount: string) => void;
  cryptoAmount: string;
  setCryptoAmount: (cryptoAmount: string) => void;
  amountType: AmountType;
  setAmountType: (amountType: AmountType) => void;
  currency: CryptoCurrency;
  setCurrency: (currency: CryptoCurrency) => void;
  address: string;
  setAddress: (address: string) => void;
  destinationTag: string;
  setDestinationTag: (destinationTag: string) => void;
  showAddressScanner: boolean;
  setShowAddressScanner: (showAddressScanner: boolean) => void;
  reset: () => void;
}

export const initialState = {
  fiatAmount: '',
  cryptoAmount: '',
  amountType: 'crypto' as AmountType,
  currency: CryptoCurrency.Btc,
  destinationTag: '',
  address: '',
  showAddressScanner: false,
};

export const useSendCrypto = create<State>()((set) => ({
  ...initialState,
  setFiatAmount: (fiatAmount) => set({ fiatAmount }),
  setCryptoAmount: (cryptoAmount) => set({ cryptoAmount }),
  setAmountType: (amountType) => set({ amountType }),
  setCurrency: (currency) => set({ currency }),
  setDestinationTag: (destinationTag) => set({ destinationTag }),
  setAddress: (address) => set({ address }),
  setShowAddressScanner: (showAddressScanner) => set({ showAddressScanner }),
  reset: () => set({ ...initialState }),
}));
