import * as React from 'react';
import { SVGProps } from 'react';
const SvgGenericCoin = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 7C14.5 8.11941 13.7643 9.06698 12.75 9.38555V14.8594L16.25 13.1094V12.3855C15.2357 12.067 14.5 11.1194 14.5 10C14.5 8.61929 15.6193 7.5 17 7.5C18.3807 7.5 19.5 8.61929 19.5 10C19.5 11.1194 18.7643 12.067 17.75 12.3855V13.7275C17.75 13.9168 17.643 14.09 17.4736 14.1747L12.75 16.5365V19C12.75 19.2761 12.5261 19.5 12.25 19.5H11.75C11.4739 19.5 11.25 19.2761 11.25 19V17.4635L6.52639 15.1017C6.357 15.017 6.25 14.8439 6.25 14.6545V13.3855C5.23572 13.067 4.5 12.1194 4.5 11C4.5 9.61929 5.61929 8.5 7 8.5C8.38071 8.5 9.5 9.61929 9.5 11C9.5 12.1194 8.76428 13.067 7.75 13.3855V14.0365L11.25 15.7865V9.38555C10.2357 9.06698 9.5 8.11941 9.5 7C9.5 5.61929 10.6193 4.5 12 4.5C13.3807 4.5 14.5 5.61929 14.5 7ZM12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8ZM8 11C8 11.5523 7.55228 12 7 12C6.44772 12 6 11.5523 6 11C6 10.4477 6.44772 10 7 10C7.55228 10 8 10.4477 8 11ZM18 10C18 10.5523 17.5523 11 17 11C16.4477 11 16 10.5523 16 10C16 9.44772 16.4477 9 17 9C17.5523 9 18 9.44772 18 10Z"
      fill="#12123B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12Z"
      fill="#12123B"
    />
  </svg>
);
export default SvgGenericCoin;
