import { gql, useQuery } from '@apollo/client';

import {
  CryptoCurrency,
  UnstakingRequestsQuery,
  UnstakingRequestsQueryVariables,
} from '../gqlTypes';

const QUERY = gql`
  query UnstakingRequests($currency: CryptoCurrency, $status: [UnstakingRequestStatus!]) {
    staking {
      unstakingRequests(currency: $currency, status: $status) {
        id
        status
        currency
        createdAt
        completedAt
        amount
        updatedAt
      }
    }
  }
`;

export const useUnstakingRequests = (
  input?: {
    currency?: CryptoCurrency;
    status: any;
  },
  options?: {
    skip?: boolean;
  },
) => {
  const result = useQuery<UnstakingRequestsQuery, UnstakingRequestsQueryVariables>(QUERY, {
    skip: options?.skip,
    fetchPolicy: 'cache-and-network',
    variables: {
      currency: input?.currency,
      status: input?.status,
    },
  });

  return result;
};
