import * as React from 'react';
import { SVGProps } from 'react';
const SvgCoinsFiri = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 175 143" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M174.257 114.739H0.74286C0.545841 114.739 0.356892 114.66 0.217579 114.521C0.0782654 114.381 0 114.192 0 113.995C0 113.797 0.0782654 113.608 0.217579 113.469C0.356892 113.329 0.545841 113.251 0.74286 113.251H174.257C174.454 113.251 174.643 113.329 174.782 113.469C174.922 113.608 175 113.797 175 113.995C175 114.192 174.922 114.381 174.782 114.521C174.643 114.66 174.454 114.739 174.257 114.739Z"
      fill="#12123B"
    />
    <path
      d="M81.1708 110.566C96.8021 110.566 109.474 107.241 109.474 103.139C109.474 99.0367 96.8021 95.7112 81.1708 95.7112C65.5395 95.7112 52.8679 99.0367 52.8679 103.139C52.8679 107.241 65.5395 110.566 81.1708 110.566Z"
      fill="#FDEB8B"
    />
    <path
      d="M101.326 97.3094C95.933 95.8959 88.7718 95.1162 81.1619 95.1162C73.5521 95.1162 66.3909 95.8959 60.9978 97.3094C57.013 98.3569 52.2617 100.217 52.2617 103.139V111.79C52.2617 114.715 57.016 116.575 60.9978 117.619C66.3909 119.036 73.5521 119.816 81.1619 119.816C88.7718 119.816 95.9419 119.033 101.326 117.619C105.311 116.575 110.059 114.715 110.059 111.79V103.139C110.068 100.217 105.32 98.3569 101.326 97.3094ZM108.871 111.79C108.871 113.429 105.938 115.179 101.026 116.468C95.7279 117.857 88.6737 118.625 81.1619 118.625C73.6501 118.625 66.6019 117.857 61.2979 116.468C56.3861 115.179 53.4503 113.429 53.4503 111.79V105.541C55.1143 107.13 58.2373 108.243 60.9978 108.969C66.3909 110.382 73.5521 111.162 81.1619 111.162C88.7718 111.162 95.933 110.382 101.326 108.969C104.087 108.243 107.21 107.13 108.871 105.541V111.79ZM101.029 107.817C95.7309 109.207 88.6767 109.971 81.1649 109.971C73.6531 109.971 66.6049 109.207 61.3008 107.817C56.389 106.525 53.4533 104.779 53.4533 103.139C53.4533 101.499 56.389 99.7525 61.3008 98.461C66.5989 97.0713 73.6531 96.3065 81.1649 96.3065C88.6767 96.3065 95.725 97.0713 101.029 98.461C105.941 99.7525 108.874 101.499 108.874 103.139C108.874 104.779 105.947 106.534 101.029 107.817Z"
      fill="#12123B"
    />
    <path
      d="M108.88 105.54V111.79C108.88 113.429 105.947 115.179 101.035 116.467C95.7368 117.857 88.6826 118.625 81.1708 118.625C73.659 118.625 66.6108 117.857 61.3067 116.467C56.395 115.179 53.4592 113.429 53.4592 111.79V105.54C55.1232 107.129 58.2462 108.242 61.0066 108.968C66.3998 110.382 73.561 111.162 81.1708 111.162C88.7807 111.162 95.9418 110.382 101.335 108.968C104.095 108.242 107.218 107.129 108.88 105.54Z"
      fill="#FBDD3E"
    />
    <path
      d="M80.3834 101.958C96.0147 101.958 108.686 98.6321 108.686 94.5299C108.686 90.4278 96.0147 87.1023 80.3834 87.1023C64.7521 87.1023 52.0804 90.4278 52.0804 94.5299C52.0804 98.6321 64.7521 101.958 80.3834 101.958Z"
      fill="#FDEB8B"
    />
    <path
      d="M100.548 88.7003C95.1544 87.2867 87.9932 86.5071 80.3834 86.5071C72.7735 86.5071 65.6124 87.2867 60.2192 88.7003C56.2345 89.7477 51.4832 91.6076 51.4832 94.5299V103.181C51.4832 106.106 56.2375 107.966 60.2192 109.01C65.6124 110.427 72.7735 111.206 80.3834 111.206C87.9932 111.206 95.1544 110.427 100.548 109.01C104.532 107.966 109.281 106.106 109.281 103.181V94.5299C109.281 91.6076 104.532 89.7477 100.548 88.7003ZM108.092 103.181C108.092 104.82 105.159 106.57 100.247 107.859C94.9494 109.248 87.8952 110.016 80.3834 110.016C72.8716 110.016 65.8233 109.248 60.5193 107.859C55.6075 106.57 52.6717 104.82 52.6717 103.181V96.9314C54.3357 98.5205 57.4587 99.6334 60.2192 100.36C65.6124 101.773 72.7735 102.553 80.3834 102.553C87.9932 102.553 95.1544 101.773 100.548 100.36C103.308 99.6334 106.431 98.5205 108.092 96.9314V103.181ZM100.247 99.2079C94.9494 100.598 87.8952 101.362 80.3834 101.362C72.8716 101.362 65.8233 100.598 60.5193 99.2079C55.6075 97.9164 52.6717 96.1696 52.6717 94.5299C52.6717 92.8902 55.6075 91.1434 60.5193 89.8519C65.8174 88.4622 72.8716 87.6974 80.3834 87.6974C87.8952 87.6974 94.9434 88.4622 100.247 89.8519C105.159 91.1434 108.092 92.8902 108.092 94.5299C108.092 96.1696 105.159 97.9045 100.247 99.2079Z"
      fill="#12123B"
    />
    <path
      d="M108.092 96.9314V103.181C108.092 104.82 105.159 106.57 100.247 107.859C94.9494 109.248 87.8952 110.016 80.3834 110.016C72.8716 110.016 65.8233 109.248 60.5193 107.859C55.6075 106.57 52.6718 104.82 52.6718 103.181V96.9314C54.3358 98.5205 57.4587 99.6334 60.2192 100.36C65.6124 101.773 72.7735 102.553 80.3834 102.553C87.9933 102.553 95.1544 101.773 100.548 100.36C103.308 99.6334 106.431 98.5205 108.092 96.9314Z"
      fill="#FBDD3E"
    />
    <path
      d="M81.1708 95.7113C96.8021 95.7113 109.474 92.3858 109.474 88.2836C109.474 84.1814 96.8021 80.856 81.1708 80.856C65.5395 80.856 52.8679 84.1814 52.8679 88.2836C52.8679 92.3858 65.5395 95.7113 81.1708 95.7113Z"
      fill="#FDEB8B"
    />
    <path
      d="M101.326 82.4539C95.933 81.0404 88.7718 80.2607 81.1619 80.2607C73.5521 80.2607 66.3909 81.0404 60.9978 82.4539C57.013 83.5014 52.2617 85.3613 52.2617 88.2836V96.9343C52.2617 99.8595 57.016 101.719 60.9978 102.764C66.3909 104.18 73.5521 104.96 81.1619 104.96C88.7718 104.96 95.933 104.18 101.326 102.764C105.311 101.719 110.059 99.8595 110.059 96.9343V88.2836C110.068 85.3613 105.32 83.5014 101.326 82.4539ZM108.871 96.9343C108.871 98.5739 105.938 100.324 101.026 101.612C95.7279 103.002 88.6737 103.77 81.1619 103.77C73.6501 103.77 66.6019 103.002 61.2979 101.612C56.3861 100.324 53.4503 98.5739 53.4503 96.9343V90.685C55.1143 92.2741 58.2373 93.3871 60.9978 94.1132C66.3909 95.5267 73.5521 96.3064 81.1619 96.3064C88.7718 96.3064 95.9419 95.5237 101.326 94.1132C104.087 93.3871 107.21 92.2741 108.871 90.685V96.9343ZM101.029 92.9615C95.7309 94.3513 88.6767 95.116 81.1649 95.116C73.6531 95.116 66.6049 94.3513 61.3008 92.9615C56.389 91.67 53.4533 89.9232 53.4533 88.2836C53.4533 86.6439 56.389 84.8971 61.3008 83.6056C66.5989 82.2159 73.6531 81.4511 81.1649 81.4511C88.6767 81.4511 95.725 82.2159 101.029 83.6056C105.941 84.8971 108.874 86.6439 108.874 88.2836C108.874 89.9232 105.947 91.67 101.029 92.9615Z"
      fill="#12123B"
    />
    <path
      d="M108.88 90.6851V96.9343C108.88 98.574 105.947 100.324 101.035 101.612C95.7368 103.002 88.6826 103.77 81.1708 103.77C73.659 103.77 66.6108 103.002 61.3067 101.612C56.395 100.324 53.4592 98.574 53.4592 96.9343V90.6851C55.1232 92.2741 58.2462 93.3871 61.0066 94.1132C66.3998 95.5267 73.561 96.3064 81.1708 96.3064C88.7807 96.3064 95.9418 95.5237 101.326 94.1132C104.095 93.3871 107.218 92.2741 108.88 90.6851Z"
      fill="#FBDD3E"
    />
    <path
      d="M79.1948 84.8377C94.8261 84.8377 107.498 81.5122 107.498 77.4101C107.498 73.3079 94.8261 69.9824 79.1948 69.9824C63.5635 69.9824 50.8918 73.3079 50.8918 77.4101C50.8918 81.5122 63.5635 84.8377 79.1948 84.8377Z"
      fill="#FDEB8B"
    />
    <path
      d="M99.359 71.5804C93.9658 70.1669 86.8047 69.3872 79.1948 69.3872C71.585 69.3872 64.4238 70.1669 59.0306 71.5804C55.0459 72.6279 50.2946 74.4878 50.2946 77.41V86.0607C50.2946 88.986 55.0489 90.8458 59.0306 91.8903C64.4238 93.3068 71.585 94.0865 79.1948 94.0865C86.8047 94.0865 93.9658 93.3068 99.359 91.8903C103.344 90.8458 108.092 88.986 108.092 86.0607V77.41C108.092 74.4878 103.344 72.6279 99.359 71.5804ZM106.903 86.0607C106.903 87.7004 103.971 89.4502 99.0589 90.7387C93.7608 92.1284 86.7066 92.8962 79.1948 92.8962C71.683 92.8962 64.6348 92.1284 59.3307 90.7387C54.4189 89.4502 51.4832 87.7004 51.4832 86.0607V79.8115C53.1472 81.4006 56.2702 82.5135 59.0306 83.2396C64.4238 84.6532 71.585 85.4328 79.1948 85.4328C86.8047 85.4328 93.9658 84.6532 99.359 83.2396C102.119 82.5135 105.242 81.4006 106.903 79.8115V86.0607ZM99.0589 82.088C93.7608 83.4777 86.7066 84.2425 79.1948 84.2425C71.683 84.2425 64.6348 83.4777 59.3307 82.088C54.4189 80.7965 51.4832 79.0497 51.4832 77.41C51.4832 75.7703 54.4189 74.0235 59.3307 72.732C64.6288 71.3423 71.683 70.5775 79.1948 70.5775C86.7066 70.5775 93.7549 71.3423 99.0589 72.732C103.971 74.0235 106.903 75.7703 106.903 77.41C106.903 79.0497 103.971 80.7965 99.0589 82.088Z"
      fill="#12123B"
    />
    <path
      d="M106.903 79.8115V86.0607C106.903 87.7004 103.971 89.4502 99.0589 90.7387C93.7608 92.1284 86.7066 92.8962 79.1948 92.8962C71.683 92.8962 64.6348 92.1284 59.3307 90.7387C54.4189 89.4502 51.4832 87.7004 51.4832 86.0607V79.8115C53.1472 81.4006 56.2701 82.5136 59.0306 83.2397C64.4238 84.6532 71.5849 85.4328 79.1948 85.4328C86.8047 85.4328 93.9658 84.6532 99.359 83.2397C102.119 82.5136 105.242 81.4006 106.903 79.8115Z"
      fill="#FBDD3E"
    />
    <path
      d="M81.1708 76.1304C96.8021 76.1304 109.474 72.805 109.474 68.7028C109.474 64.6006 96.8021 61.2751 81.1708 61.2751C65.5395 61.2751 52.8679 64.6006 52.8679 68.7028C52.8679 72.805 65.5395 76.1304 81.1708 76.1304Z"
      fill="#FDEB8B"
    />
    <path
      d="M101.326 62.8731C95.933 61.4596 88.7718 60.6799 81.1619 60.6799C73.5521 60.6799 66.3909 61.4596 60.9978 62.8731C57.013 63.9206 52.2617 65.7805 52.2617 68.7027V77.3534C52.2617 80.2787 57.016 82.1386 60.9978 83.1831C66.3909 84.5996 73.5521 85.3792 81.1619 85.3792C88.7718 85.3792 95.933 84.5996 101.326 83.1831C105.311 82.1386 110.059 80.2787 110.059 77.3534V68.7027C110.068 65.7805 105.32 63.9206 101.326 62.8731ZM108.871 77.3534C108.871 78.9931 105.938 80.7429 101.026 82.0314C95.7279 83.4211 88.6737 84.1889 81.1619 84.1889C73.6501 84.1889 66.6019 83.4211 61.2979 82.0314C56.3861 80.7429 53.4503 78.9931 53.4503 77.3534V71.1042C55.1143 72.6933 58.2373 73.8063 60.9978 74.5324C66.3909 75.9459 73.5521 76.7255 81.1619 76.7255C88.7718 76.7255 95.933 75.9459 101.326 74.5324C104.087 73.8063 107.21 72.6933 108.871 71.1042V77.3534ZM101.029 73.3807C95.7309 74.7704 88.6767 75.5352 81.1649 75.5352C73.6531 75.5352 66.6049 74.7704 61.3008 73.3807C56.389 72.0892 53.4533 70.3424 53.4533 68.7027C53.4533 67.0631 56.389 65.3163 61.3008 64.0247C66.5989 62.635 73.6531 61.8703 81.1649 61.8703C88.6767 61.8703 95.725 62.635 101.029 64.0247C105.941 65.3163 108.874 67.0631 108.874 68.7027C108.874 70.3424 105.947 72.0892 101.029 73.3807Z"
      fill="#12123B"
    />
    <path
      d="M108.88 71.1042V77.3535C108.88 78.9931 105.947 80.7429 101.035 82.0315C95.7368 83.4212 88.6826 84.1889 81.1708 84.1889C73.659 84.1889 66.6108 83.4212 61.3067 82.0315C56.395 80.7429 53.4592 78.9931 53.4592 77.3535V71.1042C55.1232 72.6933 58.2462 73.8063 61.0066 74.5324C66.3998 75.9459 73.561 76.7256 81.1708 76.7256C88.7807 76.7256 95.9418 75.9459 101.335 74.5324C104.095 73.8003 107.218 72.6933 108.88 71.1042Z"
      fill="#FBDD3E"
    />
    <path
      d="M81.1708 67.3218C96.8021 67.3218 109.474 63.9964 109.474 59.8942C109.474 55.792 96.8021 52.4666 81.1708 52.4666C65.5395 52.4666 52.8679 55.792 52.8679 59.8942C52.8679 63.9964 65.5395 67.3218 81.1708 67.3218Z"
      fill="#FDEB8B"
    />
    <path
      d="M101.326 54.0648C95.933 52.6512 88.7718 51.8716 81.1619 51.8716C73.5521 51.8716 66.3909 52.6512 60.9978 54.0648C57.013 55.1122 52.2617 56.9721 52.2617 59.8944V68.5451C52.2617 71.4703 57.016 73.3302 60.9978 74.3747C66.3909 75.7912 73.5521 76.5709 81.1619 76.5709C88.7718 76.5709 95.933 75.7912 101.326 74.3747C105.311 73.3302 110.059 71.4703 110.059 68.5451V59.8944C110.068 56.9721 105.32 55.1122 101.326 54.0648ZM108.871 68.5451C108.871 70.1848 105.938 71.9346 101.026 73.2231C95.7279 74.6128 88.6737 75.3806 81.1619 75.3806C73.6501 75.3806 66.6019 74.6128 61.2979 73.2231C56.3861 71.9346 53.4503 70.1848 53.4503 68.5451V62.2959C55.1143 63.885 58.2373 64.9979 60.9978 65.724C66.3909 67.1375 73.5521 67.9172 81.1619 67.9172C88.7718 67.9172 95.933 67.1375 101.326 65.724C104.087 64.9979 107.21 63.885 108.871 62.2959V68.5451ZM101.029 64.5754C95.7309 65.9651 88.6767 66.7298 81.1649 66.7298C73.6531 66.7298 66.6049 65.9651 61.3008 64.5754C56.389 63.2838 53.4533 61.537 53.4533 59.8974C53.4533 58.2577 56.389 56.5109 61.3008 55.2194C66.5989 53.8297 73.6531 53.0649 81.1649 53.0649C88.6767 53.0649 95.725 53.8297 101.029 55.2194C105.941 56.5109 108.874 58.2577 108.874 59.8974C108.874 61.537 105.947 63.2809 101.029 64.5754Z"
      fill="#12123B"
    />
    <path
      d="M108.88 62.2959V68.5451C108.88 70.1848 105.947 71.9346 101.035 73.2231C95.7368 74.6128 88.6826 75.3806 81.1708 75.3806C73.659 75.3806 66.6108 74.6128 61.3067 73.2231C56.395 71.9346 53.4592 70.1848 53.4592 68.5451V62.2959C55.1232 63.885 58.2462 64.9979 61.0066 65.724C66.3998 67.1376 73.561 67.9172 81.1708 67.9172C88.7807 67.9172 95.9418 67.1376 101.335 65.724C104.095 64.9979 107.218 63.885 108.88 62.2959Z"
      fill="#FBDD3E"
    />
    <path
      d="M84.3324 58.6951C99.9637 58.6951 112.635 55.3697 112.635 51.2675C112.635 47.1653 99.9637 43.8398 84.3324 43.8398C68.7012 43.8398 56.0295 47.1653 56.0295 51.2675C56.0295 55.3697 68.7012 58.6951 84.3324 58.6951Z"
      fill="#FDEB8B"
    />
    <path
      d="M104.497 45.4378C99.1035 44.0243 91.9423 43.2446 84.3324 43.2446C76.7226 43.2446 69.5614 44.0243 64.1683 45.4378C60.1836 46.4853 55.4322 48.3452 55.4322 51.2674V59.9181C55.4322 62.8434 60.1865 64.7033 64.1683 65.7478C69.5614 67.1643 76.7226 67.9439 84.3324 67.9439C91.9423 67.9439 99.1035 67.1643 104.497 65.7478C108.481 64.7033 113.23 62.8434 113.23 59.9181V51.2674C113.23 48.3452 108.481 46.4853 104.497 45.4378ZM112.041 59.9181C112.041 61.5578 109.108 63.3076 104.197 64.5961C98.8984 65.9858 91.8442 66.7536 84.3324 66.7536C76.8206 66.7536 69.7724 65.9858 64.4684 64.5961C59.5566 63.3076 56.6208 61.5578 56.6208 59.9181V53.6689C58.2848 55.258 61.4078 56.371 64.1683 57.0971C69.5614 58.5106 76.7226 59.2902 84.3324 59.2902C91.9423 59.2902 99.1035 58.5106 104.497 57.0971C107.257 56.371 110.38 55.258 112.041 53.6689V59.9181ZM104.197 55.9454C98.8984 57.3351 91.8442 58.0999 84.3324 58.0999C76.8206 58.0999 69.7724 57.3351 64.4684 55.9454C59.5566 54.6539 56.6208 52.9071 56.6208 51.2674C56.6208 49.6278 59.5566 47.881 64.4684 46.5894C69.7664 45.1997 76.8206 44.435 84.3324 44.435C91.8442 44.435 98.8925 45.1997 104.197 46.5894C109.108 47.881 112.041 49.6278 112.041 51.2674C112.041 52.9071 109.108 54.6539 104.197 55.9454Z"
      fill="#12123B"
    />
    <path
      d="M112.041 53.6689V59.9182C112.041 61.5578 109.108 63.3076 104.197 64.5962C98.8984 65.9859 91.8442 66.7536 84.3324 66.7536C76.8206 66.7536 69.7724 65.9859 64.4684 64.5962C59.5566 63.3076 56.6208 61.5578 56.6208 59.9182V53.6689C58.2848 55.258 61.4078 56.371 64.1682 57.0971C69.5614 58.5106 76.7226 59.2903 84.3324 59.2903C91.9423 59.2903 99.1035 58.5106 104.497 57.0971C107.269 56.371 110.38 55.258 112.041 53.6689Z"
      fill="#FBDD3E"
    />
    <path
      d="M81.1708 50.086C96.8021 50.086 109.474 46.7605 109.474 42.6584C109.474 38.5562 96.8021 35.2307 81.1708 35.2307C65.5395 35.2307 52.8679 38.5562 52.8679 42.6584C52.8679 46.7605 65.5395 50.086 81.1708 50.086Z"
      fill="#FDEB8B"
    />
    <path
      d="M101.326 36.8287C95.933 35.4152 88.7718 34.6355 81.1619 34.6355C73.5521 34.6355 66.3909 35.4152 60.9978 36.8287C57.013 37.8762 52.2617 39.7361 52.2617 42.6583V51.309C52.2617 54.2342 57.016 56.0941 60.9978 57.1386C66.3909 58.5551 73.5521 59.3348 81.1619 59.3348C88.7718 59.3348 95.9419 58.5551 101.326 57.1357C105.311 56.0912 110.059 54.2313 110.059 51.306V42.6583C110.068 39.7361 105.32 37.8762 101.326 36.8287ZM108.871 51.309C108.871 52.9487 105.938 54.6985 101.026 55.987C95.7279 57.3767 88.6737 58.1445 81.1619 58.1445C73.6501 58.1445 66.6019 57.3767 61.2979 55.987C56.3861 54.6985 53.4503 52.9487 53.4503 51.309V45.0598C55.1143 46.6489 58.2373 47.7618 60.9978 48.4879C66.3909 49.9014 73.5521 50.6811 81.1619 50.6811C88.7718 50.6811 95.933 49.9014 101.326 48.4879C104.087 47.7618 107.21 46.6489 108.871 45.0598V51.309ZM101.029 47.3363C95.7309 48.726 88.6767 49.4908 81.1649 49.4908C73.6531 49.4908 66.6049 48.726 61.3008 47.3363C56.389 46.0448 53.4533 44.298 53.4533 42.6583C53.4533 41.0186 56.389 39.2718 61.3008 37.9803C66.5989 36.5906 73.6531 35.8258 81.1649 35.8258C88.6767 35.8258 95.725 36.5906 101.029 37.9803C105.941 39.2718 108.874 41.0186 108.874 42.6583C108.874 44.298 105.947 46.0448 101.029 47.3363Z"
      fill="#12123B"
    />
    <path
      d="M108.88 45.0598V51.309C108.88 52.9487 105.947 54.6985 101.035 55.987C95.7368 57.3767 88.6826 58.1445 81.1708 58.1445C73.659 58.1445 66.6108 57.3767 61.3067 55.987C56.395 54.6985 53.4592 52.9487 53.4592 51.309V45.0598C55.1232 46.6489 58.2462 47.7619 61.0066 48.488C66.3998 49.9015 73.561 50.6811 81.1708 50.6811C88.7807 50.6811 95.9418 49.9015 101.335 48.488C104.095 47.7619 107.218 46.6489 108.88 45.0598Z"
      fill="#FBDD3E"
    />
    <path
      d="M81.1708 41.3787C96.8021 41.3787 109.474 38.0533 109.474 33.9511C109.474 29.8489 96.8021 26.5234 81.1708 26.5234C65.5395 26.5234 52.8679 29.8489 52.8679 33.9511C52.8679 38.0533 65.5395 41.3787 81.1708 41.3787Z"
      fill="#FDEB8B"
    />
    <path
      d="M101.326 28.1214C95.933 26.7079 88.7718 25.9282 81.1619 25.9282C73.5521 25.9282 66.3909 26.7079 60.9978 28.1214C57.013 29.1689 52.2617 31.0288 52.2617 33.951V42.6017C52.2617 45.527 57.016 47.3869 60.9978 48.4314C66.3909 49.8479 73.5521 50.6275 81.1619 50.6275C88.7718 50.6275 95.933 49.8479 101.326 48.4314C105.311 47.3869 110.059 45.527 110.059 42.6017V33.951C110.068 31.0288 105.32 29.1629 101.326 28.1214ZM108.871 42.6017C108.871 44.2414 105.938 45.9912 101.026 47.2797C95.7279 48.6694 88.6737 49.4372 81.1619 49.4372C73.6501 49.4372 66.6019 48.6694 61.2979 47.2797C56.3861 45.9912 53.4503 44.2414 53.4503 42.6017V36.3525C55.1143 37.9416 58.2373 39.0546 60.9978 39.7807C66.3909 41.1942 73.5521 41.9738 81.1619 41.9738C88.7718 41.9738 95.933 41.1942 101.326 39.7807C104.087 39.0546 107.21 37.9416 108.871 36.3525V42.6017ZM101.029 38.629C95.7309 40.0187 88.6767 40.7835 81.1649 40.7835C73.6531 40.7835 66.6049 40.0187 61.3008 38.629C56.389 37.3375 53.4533 35.5907 53.4533 33.951C53.4533 32.3114 56.389 30.5646 61.3008 29.273C66.5989 27.8833 73.6531 27.1186 81.1649 27.1186C88.6767 27.1186 95.725 27.8833 101.029 29.273C105.941 30.5646 108.874 32.3114 108.874 33.951C108.874 35.5907 105.947 37.3375 101.029 38.629Z"
      fill="#12123B"
    />
    <path
      d="M108.88 36.3525V42.6018C108.88 44.2414 105.947 45.9912 101.035 47.2797C95.7368 48.6695 88.6826 49.4372 81.1708 49.4372C73.659 49.4372 66.6108 48.6695 61.3067 47.2797C56.395 45.9912 53.4592 44.2414 53.4592 42.6018V36.3525C55.1232 37.9416 58.2462 39.0546 61.0066 39.7807C66.3998 41.1942 73.561 41.9739 81.1708 41.9739C88.7807 41.9739 95.9418 41.1942 101.335 39.7807C104.095 39.0546 107.218 37.9416 108.88 36.3525Z"
      fill="#FBDD3E"
    />
    <path
      d="M81.1708 32.9661C96.8021 32.9661 109.474 29.6407 109.474 25.5385C109.474 21.4363 96.8021 18.1108 81.1708 18.1108C65.5395 18.1108 52.8679 21.4363 52.8679 25.5385C52.8679 29.6407 65.5395 32.9661 81.1708 32.9661Z"
      fill="#FDEB8B"
    />
    <path
      d="M101.326 19.7088C95.933 18.2953 88.7718 17.5156 81.1619 17.5156C73.5521 17.5156 66.3909 18.2953 60.9978 19.7088C57.013 20.7563 52.2617 22.6162 52.2617 25.5384V34.1891C52.2617 37.1144 57.016 38.9743 60.9978 40.0188C66.3909 41.4353 73.5521 42.2149 81.1619 42.2149C88.7718 42.2149 95.933 41.4353 101.326 40.0188C105.311 38.9743 110.059 37.1144 110.059 34.1891V25.5384C110.068 22.6162 105.32 20.7563 101.326 19.7088ZM108.871 34.1891C108.871 35.8288 105.938 37.5786 101.026 38.8671C95.7279 40.2568 88.6737 41.0246 81.1619 41.0246C73.6501 41.0246 66.6019 40.2568 61.2979 38.8671C56.3861 37.5786 53.4503 35.8288 53.4503 34.1891V27.9399C55.1143 29.529 58.2373 30.642 60.9978 31.3681C66.3909 32.7816 73.5521 33.5612 81.1619 33.5612C88.7718 33.5612 95.933 32.7816 101.326 31.3681C104.087 30.642 107.21 29.529 108.871 27.9399V34.1891ZM101.029 30.2164C95.7309 31.6061 88.6767 32.3709 81.1649 32.3709C73.6531 32.3709 66.6049 31.6061 61.3008 30.2164C56.389 28.9249 53.4533 27.1781 53.4533 25.5384C53.4533 23.8988 56.389 22.1519 61.3008 20.8604C66.5989 19.4707 73.6531 18.706 81.1649 18.706C88.6767 18.706 95.725 19.4707 101.029 20.8604C105.941 22.1519 108.874 23.8988 108.874 25.5384C108.874 27.1781 105.947 28.9249 101.029 30.2164Z"
      fill="#12123B"
    />
    <path
      d="M108.88 27.9399V34.1892C108.88 35.8288 105.947 37.5786 101.035 38.8671C95.7368 40.2569 88.6826 41.0246 81.1708 41.0246C73.659 41.0246 66.6108 40.2569 61.3067 38.8671C56.395 37.5786 53.4592 35.8288 53.4592 34.1892V27.9399C55.1232 29.529 58.2462 30.642 61.0066 31.3681C66.3998 32.7816 73.561 33.5613 81.1708 33.5613C88.7807 33.5613 95.9418 32.7816 101.335 31.3681C104.095 30.6509 107.218 29.529 108.88 27.9399Z"
      fill="#FBDD3E"
    />
    <path
      d="M84.3354 26.7645C99.9667 26.7645 112.638 23.439 112.638 19.3368C112.638 15.2346 99.9667 11.9092 84.3354 11.9092C68.7041 11.9092 56.0324 15.2346 56.0324 19.3368C56.0324 23.439 68.7041 26.7645 84.3354 26.7645Z"
      fill="#FDEB8B"
    />
    <path
      d="M104.5 13.5072C99.1064 12.0937 91.9453 11.3081 84.3354 11.3081C76.7256 11.3081 69.5644 12.0937 64.1831 13.5072C60.1984 14.5547 55.4471 16.4146 55.4471 19.3369V27.9876C55.4471 30.9128 60.2014 32.7727 64.1831 33.8172C69.5763 35.2337 76.7374 36.0134 84.3473 36.0134C91.9572 36.0134 99.1183 35.2337 104.511 33.8172C108.496 32.7727 113.245 30.9128 113.245 27.9876V19.3428C113.233 16.4146 108.484 14.5547 104.5 13.5072ZM112.044 27.9876C112.044 29.6272 109.111 31.377 104.2 32.6656C98.9014 34.0553 91.8472 34.823 84.3354 34.823C76.8236 34.823 69.7754 34.0553 64.4713 32.6656C59.5596 31.377 56.6238 29.6272 56.6238 27.9876V21.7384C58.2878 23.3274 61.4108 24.4404 64.1712 25.1665C69.5644 26.58 76.7256 27.3597 84.3354 27.3597C91.9453 27.3597 99.1064 26.58 104.5 25.1665C107.26 24.4404 110.383 23.3274 112.044 21.7384V27.9876ZM104.2 24.0149C98.9014 25.4046 91.8472 26.1693 84.3354 26.1693C76.8236 26.1693 69.7754 25.4046 64.4713 24.0149C59.5596 22.7233 56.6238 20.9765 56.6238 19.3428C56.6238 17.7091 59.5596 15.9504 64.4803 14.6589C69.7694 13.2692 76.8236 12.4984 84.3354 12.4984C91.8472 12.4984 98.8955 13.2632 104.2 14.6529C109.111 15.9444 112.044 17.6912 112.044 19.3309C112.044 20.9706 109.111 22.7233 104.2 24.0149Z"
      fill="#12123B"
    />
    <path
      d="M112.044 21.7383V27.9875C112.044 29.6272 109.111 31.377 104.2 32.6655C98.9014 34.0552 91.8472 34.823 84.3354 34.823C76.8236 34.823 69.7754 34.0552 64.4714 32.6655C59.5596 31.377 56.6238 29.6272 56.6238 27.9875V21.7383C58.2878 23.3274 61.4108 24.4403 64.1712 25.1664C69.5644 26.5799 76.7256 27.3596 84.3354 27.3596C91.9453 27.3596 99.1064 26.5799 104.5 25.1664C107.269 24.4403 110.383 23.3274 112.044 21.7383Z"
      fill="#FBDD3E"
    />
    <path
      d="M78.0062 15.4505C93.6375 15.4505 106.309 12.125 106.309 8.02286C106.309 3.92068 93.6375 0.595215 78.0062 0.595215C62.3749 0.595215 49.7033 3.92068 49.7033 8.02286C49.7033 12.125 62.3749 15.4505 78.0062 15.4505Z"
      fill="#FDEB8B"
    />
    <path
      d="M98.1704 2.19318C92.7773 0.779665 85.6161 0 78.0062 0C70.3964 0 63.2352 0.779665 57.8421 2.19318C53.8574 3.24067 49.106 5.10055 49.106 8.02281V16.6646C49.106 19.5898 53.8603 21.4497 57.8421 22.4942C63.2352 23.9196 70.3964 24.6993 78.0062 24.6993C85.6161 24.6993 92.7773 23.9196 98.1704 22.5031C102.155 21.4586 106.903 19.5987 106.903 16.6735V8.03471C106.903 5.10055 102.155 3.24067 98.1704 2.19318ZM105.715 16.6646C105.715 18.3043 102.782 20.054 97.8703 21.3426C92.5722 22.7412 85.518 23.509 78.0062 23.509C70.4944 23.509 63.4462 22.7412 58.1422 21.3515C53.2304 20.063 50.2946 18.3132 50.2946 16.6646V10.4154C51.9586 12.0045 55.0816 13.1174 57.8421 13.8435C63.2352 15.257 70.3964 16.0367 78.0062 16.0367C85.6161 16.0367 92.7773 15.257 98.1704 13.8435C100.931 13.1174 104.054 12.0045 105.715 10.4154V16.6646ZM97.8703 12.6919C92.5722 14.0816 85.518 14.8464 78.0062 14.8464C70.4944 14.8464 63.4462 14.0816 58.1422 12.6919C53.2304 11.4093 50.2946 9.66248 50.2946 8.03471C50.2946 6.40694 53.2304 4.64823 58.1422 3.35672C63.4402 1.95511 70.4944 1.19033 78.0062 1.19033C85.518 1.19033 92.5663 1.95511 97.8703 3.34482C102.782 4.63633 105.715 6.38313 105.715 8.02281C105.715 9.66248 102.782 11.4093 97.8703 12.7008V12.6919Z"
      fill="#12123B"
    />
    <path
      d="M105.715 10.4153V16.6645C105.715 18.3042 102.782 20.054 97.8703 21.3425C92.5722 22.7411 85.518 23.5089 78.0062 23.5089C70.4944 23.5089 63.4462 22.7411 58.1422 21.3514C53.2304 20.0629 50.2946 18.3131 50.2946 16.6645V10.4153C51.9586 12.0044 55.0816 13.1173 57.842 13.8434C63.2352 15.2569 70.3964 16.0366 78.0062 16.0366C85.6161 16.0366 92.7773 15.2569 98.1704 13.8434C100.931 13.1263 104.054 12.0133 105.715 10.4153Z"
      fill="#FBDD3E"
    />
    <path
      d="M125.802 133.751C141.433 133.751 154.105 130.426 154.105 126.323C154.105 122.221 141.433 118.896 125.802 118.896C110.171 118.896 97.4989 122.221 97.4989 126.323C97.4989 130.426 110.171 133.751 125.802 133.751Z"
      fill="#FDEB8B"
    />
    <path
      d="M145.966 120.494C140.573 119.08 133.412 118.301 125.802 118.301C118.192 118.301 111.031 119.08 105.638 120.494C101.653 121.541 96.9016 123.401 96.9016 126.323V134.974C96.9016 137.899 101.656 139.759 105.638 140.804C111.031 142.22 118.192 143 125.802 143C133.412 143 140.573 142.22 145.966 140.804C149.951 139.759 154.699 137.899 154.699 134.974V126.323C154.699 123.401 149.951 121.541 145.966 120.494ZM153.511 134.974C153.511 136.614 150.578 138.364 145.666 139.652C140.368 141.042 133.314 141.81 125.802 141.81C118.29 141.81 111.242 141.042 105.938 139.652C101.029 138.375 98.0902 136.614 98.0902 134.974V128.725C99.7542 130.314 102.877 131.427 105.638 132.153C111.031 133.566 118.192 134.346 125.802 134.346C133.412 134.346 140.573 133.566 145.966 132.153C148.726 131.427 151.849 130.314 153.511 128.725V134.974ZM145.666 131.001C140.368 132.391 133.314 133.156 125.802 133.156C118.29 133.156 111.242 132.391 105.938 131.001C101.029 129.71 98.0902 127.96 98.0902 126.323C98.0902 124.687 101.026 122.937 105.938 121.645C111.236 120.256 118.29 119.491 125.802 119.491C133.314 119.491 140.362 120.256 145.666 121.645C150.578 122.937 153.511 124.684 153.511 126.323C153.511 127.963 150.578 129.71 145.666 131.001Z"
      fill="#12123B"
    />
    <path
      d="M153.511 128.725V134.974C153.511 136.614 150.578 138.364 145.666 139.652C140.368 141.042 133.314 141.81 125.802 141.81C118.29 141.81 111.242 141.042 105.938 139.652C101.029 138.375 98.0902 136.614 98.0902 134.974V128.725C99.7542 130.314 102.877 131.427 105.638 132.153C111.031 133.567 118.192 134.346 125.802 134.346C133.412 134.346 140.573 133.567 145.966 132.153C148.727 131.427 151.841 130.314 153.511 128.725Z"
      fill="#FBDD3E"
    />
    <path
      d="M121.131 123.964C136.762 123.964 149.434 120.638 149.434 116.536C149.434 112.434 136.762 109.108 121.131 109.108C105.499 109.108 92.8278 112.434 92.8278 116.536C92.8278 120.638 105.499 123.964 121.131 123.964Z"
      fill="#FDEB8B"
    />
    <path
      d="M141.295 110.7C135.902 109.287 128.741 108.507 121.131 108.507C113.521 108.507 106.36 109.287 100.967 110.7C96.9819 111.748 92.2305 113.608 92.2305 116.53V125.181C92.2305 128.106 96.9848 129.966 100.967 131.01C106.36 132.427 113.521 133.206 121.131 133.206C128.741 133.206 135.902 132.427 141.295 131.01C145.28 129.966 150.028 128.106 150.028 125.181V116.536C150.028 113.614 145.28 111.754 141.295 110.7ZM148.839 125.181C148.839 126.82 145.907 128.57 140.995 129.859C135.697 131.248 128.643 132.016 121.131 132.016C113.619 132.016 106.571 131.248 101.267 129.859C96.3549 128.57 93.4191 126.82 93.4191 125.181V118.931C95.0831 120.52 98.2061 121.633 100.967 122.36C106.36 123.773 113.521 124.553 121.131 124.553C128.741 124.553 135.902 123.773 141.295 122.36C144.055 121.633 147.178 120.52 148.839 118.931V125.181ZM140.995 121.208C135.697 122.598 128.643 123.362 121.131 123.362C113.619 123.362 106.571 122.598 101.267 121.208C96.3549 119.916 93.4191 118.17 93.4191 116.53C93.4191 114.89 96.3549 113.143 101.267 111.852C106.565 110.462 113.619 109.697 121.131 109.697C128.643 109.697 135.691 110.462 140.995 111.852C145.907 113.143 148.839 114.89 148.839 116.53C148.839 118.17 145.898 119.925 140.995 121.214V121.208Z"
      fill="#12123B"
    />
    <path
      d="M148.839 118.938V125.187C148.839 126.826 145.907 128.576 140.995 129.865C135.697 131.254 128.643 132.022 121.131 132.022C113.619 132.022 106.571 131.254 101.267 129.865C96.3549 128.576 93.4191 126.826 93.4191 125.187V118.938C95.0831 120.527 98.2061 121.64 100.967 122.366C106.36 123.779 113.521 124.559 121.131 124.559C128.741 124.559 135.902 123.779 141.295 122.366C144.055 121.64 147.178 120.521 148.839 118.938Z"
      fill="#FBDD3E"
    />
    <path
      d="M125.802 116.235C141.433 116.235 154.105 112.91 154.105 108.808C154.105 104.706 141.433 101.38 125.802 101.38C110.171 101.38 97.4989 104.706 97.4989 108.808C97.4989 112.91 110.171 116.235 125.802 116.235Z"
      fill="#FDEB8B"
    />
    <path
      d="M145.966 102.978C140.573 101.565 133.412 100.785 125.802 100.785C118.192 100.785 111.031 101.565 105.638 102.978C101.653 104.026 96.9016 105.885 96.9016 108.808V117.458C96.9016 120.384 101.656 122.244 105.638 123.288C111.031 124.705 118.192 125.484 125.802 125.484C133.412 125.484 140.573 124.705 145.966 123.288C149.951 122.244 154.699 120.384 154.699 117.458V108.808C154.699 105.885 149.951 104.026 145.966 102.978ZM153.511 117.458C153.511 119.098 150.578 120.848 145.666 122.136C140.368 123.526 133.314 124.294 125.802 124.294C118.29 124.294 111.242 123.526 105.938 122.136C101.026 120.848 98.0902 119.098 98.0902 117.458V111.209C99.7542 112.798 102.877 113.911 105.638 114.637C111.031 116.051 118.192 116.831 125.802 116.831C133.412 116.831 140.573 116.051 145.966 114.637C148.726 113.911 151.849 112.798 153.511 111.209V117.458ZM145.666 113.486C140.368 114.875 133.314 115.64 125.802 115.64C118.29 115.64 111.242 114.875 105.938 113.486C101.029 112.188 98.0902 110.447 98.0902 108.808C98.0902 107.168 101.026 105.421 105.938 104.13C111.236 102.74 118.29 101.975 125.802 101.975C133.314 101.975 140.362 102.74 145.666 104.13C150.578 105.421 153.511 107.168 153.511 108.808C153.511 110.447 150.578 112.188 145.666 113.486Z"
      fill="#12123B"
    />
    <path
      d="M153.511 111.209V117.458C153.511 119.098 150.578 120.848 145.666 122.136C140.368 123.526 133.314 124.294 125.802 124.294C118.29 124.294 111.242 123.526 105.938 122.136C101.026 120.848 98.0902 119.098 98.0902 117.458V111.209C99.7542 112.798 102.877 113.911 105.638 114.637C111.031 116.051 118.192 116.831 125.802 116.831C133.412 116.831 140.573 116.051 145.966 114.637C148.727 113.911 151.841 112.798 153.511 111.209Z"
      fill="#FBDD3E"
    />
    <path
      d="M50.0182 120.223C65.6495 120.223 78.3212 116.898 78.3212 112.796C78.3212 108.693 65.6495 105.368 50.0182 105.368C34.3869 105.368 21.7153 108.693 21.7153 112.796C21.7153 116.898 34.3869 120.223 50.0182 120.223Z"
      fill="#FDEB8B"
    />
    <path
      d="M70.1824 106.966C64.7893 105.552 57.6281 104.773 50.0182 104.773C42.4084 104.773 35.2472 105.552 29.854 106.966C25.8693 108.022 21.118 109.873 21.118 112.784V121.434C21.118 124.36 25.8723 126.219 29.854 127.264C35.2472 128.68 42.4084 129.46 50.0182 129.46C57.6281 129.46 64.7893 128.68 70.1824 127.264C74.1671 126.219 78.9155 124.36 78.9155 121.434V112.784C78.9155 109.873 74.1671 108.022 70.1824 106.966ZM77.7269 121.446C77.7269 123.086 74.7941 124.836 69.8823 126.124C64.5842 127.514 57.53 128.282 50.0182 128.282C42.5064 128.282 35.4582 127.514 30.1542 126.124C25.2572 124.836 22.3066 123.086 22.3066 121.446V115.197C23.9706 116.786 27.0936 117.899 29.854 118.625C35.2472 120.039 42.4084 120.818 50.0182 120.818C57.6281 120.818 64.7893 120.039 70.1824 118.625C72.9429 117.899 76.0659 116.786 77.7269 115.197V121.446ZM69.8823 117.474C64.5842 118.863 57.53 119.628 50.0182 119.628C42.5064 119.628 35.4582 118.863 30.1542 117.474C25.2572 116.182 22.3066 114.435 22.3066 112.784C22.3066 111.132 25.2424 109.397 30.1542 108.106C35.4522 106.716 42.5064 105.951 50.0182 105.951C57.53 105.951 64.5783 106.716 69.8823 108.106C74.7941 109.397 77.7269 111.144 77.7269 112.784C77.7269 114.423 74.7941 116.182 69.8823 117.474Z"
      fill="#12123B"
    />
    <path
      d="M77.7269 115.197V121.446C77.7269 123.086 74.7941 124.836 69.8823 126.124C64.5843 127.514 57.5301 128.282 50.0183 128.282C42.5065 128.282 35.4582 127.514 30.1542 126.124C25.2573 124.836 22.3066 123.086 22.3066 121.446V115.197C23.9706 116.786 27.0936 117.899 29.8541 118.625C35.2472 120.039 42.4084 120.818 50.0183 120.818C57.6281 120.818 64.7893 120.039 70.1824 118.625C72.9429 117.899 76.0689 116.786 77.7269 115.197Z"
      fill="#FBDD3E"
    />
    <path
      d="M48.8297 111.614C64.461 111.614 77.1326 108.289 77.1326 104.186C77.1326 100.084 64.461 96.7588 48.8297 96.7588C33.1984 96.7588 20.5267 100.084 20.5267 104.186C20.5267 108.289 33.1984 111.614 48.8297 111.614Z"
      fill="#FDEB8B"
    />
    <path
      d="M68.9939 98.3568C63.6007 96.9432 56.4395 96.1636 48.8297 96.1636C41.2198 96.1636 34.0586 96.9432 28.6655 98.3568C24.6808 99.4042 19.9294 101.264 19.9294 104.186V112.837C19.9294 115.762 24.6837 117.622 28.6655 118.667C34.0586 120.083 41.2198 120.863 48.8297 120.863C56.4395 120.863 63.6007 120.083 68.9939 118.667C72.9786 117.622 77.7269 115.762 77.7269 112.837V104.186C77.7269 101.264 72.9786 99.3923 68.9939 98.3568ZM76.5383 112.837C76.5383 114.477 73.6055 116.227 68.6937 117.515C63.3957 118.905 56.3415 119.673 48.8297 119.673C41.3179 119.673 34.2696 118.905 28.9656 117.515C24.0687 116.227 21.118 114.477 21.118 112.837V106.588C22.782 108.177 25.905 109.29 28.6655 110.016C34.0586 111.43 41.2198 112.209 48.8297 112.209C56.4395 112.209 63.6007 111.43 68.9939 110.016C71.7543 109.29 74.8773 108.177 76.5383 106.588V112.837ZM68.6937 108.864C63.3957 110.254 56.3415 111.019 48.8297 111.019C41.3179 111.019 34.2696 110.254 28.9656 108.864C24.0687 107.573 21.118 105.826 21.118 104.186C21.118 102.547 24.0687 100.8 28.9656 99.5084C34.2637 98.1187 41.3179 97.3539 48.8297 97.3539C56.3415 97.3539 63.3897 98.1187 68.6937 99.5084C73.6055 100.8 76.5383 102.547 76.5383 104.186C76.5383 105.826 73.6055 107.573 68.6937 108.864Z"
      fill="#12123B"
    />
    <path
      d="M76.5383 106.588V112.837C76.5383 114.477 73.6055 116.227 68.6937 117.515C63.3957 118.905 56.3415 119.673 48.8297 119.673C41.3179 119.673 34.2696 118.905 28.9656 117.515C24.0687 116.227 21.118 114.477 21.118 112.837V106.588C22.782 108.177 25.905 109.29 28.6655 110.016C34.0586 111.43 41.2198 112.209 48.8297 112.209C56.4395 112.209 63.6007 111.43 68.9939 110.016C71.7543 109.29 74.8803 108.177 76.5383 106.588Z"
      fill="#FBDD3E"
    />
    <path
      d="M51.2068 102.907C66.8381 102.907 79.5098 99.5813 79.5098 95.4792C79.5098 91.377 66.8381 88.0515 51.2068 88.0515C35.5755 88.0515 22.9039 91.377 22.9039 95.4792C22.9039 99.5813 35.5755 102.907 51.2068 102.907Z"
      fill="#FDEB8B"
    />
    <path
      d="M71.371 89.6495C65.9779 88.236 58.8167 87.4563 51.2068 87.4563C43.597 87.4563 36.4358 88.236 31.0426 89.6495C27.0579 90.697 22.3066 92.5568 22.3066 95.4791V104.13C22.3066 107.055 27.0609 108.915 31.0426 109.959C36.4358 111.376 43.597 112.156 51.2068 112.156C58.8167 112.156 65.9779 111.376 71.371 109.959C75.3557 108.915 80.1041 107.055 80.1041 104.13V95.4791C80.1041 92.5479 75.3557 90.697 71.371 89.6495ZM78.9155 104.13C78.9155 105.769 75.9827 107.519 71.0709 108.808C65.7728 110.198 58.7186 110.965 51.2068 110.965C43.695 110.965 36.6468 110.198 31.3428 108.808C26.4458 107.519 23.4952 105.769 23.4952 104.13V97.8806C25.1592 99.4697 28.2822 100.583 31.0426 101.309C36.4358 102.722 43.597 103.502 51.2068 103.502C58.8167 103.502 65.9779 102.722 71.371 101.309C74.1315 100.583 77.2545 99.4697 78.9155 97.8806V104.13ZM71.0709 100.157C65.7728 101.547 58.7186 102.312 51.2068 102.312C43.695 102.312 36.6468 101.547 31.3428 100.157C26.4458 98.8656 23.4952 97.1188 23.4952 95.4791C23.4952 93.8394 26.431 92.0926 31.3428 90.8011C36.6408 89.4114 43.695 88.6466 51.2068 88.6466C58.7186 88.6466 65.7669 89.4114 71.0709 90.8011C75.9827 92.0926 78.9155 93.8394 78.9155 95.4791C78.9155 97.1188 75.9827 98.8656 71.0709 100.157Z"
      fill="#12123B"
    />
    <path
      d="M78.9155 97.8806V104.13C78.9155 105.77 75.9827 107.519 71.0709 108.808C65.7728 110.198 58.7186 110.965 51.2068 110.965C43.695 110.965 36.6468 110.198 31.3428 108.808C26.4458 107.519 23.4952 105.77 23.4952 104.13V97.8806C25.1592 99.4697 28.2822 100.583 31.0426 101.309C36.4358 102.722 43.597 103.502 51.2068 103.502C58.8167 103.502 65.9778 102.722 71.371 101.309C74.1315 100.583 77.2574 99.4697 78.9155 97.8806Z"
      fill="#FBDD3E"
    />
    <path
      d="M50.0182 94.4942C65.6495 94.4942 78.3212 91.1687 78.3212 87.0666C78.3212 82.9644 65.6495 79.6389 50.0182 79.6389C34.3869 79.6389 21.7153 82.9644 21.7153 87.0666C21.7153 91.1687 34.3869 94.4942 50.0182 94.4942Z"
      fill="#FDEB8B"
    />
    <path
      d="M70.1824 81.2398C64.7893 79.8263 57.6281 79.0466 50.0182 79.0466C42.4084 79.0466 35.2472 79.8263 29.854 81.2398C25.8693 82.2873 21.118 84.1472 21.118 87.0694V95.7201C21.118 98.6454 25.8723 100.505 29.854 101.55C35.2472 102.966 42.4084 103.746 50.0182 103.746C57.6281 103.746 64.7774 102.963 70.1824 101.547C74.1671 100.502 78.9155 98.6424 78.9155 95.7172V87.0665C78.9155 84.1442 74.1671 82.2843 70.1824 81.2398ZM77.7269 95.7201C77.7269 97.3598 74.7941 99.1096 69.8823 100.398C64.5842 101.788 57.53 102.556 50.0182 102.556C42.5064 102.556 35.4582 101.788 30.1542 100.398C25.2572 99.0947 22.3066 97.3568 22.3066 95.7172V89.4679C23.9706 91.057 27.0936 92.17 29.854 92.8961C35.2472 94.3096 42.4084 95.0893 50.0182 95.0893C57.6281 95.0893 64.7893 94.3096 70.1824 92.8961C72.9429 92.17 76.0659 91.057 77.7269 89.4679V95.7201ZM69.8823 91.7474C64.5842 93.1371 57.53 93.9019 50.0182 93.9019C42.5064 93.9019 35.4582 93.1371 30.1542 91.7474C25.2572 90.4648 22.3066 88.7061 22.3066 87.0665C22.3066 85.4268 25.2572 83.68 30.1542 82.3885C35.4522 80.9988 42.5064 80.234 50.0182 80.234C57.53 80.234 64.5783 80.9988 69.8823 82.3885C74.7941 83.68 77.7269 85.4268 77.7269 87.0665C77.7269 88.7061 74.7941 90.4648 69.8823 91.7444V91.7474Z"
      fill="#12123B"
    />
    <path
      d="M77.7269 89.468V95.7172C77.7269 97.3569 74.7941 99.1067 69.8823 100.395C64.5843 101.785 57.5301 102.553 50.0183 102.553C42.5065 102.553 35.4582 101.785 30.1542 100.395C25.2573 99.0948 22.3066 97.3569 22.3066 95.7172V89.468C23.9706 91.0571 27.0936 92.1701 29.8541 92.8962C35.2472 94.3097 42.4084 95.0893 50.0183 95.0893C57.6281 95.0893 64.7893 94.3097 70.1824 92.8962C72.9429 92.1701 76.0689 91.0601 77.7269 89.468Z"
      fill="#FBDD3E"
    />
    <path
      d="M50.0182 88.1378C65.6495 88.1378 78.3212 84.8123 78.3212 80.7101C78.3212 76.6079 65.6495 73.2825 50.0182 73.2825C34.3869 73.2825 21.7153 76.6079 21.7153 80.7101C21.7153 84.8123 34.3869 88.1378 50.0182 88.1378Z"
      fill="#FDEB8B"
    />
    <path
      d="M70.1824 74.8807C64.7893 73.4672 57.6281 72.6875 50.0182 72.6875C42.4084 72.6875 35.2472 73.4672 29.854 74.8807C25.8693 75.9282 21.118 77.7881 21.118 80.7103V89.361C21.118 92.2863 25.8723 94.1461 29.854 95.1907C35.2472 96.6072 42.4084 97.3868 50.0182 97.3868C57.6281 97.3868 64.7893 96.6072 70.1824 95.1907C74.1671 94.1461 78.9155 92.2863 78.9155 89.361V80.7103C78.9155 77.7881 74.1671 75.9282 70.1824 74.8807ZM77.7269 89.361C77.7269 91.0007 74.7941 92.7505 69.8823 94.039C64.5842 95.4287 57.53 96.1965 50.0182 96.1965C42.5064 96.1965 35.4582 95.4287 30.1542 94.039C25.2572 92.7505 22.3066 91.0007 22.3066 89.361V83.1118C23.9706 84.7009 27.0936 85.8138 29.854 86.5399C35.2472 87.9535 42.4084 88.7331 50.0182 88.7331C57.6281 88.7331 64.7893 87.9535 70.1824 86.5399C72.9429 85.8138 76.0659 84.7009 77.7269 83.1118V89.361ZM69.8823 85.3883C64.5842 86.778 57.53 87.5428 50.0182 87.5428C42.5064 87.5428 35.4582 86.778 30.1542 85.3883C25.2572 84.0968 22.3066 82.35 22.3066 80.7103C22.3066 79.0706 25.2572 77.3238 30.1542 76.0323C35.4522 74.6426 42.5064 73.8778 50.0182 73.8778C57.53 73.8778 64.5783 74.6426 69.8823 76.0323C74.7941 77.3238 77.7269 79.0706 77.7269 80.7103C77.7269 82.35 74.7941 84.0968 69.8823 85.3883Z"
      fill="#12123B"
    />
    <path
      d="M77.7269 83.1116V89.3608C77.7269 91.0005 74.7941 92.7503 69.8823 94.0388C64.5843 95.4285 57.5301 96.1963 50.0183 96.1963C42.5065 96.1963 35.4582 95.4285 30.1542 94.0388C25.2573 92.7503 22.3066 91.0005 22.3066 89.3608V83.1116C23.9706 84.7007 27.0936 85.8136 29.8541 86.5397C35.2472 87.9532 42.4084 88.7329 50.0183 88.7329C57.6281 88.7329 64.7893 87.9532 70.1824 86.5397C72.9429 85.8136 76.0689 84.7007 77.7269 83.1116Z"
      fill="#FBDD3E"
    />
    <path
      d="M53.2779 76.9786C68.9092 76.9786 81.5809 73.6531 81.5809 69.5509C81.5809 65.4488 68.9092 62.1233 53.2779 62.1233C37.6466 62.1233 24.9749 65.4488 24.9749 69.5509C24.9749 73.6531 37.6466 76.9786 53.2779 76.9786Z"
      fill="#FDEB8B"
    />
    <path
      d="M73.4451 63.7213C68.0519 62.3077 60.8907 61.5281 53.2809 61.5281C45.671 61.5281 38.5098 62.3077 33.1167 63.7213C29.132 64.7687 24.3806 66.6286 24.3806 69.5509V78.2016C24.3806 81.1268 29.1349 82.9867 33.1167 84.0312C38.5098 85.4477 45.671 86.2274 53.2809 86.2274C60.8907 86.2274 68.0519 85.4477 73.4451 84.0312C77.4298 82.9867 82.1781 81.1268 82.1781 78.2016V69.5509C82.1781 66.6286 77.4298 64.7687 73.4451 63.7213ZM80.9896 78.2016C80.9896 79.8413 78.0567 81.591 73.145 82.8796C67.8469 84.2693 60.7927 85.037 53.2809 85.037C45.7691 85.037 38.7208 84.2693 33.4168 82.8796C28.505 81.591 25.5692 79.8413 25.5692 78.2016V71.9524C27.2332 73.5415 30.3562 74.6544 33.1167 75.3805C38.5098 76.794 45.671 77.5737 53.2809 77.5737C60.8907 77.5737 68.0519 76.794 73.4451 75.3805C76.2055 74.6544 79.3285 73.5415 80.9896 71.9524V78.2016ZM73.145 74.2289C67.8469 75.6186 60.7927 76.3834 53.2809 76.3834C45.7691 76.3834 38.7208 75.6186 33.4168 74.2289C28.505 72.9374 25.5692 71.1906 25.5692 69.5509C25.5692 67.9112 28.505 66.1644 33.4168 64.8729C38.7149 63.4832 45.7691 62.7184 53.2809 62.7184C60.7927 62.7184 67.8409 63.4832 73.145 64.8729C78.0567 66.1644 80.9896 67.9112 80.9896 69.5509C80.9896 71.1906 78.0567 72.9374 73.145 74.2289Z"
      fill="#12123B"
    />
    <path
      d="M80.9896 71.9524V78.2016C80.9896 79.8413 78.0568 81.5911 73.145 82.8796C67.8469 84.2693 60.7927 85.0371 53.2809 85.0371C45.7691 85.0371 38.7208 84.2693 33.4168 82.8796C28.505 81.5911 25.5692 79.8413 25.5692 78.2016V71.9524C27.2333 73.5415 30.3562 74.6544 33.1167 75.3805C38.5099 76.7941 45.671 77.5737 53.2809 77.5737C60.8908 77.5737 68.0519 76.7941 73.4451 75.3805C76.2056 74.6544 79.3374 73.5415 80.9896 71.9524Z"
      fill="#FBDD3E"
    />
    <path
      d="M123.315 107.742L126.037 108.463L142.694 51.6125L140.686 51.2852L136.844 50.1663L123.315 107.742Z"
      fill="#825C2B"
    />
    <path
      d="M142.81 50.2081L140.493 48.8274L137.756 48.6399L136.671 49.4553L137.905 50.5266L140.915 51.586L142.694 51.6127L142.81 50.2081Z"
      fill="#D2AA86"
    />
    <path
      d="M141.396 52.2465C140.749 52.2381 140.106 52.1481 139.482 51.9786C137.673 51.4995 136 50.452 136.333 49.1873C136.428 48.8302 136.772 48.2113 138.032 48.0773C138.845 48.0161 139.663 48.0977 140.448 48.3184C142.255 48.7975 143.931 49.845 143.598 51.1097C143.381 51.9221 142.487 52.2465 141.396 52.2465ZM137.908 49.6039C139.097 50.4753 140.571 50.8644 142.035 50.6931C140.847 49.8232 139.375 49.4342 137.913 49.6039H137.908Z"
      fill="#12123B"
    />
    <path
      d="M126.114 108.915C125.998 108.916 125.884 108.889 125.781 108.838C125.677 108.786 125.587 108.711 125.518 108.619C125.449 108.526 125.402 108.418 125.382 108.305C125.361 108.191 125.368 108.074 125.401 107.963L142.166 50.7139C142.221 50.5245 142.349 50.3647 142.522 50.2699C142.694 50.175 142.898 50.1527 143.087 50.208C143.276 50.2632 143.435 50.3915 143.53 50.5645C143.625 50.7375 143.647 50.9411 143.592 51.1305L126.827 108.376C126.782 108.531 126.688 108.667 126.56 108.764C126.431 108.861 126.275 108.914 126.114 108.915Z"
      fill="#12123B"
    />
    <path
      d="M123.395 108.192C123.337 108.192 123.279 108.186 123.223 108.174C123.032 108.128 122.867 108.008 122.763 107.841C122.66 107.674 122.626 107.473 122.67 107.281L136.339 49.2111C136.384 49.0189 136.503 48.8524 136.67 48.7484C136.838 48.6443 137.04 48.6111 137.232 48.6561C137.423 48.7011 137.59 48.8205 137.694 48.9882C137.797 49.1559 137.831 49.3581 137.786 49.5503L124.117 107.623C124.078 107.785 123.985 107.93 123.854 108.033C123.723 108.136 123.562 108.192 123.395 108.192Z"
      fill="#12123B"
    />
    <path
      d="M159.068 60.4199L118.73 49.5918L113.117 70.5625L153.455 81.3906L159.068 60.4199Z"
      fill="white"
    />
    <path
      d="M153.303 81.1775C153.238 81.1786 153.174 81.1706 153.112 81.1537L112.915 70.527C112.82 70.5024 112.732 70.4593 112.654 70.4C112.577 70.3407 112.512 70.2666 112.463 70.1818C112.364 70.012 112.336 69.8097 112.386 69.6194L117.901 48.7173C117.926 48.6226 117.969 48.5338 118.028 48.456C118.087 48.3781 118.161 48.3127 118.246 48.2635C118.33 48.2143 118.424 48.1822 118.52 48.1692C118.617 48.1562 118.716 48.1624 118.81 48.1876L159.014 58.8261C159.108 58.8507 159.197 58.8937 159.274 58.9527C159.352 59.0117 159.417 59.0854 159.467 59.1698C159.516 59.2541 159.548 59.3474 159.561 59.4442C159.574 59.541 159.568 59.6394 159.543 59.7337L154.028 80.6359C154.004 80.7307 153.961 80.8197 153.902 80.8974C153.842 80.9752 153.768 81.0401 153.683 81.0882C153.567 81.1528 153.435 81.1838 153.303 81.1775ZM114.005 69.2742L152.774 79.5319L157.908 60.067L119.155 49.8034L114.005 69.2742Z"
      fill="#12123B"
    />
    <path
      d="M141.94 66.7537C142.017 66.7805 142.099 66.791 142.181 66.7844C142.263 66.7779 142.342 66.7545 142.415 66.7157C142.487 66.6768 142.55 66.6235 142.601 66.5589C142.652 66.4943 142.689 66.4199 142.709 66.3404C142.73 66.2609 142.734 66.178 142.721 66.0969C142.708 66.0158 142.678 65.9383 142.634 65.8692C142.589 65.8001 142.531 65.741 142.463 65.6955C142.395 65.6501 142.318 65.6192 142.237 65.605L136.811 64.1677C137.86 62.287 139.155 60.4628 140.87 60.0015C142.011 59.6861 143.289 59.9658 144.777 60.8615C144.912 60.9316 145.067 60.9478 145.213 60.9068C145.359 60.8659 145.483 60.7708 145.561 60.641C145.639 60.5111 145.665 60.3564 145.633 60.2083C145.601 60.0601 145.514 59.9298 145.39 59.8438C143.607 58.7695 142.023 58.4452 140.552 58.8558C138.344 59.4689 136.82 61.6799 135.625 63.8701L130.057 62.3822C129.98 62.3592 129.9 62.352 129.82 62.3609C129.741 62.3698 129.664 62.3946 129.595 62.4339C129.525 62.4733 129.464 62.5263 129.415 62.5898C129.367 62.6533 129.331 62.7259 129.311 62.8034C129.291 62.881 129.287 62.9617 129.299 63.0409C129.311 63.1201 129.338 63.196 129.38 63.2642C129.422 63.3324 129.477 63.3914 129.543 63.4378C129.608 63.4841 129.682 63.5168 129.76 63.5338L135.052 64.9354C133.887 67.0691 132.737 68.804 131.103 69.3099C129.983 69.6521 128.657 69.3992 127.05 68.5392C126.981 68.4959 126.904 68.4673 126.823 68.4551C126.742 68.443 126.66 68.4476 126.582 68.4686C126.503 68.4896 126.429 68.5266 126.365 68.5773C126.302 68.628 126.249 68.6913 126.21 68.7633C126.172 68.8353 126.149 68.9144 126.142 68.9957C126.136 69.077 126.146 69.1588 126.172 69.2361C126.199 69.3133 126.24 69.3842 126.295 69.4446C126.35 69.5049 126.417 69.5532 126.491 69.5866C127.149 69.952 127.847 70.2387 128.571 70.4407C129.508 70.7132 130.502 70.7132 131.439 70.4407C133.56 69.795 134.886 67.7268 136.226 65.239L141.94 66.7537Z"
      fill="#474AEE"
    />
    <path
      d="M147.802 62.016C147.711 61.8874 147.572 61.8004 147.417 61.7741C147.261 61.7479 147.102 61.7846 146.973 61.8762C146.845 61.9677 146.758 62.1066 146.732 62.2623C146.706 62.418 146.742 62.5778 146.834 62.7064C147.892 64.1943 148.477 67.5749 146.376 70.1222C145.22 71.5267 142.017 73.5741 138.172 71.2887C138.105 71.2377 138.028 71.2016 137.946 71.1827C137.864 71.1638 137.779 71.1625 137.696 71.179C137.614 71.1955 137.536 71.2293 137.467 71.2782C137.399 71.3272 137.342 71.3901 137.299 71.4629C137.257 71.5357 137.23 71.6166 137.222 71.7004C137.213 71.7842 137.222 71.8689 137.248 71.9489C137.274 72.0289 137.317 72.1025 137.374 72.1646C137.431 72.2267 137.5 72.2761 137.578 72.3094C138.275 72.7315 139.028 73.054 139.815 73.2676C143.336 74.199 146.156 72.2707 147.303 70.8869C149.817 67.8189 149.11 63.8551 147.802 62.016Z"
      fill="#474AEE"
    />
    <path
      d="M126.307 58.3558C128.845 56.243 132.226 56.8173 133.712 57.8678C133.775 57.9174 133.848 57.9537 133.926 57.9743C134.003 57.995 134.085 57.9997 134.164 57.988C134.244 57.9764 134.32 57.9487 134.389 57.9066C134.457 57.8645 134.517 57.8089 134.563 57.7432C134.61 57.6775 134.642 57.603 134.659 57.5243C134.676 57.4455 134.677 57.3641 134.661 57.2851C134.646 57.206 134.615 57.1309 134.57 57.0643C134.524 56.9978 134.466 56.941 134.398 56.8977C132.556 55.5942 128.595 54.9039 125.549 57.4422C123.882 58.8319 121.446 62.6559 124.159 67.188C124.238 67.3203 124.366 67.4163 124.515 67.4559C124.631 67.4886 124.754 67.4854 124.868 67.4465C124.982 67.4076 125.082 67.335 125.154 67.2382C125.226 67.1413 125.266 67.0249 125.271 66.9044C125.275 66.7838 125.243 66.6648 125.178 66.5631C122.878 62.7362 124.91 59.5164 126.307 58.3558Z"
      fill="#474AEE"
    />
    <path
      d="M131.269 108.936H117.966C117.769 108.936 117.58 108.858 117.441 108.718C117.302 108.578 117.223 108.389 117.223 108.192C117.223 107.995 117.302 107.805 117.441 107.666C117.58 107.526 117.769 107.448 117.966 107.448H131.272C131.469 107.448 131.658 107.526 131.798 107.666C131.937 107.805 132.015 107.995 132.015 108.192C132.015 108.389 131.937 108.578 131.798 108.718C131.658 108.858 131.469 108.936 131.272 108.936H131.269Z"
      fill="#12123B"
    />
  </svg>
);
export default SvgCoinsFiri;
