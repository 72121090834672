import { Icon } from '@firi/ui-web';
import { isSomeLocale, soMeUrls } from 'constants/urls';
import { sendGTMEvent } from 'helpers/sendGTMEvent';

import { Typography } from './Typography';

export const SoMeLinks = (props: { locale?: string }) => {
  const countryLocale = isSomeLocale(props.locale) ? props.locale : 'no';
  const urls = soMeUrls[countryLocale];
  return (
    <>
      {Object.keys(urls).map((k) => {
        const key = k as keyof typeof urls;
        return (
          <a
            className="flex text-white items-center mb-2"
            href={urls[key]}
            key={key}
            onClick={() =>
              sendGTMEvent({
                event_action: `Footer click`,
                event_label: key,
                event_category: 'Interactions',
              })
            }
          >
            <div className="mr-2">
              {key === 'Facebook' && <Icon color="white" icon="facebook-round" size="lg" />}
              {key === 'Instagram' && <Icon color="white" icon="instagram-round" size="lg" />}
              {key === 'Twitter' && <Icon color="white" icon="twitter-round" size="lg" />}
              {key === 'Linkedin' && <Icon color="white" icon="linkedin-round" size="lg" />}
            </div>
            <Typography color="white" size="sm">
              {key}
            </Typography>
          </a>
        );
      })}
    </>
  );
};
