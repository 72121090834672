import * as React from 'react';
import { SVGProps } from 'react';
const SvgDeposit2 = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M11.1179 13L12.6745 13L12.6745 4L11.1179 4L11.1179 13Z" />
    <path d="M7.77671 8.71948L11.8963 12.6892L16.0158 8.71948L17.1163 9.77998L12.9968 13.7497C12.3897 14.3347 11.402 14.3347 10.795 13.7497L6.67541 9.77998L7.77671 8.71948Z" />
    <path d="M1.00001 4.5L2.55661 4.5L2.5566 18L21.2358 18L21.2358 4.5L22.7925 4.5L22.7924 18C22.7924 18.8273 22.0943 19.5 21.2358 19.5L2.5566 19.5C1.69814 19.5 1 18.8272 1 18L1.00001 4.5Z" />
  </svg>
);
export default SvgDeposit2;
