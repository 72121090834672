import * as React from 'react';
import { SVGProps } from 'react';
const SvgShareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M18 22.5H3C2.17275 22.5 1.5 21.8273 1.5 21V6C1.5 5.17275 2.17275 4.5 3 4.5H12V6H3V21H18V12H19.5V21C19.5 21.8273 18.8273 22.5 18 22.5Z" />
    <path d="M20.4697 2.46977L6.96973 15.9697L8.03038 17.0304L21.5303 3.53042L20.4697 2.46977Z" />
    <path d="M22.5 9.75H21V3H14.25V1.5H21C21.8273 1.5 22.5 2.17275 22.5 3V9.75Z" />
  </svg>
);
export default SvgShareIcon;
