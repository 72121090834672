import * as React from 'react';
import { SVGProps } from 'react';
const SvgMarketFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#474AEE" {...props}>
    <path d="M6 12H3C2.17275 12 1.5 12.6728 1.5 13.5V21H7.5V13.5C7.5 12.6728 6.82725 12 6 12Z" />
    <path d="M13.5 7.5H10.5C9.67275 7.5 9 8.17275 9 9V21H15V9C15 8.17275 14.3273 7.5 13.5 7.5Z" />
    <path d="M21 3H18C17.1728 3 16.5 3.67275 16.5 4.5V21H22.5V4.5C22.5 3.67275 21.8273 3 21 3Z" />
  </svg>
);
export default SvgMarketFilled;
