import { iconMap, IconNames } from '@firi/icons/web';
import {
  CryptoCurrency,
  FiatCurrency,
  Symbol,
} from '@firi/shared/gql/gqlTypes';
import React from 'react';
import { join, twMerge } from 'tailwind-merge';

import { FontColors } from '../typography/WebTypography';
export type IconSize =
  | '2xs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | 'full';

export type IconName = IconNames | CryptoCurrency | FiatCurrency | Symbol;

export interface IconProps {
  className?: string;
  size?: IconSize;
  icon: IconName;
  color?: FontColors | 'none';
}

export function Icon({ className, size = 'md', icon, color = '1' }: IconProps) {
  const darkIcon = icon?.toLowerCase() + '-dark';

  const IconComponent =
    (typeof icon === 'string' &&
      iconMap[icon.toLocaleLowerCase() as IconNames]) ||
    icon;

  const DarkIconComponent = iconMap[darkIcon];

  const sizes = {
    '2xs': 'w-[12px] h-[12px]',
    xs: 'w-[16px] h-[16px]',
    sm: 'w-[18px] h-[18px]',
    md: 'w-[20px] h-[20px]',
    lg: 'w-[24px] h-[24px]',
    xl: 'w-[32px] h-[32px]',
    '2xl': 'w-[40px] h-[40px]',
    '3xl': 'w-[64px] h-[64px]',
    full: 'w-full h-full',
  };

  if (!IconComponent) return null;

  const styles = join(
    'fill-current',
    [sizes[size || 'full']],
    color === '1' &&
      'fill-text-light-1 text-text-light-1 dark:fill-white dark:text-text-white',

    color === '2' &&
      'fill-text-light-2 text-text-light-2 dark:fill-text-dark-2 dark:text-text-dark-2',

    color === '3' &&
      'fill-text-light-3 text-text-light-3 dark:fill-text-dark-3 dark:text-text-dark-3',

    color === '4' &&
      'fill-text-light-4 text-text-light-4 dark:fill-text-dark-4 dark:text-text-dark-4',

    color === 'blue' &&
      'fill-text-light-blue text-text-light-blue dark:fill-text-dark-blue dark:text-text-dark-blue',

    color === 'white' && 'fill-white text-white',
    color === 'negative' &&
      'fill-text-light-red text-text-light-red dark:fill-text-dark-red dark:text-text-dark-red',
    color === 'positive' &&
      'fill-text-light-green text-text-light-green dark:fill-text-dark-green dark:text-text-dark-green',
    color === 'pinkle' && 'fill-pinkle text-pinkle',
    color === 'none' && undefined,
    className
  );

  return (
    <>
      {DarkIconComponent ? (
        <DarkIconComponent
          aria-hidden={true}
          className={twMerge('hidden dark:inline-block', styles)}
        />
      ) : null}
      <IconComponent
        aria-hidden={true}
        className={twMerge(
          DarkIconComponent ? 'inline-block dark:hidden' : 'inline-block',
          styles
        )}
      />
    </>
  );
}
