import * as React from 'react';
import { SVGProps } from 'react';
const SvgSavingsVault = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M5.25 15.75C5.25 16.5773 5.92275 17.25 6.75 17.25H18C18.8273 17.25 19.5 16.5773 19.5 15.75V6.75C19.5 5.92275 18.8273 5.25 18 5.25H6.75C5.92275 5.25 5.25 5.92275 5.25 6.75V9H4.5V10.5H5.25V12H4.5V13.5H5.25V15.75ZM6.75 10.5H8.25V9H6.75V6.75H18L18.0008 15.75C18.0008 15.75 18.0008 15.75 18 15.75H6.75V13.5H8.25V12H6.75V10.5Z" />
    <path d="M21 2.25H3C2.17275 2.25 1.5 2.92275 1.5 3.75V18.75C1.5 19.5773 2.17275 20.25 3 20.25H4.5V21.75C4.5 22.164 4.836 22.5 5.25 22.5H9.75C10.164 22.5 10.5 22.164 10.5 21.75V20.25H13.5V21.75C13.5 22.164 13.836 22.5 14.25 22.5H18.75C19.164 22.5 19.5 22.164 19.5 21.75V20.25H21C21.8273 20.25 22.5 19.5773 22.5 18.75V3.75C22.5 2.92275 21.8273 2.25 21 2.25ZM21 18.75H18.75C18.336 18.75 18 19.086 18 19.5V21H15V19.5C15 19.086 14.664 18.75 14.25 18.75H9.75C9.336 18.75 9 19.086 9 19.5V21H6V19.5C6 19.086 5.664 18.75 5.25 18.75H3V3.75H21V18.75Z" />
    <path d="M14.25 13.5C15.4905 13.5 16.5 12.4905 16.5 11.25C16.5 10.0095 15.4905 9 14.25 9C13.0095 9 12 10.0095 12 11.25C12 12.4905 13.0095 13.5 14.25 13.5ZM14.25 10.5C14.6633 10.5 15 10.8368 15 11.25C15 11.6633 14.6633 12 14.25 12C13.8368 12 13.5 11.6633 13.5 11.25C13.5 10.8368 13.8368 10.5 14.25 10.5Z" />
  </svg>
);
export default SvgSavingsVault;
