import { gql, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

const VERIFY_CAPTCHA_TOKEN = gql`
  query Recaptcha($token: String!) {
    verifyCaptchaToken(token: $token) {
      uuid
    }
  }
`;

export const useRecaptcha = () => {
  const [uuid, setUUID] = useState<string | null>(null);

  const [verifyCaptchaToken, { loading, data }] = useLazyQuery(VERIFY_CAPTCHA_TOKEN, {
    ssr: false,
  });

  useEffect(() => {
    if (!loading && data?.verifyCaptchaToken) {
      setUUID(data.verifyCaptchaToken.uuid);
    }
  }, [data]);

  const onVerify = (token: string) => {
    if (token) {
      verifyCaptchaToken({ variables: { token } });
    }
  };
  const removeUUID = () => setUUID(null);

  return { uuid, loading, removeUUID, onVerify };
};
