import { FiatMarket } from '../hooks/useFiatMarket';
import { FractionDigits, FractionDigitsValues, parseFractionDigits } from './IntlFormatter';

export { filterPortfolioData } from './filterPortfolioData';
export { formatPortfolioDate } from './formatPortfolioDate';
// TODO require locale once we have introduces multiple langs OR make a wrapper providing the locale
interface FormatFiatProps {
  value: number | string | null | undefined;
  locale?: string;
  trailingZeros?: boolean;
  maximumFractionDigits?: FractionDigitsValues;
  minimumFractionDigits?: FractionDigitsValues;
}

export const formatFiat = (options: FormatFiatProps) => {
  if (options.value === undefined || options.value === null) return '-';
  const { trailingZeros = false } = options;

  const parsed = typeof options.value === 'string' ? parseFloat(options.value) : options.value;

  let formatter: Intl.NumberFormat | undefined;

  if (typeof parsed === 'number' && parsed % 1 === 0 && !trailingZeros) {
    formatter = parseFractionDigits(FractionDigits.zero, FractionDigits.zero);
  } else {
    formatter = parseFractionDigits(
      options.minimumFractionDigits ?? FractionDigits.two,
      options.maximumFractionDigits ?? FractionDigits.two,
    );
  }

  if (parsed) {
    return formatter.format(parsed);
  }
  return '0';
};

export const formatCrypto = (options: {
  value: number | string | null | undefined;
  locale?: string;
  maximumFractionDigits?: FractionDigitsValues;
  minimumFractionDigits?: FractionDigitsValues;
}) => {
  if (options.value === undefined || options.value === null) return '-';
  const value = typeof options.value === 'string' ? parseFloat(options.value) : options.value ?? 0;

  const formatter = parseFractionDigits(
    options.minimumFractionDigits ?? FractionDigits.two,
    options.maximumFractionDigits ?? FractionDigits.eight,
  );

  return formatter.format(value);
};

interface FormatFiatWithSymbolProps extends FormatFiatProps {
  symbol: FiatMarket['symbol'] | FiatMarket['shortName'];
  position: FiatMarket['symbolPosition'] | FiatMarket['shortNamePosition'];
}

export const formatFiatWithSymbol = (options: FormatFiatWithSymbolProps) => {
  const { position, symbol } = options;
  const format = formatFiat(options);

  if (position === 'left') {
    return `${symbol} ${format}`;
  }

  if (position === 'right') {
    return `${format} ${symbol}`;
  }

  return format;
};

export const getDecimalSeperator = (locale?: string) => {
  return Number('1.1')
    .toLocaleString(locale || 'nb-NO')
    .substring(1, 2);
};

export const formatStringWithDecimal = (value: string, locale?: string) => {
  const seperator = getDecimalSeperator(locale);
  const opposite = seperator === '.' ? ',' : '.';
  return value.replace(opposite, seperator);
};
