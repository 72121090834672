import * as React from 'react';
import { SVGProps } from 'react';
const SvgHelp = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M12 22.5C6.21 22.5 1.5 17.79 1.5 12C1.5 6.21 6.21 1.5 12 1.5C17.79 1.5 22.5 6.21 22.5 12C22.5 17.79 17.79 22.5 12 22.5ZM12 3C7.03725 3 3 7.03725 3 12C3 16.9628 7.03725 21 12 21C16.9628 21 21 16.9628 21 12C21 7.03725 16.9628 3 12 3Z" />
    <path d="M12 18C12.4142 18 12.75 17.6642 12.75 17.25C12.75 16.8358 12.4142 16.5 12 16.5C11.5858 16.5 11.25 16.8358 11.25 17.25C11.25 17.6642 11.5858 18 12 18Z" />
    <path d="M12.75 15H11.4C11.4 13.6763 14.25 12.9262 14.25 10.5C14.25 9.2595 13.2405 8.25 12 8.25C10.7595 8.25 9.75 9.2595 9.75 10.5H8.25C8.25 8.43225 9.93225 6.75 12 6.75C14.0677 6.75 15.75 8.43225 15.75 10.5C15.75 13.0238 13.005 14.5875 12.75 15Z" />
  </svg>
);
export default SvgHelp;
