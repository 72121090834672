import * as React from 'react';
import { SVGProps } from 'react';
const SvgDarkMode = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M12 2C12.4142 2 12.75 2.33579 12.75 2.75V4.75C12.75 5.16421 12.4142 5.5 12 5.5C11.5858 5.5 11.25 5.16421 11.25 4.75V2.75C11.25 2.33579 11.5858 2 12 2Z" />
    <path d="M12 18.5C12.4142 18.5 12.75 18.8358 12.75 19.25V21.25C12.75 21.6642 12.4142 22 12 22C11.5858 22 11.25 21.6642 11.25 21.25V19.25C11.25 18.8358 11.5858 18.5 12 18.5Z" />
    <path d="M22 12C22 12.4142 21.6642 12.75 21.25 12.75H19.25C18.8358 12.75 18.5 12.4142 18.5 12C18.5 11.5858 18.8358 11.25 19.25 11.25H21.25C21.6642 11.25 22 11.5858 22 12Z" />
    <path d="M5.5 12C5.5 12.4142 5.16421 12.75 4.75 12.75H2.75C2.33579 12.75 2 12.4142 2 12C2 11.5858 2.33579 11.25 2.75 11.25H4.75C5.16421 11.25 5.5 11.5858 5.5 12Z" />
    <path d="M19.0711 4.92889C19.364 5.22178 19.364 5.69666 19.0711 5.98955L17.6569 7.40376C17.364 7.69666 16.8891 7.69666 16.5962 7.40376C16.3033 7.11087 16.3033 6.636 16.5962 6.3431L18.0104 4.92889C18.3033 4.636 18.7782 4.636 19.0711 4.92889Z" />
    <path d="M7.40387 16.5962C7.69676 16.8891 7.69676 17.364 7.40387 17.6569L5.98965 19.0711C5.69676 19.364 5.22188 19.364 4.92899 19.0711C4.6361 18.7782 4.6361 18.3034 4.92899 18.0105L6.3432 16.5962C6.6361 16.3034 7.11097 16.3034 7.40387 16.5962Z" />
    <path d="M19.071 19.0711C18.7781 19.364 18.3032 19.364 18.0103 19.0711L16.5961 17.6569C16.3032 17.364 16.3032 16.8891 16.5961 16.5962C16.889 16.3033 17.3639 16.3033 17.6568 16.5962L19.071 18.0104C19.3639 18.3033 19.3639 18.7782 19.071 19.0711Z" />
    <path d="M7.40375 7.40387C7.11086 7.69676 6.63598 7.69676 6.34309 7.40386L4.92888 5.98965C4.63599 5.69676 4.63599 5.22188 4.92888 4.92899C5.22177 4.6361 5.69665 4.6361 5.98954 4.92899L7.40375 6.3432C7.69665 6.6361 7.69665 7.11097 7.40375 7.40387Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12ZM12 16.7143C14.6036 16.7143 16.7143 14.6036 16.7143 12C16.7143 9.39637 14.6036 7.28571 12 7.28571C12 7.28571 13.5 9.00001 13.5 12C13.5 15 12 16.7143 12 16.7143Z"
    />
  </svg>
);
export default SvgDarkMode;
