import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError as LinkError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLError } from 'graphql/error';
import uuid from 'react-native-uuid';

import { Environment } from '../auth/types';
import { ClientCountry, FIRI_CLIENT_VERSION } from '../constants';
import { fingerprint } from '../utils/fingerprint';
import { cacheOptions } from './cache';

export type ApolloClientParams = {
  gqlEndpoint: string;
  locale: string;
  deviceId?: string | null;
  getToken: () => string | null | Promise<string | null>;
  getUserId: () => string | null | Promise<string | null>;
  onError?: (graphQLErrors: readonly GraphQLError[]) => void;
  onUnauthorized: () => void;
  environment: Environment;
  clientCountry?: ClientCountry;
  cache?: InMemoryCache;
  commitHash?: string;
  authenticatedOnly?: boolean;
  onLogRequestId?: (reqId: string, operationName: string) => void;
} & ({ client: 'app'; OS: string } | { client: 'web' });

export type CustomGraphQlErrorResponse = Pick<GraphQLError, 'locations' | 'path' | 'message'> & {
  code: string;
};

export const createApolloClient = (params: ApolloClientParams) => {
  const isProd = params.environment === 'prod';
  // const android =10.0.2.2
  const httpLink = new HttpLink({
    uri: params.gqlEndpoint,
  });

  const namedLink = new ApolloLink((operation, forward) => {
    operation.setContext(() => ({
      uri: `${params.gqlEndpoint}?${operation.operationName}`,
    }));
    return forward ? forward(operation) : null;
  });

  const retryLink = new RetryLink({
    delay: { initial: 1000, jitter: true, max: 15000 },
    attempts: {
      max: 2,
      retryIf: (error, operation) => {
        // disable retries for every openbanking request..takes too long
        return !!error && !operation.operationName.startsWith('OpenBanking');
      },
    },
  });

  // If the session is invalid (3010), clear the session token
  const errorLink = LinkError(({ graphQLErrors, networkError }) => {
    console.log('graphQLErrors', graphQLErrors, 'networkError', networkError);
    if (networkError) {
      if ('result' in networkError && networkError.result?.errors) {
        console.log(networkError.statusCode);
        console.log('error array', JSON.stringify(networkError.result.errors, null, 2));
      }
    }
    if (graphQLErrors) {
      console.log(JSON.stringify(graphQLErrors, null, 2));
      params.onError && params.onError(graphQLErrors);
      graphQLErrors.forEach((clientError) => {
        if (clientError) {
          const err = clientError as any as CustomGraphQlErrorResponse;
          if (err.code === 'UNAUTHENTICATED') {
            params.onUnauthorized();
          }
        }
      });
    }
  });

  const localeLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      'MIRAIEX-LANGUAGE': params.locale,
    },
  }));

  const fingerprintLink = setContext(async (_, { headers }) => {
    const oldFingerprint = fingerprint.getCached();

    if (typeof oldFingerprint === 'string' && oldFingerprint) {
      return {
        headers: {
          ...headers,
          'MIRAIEX-FINGERPRINT': oldFingerprint,
        },
      };
    }
    fingerprint.setCached((await fingerprint.generate()).fingerprint);
    return {
      headers: {
        ...headers,
        'MIRAIEX-FINGERPRINT': fingerprint,
      },
    };
  });

  const authLink = setContext((operation, { headers }) => {
    // TODO: Cache lookup of token?
    // Get the session token from local storage if it exists
    // return the headers to the context so httpLink can read them

    return (async () => {
      const token = await params.getToken();
      const userId = await params.getUserId();
      const reqId = uuid.v4() as string;
      if (params.onLogRequestId && operation.operationName) {
        params.onLogRequestId(reqId, operation.operationName);
      }

      return {
        headers: {
          ...headers,
          'MIRAIEX-CLIENT': params.client,
          'MIRAIEX-FRONTEND-ENV': params.environment,
          'MIRAIEX-BETA': params.environment === 'beta' ?? null,
          'FIRI-USER-ID': userId || '',
          'FIRI-CLIENT-VERSION': FIRI_CLIENT_VERSION,
          'FIRI-DEVICE-UUID': params.deviceId,
          'FIRI-COMMIT-HASH': params.commitHash,
          'x-request-id': reqId,
          Authorization: token ? `Bearer ${token}` : '',
          ...(params.client === 'app' ? { 'FIRI-APP-OS': params.OS } : {}),
          ...(params.clientCountry ? { 'FIRI-CLIENT-COUNTRY': params.clientCountry } : {}),
        },
      };
    })();
  });
  const onlyAuthLink = new ApolloLink((operation, forward) => {
    if (params.authenticatedOnly && !params.getToken()) {
      return null;
    }
    return forward(operation);
  });

  const client = new ApolloClient({
    connectToDevTools: true,
    link: errorLink
      .concat(retryLink)
      .concat(authLink)
      .concat(localeLink)
      .concat(fingerprintLink)
      .concat(namedLink)
      .concat(onlyAuthLink)
      .concat(httpLink),
    cache: params.cache || new InMemoryCache(cacheOptions),
  });

  return client;
};
