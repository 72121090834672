export const isObject = <Type extends Object>(obj: Type): boolean => {
  if (!obj) return false;
  return obj.constructor === Object && !Array.isArray(obj);
};

export const isEmptyObject = <Type extends Object>(obj: Type): boolean => {
  return isObject(obj) && Object.keys(obj).length === 0;
};

export const isNotEmptyObject = <Type extends Object>(obj: Type): boolean => {
  return isObject(obj) && !isEmptyObject(obj);
};

export const isEqual = <Type extends Object>(obj1?: Type, obj2?: Type): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};
export const objectToQueryString = (obj: {
  [key: string]: string | number | null | undefined;
}): string => {
  const queryString = Object.keys(obj)
    .filter((key) => obj[key] !== undefined && obj[key] !== null)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key] as string | number)}`)
    .join('&');
  return queryString;
};
