import { gql, useMutation } from '@apollo/client';

import { AdTractionConversionMutation, AdTractionConversionMutationVariables } from './../gqlTypes';

const mutation = gql`
  mutation AdTractionConversion($input: AdTractionInput!) {
    adTractionConversion(input: $input) {
      success
      message
    }
  }
`;

export const useAdtractionConversion = () =>
  useMutation<AdTractionConversionMutation, AdTractionConversionMutationVariables>(mutation);
