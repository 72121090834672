import { useReducer } from 'react';

export enum FocusField {
  CRYPTO = 'crypto',
  FIAT = 'fiat',
}

export type BasicTradeType = {
  fiatAmount: string;
  cryptoAmount: string;
  exchangeRate: string;
  fee: string;
  focusField: FocusField;
  error: string | null;
  submitted: boolean;
};

const initialState: BasicTradeType = {
  fiatAmount: '',
  cryptoAmount: '',
  exchangeRate: '',
  fee: '',
  focusField: FocusField.FIAT,
  error: null,
  submitted: false,
};

export type ActionType =
  | {
      type: 'setPrice';
      fee: string;
      crypto: string;
      fiat: string;
      exchangeRate: string;
      error?: string;
    }
  | { type: 'reset' }
  | { type: 'submit' }
  | { type: 'setError'; msg: string | null }
  | { type: 'changeFocusField' }
  | { type: 'setExchangeRate'; amount: string };

const reducer = (state: BasicTradeType, action: ActionType): BasicTradeType => {
  switch (action.type) {
    case 'changeFocusField':
      return {
        ...state,
        focusField: state.focusField === FocusField.CRYPTO ? FocusField.FIAT : FocusField.CRYPTO,
      };

    case 'setPrice':
      return {
        ...state,
        cryptoAmount: action.crypto.replace('.', ','),
        fiatAmount: action.fiat.replace('.', ','),
        exchangeRate: action.exchangeRate,
        fee: action.fee,
      };

    case 'setError':
      return {
        ...state,
        error: action.msg,
      };

    case 'setExchangeRate':
      return {
        ...state,
        exchangeRate: action.amount,
        fiatAmount: '',
        cryptoAmount: '',
      };

    case 'reset':
      return {
        ...initialState,
        exchangeRate: state.exchangeRate,
      };

    case 'submit':
      return { ...state, submitted: true };
    default:
      console.log(action);
      throw new Error('Invalid basic trade action:' + action);
  }
};

export const useBasicTrade = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return { state, dispatch };
};
