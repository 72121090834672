import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CurveType } from 'recharts/types/shape/Curve';

import { WebTypography } from '../../01-atoms/typography/WebTypography';

export interface ChartProps {
  dataPoints: { price: string | number; date: string }[];
  showTooltip?: boolean;
  showXAxis?: boolean;
  showYAxis?: boolean;
  showVerticalLines?: boolean;
  showHorizontalLines?: boolean;
  lineType: CurveType;
  stroke?: string;
}

export const Chart = ({
  dataPoints,
  lineType = 'linear',
  showTooltip = true,
  showXAxis = true,
  showYAxis = true,
  showVerticalLines = true,
  showHorizontalLines = true,
  stroke = '#0017E4',
}: ChartProps) => {
  const renderCustomXAxisTick = ({ x, y, payload }: any) => {
    if (
      ![
        0,
        Math.floor((dataPoints.length - 1) / 2),
        dataPoints.length - 1,
      ].includes(payload.index)
    )
      return <></>;
    return (
      <WebTypography as="text" size="md" x={x} y={y + 20} textAnchor="middle">
        {payload.value}
      </WebTypography>
    );
  };

  const renderCustomYAxisTick = ({
    y,
    payload,
    index,
    visibleTicksCount,
  }: any) => {
    if (index === 0 || index + 1 === visibleTicksCount) return <></>;
    return (
      <WebTypography as="text" size="xs" x={0 + 8} y={y - 4}>
        {payload.value}
      </WebTypography>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart width={300} height={100} data={dataPoints}>
        <Line
          type={lineType}
          dataKey="price"
          stroke={stroke}
          strokeWidth={2}
          dot={false}
        />
        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          padding={{ left: showYAxis ? 40 : 0 }}
          interval="preserveStartEnd"
          tick={renderCustomXAxisTick}
          hide={!showXAxis}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          interval="preserveStartEnd"
          tick={renderCustomYAxisTick}
          width={1}
          hide={!showYAxis}
          className="text-firi-blue"
          domain={['auto', 'dataMax']}
        />
        <CartesianGrid
          vertical={showVerticalLines}
          horizontal={showHorizontalLines}
          stroke="#D4D4DD"
          strokeWidth={0.5}
        />
        {showTooltip && <Tooltip />}
      </LineChart>
    </ResponsiveContainer>
  );
};
