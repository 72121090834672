import { firiMerge } from '@firi/shared/styles';
import React from 'react';

export type TextAreaProps = React.ComponentPropsWithoutRef<'textarea'>;

export const TextArea = ({ className, ...props }: TextAreaProps) => {
  const baseStyles =
    'pl-4 pr-2 py-3 w-full rounded-2xl text-web-md border border-black-150 focus:outline-none focus:border-blue-700 invalid:border-red bg-white dark:bg-card-dark-4 dark:text-white';

  return <textarea {...props} className={firiMerge(baseStyles, className)} />;
};
