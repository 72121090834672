import { gql, useMutation } from '@apollo/client';

import { InternalTransferMutation, InternalTransferMutationVariables } from '../gqlTypes';

const MUTATION = gql`
  mutation InternalTransfer($input: InternAccountTransfer!) {
    internAccountTransfer(input: $input) {
      success
      message
    }
  }
`;

export const useInternalTransfer = () => {
  return useMutation<InternalTransferMutation, InternalTransferMutationVariables>(MUTATION, {
    refetchQueries: 'active',
  });
};
