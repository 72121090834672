import { firiMerge } from '@firi/shared/styles';
import { Icon, IconName } from '@firi/ui-web';
import { findLangTextByLocale } from 'helpers/language';
import { setLocaleCookies } from 'helpers/setLocaleCookies';
import { useRouter } from 'next/router';
import React from 'react';

import { getLocalizedUrl } from '../helpers/url';
import { alternateSlug } from '../helpers/url';

interface InputLangDropdownProps {
  options: string[];
  selected: string;
  label: string;
  handleChange: (event: { target: { value: string } }) => void;
  variant?: 'white' | 'blue';
}

const langIcon: { [key: string]: IconName } = {
  Norsk: 'nok',
  English: 'globe',
  Dansk: 'dkk',
};

const InputLangDropdown = ({
  variant = 'blue',
  options,
  selected,
  label,
  handleChange,
}: InputLangDropdownProps) => {
  const icon = langIcon[findLangTextByLocale(selected) as keyof typeof langIcon];

  return (
    <form className="block relative w-max h-max">
      <label htmlFor={label} className="invisible" />
      <select
        name={label}
        id={label}
        onChange={handleChange}
        value={selected}
        className={firiMerge('pl-11 pr-8 py-2 rounded-full border appearance-none', {
          'bg-firi-blue text-white border-white': variant === 'blue',
          'bg-white text-firi-blue border-firi-blue': variant === 'white',
        })}
      >
        {options.map((key) => (
          <option key={key} value={key}>
            {findLangTextByLocale(key)}
          </option>
        ))}
      </select>

      <div className="absolute top-0 mt-[1px] left-0 px-3 py-2 w-full flex items-center justify-between pointer-events-none">
        {icon && <Icon icon={icon} size="lg" color={variant === 'blue' ? 'white' : 'blue'} />}
        <Icon icon="chevron-down" color={variant === 'blue' ? 'white' : 'blue'} />
      </div>
    </form>
  );
};

export const LanguageSelect = ({
  alternateSlugs,
  variant,
}: {
  alternateSlugs: alternateSlug;
  variant?: 'white' | 'blue';
}) => {
  const router = useRouter();
  const { locales = [], pathname, query, route, locale = '' } = router;

  const handleChange = (event: { target: { value: string } }) => {
    const targetLocale = event.target.value;
    const localizedUrl = getLocalizedUrl(route, targetLocale, alternateSlugs || query);

    if (!localizedUrl.includes('undefined')) {
      setLocaleCookies(targetLocale);
      router.push(localizedUrl, localizedUrl, { locale: targetLocale });
      return;
    }

    const newLocalizedUrl = localizedUrl.replaceAll('/undefined', '');
    const newPath = pathname.split('/');
    const newPathname = newPath.slice(0, newPath.length - 1).join('/');

    if (newLocalizedUrl) {
      router.push({ pathname: newPathname }, newLocalizedUrl, {
        locale: targetLocale,
      });
    }
  };

  return (
    <InputLangDropdown
      options={locales}
      selected={locale}
      label="languageToggle"
      handleChange={handleChange}
      variant={variant}
    />
  );
};
