import * as React from 'react';
import { SVGProps } from 'react';
const SvgProgress = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18 4.94389C18 6.06087 17.1155 6.96637 16.0245 6.96637C14.9334 6.96637 14.0489 6.06087 14.0489 4.94389C14.0489 3.8269 14.9334 2.9214 16.0245 2.9214C17.1155 2.9214 18 3.8269 18 4.94389Z"
      fill="currentColor"
    />
    <path
      d="M12.951 1.91012C12.951 2.96505 12.1157 3.82024 11.0852 3.82024C10.0548 3.82024 9.21945 2.96505 9.21945 1.91012C9.21945 0.855191 10.0548 0 11.0852 0C12.1157 0 12.951 0.855191 12.951 1.91012Z"
      fill="currentColor"
    />
    <path
      d="M7.02432 2.92133C7.02432 3.91421 6.23811 4.7191 5.26827 4.7191C4.29843 4.7191 3.51222 3.91421 3.51222 2.92133C3.51222 1.92846 4.29843 1.12357 5.26827 1.12357C6.23811 1.12357 7.02432 1.92846 7.02432 2.92133Z"
      fill="currentColor"
    />
    <path
      d="M3.29258 7.52821C3.29258 8.45903 2.55551 9.21361 1.64629 9.21361C0.73707 9.21361 0 8.45903 0 7.52821C0 6.59739 0.73707 5.84281 1.64629 5.84281C2.55551 5.84281 3.29258 6.59739 3.29258 7.52821Z"
      fill="currentColor"
    />
    <path
      d="M3.07308 13.483C3.07308 14.3518 2.38515 15.0561 1.53654 15.0561C0.687932 15.0561 0 14.3518 0 13.483C0 12.6143 0.687932 11.91 1.53654 11.91C2.38515 11.91 3.07308 12.6143 3.07308 13.483Z"
      fill="currentColor"
    />
    <path
      d="M6.58463 17.9775C6.58463 18.7222 5.99498 19.3259 5.2676 19.3259C4.54022 19.3259 3.95057 18.7222 3.95057 17.9775C3.95057 17.2329 4.54022 16.6292 5.2676 16.6292C5.99498 16.6292 6.58463 17.2329 6.58463 17.9775Z"
      fill="currentColor"
    />
    <path
      d="M12.0726 18.8764C12.0726 19.4969 11.5812 20 10.975 20C10.3689 20 9.87751 19.4969 9.87751 18.8764C9.87751 18.2558 10.3689 17.7528 10.975 17.7528C11.5812 17.7528 12.0726 18.2558 12.0726 18.8764Z"
      fill="currentColor"
    />
    <path
      d="M16.9024 15.9552C16.9024 16.4517 16.5093 16.8541 16.0244 16.8541C15.5395 16.8541 15.1464 16.4517 15.1464 15.9552C15.1464 15.4588 15.5395 15.0563 16.0244 15.0563C16.5093 15.0563 16.9024 15.4588 16.9024 15.9552Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgProgress;
