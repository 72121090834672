import * as React from 'react';
import { SVGProps } from 'react';
const SvgMale = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_2024_205)">
      <path
        d="M8.78627 21.2857C12.1394 21.2857 14.8577 18.5675 14.8577 15.2143C14.8577 11.8612 12.1394 9.14288 8.78627 9.14288C5.43312 9.14288 2.71484 11.8612 2.71484 15.2143C2.71484 18.5675 5.43312 21.2857 8.78627 21.2857Z"
        stroke="black"
        strokeWidth={1.42857}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8579 2.71436H21.2865V9.14293"
        stroke="black"
        strokeWidth={1.42857}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0713 10.9286L21.2856 2.71436"
        stroke="black"
        strokeWidth={1.42857}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2024_205">
        <rect width={20} height={20} fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMale;
