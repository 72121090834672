import * as React from 'react';
import { SVGProps } from 'react';
const SvgBnb = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_2076_140)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0C18.6279 0 24 5.37212 24 12C24 18.6279 18.6279 24 12 24C5.37212 24 0 18.6279 0 12C0 5.37212 5.37212 0 12 0Z"
        fill="#F0B90B"
      />
      <path
        d="M6.59522 12L6.60387 15.1731L9.30002 16.7596V18.6173L5.02599 16.1106V11.0721L6.59522 12ZM6.59522 8.82693V10.676L5.02502 9.74712V7.89808L6.59522 6.96924L8.1731 7.89808L6.59522 8.82693ZM10.426 7.89808L11.9962 6.96924L13.5741 7.89808L11.9962 8.82693L10.426 7.89808Z"
        fill="white"
      />
      <path
        d="M7.72986 14.5154V12.6577L9.30005 13.5865V15.4356L7.72986 14.5154ZM10.426 17.425L11.9962 18.3539L13.5741 17.425V19.274L11.9962 20.2029L10.426 19.274V17.425ZM15.826 7.89808L17.3962 6.96924L18.9741 7.89808V9.74712L17.3962 10.676V8.82693L15.826 7.89808ZM17.3962 15.1731L17.4049 12L18.975 11.0712V16.1096L14.701 18.6164V16.7587L17.3962 15.1731Z"
        fill="white"
      />
      <path d="M16.2703 14.5154L14.7001 15.4356V13.5866L16.2703 12.6577V14.5154Z" fill="white" />
      <path
        d="M16.2702 9.48462L16.2789 11.3423L13.5751 12.9288V16.1096L12.0049 17.0298L10.4347 16.1096V12.9288L7.73082 11.3423V9.48462L9.30774 8.55577L11.9952 10.15L14.6991 8.55577L16.277 9.48462H16.2702ZM7.72986 6.3125L11.9962 3.79712L16.2702 6.3125L14.7001 7.24135L11.9962 5.64712L9.30005 7.24135L7.72986 6.3125Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2076_140">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgBnb;
