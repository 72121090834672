import * as React from 'react';
import { SVGProps } from 'react';
const SvgLink = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={12} cy={12} r={12} fill="#3363D2" />
    <path
      d="M12.0023 3.84656L10.5203 4.69869L6.48197 7.03279L5 7.88492V15.9616L6.48197 16.8138L10.5574 19.1479L12.0393 20L13.5213 19.1479L17.5226 16.8138L19.0046 15.9616V7.88492L17.5226 7.03279L13.4843 4.69869L12.0023 3.84656ZM7.96393 14.2574V9.58918L12.0023 7.25508L16.0407 9.58918V14.2574L12.0023 16.5915L7.96393 14.2574Z"
      fill="white"
    />
  </svg>
);
export default SvgLink;
