import { gql, useMutation } from '@apollo/client';

import {
  CreateLightningInvoiceMutation,
  CreateLightningInvoiceMutationVariables,
} from './../gqlTypes';

const mutation = gql`
  mutation CreateLightningInvoice($input: CreateLightningInvoiceInput) {
    createLightningInvoice: createLightningInvoice(input: $input) {
      address
      destinationTag
    }
  }
`;

export const useCreateLightningInvoice = () => {
  return useMutation<CreateLightningInvoiceMutation, CreateLightningInvoiceMutationVariables>(
    mutation,
  );
};
