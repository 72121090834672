import { useRef } from 'react';
export const useScrollBlock = () => {
  const blocked = useRef(false);

  const blockScroll = () => {
    if (typeof document === 'undefined') return;

    const html = document.documentElement;
    const { body } = document;
    const app = document.getElementById('App');

    if (!app) return;

    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const appPaddingRight =
      parseInt(window.getComputedStyle(app).getPropertyValue('padding-right')) || 0;
    const bodyPaddingRight =
      parseInt(window.getComputedStyle(body).getPropertyValue('padding-right')) || 0;
    /**
     * 1. Fixes a bug in iOS and desktop Safari whereby setting
     *    `overflow: hidden` on the html/body does not prevent scrolling.
     * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
     *    scroll if an `overflow-x` style is also applied to the body.
     */
    html.style.position = 'relative'; /* [1] */
    // html.style.overflow = 'hidden'; /* [2] */
    app.style.position = 'relative'; /* [1] */
    app.style.overflow = 'hidden'; /* [2] */
    app.style.paddingRight = `${appPaddingRight + scrollBarWidth}px`;

    body.style.position = 'relative'; /* [1] */
    body.style.overflow = 'hidden'; /* [2] */
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

    blocked.current = true;
  };

  const allowScroll = () => {
    if (typeof document === 'undefined' || blocked.current === false) return;

    const html = document.documentElement;
    const { body } = document;
    const app = document.getElementById('App');

    if (!app) return;
    html.style.position = '';
    // html.style.overflow = '';
    app.style.position = '';
    app.style.overflow = '';
    app.style.paddingRight = '';

    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';

    blocked.current = false;
  };

  return { blockScroll, allowScroll };
};
