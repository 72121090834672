import { gql, useQuery } from '@apollo/client';

import { CustomerIoUserAttributesQuery } from '../gqlTypes';

const query = gql`
  query CustomerIoUserAttributes {
    customerIoAttributes {
      customer {
        attributes {
          pushNewsletter
          pushProductsAndCryptos
          pushOtherMarketing
          pushChristmasCalendar
          pushCryptoNews
          pushCryptoMarket
        }
      }
    }
  }
`;

export const useGetCIOUserAttributes = () =>
  useQuery<CustomerIoUserAttributesQuery>(query, { fetchPolicy: 'network-only' });
