import type {
  PopoverArrowProps,
  PopoverCloseProps,
  PopoverContentProps,
  PopoverProps,
  PopoverTriggerProps,
} from '@radix-ui/react-popover';
import {
  Arrow as PopoverArrow,
  Close,
  Content,
  Root,
  Trigger,
} from '@radix-ui/react-popover';
import clsx from 'clsx';
import React from 'react';

import { Arrow as ArrowComponent } from '../arrow/Arrow';

function Arrow(props: PopoverArrowProps) {
  const { asChild, className, ...restProps } = props; // Do not pass asChild to PopoverArrow

  return (
    <PopoverArrow
      className={clsx('relative -inset-px', className)}
      {...restProps}
      asChild
    >
      <ArrowComponent openArrow />
    </PopoverArrow>
  );
}

export type {
  PopoverProps,
  PopoverTriggerProps,
  PopoverContentProps,
  PopoverCloseProps,
  PopoverArrowProps,
};

export const Popover = { Root, Trigger, Content, Arrow, Close };
