import * as React from 'react';
import { SVGProps } from 'react';
const SvgVippsLabel = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 60 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.71819 0L6.31835 7.41629L8.86753 0H11.8756L7.38901 11H5.14573L0.65918 0H3.71819Z"
      fill="white"
    />
    <path
      d="M18.2798 8.95455C20.0649 8.95455 21.078 8.13636 22.0429 6.95455C22.5736 6.31818 23.249 6.18182 23.7314 6.54545C24.2139 6.90909 24.2621 7.59091 23.7314 8.22727C22.3323 9.95455 20.5473 11 18.2798 11C15.8193 11 13.6483 9.72727 12.1528 7.5C11.7186 6.90909 11.8151 6.27273 12.2975 5.95455C12.78 5.63636 13.5036 5.77273 13.9378 6.40909C14.9992 7.90909 16.4465 8.95455 18.2798 8.95455ZM21.6087 3.36364C21.6087 4.18182 20.9333 4.72727 20.1613 4.72727C19.3894 4.72727 18.714 4.18182 18.714 3.36364C18.714 2.54545 19.3894 2 20.1613 2C20.9333 2 21.6087 2.59091 21.6087 3.36364Z"
      fill="white"
    />
    <path
      d="M28.955 0.296053V1.77632C29.7197 0.740132 30.8923 0 32.6258 0C34.8181 0 37.3673 1.77632 37.3673 5.57566C37.3673 9.57237 34.9201 11.4967 32.3709 11.4967C31.0453 11.4967 29.8217 11.0033 28.904 9.76974V15H26.1509V0.296053H28.955ZM28.955 5.72368C28.955 7.94408 30.2805 9.12829 31.7591 9.12829C33.1866 9.12829 34.6142 8.04276 34.6142 5.72368C34.6142 3.45395 33.1866 2.36842 31.7591 2.36842C30.3315 2.36842 28.955 3.40461 28.955 5.72368Z"
      fill="white"
    />
    <path
      d="M41.1913 0.296053V1.77632C41.9561 0.740132 43.1287 0 44.8621 0C47.0544 0 49.6036 1.77632 49.6036 5.57566C49.6036 9.57237 47.1564 11.4967 44.6072 11.4967C43.2816 11.4967 42.058 11.0033 41.1403 9.76974V15H38.3872V0.296053H41.1913ZM41.1913 5.72368C41.1913 7.94408 42.5169 9.12829 43.9954 9.12829C45.4229 9.12829 46.8505 8.04276 46.8505 5.72368C46.8505 3.45395 45.4229 2.36842 43.9954 2.36842C42.5169 2.36842 41.1913 3.40461 41.1913 5.72368Z"
      fill="white"
    />
    <path
      d="M55.2367 0C57.4933 0 59.098 0.991417 59.8001 3.44635L57.3429 3.82403C57.2927 2.59657 56.4903 2.17167 55.2868 2.17167C54.3841 2.17167 53.6821 2.54936 53.6821 3.16309C53.6821 3.63519 54.0331 4.1073 55.0862 4.29614L56.9417 4.62661C58.747 4.95708 59.75 6.09013 59.75 7.60086C59.75 9.86695 57.5936 11 55.5375 11C53.3812 11 50.9741 9.96137 50.623 7.41202L53.0803 7.03433C53.2307 8.35622 54.0833 8.82833 55.4874 8.82833C56.5405 8.82833 57.2426 8.45064 57.2426 7.83691C57.2426 7.27039 56.8915 6.84549 55.7381 6.65665L54.0331 6.37339C52.2278 6.04292 51.1245 4.86266 51.1245 3.35193C51.1747 0.991416 53.4313 0 55.2367 0Z"
      fill="white"
    />
  </svg>
);
export default SvgVippsLabel;
