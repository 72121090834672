import * as React from 'react';
import { SVGProps } from 'react';
const SvgSand = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_924_7295)">
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="url(#paint0_linear_924_7295)"
      />
      <path
        d="M7.968 5.66421L6.672 6.96021V12.0962L7.968 13.3922H13.104V15.9842H10.56V14.6882H6.672V17.2802L7.968 18.5762H15.696L16.992 17.2802V12.0962L15.696 10.8002H10.56V8.20821H13.152V9.50421H17.04V6.91221L15.744 5.61621H7.968V5.66421Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_924_7295"
        x1={5.1456}
        y1={4.2648}
        x2={20.0976}
        y2={21.1368}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00ADEF" />
        <stop offset={1} stopColor="#0084FF" />
      </linearGradient>
      <clipPath id="clip0_924_7295">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgSand;
