import { gql, useQuery } from '@apollo/client';

import { ConsentQuery } from './../gqlTypes';

const query = gql`
  query Consent {
    gdprConsent {
      v3
      v4
      profile
    }
  }
`;

export const useConsent = () => useQuery<ConsentQuery>(query);
