import clsx, { ClassValue } from 'clsx';
import React from 'react';
import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
  PolymorphicPropsWithRef,
} from 'react-polymorphic-types';

const DefaultElement = 'span';

type FontColors =
  | '1'
  | '2'
  | '3'
  | '4'
  | 'blue'
  | 'white'
  | 'negative'
  | 'positive'
  | 'black';

export type LandingTypographyOwnProps = {
  size?: '3xl' | '2xl' | 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  children: React.ReactNode;
  className?: ClassValue;
  weight?: 'regular' | 'medium';
  color?: FontColors;
};

export type LandingTypographyProps<
  T extends React.ElementType = typeof DefaultElement
> = PolymorphicPropsWithRef<LandingTypographyOwnProps, T>;

export const LandingTypography: PolymorphicForwardRefExoticComponent<
  LandingTypographyOwnProps,
  typeof DefaultElement
> = React.forwardRef(function LandingTypography<
  T extends React.ElementType = typeof DefaultElement
>(
  {
    as,
    size = 'md',
    children,
    color = '1',
    className,
    weight = 'regular',
    ...restProps
  }: PolymorphicPropsWithoutRef<LandingTypographyOwnProps, T>,
  ref: React.ForwardedRef<Element>
) {
  const Component: React.ElementType = as || DefaultElement;

  return (
    <Component
      ref={ref}
      className={clsx(
        {
          'font-medium': weight === 'medium',
          'rs-landing-xs': size === 'xs',
          'rs-landing-sm': size === 'sm',
          'rs-landing-md': size === 'md',
          'rs-landing-lg': size === 'lg',
          'rs-landing-xl': size === 'xl',
          'rs-landing-2xl': size === '2xl',
          'rs-landing-3xl': size === '3xl',
        },
        {
          'text-text-light-1 dark:text-white': color === '1',
          'text-text-light-2 dark:text-text-dark-2': color === '2',
          'text-text-light-3 dark:text-text-dark-3': color === '3',
          'text-text-light-4 dark:text-text-dark-4': color === '4',
          'text-text-light-blue dark:text-text-dark-blue': color === 'blue',
          'text-white': color === 'white',
          'text-text-light-red dark:text-text-dark-red': color === 'negative',
          'text-text-light-green dark:text-text-dark-green':
            color === 'positive',
        },
        className
      )}
      {...restProps}
    >
      {children}
    </Component>
  );
});
