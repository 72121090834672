import { gql, useMutation } from '@apollo/client';

import { CancelWithdrawMutation, CancelWithdrawMutationVariables } from '../gqlTypes';

const MUTATION = gql`
  mutation CancelWithdraw($input: CancelWithdrawInput!) {
    cancelWithdraw(input: $input) {
      withdraw {
        id
      }
    }
  }
`;

export const useCancelWithdraw = () => {
  return useMutation<CancelWithdrawMutation, CancelWithdrawMutationVariables>(MUTATION, {
    refetchQueries: ['Transaction'],
  });
};
