import * as React from 'react';
import { SVGProps } from 'react';
const SvgMoreFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#474AEE" {...props}>
    <path d="M4.125 14.625C2.6775 14.625 1.5 13.4475 1.5 12C1.5 10.5525 2.6775 9.375 4.125 9.375C5.5725 9.375 6.75 10.5525 6.75 12C6.75 13.4475 5.5725 14.625 4.125 14.625Z" />
    <path d="M12 14.625C10.5525 14.625 9.375 13.4475 9.375 12C9.375 10.5525 10.5525 9.375 12 9.375C13.4475 9.375 14.625 10.5525 14.625 12C14.625 13.4475 13.4475 14.625 12 14.625Z" />
    <path d="M19.875 14.625C18.4275 14.625 17.25 13.4475 17.25 12C17.25 10.5525 18.4275 9.375 19.875 9.375C21.3225 9.375 22.5 10.5525 22.5 12C22.5 13.4475 21.3225 14.625 19.875 14.625Z" />
  </svg>
);
export default SvgMoreFilled;
