import axios from 'axios';
import Fingerprint2 from 'fingerprintjs2';

import { ClientCountry } from '../../constants';
import { fingerprint as fp } from '../../utils/fingerprint';
import { getAuthBackendUrl } from '../getAuthBackendUrl';
import { Client, Environment } from '../types';

const BASE_URL = '/v3/bankid/corporate';

type BankIdParams = {
  client: Client;
  environment: Environment;
  uuid?: string; // only created from mobile apps
};

export type BankIdResponse = {
  url: string;
  id: string;
};

export const initiateEID = async (
  params: BankIdParams & {
    clientCountry: ClientCountry;
    redirectUrl: string;
    flow: 'redirect' | 'app-to-app';
    deviceOs?: 'android' | 'ios';
    nin: string;
  },
) => {
  const authUrl = getAuthBackendUrl(params.environment);
  return axios.request<BankIdResponse>({
    method: 'get',
    url: authUrl + BASE_URL + '/initiate' + '?nin=' + params.nin,
    headers: {
      'miraiex-client': params.client,
      'miraiex-frontend-env': params.environment,
      'firi-bankid-redirect-url': params.redirectUrl,
      'firi-bankid-flow': params.flow,
      'firi-client-uuid': params.uuid,
      ...(params.deviceOs ? { 'firi-app-os': params.deviceOs } : {}),
      ...(params.clientCountry ? { 'firi-client-country': params.clientCountry } : {}),
    },
  });
};

export interface CorporateAccess {
  corporate_id: number;
  name: string;
  org_number: string;
}

export const getCorporateAccessList = async (params: BankIdParams & { loginUuid: string }) => {
  const authUrl = getAuthBackendUrl(params.environment);
  return axios.request<Array<CorporateAccess>>({
    method: 'get',
    url: authUrl + BASE_URL + '/list' + `?loginUuid=${params.loginUuid}`,
  });
};

type LoginResponse = { session: string; sessionExpiration: string };

export const loginEID = async (
  params: BankIdParams & {
    loginUuid: string;
    corporateId: number;
    components?: Fingerprint2.Component[];
    clientCountry: ClientCountry;
  },
) => {
  const { client, environment, ...restParams } = params;
  const fingerprint = await fp.generate();
  const components = params.components || fingerprint.components;

  const authUrl = getAuthBackendUrl(params.environment);

  return axios.request<LoginResponse>({
    method: 'post',
    url: authUrl + BASE_URL + '/login',
    withCredentials: true,
    headers: {
      'miraiex-client': client,
      'miraiex-frontend-env': environment,
      'miraiex-fingerprint': fingerprint.fingerprint,
      'firi-client-uuid': params.uuid,
      components: JSON.stringify(components),
    },
    data: restParams,
  });
};
