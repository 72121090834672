import { getCookieByName } from '@firi/shared/utils/getCookieByName';
import { setCookie } from 'cookies-next';
import { useEffect } from 'react';

export const useStoreTrackingCookies = () => {
  const thirtyDays = new Date();
  thirtyDays.setDate(thirtyDays.getDate() + 30);

  const cookieOptions = {
    domain: process.env.NEXT_PUBLIC_DOMAIN || 'localhost',
    path: '/',
    expires: thirtyDays,
    sameSite: 'lax' as any,
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const referrer = document.referrer;
    if (validateReferrer(referrer)) {
      setCookie('referrer', referrer, cookieOptions);
    }

    const utmAttributions = new URLSearchParams(window.location.search);

    if (validateUTMAttribution(utmAttributions.get('utm_source'))) {
      setCookie('utm_source', utmAttributions.get('utm_source'), cookieOptions);
    }

    if (validateUTMAttribution(utmAttributions.get('utm_medium'))) {
      setCookie('utm_medium', utmAttributions.get('utm_medium'), cookieOptions);
    }

    if (validateUTMAttribution(utmAttributions.get('utm_campaign'))) {
      setCookie('utm_campaign', utmAttributions.get('utm_campaign'), cookieOptions);
    }

    if (validateUTMAttribution(utmAttributions.get('utm_content'))) {
      setCookie('utm_content', utmAttributions.get('utm_content'), cookieOptions);
    }
  }, []);
};

export const checkForAnyCookieAttributions = () => {
  return (
    Boolean(getCookieByName('referrer')) ||
    Boolean(getCookieByName('utm_source')) ||
    Boolean(getCookieByName('utm_medium')) ||
    Boolean(getCookieByName('utm_campaign')) ||
    Boolean(getCookieByName('utm_content'))
  );
};

const validateReferrer = (referrer: string | null) => {
  if (referrer === null) return false;

  try {
    const url = new URL(referrer);
    if (url) {
      return url.hostname !== window.location.hostname;
    }
    return false;
  } catch (e) {
    // invalid referrer url
    return false;
  }
};

const validateUTMAttribution = (attribution: string | null) => {
  return (
    attribution &&
    typeof attribution === 'string' &&
    attribution.length > 0 &&
    attribution.length <= 25 &&
    /^[a-zA-Z0-9_-]*$/.test(attribution)
  );
};
