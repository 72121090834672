import * as React from 'react';
import { SVGProps } from 'react';
const SvgBtc = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6141 10.6059C15.7759 9.52268 14.9512 8.94037 13.8234 8.55191L14.1893 7.08456L13.2958 6.86196L12.9397 8.29064C12.7051 8.23213 12.464 8.17693 12.2243 8.12224L12.583 6.68414L11.6903 6.46155L11.3243 7.92839C11.1299 7.88412 10.9391 7.84037 10.7539 7.79432L10.7549 7.78974L9.52316 7.48218L9.28556 8.43616C9.28556 8.43616 9.94825 8.58803 9.93426 8.59744C10.296 8.68775 10.3616 8.92714 10.3505 9.11692L9.93375 10.7885C9.95868 10.7949 9.99099 10.8041 10.0266 10.8183L9.93248 10.7949L9.34814 13.1366C9.30387 13.2465 9.19168 13.4114 8.93882 13.3488C8.94772 13.3617 8.2896 13.1867 8.2896 13.1867L7.84619 14.2094L9.00877 14.4991C9.13994 14.532 9.26952 14.5658 9.39768 14.5993L9.39791 14.5993C9.48094 14.621 9.56338 14.6425 9.64527 14.6635L9.27563 16.1479L10.1678 16.3705L10.5341 14.9021C10.7776 14.9682 11.0142 15.0293 11.2457 15.0868L10.8809 16.5483L11.774 16.7709L12.1437 15.2895C13.6667 15.5778 14.8123 15.4615 15.2938 14.0842C15.6823 12.9751 15.2748 12.3353 14.4734 11.9178C15.057 11.7827 15.4966 11.3988 15.6139 10.6059H15.6141ZM13.5731 13.4676C13.3195 14.4856 11.7258 14.0644 10.9972 13.8718L10.9971 13.8718C10.9319 13.8546 10.8736 13.8392 10.8239 13.8268L11.3143 11.8606C11.3752 11.8758 11.4495 11.8924 11.5337 11.9113L11.5337 11.9113C12.2875 12.0805 13.8322 12.4271 13.5733 13.4676H13.5731ZM11.6853 10.999C12.2932 11.1612 13.6183 11.5147 13.8491 10.5899C14.0851 9.64383 12.7966 9.35868 12.1673 9.21942C12.0966 9.20376 12.0341 9.18995 11.9832 9.17725L11.5385 10.9606C11.5806 10.971 11.6299 10.9842 11.6853 10.999Z"
      fill="#F7931A"
    />
    <path
      d="M23.6383 14.9029C22.0356 21.3315 15.5244 25.2438 9.09503 23.6407C2.66833 22.038 -1.24401 15.5265 0.359382 9.09837C1.96136 2.66903 8.47253 -1.24361 14.8999 0.359081C21.3289 1.96177 25.241 8.47404 23.6381 14.903L23.6382 14.9029H23.6383Z"
      fill="#F7931A"
    />
    <path
      d="M17.3062 10.2699C17.545 8.67295 16.3292 7.81453 14.6667 7.24189L15.206 5.07869L13.8892 4.75058L13.3642 6.85682C13.018 6.77048 12.6625 6.68912 12.3092 6.60847L12.838 4.48833L11.522 4.16022L10.9824 6.32271C10.6959 6.25748 10.4146 6.19302 10.1416 6.1251L10.1431 6.11829L8.32723 5.66483L7.97694 7.07126C7.97694 7.07126 8.95389 7.2952 8.93331 7.30899C9.46654 7.44208 9.56297 7.79506 9.54695 8.07484L8.9326 10.5392C8.96932 10.5486 9.01695 10.562 9.0695 10.5832C9.02557 10.5723 8.97882 10.5603 8.93032 10.5487L8.0692 14.001C8.00404 14.163 7.83863 14.4061 7.46582 14.3138C7.47902 14.3329 6.50875 14.0749 6.50875 14.0749L5.85498 15.5823L7.56858 16.0094C7.88737 16.0894 8.19977 16.173 8.50741 16.2517L7.96251 18.4397L9.27779 18.7678L9.81741 16.603C10.1767 16.7006 10.5254 16.7906 10.8668 16.8754L10.329 19.0299L11.6459 19.3581L12.1907 17.1742C14.4361 17.5991 16.1245 17.4278 16.8352 15.3968C17.4078 13.7616 16.8067 12.8184 15.6254 12.2034C16.4858 12.005 17.1338 11.4391 17.3066 10.2701L17.3062 10.2698L17.3062 10.2699ZM14.2976 14.4887C13.8906 16.1239 11.1375 15.24 10.2449 15.0183L10.968 12.1196C11.8605 12.3424 14.7229 12.7834 14.2976 14.4887H14.2976ZM14.7048 10.2462C14.3336 11.7336 12.0421 10.9779 11.2988 10.7926L11.9544 8.16365C12.6977 8.34894 15.0915 8.69477 14.7049 10.2462H14.7048Z"
      fill="white"
    />
  </svg>
);
export default SvgBtc;
