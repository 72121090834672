import { Environment } from './types';

export const getAuthBackendUrl = (env: Environment) => {
  let base;
  switch (env) {
    case 'prod':
    case 'beta':
      base = 'https://auth.firi.com';
      break;
    case 'sandbox':
      base = 'https://auth.sandboxfiri.com';
      break;
    case 'test':
      base = 'https://auth.testfiri.com';
      break;
    case 'dev':
      base = 'https://auth.testfiri.com';
      // base = 'https://auth.firi.com';
      // base = 'http://localhost:3456';
      break;
    default:
      base = 'https://auth.firi.com';

    // throw new Error(`Invalid environment: ${env}`);
  }
  return base;
};
