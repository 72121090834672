import * as React from 'react';
import { SVGProps } from 'react';
const SvgCustomerAlarm = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11 14.5H7C5.067 14.5 3.5 16.067 3.5 18V20H2V18C2 15.2386 4.23858 13 7 13H11C13.7614 13 16 15.2386 16 18V20H14.5V18C14.5 16.067 12.933 14.5 11 14.5Z"
      fill="#12123B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 10.5C10.3807 10.5 11.5 9.38071 11.5 8C11.5 6.61929 10.3807 5.5 9 5.5C7.61929 5.5 6.5 6.61929 6.5 8C6.5 9.38071 7.61929 10.5 9 10.5ZM9 12C11.2091 12 13 10.2091 13 8C13 5.79086 11.2091 4 9 4C6.79086 4 5 5.79086 5 8C5 10.2091 6.79086 12 9 12Z"
      fill="#12123B"
    />
    <path
      d="M22 17.5C22 18.8807 20.8807 20 19.5 20C18.1193 20 17 18.8807 17 17.5C17 16.1193 18.1193 15 19.5 15C20.8807 15 22 16.1193 22 17.5Z"
      fill="#DA2D43"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.105 4C17.5134 4 17.0511 4.51082 17.11 5.09951L17.91 13.0995C17.9611 13.6107 18.3912 14 18.905 14H20.095C20.6088 14 21.0389 13.6107 21.09 13.0995L21.89 5.0995C21.9489 4.51082 21.4866 4 20.895 4H18.105Z"
      fill="#DA2D43"
    />
  </svg>
);
export default SvgCustomerAlarm;
