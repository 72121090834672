import * as React from 'react';
import { SVGProps } from 'react';
const SvgAge18 = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.05 10.3197C9.81667 10.6197 9.52 10.8731 9.16 11.0797C8.8 11.2864 8.41333 11.4131 8 11.4597V9.99973C8.5 9.89973 8.92667 9.70973 9.28 9.42973C9.64 9.14306 9.91333 8.78973 10.1 8.36973H11.67V15.9997H10.05V10.3197Z"
      fill="#DA2D43"
    />
    <path
      d="M15.8208 16.1597C15.2541 16.1597 14.7508 16.0597 14.3108 15.8597C13.8774 15.6531 13.5408 15.3664 13.3008 14.9997C13.0674 14.6331 12.9508 14.2164 12.9508 13.7497C12.9508 13.3231 13.0608 12.9397 13.2808 12.5997C13.5008 12.2531 13.8474 11.9897 14.3208 11.8097C13.9608 11.6697 13.6974 11.4631 13.5308 11.1897C13.3708 10.9164 13.2908 10.6197 13.2908 10.2997C13.2908 9.89973 13.3974 9.54306 13.6108 9.22973C13.8241 8.90973 14.1208 8.66306 14.5008 8.48973C14.8874 8.30973 15.3274 8.21973 15.8208 8.21973C16.3141 8.21973 16.7541 8.30973 17.1408 8.48973C17.5274 8.66306 17.8274 8.90973 18.0408 9.22973C18.2541 9.54306 18.3608 9.89973 18.3608 10.2997C18.3608 10.6197 18.2774 10.9164 18.1108 11.1897C17.9441 11.4631 17.6808 11.6697 17.3208 11.8097C17.8008 11.9897 18.1508 12.2531 18.3708 12.5997C18.5908 12.9397 18.7008 13.3231 18.7008 13.7497C18.7008 14.2164 18.5808 14.6331 18.3408 14.9997C18.1008 15.3664 17.7641 15.6531 17.3308 15.8597C16.8974 16.0597 16.3941 16.1597 15.8208 16.1597ZM15.8208 11.3397C16.1341 11.3397 16.3774 11.2531 16.5508 11.0797C16.7241 10.9064 16.8108 10.6831 16.8108 10.4097C16.8108 10.1431 16.7241 9.92639 16.5508 9.75973C16.3774 9.59306 16.1341 9.50973 15.8208 9.50973C15.5074 9.50973 15.2641 9.59306 15.0908 9.75973C14.9174 9.92639 14.8308 10.1431 14.8308 10.4097C14.8308 10.6831 14.9174 10.9064 15.0908 11.0797C15.2641 11.2531 15.5074 11.3397 15.8208 11.3397ZM15.8208 14.7897C16.2008 14.7897 16.5041 14.6797 16.7308 14.4597C16.9641 14.2331 17.0808 13.9497 17.0808 13.6097C17.0808 13.2564 16.9674 12.9697 16.7408 12.7497C16.5208 12.5231 16.2141 12.4097 15.8208 12.4097C15.4341 12.4097 15.1274 12.5231 14.9008 12.7497C14.6741 12.9764 14.5608 13.2631 14.5608 13.6097C14.5608 13.9497 14.6741 14.2331 14.9008 14.4597C15.1341 14.6797 15.4408 14.7897 15.8208 14.7897Z"
      fill="#DA2D43"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.28668 3.42921C11.1222 2.68456 13.1482 2.549 15.0665 3.04248C16.9848 3.53595 18.694 4.63239 19.9425 6.17038C21.1911 7.70837 21.9129 9.60665 22.0018 11.5858C22.0907 13.5649 21.542 15.5202 20.4364 17.164C19.3308 18.8078 17.7267 20.0531 15.8604 20.7166C13.9941 21.3802 11.9641 21.4269 10.0692 20.8499C8.17435 20.2729 6.51477 19.1026 5.33478 17.5114L4.13023 18.405C5.50156 20.2542 7.43027 21.6143 9.6324 22.2848C11.8345 22.9554 14.1937 22.9012 16.3627 22.13C18.5317 21.3589 20.3958 19.9116 21.6807 18.0013C22.9656 16.0909 23.6033 13.8185 23.5 11.5184C23.3967 9.21839 22.5578 7.01228 21.1068 5.22489C19.6558 3.4375 17.6694 2.16326 15.4401 1.58976C13.2107 1.01626 10.8561 1.1738 8.72298 2.0392C6.58984 2.90459 4.79085 4.43211 3.59084 6.39688L4.87065 7.17884C5.90322 5.48823 7.45119 4.17385 9.28668 3.42921Z"
      fill="#12123B"
    />
    <path
      d="M4.20633 9.53476C4.20633 9.94897 3.87054 10.2848 3.45633 10.2848C3.04212 10.2848 2.70633 9.94897 2.70633 9.53476C2.70633 9.12054 3.04212 8.78476 3.45633 8.78476C3.87054 8.78476 4.20633 9.12054 4.20633 9.53476Z"
      fill="#12123B"
    />
    <path
      d="M4.20633 14.4297C4.20633 14.8439 3.87054 15.1797 3.45633 15.1797C3.04212 15.1797 2.70633 14.8439 2.70633 14.4297C2.70633 14.0155 3.04212 13.6797 3.45633 13.6797C3.87054 13.6797 4.20633 14.0155 4.20633 14.4297Z"
      fill="#12123B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 12.6601H0.5V11.1601H6.5V12.6601Z"
      fill="#12123B"
    />
  </svg>
);
export default SvgAge18;
