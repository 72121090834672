import { gql, useMutation } from '@apollo/client';

import { ChangePasswordMutation, ChangePasswordMutationVariables } from '../gqlTypes';

const MUTATION = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      success
    }
  }
`;

export const useChangePassword = () => {
  return useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(MUTATION);
};
