import * as React from 'react';
import { SVGProps } from 'react';
const SvgVippsDisabled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.4417 16.8008C20.1331 16.1837 20.7768 15.4714 21.3761 14.6748C22.2231 13.5593 22.1929 12.4134 21.3761 11.78C20.6194 11.1763 19.5 11.3884 18.6533 12.5039C18.1206 13.1977 17.5776 13.8191 16.9785 14.3376L19.4417 16.8008ZM13.3503 15.9268C13.0729 15.9562 12.7833 15.9713 12.48 15.9713H12.4804C9.48568 15.9713 7.15528 14.162 5.33993 11.5085C4.61349 10.4228 3.49393 10.1817 2.67685 10.7546C1.92051 11.2975 1.73941 12.4434 2.43492 13.4387C4.94616 17.2684 8.42566 19.4995 12.48 19.4995C13.8362 19.4995 15.0838 19.2594 16.2317 18.8082L13.3503 15.9268ZM17.9272 6.3519C17.9272 7.73886 16.8379 8.7038 15.5669 8.7038C14.2961 8.7038 13.2071 7.73886 13.2071 6.3519C13.2071 4.96461 14.2961 4 15.5669 4C16.8379 4 17.9275 4.96461 17.9275 6.3519H17.9272Z"
      fill="#12123B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.50165 2.46967C2.79455 2.17678 3.26943 2.17678 3.56232 2.46967L21.5624 20.4697C21.8553 20.7626 21.8553 21.2374 21.5624 21.5303C21.2695 21.8232 20.7947 21.8232 20.5018 21.5303L2.50165 3.53033C2.20876 3.23744 2.20876 2.76256 2.50165 2.46967Z"
      fill="#DA2D43"
    />
  </svg>
);
export default SvgVippsDisabled;
