import { gql, useLazyQuery } from '@apollo/client';

import { PreviousTranslationQuery, PreviousTranslationQueryVariables } from '../gqlTypes';

const QUERY = gql`
  query PreviousTranslation($input: PreviousTranslationInput!) {
    previousTranslation(input: $input) {
      originalText
      proposedText
      author
      timestamp
    }
  }
`;

export const useLazyPreviousTranslation = () =>
  useLazyQuery<PreviousTranslationQuery, PreviousTranslationQueryVariables>(QUERY, {
    fetchPolicy: 'network-only',
  });
