import { gql, useMutation } from '@apollo/client';

import { TwoFaMutation, TwoFaMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation TwoFA($input: EnableOtpInput!) {
    toggle2fa: toggle2fa_v2(input: $input) {
      success
      message
    }
  }
`;

export const use2FA = () =>
  useMutation<TwoFaMutation, TwoFaMutationVariables>(mutation, { refetchQueries: ['User'] });
