import * as React from 'react';
import { SVGProps } from 'react';
const SvgTwitterRound = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#ffffff" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM11.7005 10.1281L11.7215 10.4741L11.3717 10.4318C10.0987 10.2694 8.98652 9.71855 8.04222 8.79347L7.58057 8.33446L7.46166 8.67342C7.20984 9.42902 7.37072 10.227 7.89533 10.7637C8.17513 11.0603 8.11217 11.1026 7.62953 10.9261C7.46166 10.8696 7.31477 10.8272 7.30078 10.8484C7.25181 10.8978 7.41969 11.5405 7.55259 11.7947C7.73445 12.1478 8.10518 12.4938 8.51088 12.6986L8.85362 12.861L8.44792 12.8681C8.05621 12.8681 8.04222 12.8751 8.08419 13.0234C8.22409 13.4824 8.77668 13.9697 9.39222 14.1815L9.8259 14.3298L9.44818 14.5558C8.88859 14.8806 8.23108 15.0642 7.57357 15.0784C7.25881 15.0854 7 15.1137 7 15.1349C7 15.2055 7.85336 15.6009 8.35 15.7563C9.83989 16.2153 11.6096 16.0176 12.9386 15.2337C13.8829 14.6759 14.8272 13.5672 15.2678 12.4938C15.5057 11.9218 15.7435 10.8767 15.7435 10.3753C15.7435 10.0504 15.7645 10.0081 16.1562 9.61968C16.387 9.39371 16.6039 9.14655 16.6458 9.07593C16.7158 8.94176 16.7088 8.94176 16.352 9.06181C15.7575 9.27366 15.6735 9.24541 15.9673 8.92764C16.1842 8.70166 16.443 8.29209 16.443 8.17204C16.443 8.15085 16.338 8.18616 16.2191 8.24972C16.0932 8.32033 15.8134 8.42626 15.6036 8.48981L15.2259 8.60986L14.8831 8.37683C14.6943 8.24972 14.4285 8.10848 14.2886 8.06611C13.9318 7.96725 13.3862 7.98137 13.0645 8.09436C12.1901 8.41214 11.6375 9.23129 11.7005 10.1281Z"
    />
  </svg>
);
export default SvgTwitterRound;
