import { gql, useMutation } from '@apollo/client';

import {
  SubmitSurveyFirestoreMutation,
  SubmitSurveyFirestoreMutationVariables,
} from './../gqlTypes';

const mutation = gql`
  mutation SubmitSurveyFirestore($input: SurveyInput!) {
    submitSurveyFirestore(input: $input) {
      success
      message
    }
  }
`;

export const useSubmitSurveyFirestore = () =>
  useMutation<SubmitSurveyFirestoreMutation, SubmitSurveyFirestoreMutationVariables>(mutation);
