import { gql } from '@apollo/client';

export const TransferWithdrawFragment = gql`
  fragment TransferWithdrawFragment on TransferWithdraw {
    id
    amount
    currency
    createdAt
    state
    destination
    txid
    feeAmount
    feeCurrency
    isFiat
    fiatValue
    accountGroupId
  }
`;
