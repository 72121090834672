import * as React from 'react';
import { SVGProps } from 'react';
const SvgWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M20.9985 21.75H3.00153C2.46678 21.75 1.98453 21.4755 1.71153 21.0158C1.43853 20.556 1.42878 20.001 1.68453 19.5315L10.683 3.0315C10.9463 2.54925 11.451 2.25 12 2.25C12.549 2.25 13.0538 2.54925 13.317 3.0315L22.3155 19.5315C22.5713 20.001 22.5615 20.556 22.2885 21.0158C22.0155 21.4755 21.5333 21.75 20.9985 21.75ZM11.3415 3.39075L12 3.75L3.00153 20.25H20.9993L12 3.75L11.3415 3.39075Z" />
    <path d="M12 15C11.586 15 11.25 14.664 11.25 14.25V9.75C11.25 9.336 11.586 9 12 9C12.414 9 12.75 9.336 12.75 9.75V14.25C12.75 14.664 12.414 15 12 15Z" />
    <path d="M12 18C12.4142 18 12.75 17.6642 12.75 17.25C12.75 16.8358 12.4142 16.5 12 16.5C11.5858 16.5 11.25 16.8358 11.25 17.25C11.25 17.6642 11.5858 18 12 18Z" />
  </svg>
);
export default SvgWarning;
