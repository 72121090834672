import * as React from 'react';
import { SVGProps } from 'react';
const SvgPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_2458_53631)">
      <path
        d="M5.29966 2.01093H10.7923C11.5059 2.01093 12.093 2.64003 12.093 3.39452V12.7872C12.093 13.5419 11.5059 14.1288 10.7923 14.1288H5.29966C4.58716 14.1288 4.00055 13.5416 4.00055 12.7871V3.39449C4.00029 2.64 4.58687 2.0109 5.29966 2.0109V2.01093ZM7.01905 12.3674V13.0799H9.07399V12.3674H7.01905ZM5.8031 2.72343C5.21652 2.72343 4.75533 3.18572 4.75533 3.68809V10.3967C4.75533 10.9413 5.21655 11.3611 5.80313 11.3611H10.2897C10.8769 11.3611 11.338 10.9413 11.338 10.3967V3.68809C11.338 3.18572 10.8766 2.72343 10.2897 2.72343H5.80313H5.8031Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2458_53631">
        <rect width={8.09333} height={12.14} fill="white" transform="translate(4 2)" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPhone;
