import clsx from 'clsx';
import React, { InputHTMLAttributes, useState } from 'react';

export interface SwitchProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'checked' | 'disabled'
  > {
  label: React.ReactNode;
  labelPosition?: 'left' | 'right';
  hideLabel?: boolean;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
}

export const Switch = (props: SwitchProps) => {
  const { hideLabel, labelPosition = 'left', className, ...rest } = props;

  const [nativeChecked, setNativeChecked] = useState(false);
  const checked = props.checked ?? nativeChecked;

  return (
    <label
      className={clsx(
        'hover:cursor-pointer inline-flex justify-between gap-x-2',
        className
      )}
    >
      {!hideLabel && labelPosition == 'left' ? props.label : null}
      <div>
        <div
          className={clsx(
            'w-12 h-6 rounded-full  flex items-center p-[3px] transition-colors relative',
            'focus-within:ring-2 focus-within:ring-blue-200 focus-within:ring-offset-1',
            checked
              ? props.disabled
                ? 'bg-blue-300 dark:bg-blue-400'
                : 'bg-blue dark:bg-blue-500'
              : props.disabled
              ? 'bg-black-300 dark:bg-black-400'
              : 'bg-black-500 dark:bg-background-dark-4'
          )}
        >
          <input
            {...rest}
            type="checkbox"
            className="hidden"
            checked={checked}
            disabled={props.disabled}
            onChange={(e) => {
              props.onChange?.(e.target.checked);
              setNativeChecked(e.target.checked);
            }}
          />
          <span
            className={clsx(
              `h-[18px] w-[18px]   rounded-full transition-all`,
              checked && 'translate-x-[24px]',
              props.disabled ? 'bg-white dark:bg-black-200' : 'bg-white'
            )}
          />
        </div>
      </div>
      {!hideLabel && labelPosition == 'right' ? props.label : null}
    </label>
  );
};
