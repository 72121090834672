const urlAliases = {
  about: {
    no: 'om-oss',
    dk: 'om-os',
  },
  press: {
    no: 'presse',
    dk: 'presse',
  },
  insight: {
    no: 'innsikt',
    dk: 'indsigt',
  },
  'press-release': {
    no: 'pressemeldinger',
    dk: 'pressemeddelelser',
  },
  articles: {
    no: 'artikler',
    dk: 'artikler',
  },
  author: {
    no: 'forfatter',
    dk: 'forfatter',
  },
  'crypto-investing-savings': {
    no: 'sparing-investering',
    dk: 'opsparing-og-investering',
  },
  'cryptocurrency-tax': {
    no: 'kryptoskatt',
    dk: 'kryptoskat',
  },
  staking: {
    no: 'staking',
    dk: 'staking',
  },
  'crypto-savings': {
    no: 'kryptosparing',
  },
  cryptocurrency: {
    no: 'kryptovaluta',
    dk: 'kryptovaluta',
  },
  career: {
    no: 'karriere',
    dk: 'karriere',
  },
  'customer-service': {
    no: 'kundestotte',
    dk: 'kundestotte',
  },
  signup: {
    no: 'opprett-bruker',
    dk: 'signup',
  },
  giftcard: {
    no: 'gavekort',
    dk: 'gavekort',
  },
  campaign: {
    no: 'kampanje',
    dk: 'kampagne',
  },
  investor: {
    no: 'investor',
    dk: 'investor',
  },
};

function generateRewrites(pages, locales) {
  const rewrites = pages.reduce((acc, cur) => {
    const paths = cur.split('/');

    for (const locale of locales) {
      const page = {
        source: [],
        destination: [],
        locale: false,
      };
      for (const path of paths) {
        if (path === 'pages') {
          page.source.push('/' + locale);
          page.destination.push('/' + locale);
          continue;
        }
        if (urlAliases[path]) {
          page.source.push(urlAliases[path][locale]);
          page.destination.push(path);
          continue;
        }
        if (path === 'index.tsx') break;
        if (path.startsWith('[')) {
          page.source.push(':path*');
          page.destination.push(':path*');
          break;
        }
        if (path.endsWith('.tsx')) {
          let p = path.replace(/\.[^/.]+$/, '');
          page.source.push(urlAliases[p] ? urlAliases[p][locale] : p);
          page.destination.push(p);
          break;
        }

        page.source.push(path);
        page.destination.push(path);
      }
      page.source = page.source.join('/');
      page.destination = page.destination.join('/');
      acc.push(page);
    }

    return acc;
  }, []);

  const sorted = rewrites.sort((a, b) => {
    if (a.source > b.source) return -1;
    if (a.source < b.source) return 1;
    return 0;
  });

  return sorted;
}

module.exports = {
  generateRewrites,
  urlAliases,
};
