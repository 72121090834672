import { gql, useMutation } from '@apollo/client';

import { TinkInitiateAccountsMutation, TinkInitiateAccountsMutationVariables } from '../gqlTypes';

const MUTATION = gql`
  mutation TinkInitiateAccounts($input: TinkInitiateAccountsInput!) {
    tinkInitiateAccounts(input: $input) {
      success
      url
      sessionId
    }
  }
`;

export const useTinkInitiateAccounts = () =>
  useMutation<TinkInitiateAccountsMutation, TinkInitiateAccountsMutationVariables>(MUTATION);
