import * as React from 'react';
import { SVGProps } from 'react';
const SvgHeart = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 54 47" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M39.9266 0.0203376C37.257 0.00485753 34.6371 0.737541 32.367 2.13442C30.1014 3.49859 28.2749 5.47777 27.1022 7.8391C25.7015 4.93275 23.3099 2.61424 20.3525 1.2955C17.4789 -0.00972452 14.2536 -0.339856 11.1729 0.355907C7.98052 0.980866 5.10558 2.68892 3.03948 5.1881C1.02313 7.60956 -0.0543805 10.669 0.00211387 13.8122C0.00211387 28.141 27.0009 47 27.0009 47C27.0009 47 53.9998 28.141 53.9998 13.8122C54.0103 12.0137 53.6573 10.2314 52.9616 8.57123C52.2659 6.91103 51.2417 5.40679 49.9499 4.14784C47.2792 1.52325 43.6816 0.0418253 39.9266 0.0203376Z"
      fill="#474AEE"
    />
  </svg>
);
export default SvgHeart;
