import * as React from 'react';
import { SVGProps } from 'react';
const SvgSbankenDark = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={20} cy={20} r={20} fill="white" />
    <path
      d="M8.60117 16.9481C8.2652 17.174 8.23045 17.6143 8.52587 17.8865C8.7344 18.0777 8.96611 18.1182 9.2094 18.0024C9.64964 17.7938 9.69018 17.174 9.2847 16.9307C9.04141 16.7801 8.84446 16.7859 8.60117 16.9481Z"
      fill="black"
    />
    <path
      d="M5.6237 17.5852C5.05023 17.8632 4.70268 18.4193 4.70847 19.0449C4.71426 19.7574 5.09657 20.1745 6.174 20.6379C6.89808 20.9565 7.15295 21.1303 7.25722 21.3735C7.431 21.7964 7.19929 22.3467 6.74747 22.5842C6.53894 22.6885 6.04077 22.6885 5.83224 22.5842C5.60632 22.4626 5.32249 22.1266 5.27615 21.918C5.25298 21.8196 5.22401 21.7385 5.21243 21.7385C5.19505 21.7385 5.01548 21.779 4.81274 21.8312C4.45359 21.9238 4.43622 21.9296 4.47677 22.0802C4.6042 22.6247 5.14292 23.2214 5.67004 23.3952C6.08132 23.5342 6.65479 23.5284 6.99655 23.372C7.85386 22.9955 8.33465 21.9876 8.0566 21.1592C7.89441 20.6726 7.57581 20.4004 6.64899 19.9601C5.67004 19.4967 5.53681 19.3809 5.53681 19.0102C5.53681 18.1934 6.74168 17.9617 7.08344 18.7089C7.14137 18.8306 7.18771 18.9349 7.1935 18.9464C7.19929 18.958 7.37307 18.9175 7.5874 18.8653C7.93495 18.7785 7.96971 18.7553 7.93495 18.6452C7.79014 18.1702 7.56423 17.8748 7.17033 17.6431C6.95021 17.5157 6.82277 17.4809 6.4115 17.4635C5.97705 17.4462 5.87858 17.4635 5.6237 17.5852Z"
      fill="black"
    />
    <path
      d="M10.7617 20.4177L10.7791 23.3893H11.0687C11.3526 23.3893 11.3583 23.3835 11.3757 23.2097L11.3931 23.0244L11.6769 23.2155C12.5169 23.7832 13.6754 23.5168 14.1851 22.6479C14.4458 22.2076 14.5327 21.7906 14.5037 21.107C14.469 20.3714 14.3357 19.9659 13.9824 19.5662C13.38 18.8827 12.3373 18.7552 11.648 19.2824L11.4452 19.433V18.4424V17.4519H11.0977H10.7501L10.7617 20.4177ZM13.0961 19.7052C13.38 19.8327 13.49 19.9543 13.658 20.3192C13.8318 20.7015 13.8492 21.6805 13.687 22.0976C13.3858 22.868 12.401 23.1402 11.787 22.6247C11.4916 22.3756 11.4452 22.1845 11.4452 21.2634C11.451 20.2034 11.5437 19.9543 12.0535 19.6878C12.291 19.5662 12.8065 19.572 13.0961 19.7052Z"
      fill="black"
    />
    <path
      d="M24.0732 20.4351V23.4241L24.4092 23.4068L24.7394 23.3894L24.7568 22.6305L24.7741 21.8717L25.0638 21.5415L25.3592 21.2114L26.0949 22.3004L26.8247 23.3894L27.2186 23.3952C27.433 23.401 27.6067 23.3836 27.6067 23.3604C27.6067 23.3372 27.2186 22.7464 26.7436 22.0513L25.8805 20.7827L26.4714 20.1571C26.79 19.8095 27.1433 19.4272 27.2476 19.2998L27.4445 19.0739H26.9869H26.5351L25.6662 20.0239L24.7973 20.9739L24.7799 19.2129L24.7684 17.452H24.4208H24.0732V20.4351Z"
      fill="black"
    />
    <path
      d="M16.2937 19.0392C15.8014 19.2188 15.4075 19.6242 15.2974 20.0703C15.2511 20.244 15.3148 20.2904 15.7029 20.3715C15.8651 20.4062 15.8882 20.3889 16.0215 20.134C16.1026 19.9834 16.259 19.798 16.369 19.7227C16.5428 19.6011 16.6297 19.5895 16.9888 19.6069C17.2959 19.6184 17.4465 19.659 17.5681 19.7459C17.8925 19.9892 17.8867 20.3947 17.5565 20.5858C17.458 20.6437 17.0989 20.7712 16.7456 20.8754C15.4886 21.2346 15.1526 21.5358 15.1526 22.3062C15.1526 22.9202 15.5581 23.3605 16.2242 23.4821C16.8209 23.5922 17.3306 23.4705 17.7187 23.1288L17.933 22.9434V23.1809V23.4242L18.2401 23.4068L18.5413 23.3894V21.7096C18.5413 20.7828 18.5181 19.9544 18.4949 19.8675C18.4196 19.5953 18.0141 19.2072 17.6666 19.0739C17.2959 18.9349 16.6355 18.9233 16.2937 19.0392ZM17.8462 22.1266C17.8056 22.3526 17.5797 22.6364 17.348 22.7522C17.2553 22.8044 17.0294 22.8565 16.844 22.8739C16.2184 22.9434 15.8477 22.7059 15.8477 22.2367C15.8535 21.8312 16.0041 21.7096 16.9483 21.3968C17.2495 21.2983 17.5797 21.1709 17.6724 21.1129L17.8462 21.0029L17.8635 21.4721C17.8751 21.7328 17.8635 22.0224 17.8462 22.1266Z"
      fill="black"
    />
    <path
      d="M20.8409 19.0739C20.7019 19.1376 20.5108 19.2419 20.4181 19.3114L20.2501 19.4272V19.2535C20.2501 19.0739 20.2501 19.0739 19.9315 19.0739H19.6129V21.2461V23.4241L19.9489 23.4068L20.2791 23.3894L20.308 21.7848C20.3428 19.9659 20.337 19.9775 20.8352 19.7227C21.0379 19.6126 21.1595 19.5894 21.426 19.6068C21.8199 19.6358 22.0226 19.7632 22.1906 20.0934C22.2949 20.3019 22.3065 20.4583 22.3239 21.8717L22.347 23.4241L22.6714 23.4068L23.0016 23.3894V21.7385C23.0016 20.1803 22.9958 20.0702 22.8799 19.8211C22.7178 19.4736 22.3876 19.1608 22.0574 19.0507C21.6925 18.9233 21.148 18.9349 20.8409 19.0739Z"
      fill="black"
    />
    <path
      d="M29.1186 19.0854C28.9158 19.1607 28.6957 19.3113 28.5219 19.4909C27.6125 20.3945 27.6473 22.1845 28.5972 23.036C28.9564 23.3604 29.3561 23.4994 29.8948 23.4994C30.5146 23.4994 30.8622 23.3546 31.3024 22.9201C31.4993 22.7232 31.6615 22.5204 31.6615 22.4683C31.6615 22.422 31.5515 22.3061 31.4125 22.2076L31.1634 22.0397L30.9606 22.3061C30.7 22.6653 30.4451 22.8217 30.0744 22.868C29.3271 22.9607 28.7189 22.4799 28.6088 21.7095L28.5741 21.4488L30.1902 21.4372L31.8063 21.4198L31.789 21.0144C31.7832 20.7884 31.7195 20.4409 31.6499 20.2324C31.482 19.7226 30.9664 19.1897 30.503 19.0507C30.0744 18.9232 29.5009 18.9348 29.1186 19.0854ZM30.3698 19.6821C30.4914 19.7342 30.6362 19.8153 30.7 19.8732C30.8506 20.0064 31.0823 20.5162 31.0823 20.7074V20.8695H29.8369C28.4466 20.8695 28.5161 20.8927 28.6725 20.4467C28.7826 20.1397 29.0259 19.8443 29.275 19.711C29.5414 19.572 30.0744 19.5604 30.3698 19.6821Z"
      fill="black"
    />
    <path
      d="M33.9785 19.0104C33.7468 19.0857 33.4861 19.2305 33.405 19.329C33.2892 19.468 33.2254 19.439 33.2254 19.2479C33.2254 19.0741 33.2197 19.0741 32.9069 19.0741H32.5883L32.5998 21.2289L32.6172 23.3896H32.9358H33.2544L33.2834 21.8256C33.3181 20.0357 33.3355 19.9662 33.8047 19.7229C34.1638 19.5375 34.7141 19.5607 34.9401 19.7692C35.2702 20.082 35.2818 20.1341 35.3108 21.8256L35.3397 23.3896H35.6583H35.9769L35.9943 21.8835C36.0175 20.2152 35.9769 19.9082 35.7163 19.5433C35.4498 19.1784 35.0907 18.9988 34.5635 18.9814C34.3202 18.9698 34.0596 18.9814 33.9785 19.0104Z"
      fill="black"
    />
  </svg>
);
export default SvgSbankenDark;
