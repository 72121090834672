import { gql, useMutation } from '@apollo/client';

import { CheckReferralCodeMutation, CheckReferralCodeMutationVariables } from '../gqlTypes';

export const QUERY = gql`
  mutation CheckReferralCode($code: String!) {
    checkReferralCode(code: $code) {
      name
      validTo
    }
  }
`;

export const useCheckReferralCode = () =>
  useMutation<CheckReferralCodeMutation, CheckReferralCodeMutationVariables>(QUERY);
