import { gql, useQuery } from '@apollo/client';

import { IntercomQuery } from './../gqlTypes';

export const query = gql`
  query Intercom {
    intercom {
      app_id
      first_name
      last_name
      created_at
      email
      user_hash
    }
  }
`;

export const useIntercom = () => useQuery<IntercomQuery>(query);
