import * as React from 'react';
import { SVGProps } from 'react';
const SvgGraph = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5405 5.54757C12.7001 5.09537 13.3431 5.10542 13.4885 5.56238L16.4612 14.905L17.8646 9.99279C17.9496 9.6956 18.2811 9.54586 18.5602 9.67863L21.6708 11.1583C22.0413 11.3435 22.1915 11.794 22.0062 12.1645C21.821 12.535 21.3705 12.6852 21 12.4999L18.9726 11.5749L16.9934 18.5023C16.8574 18.9783 16.1863 18.9883 16.0362 18.5165L12.9629 8.85774L10.5406 15.721C10.4035 16.1096 9.88162 16.1748 9.65308 15.832L6.69349 11.3926L2.94831 12.4743C2.55535 12.6053 2.13061 12.3929 1.99963 11.9999C1.86864 11.607 2.08101 11.1822 2.47397 11.0513L6.95189 9.71309C7.16236 9.65019 7.38923 9.73203 7.51108 9.9148L9.79176 13.3358L12.5405 5.54757Z"
    />
  </svg>
);
export default SvgGraph;
