import * as React from 'react';
import { SVGProps } from 'react';
const SvgInvestigation = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.29998 14.6385H10.5998V15.3764C10.5998 15.807 10.4259 16.22 10.1165 16.5245C9.80711 16.8289 9.38745 17 8.94987 17C8.51229 17 8.09264 16.8289 7.78322 16.5245C7.47381 16.22 7.29998 15.807 7.29998 15.3764V14.6385ZM9.73462 12.2769L9.76162 12.199C10.2218 10.9557 10.2998 10.7242 10.2998 10.5058C10.2998 10.063 10.178 9.53578 9.98301 9.16384C9.83782 8.88636 9.73582 8.80547 9.69982 8.80547C8.93187 8.80547 8.19992 9.61076 8.19992 10.5058C8.19992 11.072 8.24492 11.5525 8.33611 12.1341L8.35891 12.2769H9.73462ZM9.69982 7.62471C10.8997 7.62471 11.4997 9.32501 11.4997 10.5058C11.4997 11.0962 11.1997 11.6865 10.8997 12.5721L10.5998 13.4577H7.29998C7.29998 12.8673 7 11.9817 7 10.5058C7 9.02982 8.19872 7.62471 9.69982 7.62471ZM16.9318 12.3348L16.8016 13.0616C16.7256 13.4856 16.4815 13.8627 16.123 14.1097C15.7646 14.3567 15.3211 14.4535 14.8901 14.3787C14.4591 14.3039 14.076 14.0638 13.825 13.711C13.5739 13.3583 13.4756 12.9218 13.5516 12.4977L13.6824 11.7716L16.9318 12.3348ZM16.3018 9.82802C16.4008 9.53283 16.4824 9.29962 16.4836 9.29667C16.6048 8.9082 16.7002 8.5274 16.7746 8.11118C16.9324 7.23033 16.3534 6.31228 15.5974 6.18063C15.562 6.17472 15.4475 6.23671 15.2555 6.48467C14.9975 6.81824 14.7851 7.31712 14.7065 7.75223C14.6717 7.94706 14.6771 7.99133 14.8685 9.08827C14.8955 9.23941 14.9177 9.37519 14.9381 9.51157L14.9501 9.59305L16.3018 9.82802ZM15.8062 5.01816C17.2845 5.27439 18.2169 6.86252 17.9565 8.31605C17.6961 9.77016 17.2443 10.5908 17.1405 11.1723L13.8906 10.6085L13.7514 9.68515C13.6122 8.76178 13.4214 8.12889 13.5252 7.54796C13.7334 6.3849 14.6243 4.8133 15.8062 5.01816Z"
      fill="#DA2D43"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 12C21 16.9706 16.9706 21 12 21C9.77281 21 7.73457 20.191 6.16311 18.8508C6.12893 18.9154 6.08467 18.976 6.03033 19.0303L3.03033 22.0303C2.73744 22.3232 2.26256 22.3232 1.96967 22.0303C1.67678 21.7374 1.67678 21.2626 1.96967 20.9697L4.96967 17.9697C5.02401 17.9153 5.08462 17.8711 5.14917 17.8369C3.809 16.2654 3 14.2272 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM19.65 12C19.65 16.225 16.225 19.65 12 19.65C7.77502 19.65 4.35 16.225 4.35 12C4.35 7.77502 7.77502 4.35 12 4.35C16.225 4.35 19.65 7.77502 19.65 12Z"
      fill="#12123B"
    />
  </svg>
);
export default SvgInvestigation;
