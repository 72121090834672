import * as React from 'react';
import { SVGProps } from 'react';
const SvgDnb = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={20} cy={20} r={20} fill="#007C85" />
    <path
      d="M23.0538 10.1875C23.3913 10.1875 23.7289 10.1798 24.0664 10.1951C24.0511 10.3562 24.0511 10.5097 24.0511 10.6707C24.0511 15.4192 24.0511 20.1753 24.0511 24.9161C24.0357 25.1769 23.6598 25.269 23.5064 25.0619C21.3201 22.2849 19.1415 19.5003 16.9552 16.7233C16.9476 20.9578 16.9476 25.1923 16.9552 29.4267C16.6177 29.4344 16.2878 29.4267 15.9503 29.4267C15.9503 24.8701 15.9503 20.3134 15.9503 15.7491C15.958 15.3885 15.9426 15.0126 15.958 14.6521C15.9733 14.3913 16.3569 14.3069 16.5026 14.514C18.6813 17.291 20.8599 20.0756 23.0385 22.8526C23.0538 18.6411 23.0461 14.4143 23.0538 10.1875Z"
      fill="white"
    />
    <path
      d="M9.42216 14.4911C10.3964 14.5218 11.3706 14.8209 12.1301 15.4346C12.8205 15.9716 13.3114 16.7387 13.5876 17.5672C13.9021 18.5184 14.0095 19.531 13.9251 20.5206C13.8407 21.3798 13.6183 22.2389 13.1734 22.983C12.8358 23.5661 12.3602 24.0647 11.8002 24.4329C11.0561 24.9162 10.1586 25.131 9.28408 25.1386C8.30984 25.1386 7.32793 25.1386 6.35369 25.1386C6.16958 25.154 5.99315 24.9852 6.00082 24.8011C6.00082 21.4948 6.00082 18.1809 6.00082 14.867C5.99315 14.7442 6.03917 14.6061 6.15424 14.5371C6.23095 14.4834 6.33835 14.4911 6.43807 14.4834C7.42765 14.4911 8.4249 14.4757 9.42216 14.4911ZM7.00574 15.4883C7.00574 18.3727 7.00574 21.2494 7.00574 24.1337C7.77286 24.1337 8.54764 24.1414 9.32243 24.1337C10.1356 24.1261 10.9717 23.8729 11.6008 23.3283C12.1454 22.8603 12.5136 22.2159 12.7131 21.5332C12.9969 20.582 13.0199 19.5694 12.8282 18.6028C12.6747 17.8357 12.3832 17.0686 11.8309 16.5086C11.2019 15.8335 10.2737 15.5037 9.36846 15.4883C8.57833 15.4883 7.79587 15.4883 7.00574 15.4883Z"
      fill="white"
    />
    <path
      d="M29.9196 14.4834C30.6867 14.4987 31.4998 14.6061 32.1595 15.0281C32.6888 15.3579 33.0647 15.9179 33.1875 16.5316C33.3332 17.2297 33.3025 17.9891 32.988 18.6488C32.8499 18.9557 32.6351 19.2088 32.3897 19.4313C33.0034 19.7381 33.525 20.2444 33.7935 20.8888C34.062 21.5179 34.039 22.2313 33.8932 22.891C33.7398 23.5277 33.3562 24.1107 32.8116 24.4789C32.0982 24.9776 31.2083 25.131 30.3568 25.1463C29.1755 25.1463 27.9941 25.1463 26.8204 25.1463C26.621 25.177 26.4215 25.0082 26.4368 24.8088C26.4368 21.4872 26.4368 18.1579 26.4368 14.8363C26.4215 14.6445 26.5979 14.4834 26.782 14.4987C27.8253 14.4834 28.8686 14.4834 29.9196 14.4834ZM27.4341 15.4883C27.4264 16.6697 27.4341 17.851 27.4341 19.0324C28.5081 19.0401 29.5744 19.0324 30.6483 19.0401C31.17 19.0477 31.7146 18.8329 31.9984 18.3803C32.2899 17.9124 32.313 17.3294 32.2209 16.8078C32.1519 16.3935 31.891 16.0176 31.5228 15.8259C30.9245 15.5113 30.2187 15.4883 29.5513 15.496C28.8456 15.4883 28.1398 15.4883 27.4341 15.4883ZM27.4341 20.045C27.4341 21.4105 27.4341 22.7683 27.4341 24.1414C28.439 24.1414 29.4516 24.1491 30.4642 24.1414C31.1469 24.1184 31.891 23.988 32.4204 23.52C32.8346 23.1672 33.0034 22.6072 33.0034 22.0855C33.0417 21.5332 32.8116 20.9732 32.3897 20.6203C31.8987 20.2061 31.239 20.045 30.61 20.0527C29.5437 20.045 28.4927 20.0373 27.4341 20.045Z"
      fill="white"
    />
  </svg>
);
export default SvgDnb;
