import * as React from 'react';
import { SVGProps } from 'react';
const SvgLocked = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M16.875 10.5H15.375V6.375C15.375 4.51425 13.8608 3 12 3C10.1393 3 8.625 4.51425 8.625 6.375V10.5H7.125V6.375C7.125 3.687 9.312 1.5 12 1.5C14.688 1.5 16.875 3.687 16.875 6.375V10.5Z" />
    <path d="M18 22.5H6C5.17275 22.5 4.5 21.8273 4.5 21V11.25C4.5 10.4228 5.17275 9.75 6 9.75H18C18.8273 9.75 19.5 10.4228 19.5 11.25V21C19.5 21.8273 18.8273 22.5 18 22.5ZM6 11.25V21H18C18.0008 21 18.0008 21 18.0008 21L18 11.25H6Z" />
    <path d="M12 18.8003C11.586 18.8003 11.25 18.4643 11.25 18.0503V14.2913C11.25 13.8773 11.586 13.5413 12 13.5413C12.414 13.5413 12.75 13.8773 12.75 14.2913V18.0503C12.75 18.4643 12.414 18.8003 12 18.8003Z" />
  </svg>
);
export default SvgLocked;
