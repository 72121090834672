import { REDEGORELSE_CDN_DK_URL } from '@firi/shared/constants';
import { Icon } from '@firi/ui-web';
import { t } from '@lingui/macro';
import { findSanityLanguageByLocale } from 'helpers/language';
import { sendGTMEvent } from 'helpers/sendGTMEvent';
import { getLocalizedUrl, getSanitySlug } from 'helpers/url';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FooterItemsData } from 'pages/api/footeritems';
import useSWR, { Fetcher } from 'swr';

import { Typography } from './Typography';

const fetcher: Fetcher<FooterItemsData, string> = (url) => fetch(url).then((res) => res.json());

export const NavItems = ({ placement = 'Footer' }: { placement: 'Footer' | 'Drawer' }) => {
  const router = useRouter();
  const { locale, query } = router;
  const { data } = useSWR('/api/footeritems', fetcher);

  const items =
    data?.footerItems
      .map((item) => {
        const localeAltSlug = item.alternateSlugs.find(
          (p) => p?.__i18n_lang === findSanityLanguageByLocale(locale || ''),
        );

        let slug = '';
        if (localeAltSlug) {
          slug = getLocalizedUrl(
            `/${
              (localeAltSlug.slugV2?.parentSlug && localeAltSlug.slugV2.parentSlug + '/') || ''
            }${getSanitySlug(localeAltSlug.slugV2)}`,
            locale,
            query,
          );
        }

        return {
          title: localeAltSlug?.title,
          slug,
        };
      })
      ?.filter((item) => item.title) || [];

  return (
    <>
      {[...items]?.map((item) => (
        <Link key={item.slug} href={item.slug} locale={router.locale}>
          <a
            onClick={() =>
              sendGTMEvent({
                event_action: `${placement} click`,
                event_label: item.title || '',
                event_category: 'Interactions',
              })
            }
          >
            <Typography color="white" size="sm">
              {item.title}
            </Typography>
          </a>
        </Link>
      ))}
      {locale === 'dk' ? (
        <div className="lg:col-start-3 lg:row-start-4">
          <Link href={REDEGORELSE_CDN_DK_URL} locale={router.locale}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                sendGTMEvent({
                  event_action: `${placement} click`,
                  event_label: 'Redegørelse fra Finanstilsynet',
                  event_category: 'Interactions',
                })
              }
            >
              <Typography color="white" size="sm">
                Redegørelse fra Finanstilsynet
              </Typography>
              <span className="ml-1">
                <Icon icon="external-link" color="white" size="xs" className="mb-1" />
              </span>
            </a>
          </Link>
        </div>
      ) : null}
    </>
  );
};
