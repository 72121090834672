import { gql, useQuery } from '@apollo/client';

import { LightningWithdrawsQuery } from './../gqlTypes';

const query = gql`
  query LightningWithdraws {
    withdrawsLightning {
      invoice
      state
    }
  }
`;

export const useLightningWithdraws = () => useQuery<LightningWithdrawsQuery>(query);
