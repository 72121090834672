import { gql, useMutation } from '@apollo/client';

import { KycFormFragment } from '../fragments';
import { CURRENT_USER_QUERY } from '../queries/useCurrentUser';
import { KycMultipleAnswersMutation, KycMultipleAnswersMutationVariables } from './../gqlTypes';

const mutation = gql`
  mutation KycMultipleAnswers($input: KycMultipleInput!) {
    sendMultipleAnswer(input: $input) {
      ...KycFormFragment
    }
  }
  ${KycFormFragment}
`;

export const useKycMultipleAnswers = () =>
  useMutation<KycMultipleAnswersMutation, KycMultipleAnswersMutationVariables>(mutation, {
    refetchQueries: ['Banners', CURRENT_USER_QUERY],
  });
