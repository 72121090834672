import * as React from 'react';
import { SVGProps } from 'react';
const SvgCrown = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.25C12.3 3.25 12.5712 3.4288 12.6894 3.70456L16.6552 12.9582L20.3834 7.57309C20.5701 7.30336 20.9105 7.1863 21.2236 7.28411C21.5368 7.38193 21.75 7.67193 21.75 8V20C21.75 20.4142 21.4142 20.75 21 20.75H3C2.58579 20.75 2.25 20.4142 2.25 20V8C2.25 7.67193 2.46323 7.38193 2.77637 7.28411C3.08952 7.1863 3.4299 7.30336 3.61664 7.57309L7.3448 12.9582L11.3106 3.70456C11.4288 3.4288 11.7 3.25 12 3.25ZM12 5.90394L8.18936 14.7954C8.08187 15.0463 7.84663 15.2189 7.57512 15.2462C7.30361 15.2736 7.03868 15.1513 6.88336 14.9269L3.75 10.4009V19.25H20.25V10.4009L17.1166 14.9269C16.9613 15.1513 16.6964 15.2736 16.4249 15.2462C16.1534 15.2189 15.9181 15.0463 15.8106 14.7954L12 5.90394Z"
      fill="#12123B"
    />
  </svg>
);
export default SvgCrown;
