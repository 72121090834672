import { gql, useMutation } from '@apollo/client';

import { ResendVerificationCodeMutation } from '../gqlTypes';

const mutation = gql`
  mutation ResendVerificationCode {
    resendVerificationCode {
      success
      message
    }
  }
`;

export const useResendVerificationCode = () =>
  useMutation<ResendVerificationCodeMutation>(mutation);
