import * as React from 'react';
import { SVGProps } from 'react';
const SvgXrpDark = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={12} cy={12} r={12} fill="#E9E9E9" />
    <path
      d="M17.6559 5H19.9699L15.1551 10.0346C13.4121 11.8573 10.5867 11.8573 8.84365 10.0346L4.02944 5H6.34341L10.001 8.82464C11.1045 9.97913 12.8942 9.97913 13.9984 8.82464L17.6559 5ZM6.31397 19H4L8.84429 13.9343C10.5873 12.1116 13.4127 12.1116 15.1557 13.9343L20 19H17.686L13.9984 15.1443C12.8949 13.9898 11.1051 13.9898 10.001 15.1443L6.31397 19Z"
      fill="#23292F"
    />
  </svg>
);
export default SvgXrpDark;
