import * as React from 'react';
import { SVGProps } from 'react';
const SvgChat = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="black" {...props}>
    <path d="M18 9H6V10.5H18V9Z" />
    <path d="M15 12.75H6V14.25H15V12.75Z" />
    <path d="M3.6045 21.78C3.4155 21.78 3.22425 21.7395 3.042 21.657C2.55375 21.4357 2.25 20.964 2.25 20.4277V6C2.25 5.17275 2.92275 4.5 3.75 4.5H20.25C21.0773 4.5 21.75 5.17275 21.75 6V17.25C21.75 18.0772 21.0773 18.75 20.25 18.75H7.8495C7.668 18.75 7.4925 18.816 7.356 18.9352L4.4895 21.4432C4.236 21.6652 3.92325 21.78 3.6045 21.78ZM3.75 6C3.74925 6 3.74925 6 3.74925 6L3.75 20.097L6.3675 17.8065C6.77775 17.4472 7.30425 17.25 7.84875 17.25H20.25V6H3.75Z" />
  </svg>
);
export default SvgChat;
