import { gql, useMutation } from '@apollo/client';

import { RemoveOtpMutation, RemoveOtpMutationVariables } from '../gqlTypes';

const MUTATION = gql`
  mutation RemoveOtp($code: String!) {
    removeOtp(code: $code) {
      success
      message
    }
  }
`;

export const useRemoveTwoFactor = () =>
  useMutation<RemoveOtpMutation, RemoveOtpMutationVariables>(MUTATION, {
    refetchQueries: ['User'],
  });
