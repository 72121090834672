import * as React from 'react';
import { SVGProps } from 'react';
const SvgVippsLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 52 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.6055 1.26318L4.82018 7.80025L6.99144 1.26318H9.55352L5.73211 10.9591H3.82141L0 1.26318H2.6055Z"
      fill="#FF5B24"
    />
    <path
      d="M15.329 9.42349C16.9358 9.42349 17.8477 8.63378 18.7162 7.49309C19.1939 6.87887 19.8018 6.74725 20.2361 7.09823C20.6703 7.44921 20.7137 8.10731 20.2361 8.72153C18.9767 10.3887 17.37 11.3978 15.329 11.3978C13.1143 11.3978 11.1602 10.1693 9.81404 8.01956C9.42321 7.44921 9.51006 6.83499 9.94432 6.52788C10.3786 6.22077 11.0299 6.35239 11.4208 6.96661C12.3761 8.41442 13.6789 9.42349 15.329 9.42349ZM18.3254 4.02713C18.3254 4.81684 17.7174 5.34331 17.0226 5.34331C16.3278 5.34331 15.7198 4.81684 15.7198 4.02713C15.7198 3.23741 16.3278 2.71094 17.0226 2.71094C17.7174 2.71094 18.3254 3.28129 18.3254 4.02713Z"
      fill="#FF5B24"
    />
    <path
      d="M24.4484 1.26324V2.57943C25.0998 1.65809 26.0986 1 27.575 1C29.4423 1 31.6136 2.57943 31.6136 5.95764C31.6136 9.51135 29.5292 11.2224 27.3579 11.2224C26.2289 11.2224 25.1867 10.7837 24.405 9.68684V14.3374H22.0601V1.26324H24.4484ZM24.4484 6.08926C24.4484 8.06354 25.5775 9.11649 26.8368 9.11649C28.0527 9.11649 29.2686 8.15129 29.2686 6.08926C29.2686 4.07111 28.0527 3.1059 26.8368 3.1059C25.6209 3.1059 24.4484 4.02723 24.4484 6.08926Z"
      fill="#FF5B24"
    />
    <path
      d="M35.739 1.26324V2.57943C36.3903 1.65809 37.3891 1 38.8656 1C40.7328 1 42.9041 2.57943 42.9041 5.95764C42.9041 9.51135 40.8197 11.2224 38.6484 11.2224C37.5194 11.2224 36.4772 10.7837 35.6955 9.68684V14.3374H33.3506V1.26324H35.739ZM35.739 6.08926C35.739 8.06354 36.868 9.11649 38.1273 9.11649C39.3432 9.11649 40.5591 8.15129 40.5591 6.08926C40.5591 4.07111 39.3432 3.1059 38.1273 3.1059C36.868 3.1059 35.739 4.02723 35.739 6.08926Z"
      fill="#FF5B24"
    />
    <path
      d="M47.8979 1C49.8521 1 51.2417 1.92133 51.8496 4.20272L49.7218 4.55371C49.6784 3.41301 48.9836 3.01815 47.9414 3.01815C47.1597 3.01815 46.5518 3.36914 46.5518 3.93949C46.5518 4.37821 46.8557 4.81694 47.7677 4.99244L49.3744 5.29955C50.9377 5.60666 51.8062 6.65961 51.8062 8.06354C51.8062 10.1694 49.9389 11.2224 48.1585 11.2224C46.2912 11.2224 44.2068 10.2572 43.9028 7.88805L46.0307 7.53706C46.1609 8.76551 46.8992 9.20424 48.1151 9.20424C49.027 9.20424 49.6349 8.85325 49.6349 8.2829C49.6349 7.75643 49.331 7.36157 48.3322 7.18608L46.8557 6.92284C45.2924 6.61573 44.3371 5.51891 44.3371 4.11498C44.3805 1.92133 46.3346 1 47.8979 1Z"
      fill="#FF5B24"
    />
  </svg>
);
export default SvgVippsLogo;
