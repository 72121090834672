import * as React from 'react';
import { SVGProps } from 'react';
const SvgSuspendedFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2548 17.6461C21.3558 16.0393 22 14.0949 22 12C22 6.47715 17.5228 2 12 2C9.9051 2 7.96065 2.64417 6.35394 3.74524L9.69201 7.08332C10.2423 6.42141 11.0719 6 12 6C13.6569 6 15 7.34315 15 9C15 9.92806 14.5786 10.7577 13.9167 11.308L20.2548 17.6461ZM13.1157 10.507C13.5764 10.1654 13.875 9.61758 13.875 9C13.875 7.96447 13.0355 7.125 12 7.125C11.3824 7.125 10.8346 7.42358 10.493 7.88426L13.1157 10.507ZM3.74524 6.35394C2.64417 7.96065 2 9.9051 2 12C2 17.5228 6.47715 22 12 22C14.0949 22 16.0393 21.3558 17.6461 20.2548L11.4627 14.0714H10.5714C9.19072 14.0714 8.07143 15.1907 8.07143 16.5714V18H7V16.5714C7 14.658 8.50481 13.0959 10.3956 13.0043L3.74524 6.35394Z"
      fill="#12123B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.96967 1.96979C2.26256 1.6769 2.73744 1.6769 3.03033 1.96979L21.0303 19.9698C21.3232 20.2627 21.3232 20.7376 21.0303 21.0305C20.7374 21.3233 20.2626 21.3233 19.9697 21.0305L1.96967 3.03045C1.67678 2.73756 1.67678 2.26269 1.96967 1.96979Z"
      fill="#DA2D43"
    />
  </svg>
);
export default SvgSuspendedFilled;
