import * as React from 'react';
import { SVGProps } from 'react';
const SvgThumbsUpFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4 18.9034V10.131H6.62742C6.90607 10.131 7.13195 10.3548 7.13195 10.631V18.4034C7.13195 18.6796 6.90607 18.9034 6.62742 18.9034H4Z"
      fill="#1E856C"
    />
    <path
      d="M13.3892 4.72871C13.0943 8.23626 12.2535 9.69502 9.5023 11.088C9.33046 11.175 9.21992 11.3489 9.21992 11.5401V17.8962C9.21992 18.1558 9.42132 18.3684 9.67947 18.4124C10.5818 18.566 11.2223 18.955 12.2047 19.8626C12.2994 19.9501 12.4241 20 12.5535 20H17.4652C17.5355 20 17.6056 19.9855 17.6694 19.9563C18.7768 19.4488 19.054 18.9222 18.8758 18.094C18.8292 17.8776 18.9109 17.6547 19.0664 17.4958C19.5998 16.9507 19.4666 16.2798 19.4434 15.4231C19.4409 15.3283 19.4688 15.2355 19.5142 15.1519C19.8713 14.4948 19.9006 13.6861 19.5596 12.8656C19.4922 12.7034 19.4992 12.5171 19.5929 12.3682C20.0749 11.6016 20.1068 11.4625 19.8047 10.9278C19.7172 10.773 19.5497 10.6792 19.3706 10.6792H15.2451C14.8727 10.6792 14.6293 10.2928 14.7675 9.95009C15.3495 8.50639 15.4193 7.22152 14.9718 4.70374C14.9653 4.66735 14.9546 4.63032 14.9391 4.5967C14.5461 3.74383 13.7735 3.86711 13.4288 4.57548C13.4057 4.62303 13.3936 4.6761 13.3892 4.72871Z"
      fill="#1E856C"
    />
  </svg>
);
export default SvgThumbsUpFilled;
