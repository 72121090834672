import { gql, useMutation } from '@apollo/client';

import {
  CreateSavingAgreement,
  CreateSavingsAgreementMutation,
  CreateSavingsAgreementMutationVariables,
  UpdateSavingAgreementDetails,
} from '../gqlTypes';
import { useUpdateSavingAgreementDetails } from './useUpdateSavingAgreementDetails';

const MUTATION = gql`
  mutation CreateSavingsAgreement($input: CreateSavingAgreement!) {
    createSavingAgreement(input: $input) {
      success
      message
    }
  }
`;

export const useCreateSavingsAgreement = () => {
  const [createMutation, createResult] = useMutation<
    CreateSavingsAgreementMutation,
    CreateSavingsAgreementMutationVariables
  >(MUTATION);

  const [updateMutation, updateResult] = useUpdateSavingAgreementDetails();

  const createSavingsAgreement = async (
    params: CreateSavingAgreement & UpdateSavingAgreementDetails,
  ) => {
    return createMutation({
      variables: {
        input: {
          recurring: params.recurring,
          accountGroupId: params.accountGroupId,
          amount: params.amount,
        },
      },
    })
      .then((r) => {
        /**
         * create does not handle distribution, therefore we need to add the distribution after agreement has been created
         */
        if (r.data?.createSavingAgreement.success) {
          return updateMutation({
            variables: {
              input: {
                accountGroupId: params.accountGroupId,
                details: params.details,
              },
            },
          });
        }
      })
      .catch(() => {
        // TODO track sentry
      });
  };

  return { createSavingsAgreement, createResult, updateResult };
};
