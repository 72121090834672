import * as React from 'react';
import { SVGProps } from 'react';
const SvgBankIdDisabled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0634 17.4547H20.1818C20.6182 17.4547 21 17.0729 21 16.6365C21 16.2002 20.6182 15.8184 20.1818 15.8184H18.4271L20.0634 17.4547ZM13.518 10.9093H13.6364C14.0727 10.9093 14.4546 10.5275 14.4546 10.0911C14.4546 9.65477 14.0727 9.27295 13.6364 9.27295H11.8816L13.518 10.9093ZM3.47317 6.08187C3.19867 6.21704 3 6.50342 3 6.81818C3 7.25455 3.38182 7.63636 3.81818 7.63636H5.02767L3.47317 6.08187ZM10.0186 12.6273C9.74412 12.7625 9.54547 13.0488 9.54547 13.3636C9.54547 13.8 9.92729 14.1818 10.3637 14.1818H11.5731L10.0186 12.6273ZM7.09091 14.1818H3.81818C3.38182 14.1818 3 13.8 3 13.3636C3 12.9272 3.38182 12.5454 3.81818 12.5454H7.09091C7.52727 12.5454 7.90909 12.9272 7.90909 13.3636C7.90909 13.8 7.52727 14.1818 7.09091 14.1818ZM3.81818 17.4547H7.09091C7.52727 17.4547 7.90909 17.0729 7.90909 16.6365C7.90909 16.2002 7.52727 15.8184 7.09091 15.8184H3.81818C3.38182 15.8184 3 16.2002 3 16.6365C3 17.0729 3.38182 17.4547 3.81818 17.4547ZM20.1818 7.63636H16.9091C16.4727 7.63636 16.0909 7.25455 16.0909 6.81818C16.0909 6.38182 16.4727 6 16.9091 6H20.1818C20.6182 6 21 6.38182 21 6.81818C21 7.25455 20.6182 7.63636 20.1818 7.63636ZM16.9091 10.9093H20.1818C20.6182 10.9093 21 10.5275 21 10.0911C21 9.65477 20.6182 9.27295 20.1818 9.27295H16.9091C16.4727 9.27295 16.0909 9.65477 16.0909 10.0911C16.0909 10.5275 16.4727 10.9093 16.9091 10.9093Z"
      fill="#39134C"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.46967 2.21967C2.76256 1.92678 3.23744 1.92678 3.53033 2.21967L21.5303 20.2197C21.8232 20.5126 21.8232 20.9874 21.5303 21.2803C21.2374 21.5732 20.7626 21.5732 20.4697 21.2803L2.46967 3.28033C2.17678 2.98744 2.17678 2.51256 2.46967 2.21967Z"
      fill="#DA2D43"
    />
  </svg>
);
export default SvgBankIdDisabled;
