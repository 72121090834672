import { gql, useMutation } from '@apollo/client';

import { CreateAccountGroupMutation, CreateAccountGroupMutationVariables } from '../gqlTypes';

const MUTATION = gql`
  mutation CreateAccountGroup($input: CreateAccountGroup!) {
    createAccountGroup(input: $input) {
      success
      message
      accountGroupId
    }
  }
`;

export const useCreateAccountGroup = () => {
  return useMutation<CreateAccountGroupMutation, CreateAccountGroupMutationVariables>(MUTATION, {
    refetchQueries: ['Accounts'],
  });
};
