import { ADTRACTION_COOKIE, HEY_LINK_COOKIE } from '@firi/shared/cookies';
import { getCookie, setCookies } from 'cookies-next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useAffiliateCookie = () => {
  const router = useRouter();

  const thirtyDays = new Date();
  thirtyDays.setDate(thirtyDays.getDate() + 30);
  const cookieOptions = {
    domain: 'firi.com',
    path: '/',
    expires: thirtyDays,
    sameSite: 'lax' as any,
  };

  useEffect(() => {
    if ('at_gd' in router.query) {
      setCookies(ADTRACTION_COOKIE, router.query['at_gd'], cookieOptions);
    }
    if ('hltid' in router.query) {
      setCookies(HEY_LINK_COOKIE, router.query['hltid'], cookieOptions);
    }
  }, [router.query]);
  const at_gd = getCookie(ADTRACTION_COOKIE) || router.query['at_gd'];
  const hltid = getCookie(HEY_LINK_COOKIE) || router.query['hltid'];

  return {
    at_gd,
    hltid,
    appLinkUrl: at_gd
      ? `https://firi.com/app-link?at_gd=${at_gd}`
      : hltid
      ? `https://firi.com/app-link?hltid=${hltid}`
      : 'https://firi.com/app-link',
  };
};
