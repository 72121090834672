import * as React from 'react';
import { SVGProps } from 'react';
const SvgReset = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.125 14.325C11.4098 14.325 11.6406 14.09 11.6406 13.8C11.6406 13.5101 11.4098 13.275 11.125 13.275C10.8402 13.275 10.6094 13.5101 10.6094 13.8C10.6094 14.09 10.8402 14.325 11.125 14.325Z"
      fill="#12123B"
    />
    <path
      d="M14.3906 13.8022C14.3906 14.0921 14.1598 14.3272 13.875 14.3272C13.5902 14.3272 13.3594 14.0921 13.3594 13.8022C13.3594 13.5122 13.5902 13.2772 13.875 13.2772C14.1598 13.2772 14.3906 13.5122 14.3906 13.8022Z"
      fill="#12123B"
    />
    <path
      d="M12.5 14.3272C12.7848 14.3272 13.0156 14.0921 13.0156 13.8022C13.0156 13.5122 12.7848 13.2772 12.5 13.2772C12.2152 13.2772 11.9844 13.5122 11.9844 13.8022C11.9844 14.0921 12.2152 14.3272 12.5 14.3272Z"
      fill="#12123B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.23437 7.325V9.6H8.30625C7.58483 9.6 7 10.1955 7 10.93V16.67C7 17.4045 7.58483 18 8.30625 18H16.6937C17.4152 18 18 17.4045 18 16.67V10.93C18 10.1955 17.4152 9.6 16.6937 9.6H15.7656V7.325C15.7656 5.48865 14.3036 4 12.5 4C10.6964 4 9.23437 5.48865 9.23437 7.325ZM12.5 5.05C11.266 5.05 10.2656 6.06855 10.2656 7.325V9.6H14.7344V7.325C14.7344 6.06855 13.734 5.05 12.5 5.05ZM16.6937 10.9125H8.30625C8.30337 10.9125 8.30142 10.9129 8.30142 10.9129L8.29995 10.9133C8.29995 10.9133 8.29659 10.9151 8.2941 10.9176C8.2916 10.9202 8.28988 10.9236 8.28988 10.9236L8.28943 10.9251C8.28943 10.9251 8.28906 10.9271 8.28906 10.93V16.67C8.28906 16.6729 8.28943 16.6749 8.28943 16.6749L8.28988 16.6764C8.28988 16.6764 8.2916 16.6798 8.2941 16.6824C8.29659 16.6849 8.29996 16.6867 8.29996 16.6867L8.30142 16.6871C8.30142 16.6871 8.30337 16.6875 8.30625 16.6875H16.6937C16.6966 16.6875 16.6986 16.6871 16.6986 16.6871L16.7 16.6867C16.7 16.6867 16.7034 16.6849 16.7059 16.6824C16.7084 16.6798 16.7101 16.6764 16.7101 16.6764L16.7106 16.6749C16.7106 16.6749 16.7109 16.6729 16.7109 16.67V10.93C16.7109 10.9271 16.7106 10.9251 16.7106 10.9251L16.7101 10.9236C16.7101 10.9236 16.7084 10.9202 16.7059 10.9176C16.7034 10.9151 16.7 10.9133 16.7 10.9133L16.6986 10.9129L16.697 10.9126L16.6937 10.9125Z"
      fill="#12123B"
    />
    <path d="M1.5 11.5L2.5 11.5L3.5 11.5L2.5 13L1.5 11.5Z" fill="#12123B" />
    <path
      d="M5.07538 18.9246C6.7931 20.6424 9.05329 21.7114 11.4708 21.9495C13.8884 22.1876 16.3137 21.5801 18.3335 20.2304C20.3533 18.8808 21.8427 16.8726 22.5479 14.548C23.253 12.2234 23.1304 9.72616 22.2007 7.48184C21.2711 5.23753 19.5921 3.38498 17.4497 2.23984C15.3073 1.09471 12.8341 0.727852 10.4516 1.20177C8.069 1.67569 5.92448 2.96107 4.38339 4.83889C2.8423 6.71671 2.5 9.07077 2.5 11.5M2.5 11.5L1.5 11.5L2.5 13L3.5 11.5L2.5 11.5Z"
      stroke="#12123B"
      strokeWidth={1.5}
    />
  </svg>
);
export default SvgReset;
