import React from 'react';
import { twMerge } from 'tailwind-merge';

export type PillChipVariants = 'one' | 'two' | 'three';
export interface PillChipProps {
  size?: 'md' | 'sm';
  variant: PillChipVariants;
  active?: boolean;
  children: string | React.ReactNode;
  className?: string;
  notification?: boolean;
  onClick?: () => void;
}

const PillChipNotification = ({
  variant,
  active,
}: Pick<PillChipProps, 'size' | 'variant' | 'active'>) => {
  return (
    <span
      className={twMerge(
        'h-1.5 w-1.5 rounded-full',
        variant === 'one'
          ? active
            ? 'bg-blue dark:bg-black'
            : 'bg-blue dark:bg-text-dark-2'
          : '',
        variant === 'two'
          ? active
            ? 'bg-blue dark:bg-black'
            : 'bg-white dark:bg-text-dark-2'
          : '',
        variant === 'three'
          ? active
            ? 'bg-blue dark:bg-black'
            : 'bg-blue-100 dark:bg-text-dark-2'
          : ''
      )}
    />
  );
};

export const PillChip = ({
  size = 'md',
  variant,
  active,
  children,
  notification,
  className,
  onClick,
}: PillChipProps) => {
  const chip = (
    <span
      className={twMerge(
        'rounded-full px-3 py-1 w-max flex',
        variant === 'one'
          ? active
            ? 'bg-blue-200 text-blue dark:bg-background-dark-4 dark:text-black'
            : 'text-black dark:text-text-dark-2'
          : '',
        variant === 'two'
          ? active
            ? 'bg-white text-blue dark:bg-background-dark-4 dark:text-black'
            : 'text-white dark:text-text-dark-2'
          : '',
        variant === 'three'
          ? active
            ? 'bg-blue-200 text-blue dark:bg-background-dark-4 dark:text-black'
            : 'text-blue-100 dark:text-text-dark-2'
          : '',
        size === 'md' ? 'text-web-sm' : 'text-web-xs',
        className
      )}
    >
      <span className="flex">
        <span
          className={twMerge('whitespace-nowrap', active ? 'font-medium' : '')}
        >
          {children}
        </span>
        {notification && (
          <PillChipNotification size={size} variant={variant} active={active} />
        )}
      </span>
    </span>
  );

  if (onClick) {
    return (
      <button className="rounded-full" onClick={onClick}>
        {chip}
      </button>
    );
  }

  return chip;
};
