import { gql, useQuery } from '@apollo/client';

import { useFiatMarket } from '../../hooks/useFiatMarket';
import { PriceIndexQuery, PriceIndexQueryVariables, Symbol } from '../gqlTypes';

const QUERY = gql`
  query PriceIndex($symbol: ID!) {
    priceIndex(symbol: $symbol) {
      rate
    }
  }
`;

export const usePriceIndex = (currency?: Symbol, skip?: boolean) => {
  const fiatMarket = useFiatMarket();
  const market = `${currency}${fiatMarket.symbol}`;

  return useQuery<PriceIndexQuery, PriceIndexQueryVariables>(QUERY, {
    variables: {
      symbol: market,
    },
    fetchPolicy: 'network-only',
    skip: skip || !currency,
  });
};
