import { urlAliases as aliases } from '../rewrites';

const urlAliases: Record<string, Record<string, string>> = aliases;

export { urlAliases };

const validSomeLocales = ['no', 'dk'] as const;
type SomeLocale = typeof validSomeLocales[number];

export const soMeUrls: Record<SomeLocale, {}> = {
  no: {
    Facebook: 'https://www.facebook.com/firiapp/',
    Linkedin: 'https://www.linkedin.com/company/firias',
    Twitter: 'https://twitter.com/firiapp',
    Instagram: 'https://www.instagram.com/firiapp/',
  },
  dk: {
    Facebook: 'https://www.facebook.com/FiriDanmark ',
    Linkedin: 'https://www.linkedin.com/company/firias',
    Twitter: 'https://twitter.com/firi_dk',
    Instagram: 'https://www.instagram.com/firi.dk',
  },
};

export const isSomeLocale = (locale?: string): locale is SomeLocale => {
  if (!locale) return false;
  return validSomeLocales.includes(locale as any);
};
