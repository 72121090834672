import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { addition } from '../../utils/numbers';
import { AccountType } from '../gqlTypes';
import { getTotalAccountValues } from './../../utils/getTotalAccountValues';
import { AccountsQuery, FiatCurrency } from './../gqlTypes';

const QUERY = gql`
  query Accounts {
    account {
      accounts: accountGroups {
        id
        uuid
        name
        type
        totalFiatValue {
          currency
          value
          valueWithFiat
        }
        accounts {
          id
          currency
          currencyName
          balance
          hold
          available
          fiatValue {
            currency
            balance
            hold
            available
          }
          fiatValues {
            currency
            balance
            hold
            available
          }
        }
        ... on SavingAccountGroup {
          savingsAgreement {
            id
            accountGroupId
            amount
            recurring
            savingDetails {
              id
              currency
              distribution
            }
          }
        }
      }
    }
  }
`;

export const useAccounts = (options?: QueryHookOptions<AccountsQuery>) => {
  const res = useQuery<AccountsQuery>(QUERY, {
    fetchPolicy: options?.fetchPolicy,
    skip: options?.skip,
  });
  const mainAccount = res.data?.account?.accounts?.find((ag) => ag.type === AccountType.Current);
  const savingsAccount = res.data?.account?.accounts?.find((a) => a.type === AccountType.Savings);
  const stakingAccount = res.data?.account?.accounts?.find((a) => a.type === AccountType.Staking);

  const totalAccountValues = useMemo(() => {
    return getTotalAccountValues(res.data?.account.accounts);
  }, [res.data?.account.accounts]);

  const totalFiatValues = useMemo(
    () =>
      res.data?.account.accounts.reduce(
        (acc, curr) => {
          const newTotal = {
            currency: curr.totalFiatValue.currency,
            value: addition(acc.value, curr.totalFiatValue.value),
            valueWithFiat: addition(acc.valueWithFiat, curr.totalFiatValue.valueWithFiat),
          };
          return newTotal;
        },
        {
          currency: FiatCurrency.Nok,
          value: '0',
          valueWithFiat: '0',
        },
      ),
    [res.data?.account.accounts],
  );

  return {
    ...res,
    totalAccountValues,
    totalFiatValues,
    mainAccount,
    savingsAccount,
    savingAgreementExists:
      savingsAccount?.__typename === 'SavingAccountGroup' && savingsAccount.savingsAgreement,
    savingsAgreement:
      savingsAccount?.__typename === 'SavingAccountGroup'
        ? savingsAccount.savingsAgreement
        : undefined,
    savingsAccountExists: !!savingsAccount,
    stakingAccount,
    stakingAccountExists: !!stakingAccount,
  };
};
