import * as React from 'react';
import { SVGProps } from 'react';
const SvgCloud = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M12.6572 21L12.6574 11.8896L11.1574 11.8896L11.1572 21L12.6572 21Z" />
    <path d="M7.75391 14.5323L10.847 11.4395C11.1305 11.156 11.507 11 11.9075 11C12.308 11 12.6845 11.156 12.968 11.4395L16.0608 14.5323L15.0003 15.5928L11.9075 12.5L8.81441 15.5928L7.75391 14.5323Z" />
    <path d="M17.4896 9.30903L17.4183 10.3654L18.3898 10.7864C19.6342 11.3256 20.5 12.5634 20.5 14C20.5 15.6915 19.2985 17.1058 17.7016 17.43L17.3566 17.5H16V19H18C20.2822 18.5367 22 16.419 22 14C22 11.9443 20.7595 10.1784 18.9862 9.41005C18.9954 9.27455 19 9.13782 19 9C19 5.68629 16.3137 3 13 3C10.0215 3 7.5499 5.1703 7.08033 8.01577C4.23883 8.23017 2 10.6036 2 13.5C2 16.369 4.19675 18.7474 7 19H8V17.5H7.31625L7.13463 17.4836C5.09749 17.3001 3.5 15.5857 3.5 13.5C3.5 11.3942 5.12816 9.66733 7.19319 9.51152L8.36842 9.42285L8.56032 8.26C8.9123 6.12707 10.7676 4.5 13 4.5C15.4853 4.5 17.5 6.51472 17.5 9C17.5 9.10409 17.4965 9.20713 17.4896 9.30903Z" />
  </svg>
);
export default SvgCloud;
