import { gql, useLazyQuery } from '@apollo/client';

import { ValidateAddressQuery, ValidateAddressQueryVariables } from '../gqlTypes';

const QUERY = gql`
  query ValidateAddress($address: String!, $currency: Symbol, $unlistedCurrency: String) {
    transfers {
      validateAddress(address: $address, currency: $currency, unlistedCurrency: $unlistedCurrency) {
        valid
        error
      }
    }
  }
`;

export const useValidateAddress = () => {
  const [validateAddress, { data }] = useLazyQuery<
    ValidateAddressQuery,
    ValidateAddressQueryVariables
  >(QUERY, { fetchPolicy: 'no-cache' });

  return { validateAddress, valid: !!data?.transfers?.validateAddress.valid };
};
