import * as React from 'react';
import { SVGProps } from 'react';
const SvgMoreVertical = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.25 2.75012C6.25 1.78512 7.035 1.00012 8 1.00012C8.965 1.00012 9.75 1.78512 9.75 2.75012C9.75 3.71512 8.965 4.50012 8 4.50012C7.035 4.50012 6.25 3.71512 6.25 2.75012ZM8.75 2.75012C8.75 2.33662 8.4135 2.00012 8 2.00012C7.5865 2.00012 7.25 2.33662 7.25 2.75012C7.25 3.16362 7.5865 3.50012 8 3.50012C8.4135 3.50012 8.75 3.16362 8.75 2.75012Z"
      fill="currentColor"
    />
    <path
      d="M6.25 8.00012C6.25 7.03512 7.035 6.25012 8 6.25012C8.965 6.25012 9.75 7.03512 9.75 8.00012C9.75 8.96512 8.965 9.75012 8 9.75012C7.035 9.75012 6.25 8.96512 6.25 8.00012ZM8.75 8.00012C8.75 7.58662 8.4135 7.25012 8 7.25012C7.5865 7.25012 7.25 7.58662 7.25 8.00012C7.25 8.41362 7.5865 8.75012 8 8.75012C8.4135 8.75012 8.75 8.41362 8.75 8.00012Z"
      fill="currentColor"
    />
    <path
      d="M6.25 13.2501C6.25 12.2851 7.035 11.5001 8 11.5001C8.965 11.5001 9.75 12.2851 9.75 13.2501C9.75 14.2151 8.965 15.0001 8 15.0001C7.035 15.0001 6.25 14.2151 6.25 13.2501ZM8.75 13.2501C8.75 12.8366 8.4135 12.5001 8 12.5001C7.5865 12.5001 7.25 12.8366 7.25 13.2501C7.25 13.6636 7.5865 14.0001 8 14.0001C8.4135 14.0001 8.75 13.6636 8.75 13.2501Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMoreVertical;
