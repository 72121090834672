import * as React from 'react';
import { SVGProps } from 'react';
const SvgNoCredit = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.46967 2.46967C2.76256 2.17678 3.23744 2.17678 3.53033 2.46967L21.5303 20.4697C21.8232 20.7626 21.8232 21.2374 21.5303 21.5303C21.2374 21.8232 20.7626 21.8232 20.4697 21.5303L2.46967 3.53033C2.17678 3.23744 2.17678 2.76256 2.46967 2.46967Z"
      fill="#DA2D43"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.75 23.25L19.25 21.75L3 21.75L3 17.25H1.5V21.75C1.5 22.5772 2.17275 23.25 3 23.25L20.75 23.25ZM21 18.5V17.25H22.5V20L21 18.5ZM18.8415 16.3415L17.7774 15.2774C18.8529 13.9791 19.5 12.3137 19.5 10.5C19.5 6.3645 16.1355 3 12 3C10.1863 3 8.52093 3.64712 7.2226 4.7226L6.15847 3.65847C7.73165 2.31327 9.77261 1.5 12 1.5C16.9628 1.5 21 5.53725 21 10.5C21 12.7274 20.1867 14.7683 18.8415 16.3415ZM3.95912 6.45912C3.3457 7.67485 3 9.0478 3 10.5C3 15.4627 7.03725 19.5 12 19.5C13.4522 19.5 14.8251 19.1543 16.0409 18.5409L14.9117 17.4117C14.016 17.7905 13.032 18 12 18C7.8645 18 4.5 14.6355 4.5 10.5C4.5 9.46798 4.70953 8.48397 5.08828 7.58828L3.95912 6.45912ZM7.7499 10.2499L6.96901 11.0302L10.9388 15C11.4268 15.488 12.17 15.5689 12.7427 15.2427L7.7499 10.2499ZM15.2802 12.7802L17.0303 11.0302L15.9698 9.96975L14.2197 11.7197L15.2802 12.7802ZM12.75 10.25L12.75 5.25H11.25V8.75L12.75 10.25Z"
      fill="#12123B"
    />
  </svg>
);
export default SvgNoCredit;
