import * as React from 'react';
import { SVGProps } from 'react';
const SvgAdvancedTradeFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#474AEE" {...props}>
    <path d="M5.75 8H6.5C7.32725 8 8 8.67275 8 9.5V15.5C8 16.3284 7.32843 17 6.5 17H5.75V18.5C5.75 18.7761 5.52614 19 5.25 19H4.75C4.47386 19 4.25 18.7761 4.25 18.5V17H3.5C2.67157 17 2 16.3284 2 15.5V9.5C2 8.67275 2.67275 8 3.5 8H4.25V4.5C4.25 4.22386 4.47386 4 4.75 4H5.25C5.52614 4 5.75 4.22386 5.75 4.5V8Z" />
    <path d="M11.25 5.5V4.5C11.25 4.22386 11.4739 4 11.75 4H12.25C12.5261 4 12.75 4.22386 12.75 4.5V5.5H13.5C14.3273 5.5 15 6.17275 15 7V13C15 13.8284 14.3284 14.5 13.5 14.5H12.75V17C12.75 17.2761 12.5261 17.5 12.25 17.5H11.75C11.4739 17.5 11.25 17.2761 11.25 17V14.5H10.5C9.67157 14.5 9 13.8284 9 13V7C9 6.17275 9.67275 5.5 10.5 5.5H11.25Z" />
    <path d="M19.25 6C19.5261 6 19.75 6.22386 19.75 6.5L19.75 9.5H20.5C21.3284 9.5 22 10.1716 22 11L22 17C22 17.8272 21.3273 18.5 20.5 18.5H19.75L19.75 19.5C19.75 19.7761 19.5261 20 19.25 20H18.75C18.4739 20 18.25 19.7761 18.25 19.5L18.25 18.5H17.5C16.6727 18.5 16 17.8272 16 17V11C16 10.1716 16.6716 9.5 17.5 9.5H18.25L18.25 6.5C18.25 6.22386 18.4739 6 18.75 6H19.25Z" />
  </svg>
);
export default SvgAdvancedTradeFilled;
