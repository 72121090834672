import axios, { AxiosResponse } from 'axios';
import Fingerprint2 from 'fingerprintjs2';
import { useState } from 'react';

import { ClientCountry } from '../../constants';
import { fingerprint as fp } from '../../utils/fingerprint';
import { getAuthBackendUrl } from '../getAuthBackendUrl';
import { AuthErrorResponse, AuthResponse, Client, Environment, ValidateNinInput } from '../types';

const BASE_URL = '/v3/bankid/user';

type BankIdParams = {
  client: Client;
  environment: Environment;
  uuid?: string; // only created from mobile apps
};

export type BankIdResponse = {
  url: string;
  id?: string;
};

export const initiateBankId = async (
  params: BankIdParams & {
    clientCountry: ClientCountry;
    redirectUrl: string;
    flow: 'redirect' | 'app-to-app';
    deviceOs?: 'android' | 'ios';
  },
) => {
  const authUrl = getAuthBackendUrl(params.environment);
  return axios.request<BankIdResponse>({
    method: 'get',
    url: authUrl + BASE_URL + '/initiate',
    headers: {
      'miraiex-client': params.client,
      'miraiex-frontend-env': params.environment,
      'firi-bankid-redirect-url': params.redirectUrl,
      'firi-bankid-flow': params.flow,
      'firi-client-uuid': params.uuid,
      ...(params.deviceOs ? { 'firi-app-os': params.deviceOs } : {}),
      ...(params.clientCountry ? { 'firi-client-country': params.clientCountry } : {}),
    },
  });
};

export const checkBankIdStatus = async (params: {
  environment: Environment;
  requestId: string;
}) => {
  const authUrl = getAuthBackendUrl(params.environment);
  return axios.request<{ status: string }>({
    method: 'get',
    url: authUrl + BASE_URL + '/check' + `?requestId=${params.requestId}`,
  });
};

type LoginResponse = { session: string; sessionExpiration: string };

export const loginBankId = async (
  params: BankIdParams & {
    loginUuid: string;
    components?: Fingerprint2.Component[];
    clientCountry: ClientCountry;
  },
) => {
  const { client, environment, ...restParams } = params;
  const fingerprint = await fp.generate();
  const components = params.components || fingerprint.components;

  const authUrl = getAuthBackendUrl(params.environment);

  return axios.request<LoginResponse>({
    method: 'post',
    url: authUrl + BASE_URL + '/login',
    withCredentials: true,
    headers: {
      'miraiex-client': client,
      'miraiex-frontend-env': environment,
      'miraiex-fingerprint': fingerprint.fingerprint,
      'firi-client-uuid': params.uuid,
      components: JSON.stringify(components),
    },
    data: restParams,
  });
};

export type CreateBankIdInput = BankIdParams & {
  stateUuid: string;
  address: string;
  postalArea: string;
  city: string;
  country: string;
  email: string;
  affiliate?: string;
  components?: Fingerprint2.Component[];
  clientCountry: ClientCountry;
  nin?: string;
  onLogReqId?: (reqId: string) => void;
};
export const createBankId = async (params: CreateBankIdInput) => {
  const { client, environment, ...restParams } = params;
  const fingerprint = await fp.generate();
  const components = params.components || fingerprint.components;

  const authUrl = getAuthBackendUrl(params.environment);
  return axios
    .request<{
      session: string;
      sessionExpiration: string;
    }>({
      method: 'post',
      url: authUrl + BASE_URL + '/create',
      withCredentials: true,
      headers: {
        'miraiex-client': client,
        'miraiex-frontend-env': environment,
        'miraiex-fingerprint': fingerprint.fingerprint,
        'firi-client-uuid': params.uuid,
        components: JSON.stringify(components),
      },
      data: restParams,
    })
    .then((res) => {
      if (params.onLogReqId && res.headers['x-request-id']) {
        params.onLogReqId(res.headers['x-request-id']);
      }
      return res;
    });
};

export const validateNin = async (
  params: ValidateNinInput,
): Promise<AxiosResponse<AuthResponse, any> | AuthErrorResponse | undefined> => {
  const authUrl = getAuthBackendUrl(params.environment);
  return axios.request<AuthResponse>({
    method: 'post',
    url: authUrl + BASE_URL + '/validate',
    headers: {
      'miraiex-client': params.client,
      'MIRAIEX-FRONTEND-ENV': params.environment,
      ...(params.clientCountry ? { 'FIRI-CLIENT-COUNTRY': params.clientCountry } : {}),
    },
    withCredentials: true,
    data: params,
  });
};

export function useValidateNin() {
  const [isLoading, setIsLoading] = useState(false);

  async function handleValidateNin(input: ValidateNinInput) {
    setIsLoading(true);

    return await validateNin(input).finally(() => setIsLoading(false));
  }

  return { validateNin: handleValidateNin, isLoading };
}
