import { gql, useMutation } from '@apollo/client';

import { HeyLinkConversionMutation, HeyLinkConversionMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation HeyLinkConversion($input: HeyLinkInput!) {
    heyLinkConversion(input: $input) {
      success
    }
  }
`;

export const useHeyLinkMutation = () =>
  useMutation<HeyLinkConversionMutation, HeyLinkConversionMutationVariables>(mutation);
