import { gql, useMutation } from '@apollo/client';

import { RedeemVoucherMutation, RedeemVoucherMutationVariables } from '../gqlTypes';

const REDEEM_VOUCHER_QUERY = gql`
  mutation RedeemVoucher($id: String!) {
    redeemVoucher(id: $id) {
      amount
      currency
      error
    }
  }
`;

export const useRedeemVoucher = () => {
  return useMutation<RedeemVoucherMutation, RedeemVoucherMutationVariables>(REDEEM_VOUCHER_QUERY);
};
