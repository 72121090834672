import * as React from 'react';
import { SVGProps } from 'react';
const SvgEasterEgg = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.99951 13C5.10408 13 5.99951 12.1046 5.99951 11C5.99951 9.89543 5.10408 9 3.99951 9C2.89494 9 1.99951 9.89543 1.99951 11C1.99951 12.1046 2.89494 13 3.99951 13ZM3.99951 12C4.5518 12 4.99951 11.5523 4.99951 11C4.99951 10.4477 4.5518 10 3.99951 10C3.44723 10 2.99951 10.4477 2.99951 11C2.99951 11.5523 3.44723 12 3.99951 12Z"
      fill="#12123B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99951 15C9.99951 16.1046 9.10408 17 7.99951 17C6.89494 17 5.99951 16.1046 5.99951 15C5.99951 13.8954 6.89494 13 7.99951 13C9.10408 13 9.99951 13.8954 9.99951 15ZM8.99951 15C8.99951 15.5523 8.5518 16 7.99951 16C7.44723 16 6.99951 15.5523 6.99951 15C6.99951 14.4477 7.44723 14 7.99951 14C8.5518 14 8.99951 14.4477 8.99951 15Z"
      fill="#12123B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99951 9C9.10408 9 9.99951 8.10457 9.99951 7C9.99951 5.89543 9.10408 5 7.99951 5C6.89494 5 5.99951 5.89543 5.99951 7C5.99951 8.10457 6.89494 9 7.99951 9ZM7.99951 8C8.5518 8 8.99951 7.55228 8.99951 7C8.99951 6.44772 8.5518 6 7.99951 6C7.44723 6 6.99951 6.44772 6.99951 7C6.99951 7.55228 7.44723 8 7.99951 8Z"
      fill="#12123B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9995 11C13.9995 12.1046 13.1041 13 11.9995 13C10.8949 13 9.99951 12.1046 9.99951 11C9.99951 9.89543 10.8949 9 11.9995 9C13.1041 9 13.9995 9.89543 13.9995 11ZM12.9995 11C12.9995 11.5523 12.5518 12 11.9995 12C11.4472 12 10.9995 11.5523 10.9995 11C10.9995 10.4477 11.4472 10 11.9995 10C12.5518 10 12.9995 10.4477 12.9995 11Z"
      fill="#12123B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99951 20C12.4178 20 15.9995 16.4183 15.9995 12C15.9995 7.58172 12.4178 0 7.99951 0C3.58123 0 -0.000488281 7.58172 -0.000488281 12C-0.000488281 16.4183 3.58123 20 7.99951 20ZM7.99951 18.5C11.5894 18.5 14.4995 15.5899 14.4995 12C14.4995 10.1145 13.7073 7.37571 12.3653 5.10676C11.7021 3.98543 10.9473 3.05312 10.166 2.41656C9.38617 1.78118 8.65799 1.5 7.99951 1.5C7.34103 1.5 6.61285 1.78118 5.83303 2.41656C5.05175 3.05312 4.29696 3.98543 3.63374 5.10676C2.29176 7.37571 1.49951 10.1145 1.49951 12C1.49951 15.5899 4.40966 18.5 7.99951 18.5Z"
      fill="#12123B"
    />
  </svg>
);
export default SvgEasterEgg;
