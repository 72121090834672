import { gql, useQuery } from '@apollo/client';

import { PnlPeriod, PriceHistoryQuery, PriceHistoryQueryVariables } from '../gqlTypes';

const query = gql`
  query PriceHistory($market: ID!, $period: PNLPeriod!) {
    market: market_v2(symbol: $market) {
      symbol
      baseCurrency {
        symbol
        name
      }
      priceHistory(period: $period) {
        change
        data {
          date
          price
        }
      }
    }
  }
`;
export const usePriceHistory = (params: { market: string; period?: PnlPeriod }) => {
  const res = useQuery<PriceHistoryQuery, PriceHistoryQueryVariables>(query, {
    variables: {
      period: params.period || PnlPeriod.Week,
      market: params.market,
    },
    skip: !params.period,
    fetchPolicy: 'cache-and-network',
  });

  const priceHistory = res.data?.market?.priceHistory;
  return { priceHistory, loading: res.loading };
};
