import * as React from 'react';
import { SVGProps } from 'react';
const SvgSek = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 23.9998C18.6274 23.9998 24 18.6272 24 11.9999C24 5.37253 18.6274 0 12 0C5.37258 0 0 5.37253 0 11.9999C0 18.6272 5.37258 23.9998 12 23.9998Z"
      fill="#004B87"
    />
    <path
      d="M0.413574 15.1306C1.10789 17.7068 2.63812 19.9396 4.69584 21.5206V15.1306H0.413574Z"
      fill="#004B87"
    />
    <path
      d="M10.9526 23.9543C11.2966 23.9839 11.6445 23.9999 11.9961 23.9999C17.5405 23.9999 22.2057 20.2396 23.5827 15.1305H10.9526V23.9543Z"
      fill="#004B87"
    />
    <path
      d="M23.5827 8.86946C22.2057 3.76032 17.5405 0 11.9961 0C11.6445 0 11.2966 0.0160311 10.9526 0.0456558V8.86946H23.5827Z"
      fill="#004B87"
    />
    <path
      d="M4.69584 2.47961C2.63812 4.06069 1.10789 6.29346 0.413574 8.86964H4.69584V2.47961Z"
      fill="#004B87"
    />
    <path
      d="M23.8984 10.4349H9.39136L9.39131 0.2854C8.28234 0.531304 7.23098 0.929878 6.26086 1.45923V10.4348L0.101578 10.4348C0.0347813 10.9472 0 11.4696 0 12C0 12.5305 0.0347813 13.0529 0.101578 13.5652H6.26081L6.26086 22.5408C7.23098 23.0701 8.28234 23.4688 9.39131 23.7146V13.5654L23.8984 13.5653C23.9651 13.0529 24 12.5305 24 12C24 11.4696 23.9651 10.9472 23.8984 10.4349Z"
      fill="#FFC200"
    />
  </svg>
);
export default SvgSek;
