import * as React from 'react';
import { SVGProps } from 'react';
const SvgUsdc = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 24C18.65 24 24 18.65 24 12C24 5.34996 18.65 0 12 0C5.34996 0 0 5.34996 0 12C0 18.65 5.34996 24 12 24Z"
      fill="#2775CA"
    />
    <path
      d="M15.3001 13.9C15.3001 12.15 14.2501 11.55 12.1501 11.3001C10.6501 11.1 10.3501 10.7001 10.3501 9.99998C10.3501 9.2999 10.8501 8.85002 11.8501 8.85002C12.7501 8.85002 13.2501 9.15002 13.5001 9.90002C13.5501 10.05 13.7001 10.15 13.8501 10.15H14.65C14.8501 10.15 15.0001 9.99998 15.0001 9.80006V9.75002C14.8 8.64998 13.9 7.80002 12.7501 7.70006V6.50006C12.7501 6.30002 12.6001 6.15002 12.3501 6.09998H11.6001C11.4001 6.09998 11.2501 6.24998 11.2 6.50006V7.65002C9.70002 7.85006 8.7501 8.85002 8.7501 10.1001C8.7501 11.7501 9.75006 12.4 11.8501 12.6501C13.2501 12.9 13.7001 13.2 13.7001 14.0001C13.7001 14.8001 13 15.3501 12.0501 15.3501C10.75 15.3501 10.3 14.8 10.15 14.05C10.1001 13.8501 9.9501 13.75 9.8001 13.75H8.95002C8.7501 13.75 8.6001 13.9 8.6001 14.1V14.1501C8.80002 15.4 9.60006 16.3 11.2501 16.5501V17.7501C11.2501 17.95 11.4001 18.1 11.65 18.15H12.4C12.6001 18.15 12.7501 18 12.8001 17.7501V16.5501C14.3001 16.3 15.3001 15.25 15.3001 13.9Z"
      fill="white"
    />
    <path
      d="M9.45 19.1499C5.55 17.75 3.54996 13.4 5.00004 9.54992C5.75004 7.44992 7.40004 5.84996 9.45 5.09996C9.65004 5 9.75 4.85 9.75 4.59992V3.89996C9.75 3.69992 9.65004 3.54992 9.45 3.5C9.39996 3.5 9.3 3.5 9.24996 3.54992C4.5 5.04992 1.89996 10.1 3.39996 14.85C4.29996 17.6499 6.45 19.8 9.24996 20.7C9.45 20.7999 9.65004 20.7 9.69996 20.4999C9.75 20.45 9.75 20.4 9.75 20.3V19.5999C9.75 19.4499 9.6 19.25 9.45 19.1499ZM14.75 3.54992C14.55 3.44996 14.35 3.54992 14.3 3.74996C14.25 3.8 14.25 3.84992 14.25 3.95V4.64996C14.25 4.85 14.4 5.04992 14.55 5.15C18.45 6.54992 20.45 10.8999 19 14.75C18.25 16.85 16.6 18.45 14.55 19.2C14.35 19.2999 14.25 19.4499 14.25 19.7V20.4C14.25 20.6 14.35 20.75 14.55 20.7999C14.6 20.7999 14.7 20.7999 14.75 20.75C19.5 19.25 22.1 14.1999 20.6 9.44996C19.7 6.59996 17.5 4.44992 14.75 3.54992Z"
      fill="white"
    />
  </svg>
);
export default SvgUsdc;
