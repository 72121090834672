import { gql } from '@apollo/client';

export const KycFormFragment = gql`
  fragment KycFormFragment on KycFormResponse {
    id
    title
    prologue
    hasUpload
    completedOn {
      seconds
      nanos
    }
    questions {
      id
      order
      isFreeText
      answered
      translationKey
      title
      required
      answers {
        id
        answerText
        translationKey
        subsequentquestionAnswerId
      }
    }
  }
`;
