import { LandingTypography, LandingTypographyProps } from '@firi/ui-web';
import clsx from 'clsx';
import React from 'react';

export const Typography = React.forwardRef(
  ({ children, className, size, ...props }: LandingTypographyProps, ref) => (
    <LandingTypography
      ref={ref}
      className={clsx(
        'break-words',
        {
          'pb-[2px]': ['3xl', '2xl', 'xl'].includes(size || ''),
        },
        className,
      )}
      {...props}
      size={size}
    >
      {children}
    </LandingTypography>
  ),
);
