import { gql, useQuery } from '@apollo/client';

import { BankAccountsQuery } from './../gqlTypes';

const query = gql`
  query BankAccounts {
    bankAccounts {
      id
      iban
      swiftbic
      displayName
      accountNumber
      routingNumber
    }
  }
`;

/**
 * Hook query for bank accounts and FIAT withdraw limit
 * @returns List of bank accounts with corresponding FIAT withdraw limit
 */
export const useBankAccounts = () => useQuery<BankAccountsQuery>(query);
