import { gql, useMutation } from '@apollo/client';

import {
  InvalidateBankIdSessionMutation,
  InvalidateBankIdSessionMutationVariables,
} from '../gqlTypes';

const mutation = gql`
  mutation InvalidateBankIdSession($input: InvalidateBankIdSessionInput!) {
    invalidateBankIdSession(input: $input) {
      success
    }
  }
`;

export const useInvalidateBankIdSession = () => {
  return useMutation<InvalidateBankIdSessionMutation, InvalidateBankIdSessionMutationVariables>(
    mutation,
  );
};
