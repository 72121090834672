import { gql, useMutation } from '@apollo/client';

import { CreateVoucherBankIdMutation, CreateVoucherBankIdMutationVariables } from '../gqlTypes';

const CREATE_VOUCHER = gql`
  mutation CreateVoucherBankId($input: CreateVoucherBankIdInput!) {
    createVoucherBankId(input: $input) {
      success
      message
      id
    }
  }
`;

export const useCreateVoucherBankId = () => {
  return useMutation<CreateVoucherBankIdMutation, CreateVoucherBankIdMutationVariables>(
    CREATE_VOUCHER,
  );
};
