import * as React from 'react';
import { SVGProps } from 'react';
const SvgMitId = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 152 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M151.983 22.2049C151.839 23.0724 151.716 23.9429 151.545 24.8044C150.023 32.507 145.25 37.1808 137.815 39.135C135.251 39.8104 132.544 40.0385 129.887 40.2276C127.369 40.4077 124.829 40.2666 122.227 40.2666V0.718125C122.299 0.679102 122.35 0.628072 122.401 0.628072C127.663 0.604057 132.94 0.315886 138.097 1.78976C146.274 4.12515 151.035 9.83154 151.836 18.3146C151.851 18.4827 151.932 18.6418 151.983 18.8069V22.2049Z"
      fill="white"
    />
    <path
      d="M0 1.99609C2.23333 2.0051 4.46666 2.03812 6.69998 2.0111C7.27633 2.0051 7.60052 2.1822 7.9127 2.6715C11.4308 8.19478 14.9729 13.7031 18.512 19.2113C18.6681 19.4545 18.8422 19.6856 19.0643 20.0008C20.4241 17.8695 21.7359 15.8163 23.0477 13.7631C25.4011 10.0739 27.7635 6.39071 30.0959 2.68951C30.4021 2.20322 30.7203 1.9991 31.3056 2.0081C33.4459 2.04412 35.5892 2.02311 37.8015 2.02311V40.1848H29.9578V16.1615L29.7357 16.0685C26.1876 21.4116 22.6395 26.7578 18.9773 32.2781C15.2731 26.7578 11.6679 21.3846 7.92471 15.8103V40.2688H0V1.99609Z"
      fill="white"
    />
    <path
      d="M117.234 40.2237H82.5781C82.8813 36.1923 83.8629 32.431 86.2763 29.1471C88.8939 25.587 92.511 23.6598 96.8246 23.1225C108.871 21.6186 116.384 28.3486 117.237 40.2237H117.234Z"
      fill="white"
    />
    <path
      d="M56.6875 12.408H60.869V4.93359H68.5475V12.36H74.5721V18.6998H68.6196C68.5986 19.15 68.5716 19.4712 68.5716 19.7894C68.5716 23.3946 68.5656 26.9967 68.5716 30.6019C68.5716 33.1834 69.9434 34.3721 72.5159 33.9518C73.3534 33.8138 74.1639 33.4956 75.0554 33.2404C75.0674 33.4596 75.0914 33.7387 75.0944 34.0149C75.0974 35.4437 75.0944 36.8756 75.0944 38.3044C75.0944 40.1685 75.1034 40.2106 73.3084 40.5438C71.1861 40.94 69.0488 40.991 66.9266 40.4777C63.4295 39.6312 61.2712 37.1578 60.9831 33.5586C60.869 32.1388 60.89 30.7039 60.884 29.2751C60.872 26.1472 60.881 23.0193 60.881 19.8915C60.881 19.5433 60.881 19.1921 60.881 18.7268H56.6875V12.405V12.408Z"
      fill="white"
    />
    <path
      d="M99.9319 19.2414C94.6938 19.2745 90.3262 14.9819 90.2932 9.77382C90.2601 4.32858 94.4987 0.00901173 99.8809 6.37885e-06C105.206 -0.00599719 109.481 4.22652 109.514 9.54568C109.55 14.8979 105.29 19.2084 99.9289 19.2414H99.9319Z"
      fill="white"
    />
    <path d="M44.7812 40.2205V12.457H52.4628V40.2205H44.7812Z" fill="white" />
    <path
      d="M48.6617 5.28561e-05C51.1982 0.01206 53.2694 2.0923 53.2544 4.60779C53.2364 7.1383 51.1501 9.19452 48.6226 9.17651C46.0801 9.1585 43.9879 7.06325 44.0239 4.56877C44.0599 2.02326 46.1251 -0.0119543 48.6617 5.28561e-05Z"
      fill="white"
    />
  </svg>
);
export default SvgMitId;
