import * as React from 'react';
import { SVGProps } from 'react';
const SvgAffiliate = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M15.75 5.25C15.75 3.18225 14.0677 1.5 12 1.5C9.93225 1.5 8.25 3.18225 8.25 5.25C8.25 7.31775 9.93225 9 12 9C14.0677 9 15.75 7.31775 15.75 5.25ZM12 7.5C10.7595 7.5 9.75 6.4905 9.75 5.25C9.75 4.0095 10.7595 3 12 3C13.2405 3 14.25 4.0095 14.25 5.25C14.25 6.4905 13.2405 7.5 12 7.5Z" />
    <path d="M10.5 15C10.5 13.4662 9.5745 12.1463 8.253 11.5658C8.2845 10.9725 8.775 10.5 9.375 10.5H14.25C14.9348 10.5 15.513 10.962 15.6915 11.5905C14.4 12.183 13.5 13.488 13.5 15C13.5 17.0677 15.1823 18.75 17.25 18.75C19.3178 18.75 21 17.0677 21 15C21 12.9323 19.3178 11.25 17.25 11.25C17.2185 11.25 17.187 11.2515 17.1555 11.2522C16.8225 9.9585 15.6465 9 14.25 9H12H9.375C8.055 9 6.95925 9.98025 6.777 11.2507C6.768 11.2507 6.759 11.25 6.75 11.25C4.68225 11.25 3 12.9323 3 15C3 17.0677 4.68225 18.75 6.75 18.75C8.81775 18.75 10.5 17.0677 10.5 15ZM19.5 15C19.5 16.2405 18.4905 17.25 17.25 17.25C16.0095 17.25 15 16.2405 15 15C15 13.7595 16.0095 12.75 17.25 12.75C18.4905 12.75 19.5 13.7595 19.5 15ZM6.75 17.25C5.5095 17.25 4.5 16.2405 4.5 15C4.5 13.7595 5.5095 12.75 6.75 12.75C7.9905 12.75 9 13.7595 9 15C9 16.2405 7.9905 17.25 6.75 17.25Z" />
    <path d="M19.5 18.75H17.25H14.625C13.1775 18.75 12 19.9275 12 21.375V21.75C12 20.0955 10.6545 18.75 9 18.75H6.75H4.125C2.6775 18.75 1.5 19.9275 1.5 21.375V22.5H3V21.375C3 20.7548 3.50475 20.25 4.125 20.25H9C9.82725 20.25 10.5 20.9227 10.5 21.75V22.5H12H13.5V21.375C13.5 20.7548 14.0048 20.25 14.625 20.25H19.5C20.3273 20.25 21 20.9227 21 21.75V22.5H22.5V21.75C22.5 20.0955 21.1545 18.75 19.5 18.75Z" />
  </svg>
);
export default SvgAffiliate;
