import * as React from 'react';
import { SVGProps } from 'react';
const SvgLtc = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.52345 12.345C4.61956 4.87166 11.9127 -0.000679986 20.002 7.11801e-08C25.3061 7.11801e-08 30.3929 2.10716 34.1433 5.85788C37.8937 9.6086 40.0004 14.6956 40 19.9997C40 28.089 35.127 35.3817 27.6535 38.4772C20.1799 41.5727 11.5775 39.8613 5.85777 34.141C0.138025 28.4208 -1.57265 19.8183 1.52345 12.345ZM27.8941 30.0055C27.9417 30.0052 27.9833 29.9738 27.9967 29.9282L29.0694 26.3721C29.0797 26.3393 29.0734 26.3036 29.0526 26.2763C29.0323 26.2492 29.0007 26.2331 28.9669 26.2326H18.5425L19.98 20.9733L23.2149 19.7375C23.2563 19.7211 23.2836 19.6811 23.2839 19.6366V17.4273C23.2838 17.3922 23.2666 17.3593 23.2378 17.3392C23.209 17.3191 23.1723 17.3144 23.1393 17.3264L20.7383 18.2007L22.9442 10.1302C22.9532 10.0978 22.9464 10.063 22.9259 10.0362C22.9055 10.0095 22.8737 9.99389 22.84 9.99405H16.4929C16.4433 9.99422 16.4003 10.0282 16.3886 10.0764L13.722 20.753L11.0066 21.7417C10.9643 21.7572 10.9361 21.7974 10.936 21.8426V24.2637C10.936 24.2994 10.9536 24.3327 10.9831 24.3528C11.0122 24.3728 11.0493 24.3772 11.0823 24.3646L13.0024 23.6315L11.4455 29.866C11.4378 29.8983 11.4452 29.9323 11.4656 29.9584C11.4843 29.9861 11.5148 30.0034 11.548 30.0055H27.8941Z"
      fill="#B5B5B5"
    />
  </svg>
);
export default SvgLtc;
