import * as React from 'react';
import { SVGProps } from 'react';
const Svg2FaDisabled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 19.5L17.5 21H14C13.4477 21 13 20.5523 13 20V16.5L14.5 18V19.5H16ZM20.5 19V9.5L14.5 9.5V13L13 11.5V9C13 8.44771 13.4477 8 14 8L21 8C21.5523 8 22 8.44772 22 9V20C22 20.1467 21.9684 20.2861 21.9116 20.4116L20.5 19ZM7 5.5H18.5V7L20 7V5C20 4.44772 19.5523 4 19 4H5.5L7 5.5ZM3.5 7L2 5.5V16C2 16.5523 2.44772 17 3 17H10.5V18H7.5V19.5H12V15.5H3.5V7Z"
      fill="#12123B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.21967 3.21967C2.51256 2.92678 2.98744 2.92678 3.28033 3.21967L21.2803 21.2197C21.5732 21.5126 21.5732 21.9874 21.2803 22.2803C20.9874 22.5732 20.5126 22.5732 20.2197 22.2803L2.21967 4.28033C1.92678 3.98744 1.92678 3.51256 2.21967 3.21967Z"
      fill="#DA2D43"
    />
  </svg>
);
export default Svg2FaDisabled;
