import { t } from '@lingui/macro';

import { ErrorResponse } from './types';

export const authErrorToText: { [key in ErrorResponse]: string } = {
  OtpRequired: t`One-time password is required.`,
  OtpUsed: t`The one-time password has already been used.`,
  WrongOtp: t`Verification failed. Are you sure you typed the correct code for Firi?`,
  SessionMissing: t`Your session has either expired or is invalid`,
  ReferralNotValid: t`Referral code is not valid`,
  SessionNotFound: t`Wrong username or password`,
  '3013': t`Something is wrong with your account. Please contact us and give error code: 3013 for further assistant: support@firi.com`,
  OrderFailed: t`Something went wrong. Unable to place your order. Try again?`,
};
