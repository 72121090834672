import { gql, useMutation } from '@apollo/client';

import {
  UpdateCustomerIoPushAttributesMutation,
  UpdateCustomerIoPushAttributesMutationVariables,
} from '../gqlTypes';

const mutation = gql`
  mutation UpdateCustomerIoPushAttributes($input: CustomerIoPushAttributes!) {
    updateCustomerIoPushAttributes(input: $input) {
      success
    }
  }
`;

export const useUpdateCustomerIoAttributes = () =>
  useMutation<
    UpdateCustomerIoPushAttributesMutation,
    UpdateCustomerIoPushAttributesMutationVariables
  >(mutation, { refetchQueries: ['CustomerIoUserAttributes'] });
