import * as React from 'react';
import { SVGProps } from 'react';
const SvgAlarm = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4 21.25C4 20.8358 4.33579 20.5 4.75 20.5H19.25C19.6642 20.5 20 20.8358 20 21.25V21.25C20 21.6642 19.6642 22 19.25 22H4.75C4.33579 22 4 21.6642 4 21.25V21.25Z"
      fill="#DA2D43"
    />
    <path
      d="M15.25 10V18.25H8.75V10C8.75 8.20507 10.2051 6.75 12 6.75C13.7949 6.75 15.25 8.20507 15.25 10Z"
      stroke="#DA2D43"
      strokeWidth={1.5}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.5C12.4142 1.5 12.75 1.83579 12.75 2.25V4.25C12.75 4.66421 12.4142 5 12 5C11.5858 5 11.25 4.66421 11.25 4.25V2.25C11.25 1.83579 11.5858 1.5 12 1.5Z"
      fill="#DA2D43"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4142 4.15837C18.7071 4.45126 18.7071 4.92614 18.4142 5.21903L17 6.63324C16.7071 6.92614 16.2322 6.92614 15.9393 6.63324C15.6464 6.34035 15.6464 5.86548 15.9393 5.57258L17.3536 4.15837C17.6464 3.86548 18.1213 3.86548 18.4142 4.15837Z"
      fill="#DA2D43"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.53044 4.15817C5.82333 3.86528 6.29821 3.86528 6.5911 4.15817L8.00531 5.57239C8.29821 5.86528 8.29821 6.34015 8.00531 6.63305C7.71242 6.92594 7.23755 6.92594 6.94465 6.63305L5.53044 5.21883C5.23755 4.92594 5.23755 4.45107 5.53044 4.15817Z"
      fill="#DA2D43"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5427 10C20.5427 10.4142 20.2069 10.75 19.7927 10.75L17.7927 10.75C17.3784 10.75 17.0427 10.4142 17.0427 10C17.0427 9.58579 17.3784 9.25 17.7927 9.25L19.7927 9.25C20.2069 9.25 20.5427 9.58579 20.5427 10Z"
      fill="#DA2D43"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 10C7 10.4142 6.66421 10.75 6.25 10.75L4.25 10.75C3.83579 10.75 3.5 10.4142 3.5 10C3.5 9.58579 3.83579 9.25 4.25 9.25L6.25 9.25C6.66421 9.25 7 9.58579 7 10Z"
      fill="#DA2D43"
    />
  </svg>
);
export default SvgAlarm;
