import * as React from 'react';
import { SVGProps } from 'react';
const SvgAvax = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_127_103)">
      <path d="M19.4011 4.11707H4.58276V17.5852H19.4011V4.11707Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9921 12C23.9921 18.6187 18.6231 23.9841 12.0001 23.9841C5.37706 23.9841 0.00805664 18.6187 0.00805664 12C0.00805664 5.38143 5.37706 0.0159912 12.0001 0.0159912C18.6231 0.0159912 23.9921 5.38143 23.9921 12ZM8.60188 16.7691H6.27456C5.78553 16.7691 5.54396 16.7691 5.39667 16.6749C5.23758 16.5718 5.14037 16.4011 5.12858 16.2126C5.11974 16.039 5.24054 15.827 5.4821 15.403L11.2285 5.28091C11.473 4.85108 11.5968 4.63617 11.7529 4.55668C11.9208 4.47131 12.1212 4.47131 12.2891 4.55668C12.4452 4.63617 12.569 4.85108 12.8135 5.28091L13.9948 7.34172L14.0008 7.35224C14.2649 7.81336 14.3988 8.0472 14.4573 8.29262C14.5221 8.56053 14.5221 8.84316 14.4573 9.11107C14.3984 9.35836 14.2658 9.59389 13.9977 10.062L10.9793 15.3942L10.9715 15.4079C10.7056 15.8728 10.5709 16.1084 10.3842 16.2862C10.1809 16.4806 9.93641 16.6218 9.66832 16.7014C9.4238 16.7691 9.14984 16.7691 8.60188 16.7691ZM14.4791 16.7691H17.8139C18.3059 16.7691 18.5534 16.7691 18.7008 16.6721C18.8598 16.569 18.9599 16.3952 18.9689 16.2069C18.9773 16.0389 18.8592 15.8351 18.6276 15.4358C18.6196 15.4222 18.6117 15.4084 18.6035 15.3943L16.9331 12.5386L16.9141 12.5064C16.6794 12.1098 16.5609 11.9095 16.4087 11.832C16.2409 11.7466 16.0433 11.7466 15.8755 11.832C15.7223 11.9115 15.5986 12.1205 15.3541 12.5415L13.6896 15.3972L13.6839 15.4071C13.4402 15.8274 13.3185 16.0374 13.3272 16.2098C13.339 16.3982 13.4362 16.5718 13.5953 16.6749C13.7397 16.7691 13.9871 16.7691 14.4791 16.7691Z"
        fill="#E84142"
      />
    </g>
    <defs>
      <clipPath id="clip0_127_103">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgAvax;
