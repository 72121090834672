import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ReactNode, useState } from 'react';
import type { PolymorphicPropsWithoutRef } from 'react-polymorphic-types';

import { Icon } from '../..';
import { WebTypography } from '../../01-atoms';

export const AccordionDefaultElement = 'article';

export interface AccordionProps {
  children: ReactNode;
  title: ReactNode | string;
  initialOpen?: boolean;
  arrowLeftSide?: boolean;
  noPadding?: boolean;
}

export type AccordionComponentProps<
  T extends React.ElementType = typeof AccordionDefaultElement
> = PolymorphicPropsWithoutRef<AccordionProps, T>;

export function Accordion<
  T extends React.ElementType = typeof AccordionDefaultElement
>({
  as,
  initialOpen = false,
  title,
  children,
  arrowLeftSide = false,
  noPadding = false,
  className,
}: AccordionComponentProps<T>) {
  const Element: React.ElementType = as || AccordionDefaultElement;

  const [expand, setExpand] = useState<boolean>(initialOpen);

  const handleClick = () => setExpand(!expand);

  return (
    <Element className={className}>
      <motion.div onClick={handleClick} initial={false}>
        <div
          className={clsx('flex justify-between items-center cursor-pointer', {
            'flex-row-reverse': arrowLeftSide,
          })}
        >
          <div className={clsx('flex-grow', arrowLeftSide ? 'pl-2' : 'pr-2')}>
            <WebTypography color="2">{title}</WebTypography>
          </div>
          <span
            className={clsx('transition-transform', expand ? 'rotate-180' : '')}
          >
            <Icon icon="chevron-down" size="md" />
          </span>
        </div>
      </motion.div>
      <AnimatePresence initial={false}>
        {expand && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
            className={clsx(
              'overflow-hidden transition-max-h',
              expand ? `max-h-[3000px]` : 'max-h-0'
            )}
          >
            <motion.div
              variants={{ collapsed: { scale: 0.9 }, open: { scale: 1 } }}
              transition={{ duration: 0.2 }}
              className={clsx(
                noPadding ? 'pt-0' : 'pt-4',
                arrowLeftSide ? 'pl-8' : 'pr-8'
              )}
            >
              {children}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </Element>
  );
}
