import { gql, useMutation } from '@apollo/client';

import { EditPurchasePriceMutation, EditPurchasePriceMutationVariables } from '../gqlTypes';

const MUTATION = gql`
  mutation EditPurchasePrice($input: EditPurchasePriceInput!) {
    editPurchasePrice(input: $input) {
      success
      message
    }
  }
`;

export const useEditPurchasePrice = () => {
  return useMutation<EditPurchasePriceMutation, EditPurchasePriceMutationVariables>(MUTATION, {
    refetchQueries: ['TaxReport'],
  });
};
