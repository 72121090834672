import * as React from 'react';
import { SVGProps } from 'react';
const SvgExternalLink = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M18 22.5H3C2.17275 22.5 1.5 21.8272 1.5 21V6C1.5 5.17275 2.17275 4.5 3 4.5H12V6H3V21H18V12H19.5V21C19.5 21.8272 18.8272 22.5 18 22.5Z" />
    <path d="M20.4695 2.46974L6.96954 15.9697L8.03019 17.0303L21.5301 3.53039L20.4695 2.46974Z" />
    <path d="M22.5 9.75H21V3H14.25V1.5H21C21.8272 1.5 22.5 2.17275 22.5 3V9.75Z" />
  </svg>
);
export default SvgExternalLink;
