import { gql, useMutation } from '@apollo/client';

import { UpdatePushPreferenceMutation, UpdatePushPreferenceMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation UpdatePushPreference($input: PushNotificationPreferenceInput!) {
    updatePushPreference(input: $input) {
      success
      message
    }
  }
`;

export const useUpdatePushPreference = () =>
  useMutation<UpdatePushPreferenceMutation, UpdatePushPreferenceMutationVariables>(mutation, {
    refetchQueries: ['PushPreferences'],
  });
