import { gql, useQuery } from '@apollo/client';

import { StakedAssetsQuery } from './../gqlTypes';

const query = gql`
  query StakedAssets {
    staking {
      stakedCurrencies {
        currency
        fiatCurrency
        name
        type
        minimumStaked
        lockPeriod
        rewardDelay
        epochLength
        apy
        price
        stats {
          active
          amount
          fiatValue
          earnings {
            amount
            fiatValue
            yield
          }
        }
        accounts {
          accountGroupId
          stakedAmount
        }
      }
    }
  }
`;

export const useStakedAssets = (options?: { skip?: boolean }) =>
  useQuery<StakedAssetsQuery>(query, options);
