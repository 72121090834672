import { gql, useMutation } from '@apollo/client';

import { CalculatePaymentFeeMutation, CalculatePaymentFeeMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation CalculatePaymentFee($input: CalculatePaymentFeeInput!) {
    calculatePaymentFee(input: $input) {
      success
      message
      amount
      currency
      feeAmount
      feePercent
      totalAmount
    }
  }
`;

export const useCalculatePaymentFee = () =>
  useMutation<CalculatePaymentFeeMutation, CalculatePaymentFeeMutationVariables>(mutation);
