import * as React from 'react';
import { SVGProps } from 'react';
const SvgUpdate = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0929 4.04015C15.4064 2.5638 13.2413 1.75 11 1.75V0.25C13.6048 0.25 16.121 1.19577 18.0809 2.91152C20.0408 4.62727 21.311 6.99627 21.6556 9.57819C22.0001 12.1601 21.3954 14.7793 19.954 16.9489C18.5125 19.1185 16.3323 20.6909 13.8186 21.3739C11.305 22.0569 8.62883 21.8039 6.28764 20.6621C3.94644 19.5203 2.09947 17.5672 1.09004 15.1659C0.0805987 12.7647 -0.0226358 10.0786 0.799521 7.60696C1.37301 5.88288 2.36956 4.34495 3.68243 3.12497H1V1.62497H5.69273C6.23992 1.62497 6.68549 2.06475 6.69265 2.61189L6.74994 6.99019L5.25006 7.00981L5.20791 3.78789C3.82818 4.89595 2.78734 6.38338 2.22284 8.08041C1.51541 10.2072 1.60424 12.5185 2.47282 14.5847C3.34141 16.6508 4.93066 18.3314 6.94517 19.3139C8.95969 20.2964 11.2624 20.5141 13.4253 19.9264C15.5883 19.3387 17.4642 17.9857 18.7046 16.1188C19.9449 14.2519 20.4652 11.9982 20.1687 9.77658C19.8723 7.55493 18.7793 5.51649 17.0929 4.04015Z"
      fill="#12123B"
    />
  </svg>
);
export default SvgUpdate;
