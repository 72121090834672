import { gql, useQuery } from '@apollo/client';

import { useFiatMarket } from '../../hooks/useFiatMarket';
import { CryptoCurrency, Symbol } from '../gqlTypes';
import { CurrencyQuery, CurrencyQueryVariables } from './../gqlTypes';

const QUERY = gql`
  query Currency($symbol: Symbol!) {
    currency: currency_v2(symbol: $symbol) {
      symbol
      name
      hasTag
      enabled {
        withdraw
        deposit
      }
    }
  }
`;

export const useCurrency = (params: { symbol?: Symbol | CryptoCurrency | null }) => {
  const fiatMarket = useFiatMarket();

  return useQuery<CurrencyQuery, CurrencyQueryVariables>(QUERY, {
    skip: !params.symbol,
    variables: {
      symbol: params.symbol ?? (fiatMarket.symbol as any),
    },
  });
};
