import * as React from 'react';
import { SVGProps } from 'react';
const SvgMastercard = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M17.7422 2.40379H10.2581V15.6691H17.7422V2.40379Z" fill="#FF5F00" />
    <path
      d="M10.7334 9.03647C10.7322 7.75892 11.0257 6.49785 11.5917 5.34873C12.1577 4.1996 12.9813 3.19255 14.0002 2.4038C12.7384 1.42556 11.2231 0.817212 9.62732 0.64828C8.03158 0.479348 6.41984 0.756645 4.97633 1.44849C3.53281 2.14033 2.31576 3.2188 1.46429 4.56063C0.61282 5.90245 0.161285 7.45349 0.161285 9.03647C0.161285 10.6194 0.61282 12.1705 1.46429 13.5123C2.31576 14.8541 3.53281 15.9326 4.97633 16.6245C6.41984 17.3163 8.03158 17.5936 9.62732 17.4247C11.2231 17.2557 12.7384 16.6474 14.0002 15.6691C12.9813 14.8804 12.1577 13.8733 11.5917 12.7242C11.0257 11.5751 10.7322 10.314 10.7334 9.03647Z"
      fill="#EB001B"
    />
    <path
      d="M27.8387 9.03647C27.8388 10.6194 27.3873 12.1705 26.5359 13.5123C25.6844 14.8541 24.4674 15.9326 23.0239 16.6244C21.5805 17.3163 19.9687 17.5936 18.373 17.4247C16.7773 17.2557 15.262 16.6474 14.0002 15.6691C15.0182 14.8796 15.8412 13.8724 16.4071 12.7234C16.973 11.5745 17.267 10.3138 17.267 9.03647C17.267 7.75912 16.973 6.49845 16.4071 5.3495C15.8412 4.20056 15.0182 3.19335 14.0002 2.4038C15.262 1.42556 16.7773 0.817208 18.373 0.648278C19.9687 0.479348 21.5805 0.756657 23.0239 1.44851C24.4674 2.14036 25.6844 3.21883 26.5359 4.56065C27.3873 5.90248 27.8388 7.45351 27.8387 9.03647Z"
      fill="#F79E1B"
    />
    <path
      d="M27.0227 14.2641V13.9926H27.1337V13.9372H26.8509V13.9926H26.962V14.2641H27.0227ZM27.5716 14.2641V13.9367H27.485L27.3853 14.1619L27.2856 13.9367H27.1988V14.2641H27.26V14.0171L27.3535 14.2301H27.417L27.5105 14.0166V14.2641H27.5716Z"
      fill="#F79E1B"
    />
  </svg>
);
export default SvgMastercard;
