import * as React from 'react';
import { SVGProps } from 'react';
const SvgPending = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 32 32" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M16 30C8.28 30 2 23.72 2 16C2 8.28 8.28 2 16 2C23.72 2 30 8.28 30 16C30 23.72 23.72 30 16 30ZM16 4C9.383 4 4 9.383 4 16C4 22.617 9.383 28 16 28C22.617 28 28 22.617 28 16C28 9.383 22.617 4 16 4Z" />
    <path d="M16.6667 17.3332L16.6667 6.6665L14.6667 6.6665L14.6667 17.3332L16.6667 17.3332Z" />
    <path d="M14.6667 17.3335L22.6667 17.3335L22.6667 15.3335L14.6667 15.3335L14.6667 17.3335Z" />
  </svg>
);
export default SvgPending;
