import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { SystemStatusEnum, SystemStatusQuery } from './../gqlTypes';

const QUERY = gql`
  query SystemStatus {
    system {
      health {
        status
        systemName
      }
    }
  }
`;

export const useSystemStatus = (options?: QueryHookOptions<SystemStatusQuery>) => {
  const { data } = useQuery<SystemStatusQuery>(QUERY, options);
  const internalSystemHealth = data?.system.health?.find(
    (h) => h.systemName === 'internal-backend',
  );

  const internalBackendIsDown = internalSystemHealth?.status === SystemStatusEnum.Down;
  const isMaintenance = internalSystemHealth?.status === SystemStatusEnum.Maintenance;

  return { internalBackendIsDown, isMaintenance };
};
