import * as React from 'react';
import { SVGProps } from 'react';
const SvgBellNotification = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#12123B" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.20326 16.9307L6.52416 13.5213V9.26778C6.52416 6.39927 8.56706 4.00815 11.2773 3.46925V2.15938C11.2773 1.51911 11.7963 1 12.4367 1C13.077 1 13.596 1.51911 13.5961 2.15938V3.4692C16.3063 4.00805 18.3492 6.39917 18.3492 9.26773V13.5213L20.6701 16.9306C20.9145 17.2897 20.9404 17.7546 20.7373 18.1386C20.5341 18.5226 20.1353 18.7628 19.7009 18.7628H5.17244C4.73799 18.7628 4.3392 18.5226 4.13607 18.1386C3.93293 17.7546 3.95879 17.2898 4.20326 16.9307ZM19.0817 17.2628L16.8492 13.9834V9.26773C16.8492 7.12876 15 4.94041 12.5 4.94041C10 4.94041 8.02416 7.12887 8.02416 9.26778V13.9834L5.79173 17.2628H19.0817Z"
    />
    <path d="M9.98877 20C9.98877 21.3807 11.1081 22.5 12.4888 22.5C13.8695 22.5 14.9888 21.3807 14.9888 20H13.4888C13.4888 20.5523 13.0411 21 12.4888 21C11.9365 21 11.4888 20.5523 11.4888 20H9.98877Z" />
    <circle cx={19} cy={5} r={4} fill="#DA2D43" />
  </svg>
);
export default SvgBellNotification;
