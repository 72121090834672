import * as React from 'react';
import { SVGProps } from 'react';
const SvgCloseCircleFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM16.5301 16.5301C16.2372 16.823 15.7623 16.823 15.4694 16.5301L12 13.0607L8.53044 16.5302C8.23755 16.8231 7.76268 16.8231 7.46978 16.5302C7.17689 16.2373 7.17689 15.7624 7.46978 15.4695L10.9393 12L7.46967 8.53039C7.17678 8.2375 7.17678 7.76262 7.46967 7.46973C7.76256 7.17684 8.23744 7.17684 8.53033 7.46973L12 10.9394L15.4695 7.46978C15.7624 7.17689 16.2373 7.17689 16.5302 7.46978C16.8231 7.76267 16.8231 8.23755 16.5302 8.53044L13.0606 12L16.5301 15.4695C16.823 15.7624 16.823 16.2373 16.5301 16.5301Z"
    />
  </svg>
);
export default SvgCloseCircleFilled;
