import { gql, useMutation } from '@apollo/client';

import { SetMetadataMutation, SetMetadataMutationVariables } from './../gqlTypes';

const mutation = gql`
  mutation SetMetadata($input: MetadataInput!) {
    setMetadata(input: $input) {
      success
    }
  }
`;

export const useSetMetadata = () =>
  useMutation<SetMetadataMutation, SetMetadataMutationVariables>(mutation, {
    refetchQueries: ['Metadata'],
    awaitRefetchQueries: true,
  });
