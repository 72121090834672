export type SendToGTMEvent = {
  event?: string;
  event_action?: string;
  event_value?: string;
  event_label: string;
  event_category: 'Interactions' | 'Identification';
  signup_method?: 'vipps' | 'email' | 'mitid';
};

export const sendGTMEvent = (event: SendToGTMEvent): void => {
  if (typeof window === 'undefined') return;
  if (!window.dataLayer) return;

  window.dataLayer.push(event);
};
