import React from 'react';

import LogoSvg from './logo-assets/logo.svg';
import LogoNoTextBlue from './logo-assets/logo-notext-blue.svg';
import LogoNoTextWhite from './logo-assets/logo-notext-white.svg';
import LogoWhite from './logo-assets/logo-white.svg';

export type LogoProps = {
  type: 'white' | 'blue' | 'whiteNoText' | 'blueNoText';
  height?: number;
  width?: number;
};

export const Logo = ({ type = 'white', ...props }: LogoProps) => {
  const LogoMap = {
    white: <LogoWhite height={props.height} width={props.width} />,
    blue: <LogoSvg height={props.height} width={props.width} />,
    whiteNoText: <LogoNoTextWhite height={props.height} width={props.width} />,
    blueNoText: <LogoNoTextBlue height={props.height} width={props.width} />,
  };
  return LogoMap[type];
};
