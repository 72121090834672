import * as React from 'react';
import { SVGProps } from 'react';
const SvgUsdt = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_347_1788)">
      <circle cx={16.5} cy={16.5} r={16.5} fill="#26A17B" />
    </g>
    <path
      d="M19.0089 18.5951V18.5924C18.8809 18.6007 18.2202 18.6367 16.75 18.6367C15.5744 18.6367 14.75 18.6062 14.4583 18.5924V18.5951C9.93747 18.4096 6.56545 17.6783 6.56545 16.8003C6.56545 15.9251 9.94045 15.1911 14.4583 15.0055V17.8667C14.7529 17.8861 15.6012 17.9332 16.7708 17.9332C18.1756 17.9332 18.8779 17.8778 19.0089 17.8667V15.0083C23.5208 15.1966 26.8839 15.9278 26.8839 16.8031C26.8839 17.6783 23.5178 18.4096 19.0089 18.5979M19.0089 14.7119V12.1526H25.3035V8.25H8.16664V12.1526H14.4613V14.7119C9.34521 14.9307 5.49997 15.8724 5.49997 17.0025C5.49997 18.1326 9.34818 19.0743 14.4613 19.2959V27.5H19.0119V19.2959C24.119 19.0771 27.9583 18.1354 27.9583 17.0053C27.9583 15.878 24.119 14.9335 19.0119 14.7147"
      fill="white"
    />
    <defs>
      <clipPath id="clip0_347_1788">
        <rect width={33} height={33} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgUsdt;
