import { gql, useLazyQuery } from '@apollo/client';

import { ProfilePictureUploadUrlQuery } from '../gqlTypes';

const query = gql`
  query ProfilePictureUploadUrl {
    currentUser {
      id
      profilePictureUploadUrl
    }
  }
`;

export const useProfilePictureUploadUrl = () => useLazyQuery<ProfilePictureUploadUrlQuery>(query);
