import * as React from 'react';
import { SVGProps } from 'react';
const SvgFormReceived = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3V2.5H7V3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H14C15.1046 21 16 20.1046 16 19V15H14.5V19.5H4.5V4.5H7V5H12V4.5H14.5V9H16V5C16 3.89543 15.1046 3 14 3H12ZM8 12V11.25H6V12.75H8V12L7.99999 12L8 12ZM12 7L10.5 8.5H6V7H12ZM10.5 15.5L12 17H6V15.5H10.5Z"
      fill="#12123B"
    />
    <path d="M13 10L13 8L9 12L13 16L13 14L20.5 14L20.5 10L13 10Z" fill="#1E856C" />
  </svg>
);
export default SvgFormReceived;
