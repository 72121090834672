export type SanityCategoryNames =
  | 'pressRelease'
  | 'insight'
  | 'cryptoInvestingSavings'
  | 'cryptocurrencyTax'
  | 'article'
  | 'learn'
  | 'coinInfo'
  | 'news'
  | 'crypto101'
  | 'how-to-invest'
  | 'why-invest'
  | 'what-to-invest-in'
  | 'staking';

export const sanityCategoryIds: Record<SanityCategoryNames, string> = {
  pressRelease: '201697ae-8410-4afd-a468-915390c17a2e',
  insight: 'e57aa81d-aad6-4f10-9d24-0e6d8751f834',
  cryptoInvestingSavings: '78aaf181-f674-496f-bfbd-00db8d945aac',
  cryptocurrencyTax: '1d2c61fd-63be-4bc1-a4eb-1777cac6d00d',
  article: 'c2df31d4-7f97-4712-a005-49c2ab898a0d',
  learn: '20d5fdfb-1a17-417f-ae9e-d84da7826968',
  coinInfo: '478db0ff-7799-4f05-a36c-c0b9177ca23a',
  news: 'a098b723-375b-482e-98af-00595a23ebf5',
  crypto101: 'ad15121f-791f-44a2-9f49-52fcb7065fdf',
  'why-invest': '00a5e4c3-95f1-45e8-8a1c-e369b95b8357',
  'how-to-invest': '72b58239-4636-45d5-b440-0f948d646d50',
  'what-to-invest-in': '12aa6ea2-9c06-4af6-94e4-4647128fca29',
  staking: '6c50f399-ad4f-4f2d-aa58-327ae2a480d7',
};

export const sanityCategorySlugs = {
  [sanityCategoryIds.article]: 'articles',
  [sanityCategoryIds.cryptocurrencyTax]: 'cryptocurrency-tax',
  [sanityCategoryIds.cryptoInvestingSavings]: 'crypto-investing-savings',
  [sanityCategoryIds.insight]: 'press/insight',
  [sanityCategoryIds.pressRelease]: 'press/press-release',
  [sanityCategoryIds.staking]: 'staking',
};
