import * as React from 'react';
import { SVGProps } from 'react';
const SvgDeposit1 = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M12 1.5C16.9628 1.5 21 5.53725 21 10.5C21 15.4627 16.9628 19.5 12 19.5C7.03725 19.5 3 15.4627 3 10.5C3 5.53725 7.03725 1.5 12 1.5ZM12 18C16.1355 18 19.5 14.6355 19.5 10.5C19.5 6.3645 16.1355 3 12 3C7.8645 3 4.5 6.3645 4.5 10.5C4.5 14.6355 7.8645 18 12 18Z" />
    <path d="M11.25 14.25L12.75 14.25L12.75 5.25L11.25 5.25L11.25 14.25Z" />
    <path d="M8.03026 9.96975L12 13.9395L15.9698 9.96975L17.0303 11.0302L13.0605 15C12.4755 15.585 11.5238 15.585 10.9388 15L6.96901 11.0302L8.03026 9.96975Z" />
    <path d="M1.5 17.25L3 17.25L3 21.75L21 21.75L21 17.25L22.5 17.25L22.5 21.75C22.5 22.5773 21.8273 23.25 21 23.25L3 23.25C2.17275 23.25 1.5 22.5772 1.5 21.75L1.5 17.25Z" />
  </svg>
);
export default SvgDeposit1;
