import { gql, useQuery } from '@apollo/client';

import { CryptoCurrency, StakingAssetQuery, StakingAssetQueryVariables } from './../gqlTypes';

const query = gql`
  query StakingAsset($currency: CryptoCurrency!) {
    staking {
      currency(currency: $currency) {
        currency
        fiatCurrency
        name
        type
        minimumStaked
        lockPeriod
        rewardDelay
        epochLength
        apy
        price
      }
    }
  }
`;

export const useStakingAsset = (currency?: CryptoCurrency) =>
  useQuery<StakingAssetQuery, StakingAssetQueryVariables>(query, {
    variables: { currency: currency || CryptoCurrency.Btc },
    skip: !currency,
  });
