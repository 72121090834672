import { gql, useMutation } from '@apollo/client';

import { METADATA_QUERY } from '../queries/useMetadata';
import {
  MetadataQuery,
  ToggleArticleReadMutation,
  ToggleArticleReadMutationVariables,
} from './../gqlTypes';

const mutation = gql`
  mutation ToggleArticleRead($input: ToggleReadArticleInput!) {
    toggleReadArticle(input: $input) {
      success
    }
  }
`;

export const useToggleArticleRead = () =>
  useMutation<ToggleArticleReadMutation, ToggleArticleReadMutationVariables>(mutation, {
    refetchQueries: ['Metadata'],
    optimisticResponse: {
      __typename: 'Mutation',
      toggleReadArticle: { __typename: 'SuccessPayload', success: true },
    },
    update: (cache, _, { variables }) => {
      if (!variables?.input.id) return;
      const currentMetadata = cache.readQuery<MetadataQuery>({ query: METADATA_QUERY });
      const newReadArticles = currentMetadata?.metadata.readArticles?.includes(variables?.input.id)
        ? currentMetadata?.metadata.readArticles.filter((r) => r !== variables.input.id)
        : [...(currentMetadata?.metadata.readArticles || []), variables.input.id];
      const newMetadata: MetadataQuery = {
        metadata: { ...currentMetadata?.metadata, readArticles: newReadArticles },
      };
      cache.writeQuery({ query: METADATA_QUERY, data: newMetadata });
    },
  });
