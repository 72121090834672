import { IconNames } from '@firi/icons/web';
import clsx from 'clsx';
import React from 'react';

import { FontColors, Icon, WebTypography } from '..';

export type ChipVariants =
  | 'white'
  | 'blue'
  | 'lightBlue'
  | 'darkBlue'
  | 'gray'
  | 'green'
  | 'pinkle'
  | 'yellow'
  | 'darkYellow'
  | 'red';

type ChipSize = 'xs' | 'sm' | 'md' | 'lg';
export interface ChipProps {
  variant?: ChipVariants;
  className?: string;
  icon?: IconNames;
  size?: ChipSize;
  rounded?: boolean;
  bold?: boolean;
  children?: React.ReactNode;
}

export const Chip = React.forwardRef<HTMLSpanElement, ChipProps>(
  (props, ref) => {
    const variantStyles = {
      white: 'bg-white ',
      blue: 'bg-blue-200 dark:bg-black-500',
      gray: 'bg-black-200 dark:bg-black-300',
      lightBlue: 'bg-blue-100 dark:bg-blue-200',
      darkBlue: 'bg-firi-blue text-white',
      green: 'bg-green-200 dark:bg-green-600',
      red: 'bg-red-200 dark:bg-red-600',
      pinkle: 'bg-pinkle-200',
      yellow: 'bg-yellow-200',
      darkYellow: 'bg-yellow-900',
      default: '',
    };

    const fontColor: { [key: string]: FontColors } = {
      white: 'black',
      blue: '1',
      gray: 'black',
      lightBlue: 'black',
      darkBlue: 'white',
      green: 'black',
      red: 'black',
      pinkle: 'pinkle',
      yellow: 'black',
      darkYellow: 'white',
      default: 'black',
    };

    const size = props.size || 'md';

    const rounded = !props.children || props.rounded;

    const roundedSize: Record<ChipSize, string> = {
      xs: 'h-1.5 w-1.5',
      sm: 'h-4 w-4',
      md: 'h-6 w-6',
      lg: 'h-10 w-10',
    };

    return (
      <span
        ref={ref}
        className={clsx(
          'inline-flex whitespace-nowrap justify-center items-center rounded-full',
          size === 'sm' && rounded
            ? 'p-0'
            : size === 'md' || size === 'lg' || size === 'sm'
            ? 'py-1 px-3'
            : '',
          variantStyles[props.variant || 'default'],
          rounded ? 'rounded-full' : '',
          rounded ? roundedSize[size] : '',
          props.className
        )}
      >
        {props.icon ? (
          <div
            className={clsx(
              'flex items-center justify-center',
              props.children ? 'mr-1' : ''
            )}
          >
            <Icon
              icon={props.icon}
              size={size === 'sm' ? '2xs' : 'sm'}
              color={fontColor[props.variant || 'default']}
            />
          </div>
        ) : null}
        <WebTypography
          size="xs"
          color={fontColor[props.variant || 'default']}
          weight={props.bold ? 'medium' : 'regular'}
        >
          {props.children}
        </WebTypography>
      </span>
    );
  }
);
