import * as React from 'react';
import { SVGProps } from 'react';
const SvgSuspended = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2548 17.6461C21.3558 16.0393 22 14.0949 22 12C22 6.47715 17.5228 2 12 2C9.9051 2 7.96065 2.64417 6.35394 3.74524L7.4364 4.82771C8.7548 3.98709 10.3205 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 13.6795 20.0129 15.2452 19.1723 16.5636L20.2548 17.6461ZM10.3956 13.0043L11.4627 14.0714H10.5714C9.19072 14.0714 8.07143 15.1907 8.07143 16.5714V18H7V16.5714C7 14.658 8.50481 13.0959 10.3956 13.0043ZM4.82771 7.4364C3.98709 8.7548 3.5 10.3205 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C13.6795 20.5 15.2452 20.0129 16.5636 19.1723L17.6461 20.2548C16.0393 21.3558 14.0949 22 12 22C6.47715 22 2 17.5228 2 12C2 9.9051 2.64417 7.96065 3.74524 6.35394L4.82771 7.4364ZM13.9167 11.308C14.5786 10.7577 15 9.92806 15 9C15 7.34315 13.6569 6 12 6C11.0719 6 10.2423 6.42141 9.69201 7.08332L10.493 7.88426C10.8346 7.42358 11.3824 7.125 12 7.125C13.0355 7.125 13.875 7.96447 13.875 9C13.875 9.61758 13.5764 10.1654 13.1157 10.507L13.9167 11.308Z"
      fill="#12123B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.96967 1.96967C2.26256 1.67678 2.73744 1.67678 3.03033 1.96967L21.0303 19.9697C21.3232 20.2626 21.3232 20.7374 21.0303 21.0303C20.7374 21.3232 20.2626 21.3232 19.9697 21.0303L1.96967 3.03033C1.67678 2.73744 1.67678 2.26256 1.96967 1.96967Z"
      fill="#DA2D43"
    />
  </svg>
);
export default SvgSuspended;
