import * as React from 'react';
import { SVGProps } from 'react';
const SvgCheckCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_914:15555)">
      <path d="M8.73134 16.6743L4.15991 12.1028L5.40563 10.9257L9.97705 15.4971L18.5942 6.85712L19.8056 7.99997L11.1542 16.6743C10.8282 16.9844 10.3926 17.1528 9.94277 17.1428C9.49377 17.148 9.06001 16.9802 8.73134 16.6743Z" />
      <path d="M12 1.71429C14.0343 1.71429 16.023 2.31753 17.7144 3.44774C19.4059 4.57795 20.7243 6.18436 21.5028 8.06383C22.2813 9.9433 22.485 12.0114 22.0881 14.0066C21.6912 16.0019 20.7116 17.8346 19.2731 19.2731C17.8346 20.7116 16.0019 21.6912 14.0066 22.0881C12.0114 22.485 9.9433 22.2813 8.06383 21.5028C6.18436 20.7243 4.57796 19.4059 3.44775 17.7144C2.31754 16.023 1.71429 14.0343 1.71429 12C1.71429 9.27206 2.79796 6.65585 4.72691 4.7269C6.65585 2.79796 9.27206 1.71429 12 1.71429ZM12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519936 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.997 8.81833 22.7317 5.76784 20.4819 3.51806C18.2322 1.26828 15.1817 0.00302584 12 0V0Z" />
    </g>
    <defs>
      <clipPath id="clip0_914:15555">
        <rect width={24} height={24} fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgCheckCircle;
