import { gql, useQuery } from '@apollo/client';

import {
  CryptoCurrency,
  EstimatedUnstakeTimeQuery,
  EstimatedUnstakeTimeQueryVariables,
} from './../gqlTypes';

const QUERY = gql`
  query EstimatedUnstakeTime($currency: CryptoCurrency!, $amount: String!) {
    staking {
      estimatedUnstakeTime(currency: $currency, amount: $amount) {
        days
      }
    }
  }
`;

export const useEstimatedUnstakeTime = (params: { currency?: CryptoCurrency; amount: string }) => {
  return useQuery<EstimatedUnstakeTimeQuery, EstimatedUnstakeTimeQueryVariables>(QUERY, {
    variables: { currency: params.currency ?? CryptoCurrency.Eth, amount: params.amount },
    skip: !params.amount || !params.currency,
  });
};
