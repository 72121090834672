import * as React from 'react';
import { SVGProps } from 'react';
const SvgLogOut = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M19.854 12.8813L19.854 11.3247L10.854 11.3247L10.854 12.8813L19.854 12.8813Z" />
    <path d="M15.5735 16.2227L19.5432 12.1031L15.5735 7.98357L16.634 6.88306L20.6037 11.0026C21.1887 11.6097 21.1887 12.5973 20.6037 13.2044L16.634 17.324L15.5735 16.2227Z" />
    <path d="M14.6938 1.20755L14.6938 2.76416L4.5 2.76416L4.5 21.4434L14.6938 21.4434L14.6938 23L4.5 23C3.67275 23 3 22.3019 3 21.4434L3 2.76416C3 1.90569 3.67275 1.20755 4.5 1.20755L14.6938 1.20755Z" />
  </svg>
);
export default SvgLogOut;
