import * as React from 'react';
import { SVGProps } from 'react';
const SvgUser = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C9.5147 3 7.49999 5.01472 7.49999 7.5C7.49999 9.98528 9.5147 12 12 12C14.4852 12 16.5 9.98528 16.5 7.5C16.5 5.01472 14.4852 3 12 3ZM12 13.125C7.90609 13.125 4.33833 15.8053 3.06146 19.5082C2.94291 19.852 2.99786 20.232 3.20898 20.5281C3.4201 20.8242 3.76134 21 4.12501 21H19.875C20.2387 21 20.5799 20.8242 20.791 20.5281C21.0021 20.232 21.0571 19.852 20.9385 19.5082C19.6617 15.8053 16.0938 13.125 12 13.125Z"
    />
  </svg>
);
export default SvgUser;
