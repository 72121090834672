export enum FractionDigits {
  zero = 0,
  one = 1,
  two = 2,
  four = 4,
  six = 6,
  seven = 7,
  eight = 8,
}

export type FractionDigitsValues = typeof FractionDigits[keyof typeof FractionDigits];

/**
 * The reason for this class is to have a single place to pre-define Intl.NumberFormat, because it's an expensive operation
 * to create a new instance every time we need to format a number.
 */
class CustomIntlFormatter {
  public zeroDigits: Intl.NumberFormat;
  public twoDigits: Intl.NumberFormat;
  public oneDigit: Intl.NumberFormat;
  public fourDigits: Intl.NumberFormat;
  public sixDigits: Intl.NumberFormat;
  public eightDigits: Intl.NumberFormat;
  public minZeroEightMax: Intl.NumberFormat;
  public minTwoEightMax: Intl.NumberFormat;
  public minTwoSevenMax: Intl.NumberFormat;
  public minFourEightMax: Intl.NumberFormat;
  public minZeroTwoMax: Intl.NumberFormat;

  constructor(locale: string) {
    this.zeroDigits = new Intl.NumberFormat(locale, {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });
    this.oneDigit = new Intl.NumberFormat(locale, {
      maximumFractionDigits: 1,
      minimumFractionDigits: 1,
    });
    this.twoDigits = new Intl.NumberFormat(locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    this.fourDigits = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4,
    });
    this.sixDigits = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 6,
      maximumFractionDigits: 6,
    });
    this.eightDigits = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 8,
      maximumFractionDigits: 8,
    });
    this.minZeroTwoMax = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
    this.minZeroEightMax = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 8,
    });
    this.minTwoSevenMax = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 7,
    });
    this.minTwoEightMax = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 8,
    });
    this.minFourEightMax = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 4,
      maximumFractionDigits: 8,
    });
  }
}

/**
 * Currently we only use the Norwegian locale formatter for all number formatting. It's the same for NO, DK, SE
 */
const IntlFormatter = new CustomIntlFormatter('nb-NO');

export const parseFractionDigits = (minimum: FractionDigits, maximum: FractionDigits) => {
  if (minimum === FractionDigits.zero && maximum === FractionDigits.zero) {
    return IntlFormatter.zeroDigits;
  }
  if (minimum === FractionDigits.one && maximum === FractionDigits.one) {
    return IntlFormatter.oneDigit;
  }
  if (minimum === FractionDigits.two && maximum === FractionDigits.two) {
    return IntlFormatter.twoDigits;
  }
  if (minimum === FractionDigits.four && maximum === FractionDigits.eight) {
    return IntlFormatter.minFourEightMax;
  }
  if (minimum === FractionDigits.zero && maximum === FractionDigits.eight) {
    return IntlFormatter.minZeroEightMax;
  }
  if (minimum === FractionDigits.four && maximum === FractionDigits.four) {
    return IntlFormatter.fourDigits;
  }
  if (minimum === FractionDigits.two && maximum === FractionDigits.eight) {
    return IntlFormatter.minTwoEightMax;
  }
  if (minimum === FractionDigits.six && maximum === FractionDigits.six) {
    return IntlFormatter.sixDigits;
  }
  if (minimum === FractionDigits.eight && maximum === FractionDigits.eight) {
    return IntlFormatter.eightDigits;
  }
  if (minimum === FractionDigits.zero && maximum === FractionDigits.two) {
    return IntlFormatter.minZeroTwoMax;
  }
  if (minimum === FractionDigits.two && maximum === FractionDigits.seven) {
    return IntlFormatter.minTwoSevenMax;
  }
  throw new Error(`Invalid fraction digits: max ${maximum} and min ${minimum}`);
};
