import * as React from 'react';
import { SVGProps } from 'react';
const SvgElliptic = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 72 87" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M31.9 24.5V32.1H62.3V47.3L54.7 54.9H31.9V62.5H69.9V77.7L62.3 85.3H1.5V9.29999L9.1 1.70001H69.9V16.9L62.3 24.5H31.9Z"
      stroke="black"
      strokeWidth={2.5316}
      strokeMiterlimit={10}
    />
    <path
      d="M1.5 9.29999H62.3V25.5H24.3V39.7H54.7V56H24.3V70.1H62.3V85.3H1.5V9.29999Z"
      fill="black"
    />
  </svg>
);
export default SvgElliptic;
