import * as React from 'react';
import { SVGProps } from 'react';
const SvgPdf = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 2C4.44772 2 4 2.44772 4 3V21C4 21.5523 4.44772 22 5 22H19C19.5523 22 20 21.5523 20 21V9.41421C20 9.149 19.8946 8.89464 19.7071 8.70711L13.2929 2.29289C13.1054 2.10536 12.851 2 12.5858 2H5ZM18.5 10.25V20.5H5.5V3.5H11.75V9.25C11.75 9.80228 12.1977 10.25 12.75 10.25H18.5ZM17.6287 8.75L13.25 4.37132V8.75H17.6287Z"
    />
    <path d="M6.5 19.5V15.5H8.59612C9.03087 15.5 9.37246 15.5979 9.62089 15.7936C9.8732 15.9858 9.99936 16.2899 9.99936 16.7058C9.99936 16.9644 9.93919 17.1881 9.81886 17.3768C9.70241 17.5655 9.53744 17.7106 9.32394 17.8119C9.11045 17.9098 8.86784 17.9587 8.59612 17.9587H7.54224V19.5H6.5ZM8.45056 17.1461C8.79603 17.1461 8.96877 17.0098 8.96877 16.7372C8.96877 16.6079 8.92413 16.51 8.83485 16.4436C8.74945 16.3737 8.62135 16.3388 8.45056 16.3388H7.54224V17.1461H8.45056Z" />
    <path d="M10.3581 19.5V15.5H11.7031C12.4601 15.5 13.0404 15.6713 13.4441 16.0138C13.8478 16.3563 14.0496 16.8491 14.0496 17.4921C14.0496 18.1387 13.8478 18.635 13.4441 18.981C13.0404 19.327 12.4601 19.5 11.7031 19.5H10.3581ZM11.6915 18.6612C12.1262 18.6612 12.4543 18.5668 12.6755 18.3781C12.8968 18.1859 13.0074 17.8906 13.0074 17.4921C13.0074 17.1182 12.8909 16.8333 12.658 16.6376C12.4251 16.4384 12.103 16.3388 11.6915 16.3388H11.4004V18.6612H11.6915Z" />
    <path d="M15.5087 19.5H14.4664V15.5H17.5V16.3388H15.5087V17.0832H17.3079V17.9168H15.5087V19.5Z" />
  </svg>
);
export default SvgPdf;
