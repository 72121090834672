import React from 'react';
import ReactSlider from 'react-slider';
import { twMerge } from 'tailwind-merge';

import { WebTypography } from '../..';

interface Props {
  value: number;
  onChange: (value: number) => void;
}
export const Slider = (props: Props) => {
  return (
    <ReactSlider
      className="horizontal-slider h-8"
      renderTrack={(props, state) => (
        <div
          {...props}
          className={twMerge(
            'h-1 absolute bottom-1/2 rounded-full',
            state.index === 0 ? 'bg-blue' : 'bg-black-100'
          )}
        />
      )}
      renderThumb={(props, state) => (
        <div
          {...props}
          className="inline-flex items-center py-2 px-4 rounded-full top-0 bottom-0 dark:bg-dark-6 bg-blue cursor-pointer"
        >
          <WebTypography size="xs" weight="medium" color="white">
            {state.valueNow}%
          </WebTypography>
        </div>
      )}
      value={props.value}
      onChange={props.onChange}
    />
  );
};
