import { gql, useMutation } from '@apollo/client';

import { RebuildTaxMutation } from '../gqlTypes';

const MUTATION = gql`
  mutation RebuildTax {
    rebuildTax {
      success
      message
    }
  }
`;

export const useRebuildTax = () => {
  return useMutation<RebuildTaxMutation>(MUTATION, {
    refetchQueries: ['TaxRebuilding'],
  });
};
