import * as React from 'react';
import { SVGProps } from 'react';
const SvgLabelTag = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 6.0526L11 12.3333C11 12.5174 10.8508 12.6667 10.6667 12.6667H5.33333C5.14924 12.6667 5 12.5174 5 12.3333V6.0526C5 5.94881 5.04835 5.85094 5.13078 5.78787L7.79744 3.74747C7.91699 3.656 8.08301 3.656 8.20256 3.74747L10.8692 5.78787C10.9517 5.85094 11 5.94881 11 6.0526ZM4 6.0526C4 5.63744 4.19339 5.24597 4.5231 4.99369L7.18977 2.95328C7.66796 2.58739 8.33204 2.58739 8.81023 2.95328L11.4769 4.99369C11.8066 5.24597 12 5.63744 12 6.0526L12 12.3333C12 13.0697 11.403 13.6667 10.6667 13.6667H5.33333C4.59695 13.6667 4 13.0697 4 12.3333V6.0526ZM8.00004 6.30293C8.4017 6.30293 8.72731 5.97732 8.72731 5.57566C8.72731 5.174 8.4017 4.84839 8.00004 4.84839C7.59838 4.84839 7.27277 5.174 7.27277 5.57566C7.27277 5.97732 7.59838 6.30293 8.00004 6.30293Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgLabelTag;
