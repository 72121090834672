import * as React from 'react';
import { SVGProps } from 'react';
const SvgWalletFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="12123B" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.25 6.75H21C21.8272 6.75 22.5 7.42275 22.5 8.25V19.5C22.5 20.3272 21.8272 21 21 21H5.25C3.18225 21 1.5 19.3178 1.5 17.25V5.625C1.5 4.1775 2.6775 3 4.125 3H18.801C19.5998 3 20.25 3.66825 20.25 4.4895V6.75ZM4.125 4.5C3.50368 4.5 3 5.00368 3 5.625C3 6.24632 3.50368 6.75 4.125 6.75H18.75V4.5H4.125ZM18.5 15.5C19.3284 15.5 20 14.8284 20 14C20 13.1716 19.3284 12.5 18.5 12.5C17.6716 12.5 17 13.1716 17 14C17 14.8284 17.6716 15.5 18.5 15.5ZM4.125 8.2535C3.72225 8.2535 3.34125 8.16275 3 8V9.25C3.34125 9.41275 3.72225 9.5035 4.125 9.5035H21.0521V8.2535H4.125Z"
    />
  </svg>
);
export default SvgWalletFilled;
