import * as React from 'react';
import { SVGProps } from 'react';
const SvgEmailReceived = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M10 7L8 7L12 11.5L16 7L14 7L14 2L10 2L10 7Z" fill="#1E856C" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00002 7.21076L3.5 7.21077C2.67275 7.21077 2 7.88352 2 8.71077L2.00002 20.339C2.00002 21.1663 2.67277 21.839 3.50002 21.839L20.5 21.839C21.3273 21.839 22 21.1663 22 20.339L22 8.71076C22 7.88351 21.3272 7.21075 20.5 7.21075L17 7.21076V8.71076L19.1355 8.71075L12 14.0995L4.86448 8.71077L7.00002 8.71077V7.21076ZM12 15.9998L20.5 9.54979L20.5 20.339L3.50002 20.339L3.5 9.5498L12 15.9998Z"
      fill="#12123B"
    />
  </svg>
);
export default SvgEmailReceived;
