import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import { PlaceMarketOrderMutation, PlaceMarketOrderMutationVariables } from '../gqlTypes';

export const PLACE_MARKET_ORDER_MUTATION = gql`
  mutation PlaceMarketOrder($input: PlaceOrderInput!) {
    placeMarketOrder: placeMarketOrderOtc(input: $input) {
      result {
        fee
        exchangeRate
        assetMatched
        fiatMatched
      }
      errorMsg
    }
  }
`;

export const usePlaceMarketOrder = (
  options?: MutationHookOptions<PlaceMarketOrderMutation, PlaceMarketOrderMutationVariables>,
) => {
  return useMutation<PlaceMarketOrderMutation, PlaceMarketOrderMutationVariables>(
    PLACE_MARKET_ORDER_MUTATION,
    { ...options, refetchQueries: ['PNLBalance', 'Transaction', 'Accounts'] },
  );
};
