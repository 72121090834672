import * as React from 'react';
import { SVGProps } from 'react';
const SvgSortLowToHigh = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M4.87106 4L4.87106 19.1104L6.37106 19.1104L6.37106 4L4.87106 4Z" />
    <path d="M9.24204 17L6.68154 19.5605C6.39804 19.844 6.02154 20 5.62104 20C5.22054 20 4.84404 19.844 4.56054 19.5605L2.00007 17L3.06057 15.9395L5.62104 18.5L8.18154 15.9395L9.24204 17Z" />
    <path d="M11 5L16 5L16 6.5L11 6.5L11 5Z" />
    <path d="M11 17L22 17L22 18.5L11 18.5L11 17Z" />
    <path d="M11 13L20 13L20 14.5L11 14.5L11 13Z" />
    <path d="M11 9L18 9L18 10.5L11 10.5L11 9Z" />
  </svg>
);
export default SvgSortLowToHigh;
