import { messages as daMessages } from '@firi/shared/locale/da/messages.ts';
import { messages as enMessages } from '@firi/shared/locale/en/messages.ts';
import { messages as noMessages } from '@firi/shared/locale/no/messages.ts';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import * as p from 'make-plural';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

i18n.loadLocaleData('no', { plurals: p.no });
i18n.loadLocaleData('en', { plurals: p.en });
i18n.loadLocaleData('da', { plurals: p.da });
i18n.load({
  no: noMessages,
  en: enMessages,
  da: daMessages,
});

export const LanguageProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const locale = router.locale === 'dk' ? 'da' : router.locale;

  i18n.activate(locale || 'en');

  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};
