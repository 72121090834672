import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { MarketAdvancedQuery, MarketAdvancedQueryVariables, MarketType } from '../gqlTypes';
import {} from './../gqlTypes';

const query = gql`
  query MarketAdvanced($symbol: ID!, $marketType: MarketType) {
    market: market_v2(symbol: $symbol, marketType: $marketType) {
      symbol
      lastPrice
      change
      bidPriceHighest
      askPriceLowest
      volume
      baseCurrency {
        symbol
        name
      }
      quoteCurrency {
        symbol
      }
      marketType
    }
  }
`;

export const useMarketAdvanced = (
  params: { symbol?: string },
  options?: QueryHookOptions<MarketAdvancedQuery, MarketAdvancedQueryVariables>,
) => {
  return useQuery<MarketAdvancedQuery, MarketAdvancedQueryVariables>(query, {
    ...options,
    skip: !params.symbol,
    variables: { symbol: params.symbol || '', marketType: MarketType.Orderbook },
  });
};
