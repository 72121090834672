import { gql, useQuery } from '@apollo/client';

import { DonationsQuery } from '../gqlTypes';

export const QUERY = gql`
  query Donations {
    donations: donations_v2 {
      name
      short_name
      start
      end
      active
      currencies {
        currency
        amount
        fiat {
          currency
          value
        }
      }
      total_donation {
        currency
        value
        users
      }
    }
  }
`;

export const useDonations = () => {
  return useQuery<DonationsQuery>(QUERY, {
    fetchPolicy: 'cache-and-network',
  });
};
