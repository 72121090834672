import * as React from 'react';
import { SVGProps } from 'react';
const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 6.34273L15.1104 6.34273V4.84273L1.31134e-07 4.84273L0 6.34273Z"
      fill="currentColor"
    />
    <path
      d="M11.0283 0L15.5605 4.53225C15.844 4.81575 16 5.19225 16 5.59275C16 5.99325 15.844 6.36975 15.5605 6.65325L11.0282 11.1855L9.96775 10.125L14.5 5.59275L9.96775 1.0605L11.0283 0Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgArrowRight;
