import * as React from 'react';
import { SVGProps } from 'react';
const SvgDocument = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 2C4.44772 2 4 2.44772 4 3V21C4 21.5523 4.44772 22 5 22H19C19.5523 22 20 21.5523 20 21V9.41421C20 9.149 19.8946 8.89464 19.7071 8.70711L13.2929 2.29289C13.1054 2.10536 12.851 2 12.5858 2H5ZM18.5 10.25V20.5H5.5V3.5H11.75V9.25C11.75 9.80228 12.1977 10.25 12.75 10.25H18.5ZM17.6287 8.75L13.25 4.37132V8.75H17.6287ZM17 14.75H7V13.25H17V14.75ZM7 18.75H15V17.25H7V18.75Z"
    />
  </svg>
);
export default SvgDocument;
