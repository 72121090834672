import * as React from 'react';
import { SVGProps } from 'react';
const SvgComputerCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.50214 6.5C4.36407 6.5 4.25214 6.61193 4.25214 6.75V15.25C4.25214 15.3881 4.36406 15.5 4.50214 15.5H19.5021C19.6402 15.5 19.7521 15.3881 19.7521 15.25V6.75C19.7521 6.61193 19.6402 6.5 19.5021 6.5H4.50214ZM2.75214 6.75C2.75214 5.7835 3.53564 5 4.50214 5H19.5021C20.4686 5 21.2521 5.7835 21.2521 6.75V15.25C21.2521 16.2165 20.4686 17 19.5021 17H4.50214C3.53564 17 2.75214 16.2165 2.75214 15.25V6.75ZM16.5628 8.25L16.0325 8.78033L11.0325 13.7803C10.7396 14.0732 10.2647 14.0732 9.97181 13.7803L7.97181 11.7803L7.44148 11.25L8.50214 10.1893L9.03247 10.7197L10.5021 12.1893L14.9718 7.71967L15.5021 7.18934L16.5628 8.25ZM2.50214 17.5C2.08792 17.5 1.75214 17.8358 1.75214 18.25C1.75214 18.6642 2.08792 19 2.50214 19H21.5021C21.9163 19 22.2521 18.6642 22.2521 18.25C22.2521 17.8358 21.9163 17.5 21.5021 17.5H2.50214Z"
      fill="#1E856C"
    />
  </svg>
);
export default SvgComputerCheck;
