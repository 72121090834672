import * as React from 'react';
import { SVGProps } from 'react';
const SvgDanskebank = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={20} cy={20} r={20} fill="#03426A" />
    <path
      d="M37.0051 17.8686H24.8307V23H37.0051V17.8686ZM36.8727 17.9979V22.8676H24.96V17.9979H36.8727Z"
      fill="#03426A"
    />
    <path
      d="M3.05 22.95H24.2544V17.9186H3.05V22.95Z"
      fill="#03426A"
      stroke="white"
      strokeWidth={0.1}
    />
    <path d="M36.8728 17.9978V22.8676H24.9601V17.9978H36.8728Z" fill="white" />
    <path
      d="M10.3569 20.7804C10.3569 21.2852 10.086 21.5346 9.47961 21.5346H8.52536V19.6446H9.44883C10.1814 19.6446 10.3569 20.0016 10.3569 20.3926V20.7804ZM10.7478 20.765V20.4418C10.7478 19.4106 9.93518 19.3706 9.39034 19.3706H8.13135V21.8147H9.49808C10.5724 21.8147 10.7478 21.2329 10.7478 20.765Z"
      fill="white"
    />
    <path
      d="M12.8781 21.2116C12.8781 21.3532 12.8657 21.4825 12.7026 21.541C12.6072 21.5779 12.4717 21.5903 12.2439 21.5903C12.0162 21.5903 11.8807 21.5687 11.8007 21.5164C11.7453 21.4825 11.7237 21.4271 11.7237 21.3101C11.7237 21.1624 11.7607 21.0793 11.8315 21.0454C11.9023 21.0116 11.9915 21.0054 12.2686 21.0054H12.8781V21.2116ZM13.2474 21.815V20.8546C13.2474 20.6729 13.2474 20.5467 13.2321 20.4544C13.2136 20.359 13.1828 20.2912 13.1058 20.2328C12.9273 20.0942 12.4902 20.0912 12.2747 20.0912C12.1116 20.0912 11.6683 20.1096 11.5052 20.2482C11.4498 20.2943 11.4374 20.3497 11.4374 20.4175C11.4374 20.4606 11.4436 20.519 11.4467 20.596H11.8068C11.813 20.5529 11.8222 20.4482 11.8622 20.4051C11.9115 20.359 11.9946 20.3405 12.2409 20.3405C12.5241 20.3405 12.7211 20.3497 12.8042 20.3959C12.8811 20.439 12.8781 20.5313 12.8781 20.6021V20.7684H12.0469C11.8222 20.7684 11.5637 20.7745 11.4221 20.9685C11.3574 21.0516 11.3297 21.1593 11.3297 21.2978C11.3297 21.7042 11.579 21.8396 12.2193 21.8396C12.5518 21.8396 12.7703 21.7873 12.9027 21.6734L12.9365 21.815H13.2474Z"
      fill="white"
    />
    <path
      d="M15.4761 21.8147V20.8789C15.4761 20.5557 15.4607 20.488 15.3838 20.4295C15.3099 20.3741 15.1621 20.3402 14.9374 20.3402C14.7404 20.3402 14.5373 20.3649 14.3464 20.4172V21.8147H13.9801V20.1032H14.3095L14.3341 20.2263C14.408 20.1863 14.4634 20.1555 14.5403 20.134C14.6635 20.097 14.8174 20.0786 15.0236 20.0786C15.2576 20.0786 15.4392 20.1155 15.5777 20.1771C15.7131 20.2356 15.787 20.3464 15.8147 20.4757C15.8332 20.5649 15.8424 20.6419 15.8424 20.8358V21.8147H15.4761Z"
      fill="white"
    />
    <path
      d="M18.1941 21.7103C18.0248 21.7965 17.7478 21.8334 17.3876 21.8334C17.1383 21.8334 16.9321 21.815 16.772 21.7719C16.5996 21.7318 16.5134 21.6518 16.5042 21.5041C16.498 21.4271 16.5042 21.3594 16.5134 21.2671H16.8674C16.8797 21.3532 16.8797 21.3809 16.8828 21.3994C16.8951 21.4794 16.889 21.5225 16.9844 21.5533C17.0767 21.581 17.2245 21.5933 17.4123 21.5933C17.6462 21.5933 17.8094 21.5687 17.9171 21.5195C17.994 21.4856 18.031 21.424 18.031 21.3071C18.031 21.1716 18.0033 21.1162 17.8617 21.0947C17.8186 21.0854 17.7755 21.0854 17.7293 21.0823C17.7047 21.0793 17.6247 21.0793 17.4954 21.0762C17.4338 21.0762 17.363 21.07 17.2799 21.067C16.9136 21.0485 16.5257 21.0331 16.5257 20.6052C16.5257 20.2174 16.7628 20.0819 17.4153 20.0819C17.5816 20.0819 17.7847 20.0942 17.9079 20.1096C18.0495 20.1281 18.1572 20.1527 18.2341 20.1958C18.3203 20.2451 18.348 20.322 18.348 20.4575C18.348 20.4944 18.348 20.5314 18.3419 20.5898H17.9848L17.9694 20.5129C17.9509 20.4205 17.9448 20.4021 17.914 20.3805C17.8617 20.3405 17.7047 20.322 17.4831 20.322C17.2614 20.322 17.1352 20.3313 17.0336 20.3682C16.9382 20.4021 16.8951 20.4636 16.8951 20.5868C16.8951 20.6699 16.9259 20.7314 16.9659 20.7591C17.0613 20.8176 17.5662 20.8115 17.7385 20.8145C17.9232 20.8176 18.0433 20.833 18.148 20.8699C18.3357 20.9346 18.4096 21.0577 18.4096 21.3071C18.4096 21.5071 18.345 21.6334 18.1941 21.7103Z"
      fill="white"
    />
    <path
      d="M20.4535 21.8147L19.5701 21.0051H19.4408V21.8147H19.0714V19.3706H19.4408V20.762H19.5701L20.3027 20.1032H20.7336L19.881 20.8574L20.943 21.8147H20.4535Z"
      fill="white"
    />
    <path
      d="M22.7652 20.7928H21.5863C21.5986 20.5774 21.6448 20.482 21.7279 20.4204C21.8141 20.3588 21.9711 20.328 22.2019 20.328C22.5221 20.328 22.6729 20.3988 22.7283 20.5466C22.7498 20.6082 22.7652 20.6666 22.7652 20.7928ZM23.1038 21.2792H22.7406C22.7283 21.3315 22.7221 21.4639 22.6729 21.5132C22.6175 21.5686 22.5067 21.5932 22.2112 21.5932C21.9187 21.5932 21.7587 21.5532 21.6755 21.4701C21.614 21.4085 21.577 21.2792 21.577 21.0791V21.033H23.1131C23.1162 20.9498 23.1223 20.9283 23.1223 20.8606C23.1223 20.5774 23.0823 20.4204 22.9592 20.3003C22.8206 20.1557 22.5528 20.0849 22.1834 20.0849C21.7125 20.0849 21.4231 20.1926 21.3031 20.4296C21.2323 20.5651 21.1923 20.7559 21.1923 20.9929C21.1923 21.3654 21.2908 21.5993 21.5093 21.7163C21.6448 21.7902 21.8787 21.8333 22.1773 21.8364C22.4266 21.8364 22.6267 21.8241 22.8453 21.7594C23.0577 21.6917 23.11 21.5716 23.11 21.4701C23.11 21.4454 23.11 21.3623 23.1038 21.2792Z"
      fill="white"
    />
    <path
      d="M27.9552 19.9862C27.9552 20.3464 27.752 20.4203 27.3334 20.4203H26.4068V19.6446H27.3795C27.752 19.6446 27.9552 19.6784 27.9552 19.9862ZM28.4385 21.1252C28.4385 20.8235 28.2661 20.6019 27.8536 20.5372V20.5311C28.1737 20.448 28.3461 20.2817 28.3461 20.0201C28.3461 19.5891 28.1368 19.3706 27.5427 19.3706H26.0128V21.8147H27.6043C28.1399 21.8147 28.4385 21.5715 28.4385 21.1252ZM28.0444 21.1529C28.0444 21.5007 27.7982 21.5346 27.4165 21.5346H26.4068V20.6973H27.4657C27.9182 20.6973 28.0444 20.842 28.0444 21.1529Z"
      fill="#03426A"
    />
    <path
      d="M30.4361 21.2116C30.4361 21.3532 30.4269 21.4825 30.2638 21.541C30.1683 21.5779 30.0329 21.5903 29.8051 21.5903C29.5773 21.5903 29.4419 21.5687 29.3618 21.5164C29.3064 21.4825 29.2849 21.4271 29.2849 21.3101C29.2849 21.1624 29.3218 21.0793 29.3957 21.0454C29.4634 21.0116 29.5527 21.0054 29.8328 21.0054H30.4361V21.2116ZM30.8086 21.815V20.8546C30.8086 20.6729 30.8086 20.5467 30.7932 20.4544C30.7747 20.359 30.744 20.2912 30.667 20.2328C30.4885 20.0942 30.0514 20.0912 29.8359 20.0912C29.6727 20.0912 29.2295 20.1096 29.0663 20.2482C29.0109 20.2943 28.9986 20.3497 28.9986 20.4175C28.9986 20.4606 29.0048 20.519 29.0078 20.596H29.368C29.3772 20.5529 29.3834 20.4482 29.4234 20.4051C29.4727 20.359 29.5558 20.3405 29.802 20.3405C30.0852 20.3405 30.2822 20.3497 30.3653 20.3959C30.4423 20.439 30.4361 20.5313 30.4361 20.6021V20.7684H29.6081C29.3834 20.7684 29.1248 20.7745 28.9832 20.9685C28.9216 21.0516 28.8909 21.1593 28.8909 21.2978C28.8909 21.7042 29.1402 21.8396 29.7805 21.8396C30.116 21.8396 30.3315 21.7873 30.4638 21.6734L30.4977 21.815H30.8086Z"
      fill="#03426A"
    />
    <path
      d="M32.945 21.8147V20.8789C32.945 20.5557 32.9296 20.488 32.8526 20.4295C32.7788 20.3741 32.631 20.3402 32.4063 20.3402C32.2093 20.3402 32.0061 20.3649 31.8153 20.4172V21.8147H31.449V20.1032H31.7783L31.806 20.2263C31.8768 20.1863 31.9323 20.1555 32.0123 20.134C32.1323 20.097 32.2863 20.0786 32.4925 20.0786C32.7264 20.0786 32.9081 20.1155 33.0466 20.1771C33.182 20.2356 33.2559 20.3464 33.2867 20.4757C33.3051 20.5649 33.3113 20.6419 33.3113 20.8358V21.8147H32.945Z"
      fill="#03426A"
    />
    <path
      d="M35.3337 21.8147L34.4502 21.0051H34.3178V21.8147H33.9515V19.3706H34.3178V20.762H34.4502L35.1828 20.1032H35.6107L34.758 20.8574L35.82 21.8147H35.3337Z"
      fill="#03426A"
    />
  </svg>
);
export default SvgDanskebank;
