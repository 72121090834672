import { gql, useMutation } from '@apollo/client';

import { AddContactMutation, AddContactMutationVariables } from './../gqlTypes';

const mutation = gql`
  mutation AddContact($input: AddContactInput!) {
    addContact(input: $input) {
      success
      message
    }
  }
`;

export const useAddContact = () =>
  useMutation<AddContactMutation, AddContactMutationVariables>(mutation, {
    refetchQueries: ['Contacts'],
  });
