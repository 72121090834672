import { gql, useMutation } from '@apollo/client';

import { ValidateKycBankIdMutation, ValidateKycBankIdMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation ValidateKycBankId($input: ValidateKycBankIdInput!) {
    validateKycBankId(input: $input) {
      success
      message
    }
  }
`;

export const useValidateKycBankId = () =>
  useMutation<ValidateKycBankIdMutation, ValidateKycBankIdMutationVariables>(mutation, {
    refetchQueries: ['User'],
  });
