import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

import { FiatCurrency, Symbol } from '../gql/gqlTypes';
import { useCurrentUser } from '../gql/queries/useCurrentUser';

export type FiatMarket = {
  symbol: FiatCurrency;
  name: string;
  shortName: string;
  fullName: string;
  /**
   * whether name and shortName should be positioned left or right to the amount it labels
   */
  namePosition: 'left' | 'right';
  shortNamePosition: 'left' | 'right';
  symbolPosition: 'left' | 'right';
};

export const useFiatMarket = (market?: Symbol | FiatCurrency | null): FiatMarket => {
  const currencyData: Record<FiatCurrency, FiatMarket> = {
    [FiatCurrency.Nok]: {
      name: t`kroner`,
      shortName: t`kr`,
      fullName: i18n._(t`norwegian kroner`),
      namePosition: 'right',
      shortNamePosition: 'right',
      symbolPosition: 'right',
      symbol: FiatCurrency.Nok,
    },
    [FiatCurrency.Dkk]: {
      name: t`kroner`,
      shortName: t`kr`,
      fullName: t`danish kroner`,
      namePosition: 'right',
      shortNamePosition: 'right',
      symbolPosition: 'right',
      symbol: FiatCurrency.Dkk,
    },
    [FiatCurrency.Usd]: {
      name: t`dollars`,
      shortName: '$',
      fullName: t`US dollars`,
      namePosition: 'right',
      shortNamePosition: 'left',
      symbolPosition: 'right',
      symbol: FiatCurrency.Usd,
    },
    [FiatCurrency.Sek]: {
      name: t`kroner`,
      shortName: 'kr',
      fullName: t`swedish kroner`,
      namePosition: 'right',
      shortNamePosition: 'left',
      symbolPosition: 'right',
      symbol: FiatCurrency.Sek,
    },
  };

  const { data } = useCurrentUser();

  const symbol = (market ||
    data?.currentUser?.fiatMarket.symbol ||
    FiatCurrency.Nok) as FiatCurrency;

  return currencyData[symbol];
};
