import * as React from 'react';
import { SVGProps } from 'react';
const SvgXlm = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_350_2072)">
      <circle cx={16.5} cy={16.5} r={16.5} fill="#12123B" />
    </g>
    <path
      d="M24.6097 9.33859L21.9306 10.7036L8.99338 17.2931C8.94964 16.9595 8.92762 16.6233 8.92749 16.2868C8.92926 14.9441 9.28177 13.6252 9.95011 12.4607C10.6185 11.2962 11.5794 10.3265 12.7379 9.64761C13.8963 8.96876 15.212 8.60433 16.5546 8.59042C17.8972 8.5765 19.2201 8.91359 20.3924 9.56829L21.9259 8.78695L22.1546 8.67022C20.7499 7.65062 19.0905 7.03927 17.36 6.90374C15.6295 6.76821 13.8953 7.11379 12.3489 7.90227C10.8025 8.69075 9.50426 9.89144 8.59761 11.3716C7.69095 12.8518 7.2112 14.5539 7.21137 16.2896C7.21137 16.5294 7.22047 16.7682 7.23867 17.006C7.26416 17.3429 7.18946 17.6797 7.02397 17.9742C6.85847 18.2686 6.60958 18.5076 6.3086 18.661L5.49997 19.0733V20.9965L7.88069 19.7831L8.65167 19.3896L9.41135 19.0027L23.0499 12.0535L24.5824 11.2731L27.7501 9.65866V7.73639L24.6097 9.33859Z"
      fill="white"
    />
    <path
      d="M27.7501 11.5828L10.1861 20.5258L8.65355 21.3081L5.49997 22.915V24.8363L8.6319 23.2407L11.311 21.8757L24.2614 15.2767C24.3052 15.6126 24.3272 15.9509 24.3273 16.2896C24.3265 17.6339 23.9741 18.9545 23.3051 20.1204C22.6361 21.2864 21.6738 22.2571 20.5137 22.9361C19.3536 23.6152 18.036 23.979 16.6918 23.9915C15.3477 24.004 14.0236 23.6646 12.8511 23.0072L12.757 23.0571L11.0945 23.9043C12.499 24.924 14.158 25.5355 15.8882 25.6714C17.6184 25.8072 19.3525 25.4621 20.8989 24.6742C22.4453 23.8862 23.7437 22.6861 24.6508 21.2065C25.5578 19.7268 26.0382 18.0252 26.0387 16.2896C26.0387 16.0477 26.0293 15.8058 26.0114 15.5667C25.986 15.23 26.0606 14.8932 26.2259 14.5988C26.3912 14.3044 26.6398 14.0653 26.9405 13.9117L27.7501 13.4994V11.5828Z"
      fill="white"
    />
    <defs>
      <clipPath id="clip0_350_2072">
        <rect width={33} height={33} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgXlm;
