import * as React from 'react';
import { SVGProps } from 'react';
const SvgRejected = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12C3.5 16.6944 7.30558 20.5 12 20.5ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM16.5302 7.46978C16.8231 7.76268 16.8231 8.23755 16.5302 8.53044L13.0607 12L16.5301 15.4694C16.823 15.7623 16.823 16.2372 16.5301 16.5301C16.2372 16.823 15.7623 16.823 15.4694 16.5301L12 13.0607L8.53046 16.5302C8.23756 16.8231 7.76269 16.8231 7.4698 16.5302C7.1769 16.2373 7.1769 15.7624 7.4698 15.4695L10.9393 12L7.46968 8.53033C7.17679 8.23744 7.17679 7.76257 7.46968 7.46967C7.76258 7.17678 8.23745 7.17678 8.53034 7.46967L12 10.9393L15.4695 7.46978C15.7624 7.17689 16.2373 7.17689 16.5302 7.46978Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgRejected;
