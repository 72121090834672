import * as React from 'react';
import { SVGProps } from 'react';
const SvgFiriLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M17.5001 12.8107V11.0986H13.2297L13.4484 10.2607C13.9617 8.32997 14.9701 7.40103 16.8034 7.40103C17.2068 7.40103 17.6468 7.47389 18.1418 7.63782L19.0584 6.03495C18.3984 5.76173 17.5734 5.57959 16.8401 5.57959C14.0901 5.57959 12.2934 6.92746 11.5601 9.78712L11.2176 11.0986H6.50002V12.8107H10.7704L10.5517 13.6486C10.0384 15.5793 9.0117 16.5082 7.19669 16.5082C6.77502 16.5082 6.33502 16.4354 5.85835 16.2714L4.92334 17.8743C5.60168 18.1475 6.42668 18.3297 7.16002 18.3297C9.91004 18.3297 11.6701 16.9818 12.4401 14.1221L12.7825 12.8107H17.5001Z" />
    <path d="M2.83334 11.9546C2.83334 9.44104 4.88669 7.40103 7.41671 7.40103C7.94838 7.40103 8.46171 7.4921 8.95672 7.67425L9.89172 6.07138C9.10338 5.74352 8.26005 5.57959 7.41671 5.57959C3.87835 5.57959 1 8.43925 1 11.9546C1 13.94 1.95334 15.8161 3.51168 17.0182L4.44669 15.4154C3.43835 14.5593 2.83334 13.3025 2.83334 11.9546Z" />
    <path d="M20.4701 6.89111L19.5534 8.49398C20.5618 9.35006 21.1668 10.6069 21.1668 11.9547C21.1668 14.4683 19.1134 16.5083 16.5834 16.5083C16.0517 16.5083 15.5384 16.4172 15.0434 16.2351L14.1084 17.838C14.8967 18.1658 15.7401 18.3298 16.5834 18.3298C20.1218 18.3298 23.0001 15.4701 23.0001 11.9547C23.0001 9.96935 22.0468 8.09326 20.4701 6.89111Z" />
  </svg>
);
export default SvgFiriLogo;
