import { sanityCategorySlugs } from 'constants/sanityCategoryIds';
import { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import { NextRouter } from 'next/router';
import { SanityAlternateSlug, SanitySlug } from 'types/sanity';
import { PageSlugV2 } from 'types/schema';

import { urlAliases } from '../constants/urls';
import { findLocaleBySanityLanguage } from './language';

export const getSanitySlug = (slug: PageSlugV2) => slug?.dynamic?.current || slug?.predefined || '';

export const generateAlternateSlugFromSanity = (
  slugs: SanityAlternateSlug[],
  params?: NextParsedUrlQuery,
) =>
  slugs
    ?.filter((slug) => slug)
    .reduce((a, c) => {
      a = {
        ...a,
        [findLocaleBySanityLanguage(c.__i18n_lang)]: {
          ...params,
          article: c?.slug?.current || '',
          slug: (c.slug && c.slug.current) || (c.slugV2 && getSanitySlug(c.slugV2)) || '',
        },
      };
      return a;
    }, {}) as alternateSlug;

export type alternateSlug = Record<string, Record<string, string>>;

export const getLocalizedUrl = (
  url: string,
  locale: string | undefined,
  query: alternateSlug | NextParsedUrlQuery | any,
) => {
  let paths = url?.split('/');

  paths = paths?.map((p) => {
    if (locale && urlAliases[p] && urlAliases[p][locale]) return urlAliases[p][locale];
    if (p.startsWith('[') && p.endsWith(']')) {
      if (locale && query[locale]) {
        return p.replace(/\[(\w+)\]/g, (_, k: string) => query[locale][k]);
      }

      return p.replace(/\[(\w+)\]/g, (_, k) => query[k]);
    }

    return p;
  });

  return paths?.join('/') || url;
};

export const resolveArticleUrl = (
  article: {
    categories?: { _id?: string; _type?: string; isPage?: boolean; slug?: SanitySlug }[];
    slug?: SanitySlug;
  },
  locale: string,
) => {
  const { categories = [] } = article;
  const isPage = categories?.find((c) => c.isPage);
  if (isPage && isPage._id) {
    return (
      prefixLocale(locale, 'en') +
      getLocalizedUrl(`/${sanityCategorySlugs[isPage._id]}/[slug]`, locale, {
        slug: article?.slug?.current,
      })
    );
  }

  const isCryptocurrency = categories?.find((c) => c._type === 'cryptocurrency');
  if (isCryptocurrency) {
    return (
      prefixLocale(locale, 'en') +
      getLocalizedUrl(`/cryptocurrency/[coin]/[slug]`, locale, {
        coin: isCryptocurrency.slug?.current,
        slug: article?.slug?.current,
      })
    );
  }

  return null;
};

export const prefixLocale = (
  locale: NextRouter['locale'],
  defaultLocale: NextRouter['defaultLocale'],
) => (locale !== defaultLocale ? `/${locale}` : '');

export const swapTestUrl = (url: string): string => {
  if (!url) return url;
  const origin = 'https://web-landing.testfiri.com';

  if (url.includes('https://www.firi.com') || url.includes('https://firi.com')) {
    url = url.replace('https://www.firi.com', '');
    url = url.replace('https://firi.com', '');

    return origin + url;
  }

  return url;
};

export const stripTrailingSlash = (url: string) => {
  if (!url) return url;
  return url.replace(/\/+$/, '');
};

export const validateLocale = (url: string) => {
  if (!url) return url;
  if (url.endsWith('/en')) {
    return url.replace('/en', '/');
  }
  if (url.includes('/en/')) {
    return url.replace('/en/', '/');
  }
  return url;
};

export const removeWWW = (url: string) => {
  if (!url) return url;
  return url.replace('www.', '');
};

export const replaceHTTP = (url: string) => {
  if (!url) return url;

  return url.replace('http:', 'https:');
};

export const getRelativePath = (url: string) => {
  if (!url) return url;

  return url.replace('https://firi.com', '');
};

export const transformSanityUrl = (url: string) => {
  let newUrl = url;
  newUrl = stripTrailingSlash(newUrl);
  newUrl = validateLocale(newUrl);
  newUrl = removeWWW(newUrl);
  newUrl = replaceHTTP(newUrl);
  newUrl = getRelativePath(newUrl);
  return newUrl;
};
