import * as React from 'react';
import { SVGProps } from 'react';
const SvgVisaLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 38 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.54875 12.5919H6.33268L3.92102 3.21279C3.80655 2.78135 3.5635 2.39993 3.20599 2.22017C2.31377 1.76841 1.3306 1.40887 0.258057 1.22754V0.866446H5.43889C6.15392 0.866446 6.69019 1.40887 6.77957 2.03884L8.03088 8.80432L11.2454 0.866446H14.3721L9.54875 12.5919ZM16.1591 12.5919H13.1218L15.6228 0.866446H18.6601L16.1591 12.5919ZM22.591 4.11298C22.6804 3.48145 23.2167 3.12035 23.8423 3.12035C24.8255 3.02969 25.8965 3.21102 26.7903 3.66122L27.3265 1.13667C26.4327 0.775568 25.4496 0.594238 24.5573 0.594238C21.6094 0.594238 19.4643 2.21839 19.4643 4.47251C19.4643 6.18733 20.9838 7.08773 22.0563 7.63016C23.2167 8.17102 23.6636 8.53212 23.5742 9.07298C23.5742 9.88428 22.6804 10.2454 21.7882 10.2454C20.7156 10.2454 19.6431 9.97494 18.6615 9.52318L18.1252 12.0493C19.1978 12.4995 20.3581 12.6808 21.4307 12.6808C24.7361 12.7699 26.7903 11.1473 26.7903 8.71188C26.7903 5.6449 22.591 5.46514 22.591 4.11298ZM37.4182 12.5919L35.0065 0.866446H32.4161C31.8798 0.866446 31.3435 1.22754 31.1648 1.76841L26.699 12.5919H29.8257L30.4498 10.8787H34.2915L34.649 12.5919H37.4182ZM32.8634 4.02282L33.7556 8.44196H31.2546L32.8634 4.02282Z"
      fill="#172B85"
    />
  </svg>
);
export default SvgVisaLogo;
