import { gql, useQuery } from '@apollo/client';

import {
  DepositAccountQuery,
  DepositAccountQueryVariables,
  DepositBankAccountType,
} from '../gqlTypes';

export const QUERY = gql`
  query DepositAccount($type: DepositBankAccountType!) {
    deposit {
      bankAccount(type: $type) {
        accountNumber
        bankName
        country
        currency
        userIdentification
        userIdentificationType
      }
    }
  }
`;

export const useDepositAccount = (params: { type: DepositBankAccountType }) => {
  return useQuery<DepositAccountQuery, DepositAccountQueryVariables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      type: params.type,
    },
  });
};
