import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

import {
  FontColors,
  Icon,
  LandingTypography,
  LandingTypographyProps,
  WebTypography,
  WebTypographyProps,
} from '../../01-atoms';

export type ModalSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type ModalPosition = 'center' | 'top' | 'bottom-right';
export interface ModalProps extends React.ComponentPropsWithoutRef<'div'> {
  open?: boolean | undefined;
  onClose(): void;
  initialFocus?: React.MutableRefObject<HTMLElement | null> | undefined;
  size?: ModalSize;
  position?: ModalPosition;
  closeIconColor?: FontColors;
  hideCloseBtn?: boolean;
  scrollable?: boolean;
  disableBlur?: boolean;
  onCloseCompleted?: () => void;
}

interface ModalTitleLandingProps extends LandingTypographyProps {
  type: 'landing';
}
interface ModalTitleWebProps extends WebTypographyProps {
  type: 'web';
}

type ModalTitleProps = ModalTitleWebProps | ModalTitleLandingProps;

interface ModalDescriptionProps {
  // eslint-disable-next-line no-undef
  as: keyof HTMLElementTagNameMap;
  children: string | React.ReactNode;
}

function ModalTitle(props: ModalTitleProps) {
  if (props.type === 'landing') {
    const { as, type, ...restProps } = props;
    return <LandingTypography as={Dialog.Title} {...restProps} />;
  }

  if (props.type === 'web') {
    const { as, type, ...restProps } = props;
    return <WebTypography as={Dialog.Title} {...restProps} />;
  }
  return null;
}

function ModalDescription({ children, as: asProp }: ModalDescriptionProps) {
  return (
    <Dialog.Description as={asProp as React.ElementType}>
      {children}
    </Dialog.Description>
  );
}

const Modal = (props: ModalProps) => {
  const size = props.size || 'md';
  const position = props.position || 'center';

  useEffect(() => {
    if (props.scrollable && window.document.firstElementChild) {
      window.document.firstElementChild.style = 'overflow: auto !important';
    }
  }, []);

  const onTransitionEnd = () => {
    if (!props.open) {
      props.onCloseCompleted?.();
    }
  };

  return (
    <Transition appear show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50"
        onClose={props.onClose}
        initialFocus={props.initialFocus}
        onTransitionEnd={onTransitionEnd}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay
            className={twMerge(
              'fixed inset-0 bg-black bg-opacity-30 dark:bg-white dark:bg-opacity-[.12]',
              props.disableBlur ? '' : 'backdrop-blur-sm'
            )}
          />
        </Transition.Child>

        <div
          className={twMerge(
            'flex h-full justify-center items-start',
            position === 'bottom-right' && 'justify-end items-end'
          )}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={twMerge(
                'flex flex-col rounded-xl transform bg-white dark:bg-background-dark-1 overflow-y-auto max-h-[85vh]',
                size === 'xs' && 'w-[450px] px-6 pt-6 pb-8',
                size === 'sm' && 'w-[500px] px-8 pt-12 pb-10',
                size === 'md' && 'w-[600px] px-10 py-12',
                size === 'lg' && 'w-3/4 px-10 py-12',
                size === 'xl' && 'w-full px-10 py-12',
                props.className,
                position === 'center' ? 'my-auto' : '',
                position === 'top' ? 'mt-10' : '',
                position === 'bottom-right' ? 'mb-16 mr-16' : ''
              )}
            >
              {props.children}
              {props.hideCloseBtn ? null : (
                <button
                  onClick={props.onClose}
                  className="absolute top-4 right-4 rounded-full"
                >
                  <Icon
                    color={props.closeIconColor || '3'}
                    icon="close"
                    size="lg"
                  />
                </button>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export { Modal, ModalTitle, ModalDescription };
