import { gql, useQuery } from '@apollo/client';

import { ClientCountry } from '../../constants';
import {
  OpenBankingBanksQuery,
  OpenBankingBanksQueryVariables,
  OpenBankingCountryCode,
} from '../gqlTypes';

const QUERY = gql`
  query OpenBankingBanks($country: OpenBankingCountryCode!) {
    payment {
      openbankingBanks(country: $country) {
        displayName
        bankOfficialName
        providers {
          id
          name
          limitedTo
          personalIdentificationRequired
        }
      }
    }
  }
`;

export const useOpenBankingBanks = (params: { country: ClientCountry }) =>
  useQuery<OpenBankingBanksQuery, OpenBankingBanksQueryVariables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      country: params.country as OpenBankingCountryCode,
    }, // Enums vs string type
  });
