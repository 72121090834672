import { gql, useQuery } from '@apollo/client';

import { AccountFragment, SavingAgreementFragment } from '../fragments';
import { AccountGroupQuery, AccountGroupQueryVariables } from '../gqlTypes';

const QUERY = gql`
  query AccountGroup($id: ID!) {
    account {
      accountGroup(id: $id) {
        id
        uuid
        name
        type
        totalFiatValue {
          value
          valueWithFiat
          currency
        }
        accounts {
          ...AccountFragment
        }
        ... on SavingAccountGroup {
          savingsAgreement {
            ...SavingAgreementFragment
          }
        }
      }
    }
  }
  ${AccountFragment}
  ${SavingAgreementFragment}
`;

export const useAccountGroup = (params: { id?: string }) =>
  useQuery<AccountGroupQuery, AccountGroupQueryVariables>(QUERY, {
    variables: { id: params.id },
    skip: !params.id,
    nextFetchPolicy: 'cache-and-network',
  });
