import * as React from 'react';
import { SVGProps } from 'react';
const SvgSortHighToLow = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M4.87106 4L4.87107 19.1104H6.37107L6.37106 4L4.87106 4Z" />
    <path d="M9.24204 17L6.68154 19.5605C6.39804 19.844 6.02154 20 5.62104 20C5.22054 20 4.84404 19.844 4.56054 19.5605L2.00007 17L3.06057 15.9395L5.62104 18.5L8.18154 15.9395L9.24204 17Z" />
    <path d="M16 18.5L11 18.5L11 17L16 17L16 18.5Z" />
    <path d="M22 6.5L11 6.5L11 5L22 5L22 6.5Z" />
    <path d="M20 10.5L11 10.5L11 9L20 9L20 10.5Z" />
    <path d="M18 14.5L11 14.5L11 13L18 13L18 14.5Z" />
  </svg>
);
export default SvgSortHighToLow;
