import { gql, useMutation } from '@apollo/client';

import { WithdrawFiatBankIdMutation, WithdrawFiatBankIdMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation WithdrawFiatBankId($input: WithdrawFiatBankIdInput!) {
    withdrawFiatBankId(input: $input) {
      success
      message
    }
  }
`;

export const useWithdrawFiatBankId = () =>
  useMutation<WithdrawFiatBankIdMutation, WithdrawFiatBankIdMutationVariables>(mutation, {
    refetchQueries: ['Transaction'],
  });
