export const cacheOptions = {
  typePolicies: {
    Query: {
      fields: {
        search_v2: {
          keyArgs: ['entities', 'filter', 'accountGroupId', 'count'],
          merge(existing: any, incoming: any) {
            const existingRefs = existing?.results.map((r: any) => r.__ref);

            const results = [
              ...(existing?.results ?? []),
              ...(incoming?.results ?? []).filter((r: any) => !existingRefs?.includes(r.__ref)),
            ];

            return {
              hasMoreData: incoming.hasMoreData,
              nextPage: incoming.nextPage,
              results,
            };
          },
        },
        search_v3: {
          keyArgs: ['entities', 'filter', 'accountGroupId', 'count'],
          merge(existing: any, incoming: any) {
            const existingRefs = existing?.results.map((r: any) => r.__ref);

            const results = [
              ...(existing?.results ?? []),
              ...(incoming?.results ?? []).filter((r: any) => !existingRefs?.includes(r.__ref)),
            ];

            return {
              hasMoreData: incoming.hasMoreData,
              nextPage: incoming.nextPage,
              results,
            };
          },
        },
        market_v2: {
          fields: {
            bars: {
              keyArgs: ['input'],
            },
          },
          merge: true,
        },
        market: {
          merge: true,
        },
      },
    },
    Currency: { keyFields: ['symbol'] },
    AffiliateReward: {
      keyFields: ['uuid'],
    },
    Affiliate: {
      merge: true,
    },
    AccountEntity: {
      merge: true,
    },
    Portfolio: {
      fields: {
        myPortfolio: {
          merge: true,
        },
      },
      merge: true,
    },
    Calendar: {
      merge: true,
    },
    PNL: {
      fields: {
        yield: {
          keyArgs: ['input'],
          merge: true,
        },
        balance: {
          keyArgs: ['input'],
          merge: true,
        },
      },
      merge: true,
    },
    VoucherEntity: {
      merge: true,
    },
    Staking: {
      merge: true,
    },
    Tax: {
      merge: true,
    },
    Payment: {
      merge: true,
    },
    WithdrawEntity: {
      keyFields: ['withdrawLimit', ['currency']],
    },
  },
};
