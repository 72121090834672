import clsx from 'clsx';
import { motion } from 'framer-motion';
import React from 'react';

interface Props {
  size?: 'xs' | 'sm' | 'md' | 'lg';
}
export const LoadingDots = (props: Props) => {
  const size = props.size || 'md';
  return (
    <div className="flex w-max">
      <motion.div
        className="flex items-center justify-center gap-x-[1px]"
        variants={{
          hidden: {
            transition: {
              staggerChildren: 0.2,
            },
          },
          show: {
            transition: {
              staggerChildren: 0.2,
            },
          },
        }}
        initial="hidden"
        animate="show"
      >
        {[0, 1, 2].map((_, idx) => (
          <motion.span
            key={idx}
            className={clsx(
              'bg-blue-300 rounded-full',
              size === 'xs' ? 'h-1 w-1' : '',
              size === 'sm' ? 'h-2 w-2' : '',
              size === 'md' ? 'h-4 w-4' : '',
              size === 'lg' ? 'h-8 w-8' : ''
            )}
            variants={{ hidden: { opacity: 0 }, show: { opacity: 1 } }}
            transition={{
              duration: 0.75,
              repeat: Infinity,
              repeatType: 'mirror',
              ease: 'easeInOut',
            }}
          />
        ))}
      </motion.div>
    </div>
  );
};
