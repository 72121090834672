import { gql, useMutation } from '@apollo/client';

import { TinkInitiatePaymentMutation, TinkInitiatePaymentMutationVariables } from '../gqlTypes';

const MUTATION = gql`
  mutation TinkInitiatePayment($input: TinkInitiatePaymentInput!) {
    tinkInitiatePayment(input: $input) {
      success
      message
      url
      paymentId
    }
  }
`;

export const useTinkInitiatePayment = () =>
  useMutation<TinkInitiatePaymentMutation, TinkInitiatePaymentMutationVariables>(MUTATION);
