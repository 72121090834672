import { gql, useMutation } from '@apollo/client';

import { RegisterPushTokenMutation, RegisterPushTokenMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation RegisterPushToken($input: PushNotificationInput!) {
    registerPushToken(input: $input) {
      success
      message
    }
  }
`;

export const useRegisterPushToken = () =>
  useMutation<RegisterPushTokenMutation, RegisterPushTokenMutationVariables>(mutation);
