import * as React from 'react';
import { SVGProps } from 'react';
const SvgMitId = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#0060E6" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M23.9998 12.2611C23.977 12.3885 23.9576 12.5164 23.9306 12.6429C23.6902 13.7742 22.9365 14.4607 21.7624 14.7477C21.3576 14.8469 20.93 14.8804 20.5105 14.9081C20.1128 14.9346 19.7118 14.9138 19.3008 14.9138V9.10539C19.3122 9.09966 19.3202 9.09216 19.3283 9.09216C20.1593 9.08864 20.9926 9.04631 21.8069 9.26278C23.0982 9.60577 23.85 10.4439 23.9765 11.6898C23.9789 11.7144 23.9917 11.7378 23.9998 11.7621V12.2611Z" />
    <path d="M0 9.29346C0.352675 9.29478 0.70535 9.29963 1.05803 9.29566C1.14904 9.29478 1.20023 9.32079 1.24953 9.39265C1.80509 10.2039 2.36445 11.0128 2.92332 11.8218C2.94797 11.8576 2.97547 11.8915 3.01054 11.9378C3.22528 11.6248 3.43243 11.3232 3.63958 11.0217C4.01121 10.4798 4.38427 9.93889 4.75259 9.3953C4.80094 9.32388 4.85119 9.2939 4.94362 9.29522C5.2816 9.30051 5.62005 9.29743 5.9694 9.29743V14.9022H4.73078V11.3739L4.6957 11.3602C4.1354 12.145 3.5751 12.9302 2.99679 13.7409C2.41184 12.9302 1.84254 12.141 1.25143 11.3223V14.9145H0V9.29346Z" />
    <path d="M18.5137 14.9075H13.041C13.0889 14.3154 13.2439 13.763 13.625 13.2807C14.0383 12.7578 14.6095 12.4748 15.2907 12.3958C17.193 12.175 18.3795 13.1634 18.5141 14.9075H18.5137Z" />
    <path d="M8.95115 10.8224H9.6115V9.72461H10.824V10.8153H11.7754V11.7464H10.8354C10.8321 11.8126 10.8278 11.8597 10.8278 11.9065C10.8278 12.436 10.8269 12.965 10.8278 13.4945C10.8278 13.8736 11.0444 14.0482 11.4507 13.9865C11.5829 13.9662 11.7109 13.9195 11.8517 13.882C11.8536 13.9142 11.8574 13.9552 11.8579 13.9958C11.8584 14.2056 11.8579 14.4159 11.8579 14.6258C11.8579 14.8996 11.8593 14.9057 11.5758 14.9547C11.2407 15.0129 10.9032 15.0204 10.5681 14.945C10.0158 14.8206 9.675 14.4574 9.6295 13.9287C9.6115 13.7202 9.6148 13.5095 9.61385 13.2996C9.61195 12.8402 9.6134 12.3808 9.6134 11.9215C9.6134 11.8703 9.6134 11.8187 9.6134 11.7504H8.95115V10.8219V10.8224Z" />
    <path d="M15.7809 11.826C14.9538 11.8308 14.2641 11.2004 14.2588 10.4355C14.2536 9.63573 14.923 9.00132 15.7729 9C16.6138 8.99912 17.2888 9.62075 17.294 10.402C17.2997 11.188 16.6271 11.8211 15.7805 11.826H15.7809Z" />
    <path d="M7.0713 14.9067V10.8291H8.2843V14.9067H7.0713Z" />
    <path d="M7.6836 9.00001C8.08415 9.00177 8.41125 9.30729 8.40885 9.67674C8.40605 10.0484 8.0766 10.3504 7.67745 10.3477C7.27595 10.3451 6.94555 10.0374 6.95125 9.67101C6.95695 9.29715 7.28305 8.99824 7.6836 9.00001Z" />
  </svg>
);
export default SvgMitId;
