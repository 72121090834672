import * as React from 'react';
import { SVGProps } from 'react';
const SvgLoading = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 4C12.75 3.58579 12.4142 3.25 12 3.25C11.5858 3.25 11.25 3.58579 11.25 4V8.28108C11.25 8.69529 11.5858 9.03108 12 9.03108C12.4142 9.03108 12.75 8.69529 12.75 8.28108V4ZM12.75 15.7189C12.75 15.3047 12.4142 14.9689 12 14.9689C11.5858 14.9689 11.25 15.3047 11.25 15.7189V20C11.25 20.4142 11.5858 20.75 12 20.75C12.4142 20.75 12.75 20.4142 12.75 20V15.7189ZM8.28108 12.75C8.69529 12.75 9.03108 12.4142 9.03108 12C9.03108 11.5858 8.69529 11.25 8.28108 11.25H4C3.58579 11.25 3.25 11.5858 3.25 12C3.25 12.4142 3.58579 12.75 4 12.75H8.28108ZM18.1873 5.81293C18.4802 6.10582 18.4802 6.5807 18.1873 6.87359L15.1601 9.90077C14.8672 10.1937 14.3923 10.1937 14.0994 9.90077C13.8065 9.60788 13.8065 9.13301 14.0994 8.84011L17.1266 5.81293C17.4195 5.52004 17.8944 5.52004 18.1873 5.81293ZM9.90074 14.0995C10.1936 14.3924 10.1936 14.8672 9.90074 15.1601L6.87356 18.1873C6.58067 18.4802 6.10579 18.4802 5.8129 18.1873C5.52001 17.8944 5.52001 17.4195 5.8129 17.1266L8.84008 14.0995C9.13297 13.8066 9.60785 13.8066 9.90074 14.0995ZM8.84011 9.90045C9.133 10.1933 9.60787 10.1933 9.90077 9.90045C10.1937 9.60755 10.1937 9.13268 9.90077 8.83979L6.87359 5.81261C6.58069 5.51971 6.10582 5.51971 5.81293 5.81261C5.52003 6.1055 5.52003 6.58037 5.81293 6.87327L8.84011 9.90045Z"
      fill="#474AEE"
    />
  </svg>
);
export default SvgLoading;
