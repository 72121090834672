import * as React from 'react';
import { SVGProps } from 'react';
const SvgHalfStar = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.69333 0.21098C6.71686 0.148995 6.75893 0.0955855 6.81389 0.0578892C6.86884 0.0201928 6.93412 0 7.00098 0C7.06783 0 7.13311 0.0201928 7.18806 0.0578892C7.24302 0.0955855 7.28509 0.148995 7.30862 0.21098L8.97242 4.88379H13.6713C13.7385 4.88378 13.8041 4.90418 13.8592 4.94223C13.9143 4.98028 13.9564 5.03414 13.9796 5.09658C14.0029 5.15902 14.0063 5.22702 13.9894 5.29143C13.9724 5.35584 13.936 5.41357 13.8849 5.45682L9.95847 8.68142L11.6019 13.5717C11.6238 13.6372 11.6242 13.708 11.603 13.7738C11.5817 13.8396 11.5399 13.8969 11.4835 13.9376C11.4272 13.9782 11.3593 14.0001 11.2895 14C11.2198 13.9999 11.1519 13.9779 11.0957 13.937L7.00032 10.9605L2.90296 13.937C2.84668 13.9771 2.77909 13.9984 2.70982 13.9981C2.64055 13.9979 2.57316 13.9758 2.51724 13.9353C2.46133 13.8948 2.41977 13.8379 2.39849 13.7725C2.37722 13.7072 2.37731 13.637 2.39876 13.5717L4.04217 8.68142L0.115066 5.45682C0.0639952 5.41357 0.0275442 5.35584 0.0106104 5.29143C-0.00632333 5.22702 -0.00292476 5.15902 0.0203526 5.09658C0.0436299 5.03414 0.0856686 4.98028 0.140802 4.94223C0.195935 4.90418 0.261514 4.88378 0.32871 4.88379H5.02822L6.69333 0.21098Z"
      fill="#B3B3D6"
      fillOpacity={0.45}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 0V10.9608L2.90296 13.937C2.84668 13.9771 2.77909 13.9984 2.70982 13.9981C2.64055 13.9978 2.57316 13.9758 2.51724 13.9353C2.46133 13.8948 2.41977 13.8379 2.39849 13.7725C2.37722 13.7072 2.37731 13.637 2.39876 13.5717L4.04217 8.68142L0.115066 5.45682C0.0639952 5.41356 0.0275442 5.35584 0.0106104 5.29143C-0.00632333 5.22702 -0.00292476 5.15901 0.0203526 5.09658C0.0436299 5.03414 0.0856686 4.98027 0.140802 4.94223C0.195935 4.90418 0.261514 4.88378 0.32871 4.88379H5.02822L6.69333 0.210978C6.71686 0.148993 6.75893 0.0955841 6.81389 0.0578878C6.86858 0.0203748 6.93349 0.000195455 7 0Z"
      fill="#FBDD3E"
    />
  </svg>
);
export default SvgHalfStar;
