import * as React from 'react';
import { SVGProps } from 'react';
const SvgBell = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 23 29" fill="#12123B" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.604326 21.5742L3.69886 17.0284V11.357C3.69886 7.53234 6.42273 4.34418 10.0364 3.62565V1.87916C10.0364 1.02546 10.7284 0.333313 11.5822 0.333313C12.436 0.333313 13.128 1.02546 13.1281 1.87916V3.62558C16.7417 4.34405 19.4656 7.53221 19.4656 11.357V17.0283L22.5601 21.5741C22.886 22.0529 22.9206 22.6727 22.6497 23.1848C22.3788 23.6968 21.8471 24.017 21.2678 24.017H1.89656C1.3173 24.017 0.785585 23.6968 0.514738 23.1848C0.243892 22.6728 0.278363 22.053 0.604326 21.5742ZM20.4422 22.017L17.4656 17.6445V11.357C17.4656 8.505 15 5.58719 11.6666 5.58719C8.33331 5.58719 5.69886 8.50514 5.69886 11.357V17.6445L2.72229 22.017H20.4422Z"
    />
    <path d="M8.31836 25.6667C8.31836 27.5076 9.81074 29 11.6517 29C13.4926 29 14.985 27.5076 14.985 25.6667H12.985C12.985 26.4031 12.3881 27 11.6517 27C10.9153 27 10.3184 26.4031 10.3184 25.6667H8.31836Z" />
  </svg>
);
export default SvgBell;
