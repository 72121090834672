import * as React from 'react';
import { SVGProps } from 'react';
const SvgFormSent = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.5V3H14C15.1046 3 16 3.89543 16 5V9H14.5V4.5H12V5H7V4.5H4.5V19.5H14.5V15H16V19C16 20.1046 15.1046 21 14 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3H7V2.5H12ZM8 11.25V12.75H6V11.25H8ZM13 8.5H6V7H13V8.5ZM13 17H6V15.5H13V17Z"
      fill="#12123B"
    />
    <path d="M17 14L17 16L21 12L17 8L17 10L9.5 10L9.5 14L17 14Z" fill="#474AEE" />
  </svg>
);
export default SvgFormSent;
