import { gql, useQuery } from '@apollo/client';

import { PushPreferencesQuery } from '../gqlTypes';

const query = gql`
  query PushPreferences {
    getPushPreferences {
      firiFordelPushCommunication
      firiFordelEmailCommunication
      accountActivityPushCommunication
      accountActivityEmailCommunication
    }
  }
`;

export const useGetPushPreferences = () =>
  useQuery<PushPreferencesQuery>(query, { fetchPolicy: 'cache-and-network' });
