import * as React from 'react';
import { SVGProps } from 'react';
const SvgXmasTreeFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.3421 2.11961C11.6822 1.70395 12.3177 1.70394 12.6578 2.11961L16.9468 7.36169C17.4009 7.91668 17.006 8.74994 16.2889 8.74994H15.4999L18.9799 13.3899C19.4002 13.9503 19.0004 14.7499 18.2999 14.7499H17.0826L20.4069 18.8129C20.909 19.4266 20.3717 20.3327 19.5924 20.1866L12.75 18.9036V21.5C12.75 21.7761 12.5261 22 12.25 22H11.75C11.4739 22 11.25 21.7761 11.25 21.5V18.9036L4.40754 20.1866C3.62821 20.3327 3.09094 19.4266 3.59303 18.8129L6.91726 14.7499H5.69994C4.9995 14.7499 4.59968 13.9503 5.01994 13.3899L8.49994 8.74994H7.71097C6.99389 8.74994 6.59902 7.91668 7.0531 7.36169L11.3421 2.11961Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgXmasTreeFilled;
