import { gql, useLazyQuery, useQuery } from '@apollo/client';

import {
  CompleteOpenBankingQuery,
  CompleteOpenBankingQueryVariables,
  OpenBankingCompleteInput,
} from '../gqlTypes';

export const QUERY = gql`
  query CompleteOpenBanking($input: OpenBankingCompleteInput!) {
    openBankingCompleteTransfer(input: $input) {
      amount
      paymentId
    }
  }
`;

export const useCompleteOpenBanking = (params: OpenBankingCompleteInput, skip?: boolean) =>
  useQuery<CompleteOpenBankingQuery, CompleteOpenBankingQueryVariables>(QUERY, {
    skip: !params.sessionId || !params.deviceId || skip,
    variables: {
      input: {
        sessionId: params.sessionId || '',
        deviceId: params.deviceId || '',
      },
    },
  });

export const useLazyCompleteOpenBanking = (params: OpenBankingCompleteInput) =>
  useLazyQuery<CompleteOpenBankingQuery, CompleteOpenBankingQueryVariables>(QUERY, {
    variables: {
      input: {
        sessionId: params.sessionId || '',
        deviceId: params.deviceId,
      },
    },
  });
