import { gql, useQuery } from '@apollo/client';

import { LightningInvoiceQuery, LightningInvoiceQueryVariables } from './../gqlTypes';

const query = gql`
  query LightningInvoice($invoice: String!) {
    lightningDeposit(invoice: $invoice) {
      invoice
      state
    }
  }
`;

export const useLightningInvoice = () =>
  useQuery<LightningInvoiceQuery, LightningInvoiceQueryVariables>(query);
