import { gql, useQuery } from '@apollo/client';

import { ProfilePictureQuery } from '../gqlTypes';

const query = gql`
  query ProfilePicture {
    currentUser {
      id
      firstName
      lastName
      profilePicture
    }
  }
`;

export const useProfilePicture = () =>
  useQuery<ProfilePictureQuery>(query, { fetchPolicy: 'cache-and-network' });
