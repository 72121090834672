import * as React from 'react';
import { SVGProps } from 'react';
const SvgMarket = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M7.5 21H1.5V13.5C1.5 12.6728 2.17275 12 3 12H6C6.82725 12 7.5 12.6728 7.5 13.5V21ZM3 19.5H6V13.5H3V19.5Z" />
    <path d="M15 21H9V9C9 8.17275 9.67275 7.5 10.5 7.5H13.5C14.3273 7.5 15 8.17275 15 9V21ZM10.5 19.5H13.5V9H10.5V19.5Z" />
    <path d="M22.5 21H16.5V4.5C16.5 3.67275 17.1728 3 18 3H21C21.8273 3 22.5 3.67275 22.5 4.5V21ZM18 19.5H21V4.5H18V19.5Z" />
  </svg>
);
export default SvgMarket;
