import { gql, useQuery } from '@apollo/client';

import { TaxRebuildingQuery } from '../gqlTypes';

const query = gql`
  query TaxRebuilding {
    tax {
      rebuilding
    }
  }
`;
export const useTaxRebuilding = () =>
  useQuery<TaxRebuildingQuery>(query, {
    fetchPolicy: 'cache-and-network',
  });
