import { gql, useQuery } from '@apollo/client';

import { multiply } from '../../utils/numbers';
import { MarketBarQuery, MarketBarQueryVariables, MarketType } from '../gqlTypes';

export const MARKET_BARS_QUERY = gql`
  query MarketBar($market: ID!, $barInput: MarketInput!, $marketType: MarketType) {
    market: market_v2(symbol: $market, marketType: $marketType) {
      symbol
      lastPrice
      change
      baseCurrency {
        symbol
        name
      }
      quoteCurrency {
        symbol
        name
      }
      bars(input: $barInput) {
        value: close
        date
        close
        high
        low
        open
        volume
      }
    }
  }
`;
export const useMarketBar = (params: {
  market: string;
  from: string;
  to: string;
  marketType: MarketType;
}) => {
  const res = useQuery<MarketBarQuery, MarketBarQueryVariables>(MARKET_BARS_QUERY, {
    variables: {
      barInput: { from: params.from, to: params.to },
      market: params.market,
      marketType: params.marketType,
    },
  });
  const market = res.data?.market;

  const firstValue = parseFloat(market?.bars?.find((p) => !!p?.value)?.value ?? '0');
  const lastValue = parseFloat(
    [...(market?.bars ?? [])]?.reverse().find((p) => !!p?.value)?.value ?? '0',
  );

  const priceChange =
    firstValue && lastValue
      ? parseFloat(multiply((lastValue - firstValue) / firstValue, 100, 1))
      : null;

  return {
    ...res,
    priceChange,
    firstValue,
    lastValue,
  };
};
