import { gql, useMutation } from '@apollo/client';

import { DeativateStakingMutation, DeativateStakingMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation DeativateStaking($input: DeactivateStakingInput!) {
    deactivateStaking(input: $input) {
      success
    }
  }
`;

export const useDeactivateStaking = () =>
  useMutation<DeativateStakingMutation, DeativateStakingMutationVariables>(mutation, {
    refetchQueries: [
      'StakedAssets',
      'StakedAsset',
      'StakingStats',
      'StakingAssetsAttainable',
      'StakingAssetsAvailable',
      'Transaction',
      'UnstakingRequests',
    ],
  });
