import * as React from 'react';
import { SVGProps } from 'react';
const SvgTrade = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M22.5 3.75H18C17.586 3.75 17.25 4.086 17.25 4.5V9H18.75V6.05475C20.2208 7.72275 21.0855 9.93675 20.9933 12.3502C20.8155 17.007 17.0595 20.7855 12.4035 20.991C11.2065 21.0435 10.0583 20.8605 9 20.4855V22.0627C10.0673 22.3815 11.205 22.536 12.3833 22.4932C17.898 22.2945 22.3493 17.8012 22.4963 12.285C22.5675 9.606 21.6293 7.14375 20.0355 5.25075H22.5V3.75Z" />
    <path d="M11.6678 1.50522C6.1365 1.67772 1.65675 6.17322 1.5045 11.7052C1.43025 14.388 2.37 16.854 3.96525 18.75H1.5V20.25H5.514C5.53725 20.2687 5.5605 20.2875 5.5845 20.3062V20.25H6C6.414 20.25 6.75 19.914 6.75 19.5V15H5.25V17.9452C3.77925 16.2772 2.9145 14.0632 3.00675 11.6497C3.1845 6.99222 6.9405 3.21447 11.5965 3.00897C12.7935 2.95647 13.9418 3.13947 15 3.51447V1.95597H15.0623C13.9928 1.62897 12.8513 1.46847 11.6678 1.50522Z" />
    <path d="M12 16.5C9.519 16.5 7.5 14.481 7.5 12C7.5 9.519 9.519 7.5 12 7.5C14.481 7.5 16.5 9.519 16.5 12C16.5 14.481 14.481 16.5 12 16.5ZM12 9C10.3455 9 9 10.3455 9 12C9 13.6545 10.3455 15 12 15C13.6545 15 15 13.6545 15 12C15 10.3455 13.6545 9 12 9Z" />
  </svg>
);
export default SvgTrade;
