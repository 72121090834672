import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';

import { BannerArea, BannersQuery, BannersQueryVariables, Symbol } from './../gqlTypes';

const query = gql`
  query Banners($input: BannersInput) {
    banners(input: $input) {
      id
      area
      type
      currency
      no {
        title
        content
      }
      en {
        title
        content
      }
      da {
        title
        content
      }
      irremovable
    }
  }
`;

type Params = {
  area?: BannerArea;
  options?: QueryHookOptions;
  currency?: Symbol; // Symbol to filter banners by currency
};

export const useBanners = (params: Params) => {
  const { data, loading, error, refetch } = useQuery<BannersQuery, BannersQueryVariables>(query, {
    ...params.options,
    variables: { input: { currency: params.currency } },
  });
  const { i18n } = useLingui();

  const bannersFiltered = params.area
    ? data?.banners
        .filter((banner) => banner?.area === params.area)
        .filter((banner) => !params.currency || banner?.currency === params.currency)
        .map((banner: any) => ({
          ...banner,
          data: banner?.[i18n.locale],
        }))
    : [];

  return {
    banners: bannersFiltered || [],
    refetch,
    loading,
    error,
  };
};
