import * as React from 'react';
import { SVGProps } from 'react';
const SvgPolDark = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_439_419)">
      <path
        d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12Z"
        fill="white"
      />
      <path
        d="M10.5162 10.2828L9.40349 9.63876L6.06513 11.5708V15.4347L9.40349 17.3668L12.742 15.4347V9.42402L14.5966 8.35076L16.4514 9.42402V11.5708L14.5966 12.6441L13.4838 12.0002V13.7174L14.5966 14.3614L17.935 12.4294V8.56549L14.5966 6.63342L11.2582 8.56549V14.5762L9.40349 15.6494L7.54889 14.5762V12.4294L9.40349 11.3561L10.5162 12V10.2828Z"
        fill="#6C00F6"
      />
    </g>
    <defs>
      <clipPath id="clip0_439_419">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPolDark;
