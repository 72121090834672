import { gql, useQuery } from '@apollo/client';

import { StakingAssetsAvailableQuery } from './../gqlTypes';

const query = gql`
  query StakingAssetsAvailable {
    staking {
      notStakedCurrenciesWithHold {
        currency
        fiatCurrency
        name
        type
        minimumStaked
        lockPeriod
        rewardDelay
        epochLength
        apy
        price
      }
    }
  }
`;

export const useStakingAssetsAvailable = () => useQuery<StakingAssetsAvailableQuery>(query);
