import { gql, useMutation } from '@apollo/client';

import {
  AdyenSubmitPaymentDetailsMutation,
  AdyenSubmitPaymentDetailsMutationVariables,
} from '../gqlTypes';

const mutation = gql`
  mutation AdyenSubmitPaymentDetails($input: AdyenSubmitPaymentDetailsInput!) {
    adyenSubmitPaymentDetails(input: $input) {
      success
      resultCode
    }
  }
`;

export const useAdyenSubmitPaymentDetails = () =>
  useMutation<AdyenSubmitPaymentDetailsMutation, AdyenSubmitPaymentDetailsMutationVariables>(
    mutation,
  );
