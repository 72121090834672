import { gql } from '@apollo/client';

export const AccountFragment = gql`
  fragment AccountFragment on Account {
    id
    currency
    currencyName
    balance
    hold
    available
    fiatValues {
      currency
      balance
      hold
      available
    }
    fiatValue {
      currency
      balance
      hold
      available
    }
  }
`;
