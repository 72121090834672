import { t } from '@lingui/macro';
import { getLocalizedUrl, prefixLocale } from 'helpers/url';
import { useRouter } from 'next/router';

import { Meta } from './Meta';

type Props = {
  alternate?: Record<string, string>;
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
};

export const DefaultMeta = ({ alternate, title, description, keywords, image }: Props) => {
  const router = useRouter();

  const defaultImage =
    router.locale === 'dk'
      ? 'https://firi.com/images/banner-dk.jpg'
      : 'https://firi.com/images/banner.jpg';

  return (
    <Meta
      title={title || t`Buy bitcoin | Firi - The largest cryptocurrency exchange in the Nordics`}
      description={
        description ||
        t`Buy and sell bitcoin and other cryptocurrencies for NOK. We are registered as an exchange and custodian for cryptocurrency in Norway.`
      }
      image={image || defaultImage}
      keywords={keywords}
      opengraph={{
        type: 'website',
        url:
          'https://firi.com' +
          prefixLocale(router.locale, router.defaultLocale) +
          getLocalizedUrl(router.route, router.locale, router.query),
      }}
      locale={{
        current: router.locale || '',
        alternate: Object.keys(alternate || {})?.filter((key) => key !== router.locale),
      }}
    />
  );
};
