import * as React from 'react';
import { SVGProps } from 'react';
const SvgHappy = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#12123B" {...props}>
    <path d="M12 22.5C6.21 22.5 1.5 17.79 1.5 12C1.5 6.21 6.21 1.5 12 1.5C17.79 1.5 22.5 6.21 22.5 12C22.5 17.79 17.79 22.5 12 22.5ZM12 3C7.03725 3 3 7.03725 3 12C3 16.9628 7.03725 21 12 21C16.9628 21 21 16.9628 21 12C21 7.03725 16.9628 3 12 3Z" />
    <path d="M11.8889 19.1624C8.82987 19.1624 6.09112 17.226 5.07525 14.3446C5.02537 14.2029 5 14.0559 5 13.9071C5 13.5571 5.1365 13.2272 5.38413 12.9787C5.63175 12.7311 5.96162 12.5946 6.3125 12.5946H17.453C17.6035 12.5946 17.7514 12.62 17.8922 12.6699C18.2212 12.7862 18.4864 13.0251 18.6377 13.341C18.7891 13.6569 18.8075 14.0139 18.6911 14.3446C17.9614 16.4149 16.3549 18.0205 14.2846 18.7511C13.5094 19.0241 12.7035 19.1624 11.8889 19.1624ZM6.31163 13.9071C7.14462 16.2661 9.38462 17.8499 11.888 17.8499C12.5547 17.8499 13.2136 17.7361 13.8471 17.513C15.5411 16.9154 16.8554 15.6011 17.4521 13.9071H6.31163Z" />
    <path d="M17.5642 10.6259C17.2859 10.6259 17.0374 10.45 16.9455 10.1875C16.8292 9.85762 16.5903 9.59163 16.2744 9.44113C16.095 9.35538 15.9052 9.31163 15.71 9.31163C15.5613 9.31163 15.4143 9.337 15.2717 9.38775C14.8954 9.52075 14.604 9.81213 14.471 10.1884C14.3783 10.45 14.1298 10.6259 13.8524 10.6259C13.778 10.6259 13.7045 10.6128 13.6337 10.5882C13.4692 10.5296 13.3362 10.4106 13.26 10.2523C13.1848 10.0939 13.1752 9.91625 13.2338 9.75088C13.4989 8.99925 14.0825 8.41562 14.8342 8.1505C15.1185 8.05075 15.4134 8 15.71 8C16.102 8 16.4827 8.08663 16.8405 8.25725C17.4732 8.56 17.95 9.09025 18.1837 9.75175C18.2423 9.91713 18.2327 10.0948 18.1574 10.2531C18.0822 10.4115 17.9492 10.5305 17.7838 10.5891C17.7112 10.6136 17.6377 10.6259 17.5642 10.6259Z" />
    <path d="M9.91391 10.6259C9.63653 10.6259 9.38803 10.45 9.29528 10.1884C9.11066 9.66425 8.61278 9.3125 8.05628 9.3125C7.90841 9.3125 7.76141 9.33788 7.62053 9.38775C7.24428 9.52075 6.95291 9.81213 6.81991 10.1884C6.72716 10.45 6.47866 10.6259 6.20128 10.6259C6.12691 10.6259 6.05341 10.6128 5.98253 10.5882C5.81716 10.5296 5.68503 10.4106 5.60891 10.2523C5.53366 10.0939 5.52403 9.91625 5.58266 9.75088C5.84778 8.99925 6.43141 8.41562 7.18303 8.1505C7.46478 8.05075 7.75878 8 8.05541 8C9.16753 8 10.1624 8.7035 10.5325 9.75088C10.6533 10.0921 10.473 10.4675 10.1327 10.5882C10.0618 10.6136 9.98828 10.6259 9.91391 10.6259Z" />
  </svg>
);
export default SvgHappy;
