import * as React from 'react';
import { SVGProps } from 'react';
const SvgGooglePay = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 31 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.5511 5.78653V9.10784H13.4533V0.906128H16.3638C17.1014 0.906128 17.7304 1.14219 18.245 1.61431C18.7711 2.08643 19.0341 2.66285 19.0341 3.34358C19.0341 4.04078 18.7711 4.61721 18.245 5.08384C17.7361 5.55047 17.1071 5.78104 16.3638 5.78104H14.5511V5.78653ZM14.5511 1.91625V4.77641H16.3867C16.8212 4.77641 17.1872 4.63368 17.4731 4.3537C17.7647 4.07372 17.9134 3.73336 17.9134 3.34907C17.9134 2.97028 17.7647 2.6354 17.4731 2.35543C17.1872 2.06447 16.8269 1.92173 16.3867 1.92173H14.5511V1.91625Z"
      fill="currentColor"
    />
    <path
      d="M21.9046 3.31067C22.7166 3.31067 23.357 3.51928 23.8259 3.9365C24.2948 4.35372 24.5292 4.92466 24.5292 5.64931V9.10786H23.4828V8.32831H23.4371C22.9853 8.97062 22.3792 9.28902 21.6244 9.28902C20.9783 9.28902 20.4408 9.10786 20.0062 8.74005C19.5716 8.37223 19.3543 7.91658 19.3543 7.36761C19.3543 6.78569 19.5831 6.32455 20.0405 5.98418C20.498 5.63833 21.1098 5.46815 21.8703 5.46815C22.5222 5.46815 23.0597 5.58343 23.4771 5.814V5.57245C23.4771 5.20464 23.3284 4.89721 23.0254 4.63919C22.7223 4.38117 22.3678 4.25491 21.9618 4.25491C21.35 4.25491 20.8639 4.50195 20.5094 5.00152L19.543 4.4196C20.0748 3.67848 20.8639 3.31067 21.9046 3.31067ZM20.4865 7.38407C20.4865 7.65856 20.6066 7.88913 20.8525 8.07029C21.0926 8.25146 21.3785 8.34478 21.7045 8.34478C22.1676 8.34478 22.5793 8.18009 22.9396 7.8507C23.2998 7.52132 23.4828 7.13704 23.4828 6.69236C23.1397 6.43435 22.6651 6.30259 22.0533 6.30259C21.6073 6.30259 21.2356 6.4069 20.9383 6.61002C20.6352 6.82412 20.4865 7.08214 20.4865 7.38407Z"
      fill="currentColor"
    />
    <path
      d="M30.4989 3.49182L26.8393 11.5728H25.7071L27.068 8.74553L24.655 3.49182H25.8501L27.5884 7.52131H27.6113L29.3038 3.49182H30.4989Z"
      fill="currentColor"
    />
    <path
      d="M10.1842 5.11676C10.1842 4.7731 10.1522 4.44426 10.0928 4.12805H5.49084V5.93967L8.14118 5.94022C8.03368 6.543 7.68773 7.05684 7.15767 7.3994V8.57476H8.73529C9.65647 7.75624 10.1842 6.54629 10.1842 5.11676Z"
      fill="#4285F4"
    />
    <path
      d="M7.15824 7.39941C6.71909 7.68378 6.15357 7.85012 5.49199 7.85012C4.214 7.85012 3.12985 7.02336 2.74159 5.90894H1.11423V7.12108C1.92048 8.65711 3.57758 9.71115 5.49199 9.71115C6.81516 9.71115 7.92675 9.29338 8.73586 8.57422L7.15824 7.39941Z"
      fill="#34A853"
    />
    <path
      d="M2.58836 5.00974C2.58836 4.69682 2.64268 4.39434 2.74161 4.10997V2.89783H1.11424C0.780875 3.53299 0.593323 4.24996 0.593323 5.00974C0.593323 5.76952 0.781447 6.48649 1.11424 7.12165L2.74161 5.90951C2.64268 5.62514 2.58836 5.32266 2.58836 5.00974Z"
      fill="#FABB05"
    />
    <path
      d="M5.49199 2.16877C6.21419 2.16877 6.8609 2.40757 7.37153 2.8742L8.7696 1.53305C7.92046 0.773821 6.81344 0.307739 5.49199 0.307739C3.57815 0.307739 1.92048 1.36177 1.11423 2.89781L2.74159 4.10995C3.12985 2.99553 4.214 2.16877 5.49199 2.16877Z"
      fill="#E94235"
    />
  </svg>
);
export default SvgGooglePay;
