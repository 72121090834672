import * as React from 'react';
import { SVGProps } from 'react';
const SvgQr = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_1070_1347)">
      <path d="M8.54005 5.79999H6.08005C5.92541 5.79999 5.80005 5.92535 5.80005 6.07999V8.53999C5.80005 8.69463 5.92541 8.81999 6.08005 8.81999H8.54005C8.69469 8.81999 8.82005 8.69463 8.82005 8.53999V6.07999C8.82005 5.92535 8.69469 5.79999 8.54005 5.79999Z" />
      <path d="M8.54005 15.4H6.08005C5.92541 15.4 5.80005 15.5254 5.80005 15.68V18.14C5.80005 18.2947 5.92541 18.42 6.08005 18.42H8.54005C8.69469 18.42 8.82005 18.2947 8.82005 18.14V15.68C8.82005 15.5254 8.69469 15.4 8.54005 15.4Z" />
      <path d="M18.1399 5.79999H15.6799C15.5253 5.79999 15.3999 5.92535 15.3999 6.07999V8.53999C15.3999 8.69463 15.5253 8.81999 15.6799 8.81999H18.1399C18.2945 8.81999 18.4199 8.69463 18.4199 8.53999V6.07999C18.4199 5.92535 18.2945 5.79999 18.1399 5.79999Z" />
      <path d="M18.1399 15.4H15.6799C15.5253 15.4 15.3999 15.5254 15.3999 15.68V18.14C15.3999 18.2947 15.5253 18.42 15.6799 18.42H18.1399C18.2945 18.42 18.4199 18.2947 18.4199 18.14V15.68C18.4199 15.5254 18.2945 15.4 18.1399 15.4Z" />
      <path d="M19.4401 12.6H14.3701C13.9007 12.6 13.4505 12.7865 13.1185 13.1184C12.7866 13.4503 12.6001 13.9005 12.6001 14.37V16.69H14.1001V14.37C14.1001 14.2984 14.1285 14.2297 14.1792 14.1791C14.2298 14.1284 14.2985 14.1 14.3701 14.1H19.4401C19.5117 14.1 19.5804 14.1284 19.631 14.1791C19.6817 14.2297 19.7101 14.2984 19.7101 14.37V19.44C19.7101 19.5116 19.6817 19.5803 19.631 19.6309C19.5804 19.6815 19.5117 19.71 19.4401 19.71H12.6301C12.6924 20.127 12.9023 20.5077 13.2216 20.783C13.5409 21.0583 13.9485 21.2098 14.3701 21.21H19.4401C19.9095 21.21 20.3597 21.0235 20.6917 20.6916C21.0236 20.3596 21.2101 19.9094 21.2101 19.44V14.37C21.2101 13.9005 21.0236 13.4503 20.6917 13.1184C20.3597 12.7865 19.9095 12.6 19.4401 12.6Z" />
      <path d="M9.84 12.6H7.5C7.5 12.6 7.5 12.6 7.5 12.65V14.1H9.84C9.91256 14.0999 9.9823 14.1281 10.0345 14.1784C10.0868 14.2288 10.1174 14.2975 10.12 14.37V19.44C10.1174 19.5125 10.0868 19.5812 10.0345 19.6315C9.9823 19.6819 9.91256 19.71 9.84 19.71H4.77C4.69839 19.71 4.62972 19.6815 4.57908 19.6309C4.52845 19.5803 4.5 19.5116 4.5 19.44V12.65C4.08807 12.7155 3.71223 12.9236 3.43808 13.238C3.16393 13.5523 3.00888 13.953 3 14.37V19.44C3 19.9094 3.18648 20.3596 3.51842 20.6916C3.85036 21.0235 4.30057 21.21 4.77 21.21H9.84C10.3104 21.21 10.7616 21.0238 11.0951 20.6922C11.4287 20.3605 11.6174 19.9103 11.62 19.44V14.37C11.6174 13.8996 11.4287 13.4494 11.0951 13.1178C10.7616 12.7861 10.3104 12.6 9.84 12.6Z" />
      <path d="M11.56 7.52H10.12V9.84C10.1175 9.91347 10.0872 9.98325 10.0352 10.0352C9.98325 10.0872 9.91347 10.1175 9.84 10.12H4.77C4.69749 10.1174 4.62881 10.0868 4.57844 10.0345C4.52808 9.9823 4.49995 9.91256 4.5 9.84V4.77C4.5 4.69839 4.52845 4.62972 4.57908 4.57908C4.62972 4.52845 4.69839 4.5 4.77 4.5H11.59C11.5236 4.08305 11.3111 3.70328 10.9906 3.42851C10.67 3.15374 10.2622 3.00186 9.84 3H4.77C4.30138 3.00263 3.85269 3.18995 3.52132 3.52132C3.18995 3.85269 3.00263 4.30138 3 4.77V9.84C2.99999 10.3104 3.18615 10.7616 3.51781 11.0951C3.84946 11.4287 4.29965 11.6174 4.77 11.62H9.84C10.3121 11.62 10.7648 11.4325 11.0986 11.0986C11.4325 10.7648 11.62 10.3121 11.62 9.84V7.5L11.56 7.52Z" />
      <path d="M19.4401 3H14.3701C13.9007 3 13.4505 3.18648 13.1185 3.51842C12.7866 3.85036 12.6001 4.30057 12.6001 4.77V9.84C12.6001 10.3104 12.7863 10.7616 13.1179 11.0951C13.4496 11.4287 13.8997 11.6174 14.3701 11.62H16.6901V10.12H14.3701C14.2976 10.1174 14.2289 10.0868 14.1785 10.0345C14.1282 9.9823 14.1001 9.91256 14.1001 9.84V4.77C14.1001 4.69839 14.1285 4.62972 14.1792 4.57908C14.2298 4.52845 14.2985 4.5 14.3701 4.5H19.4401C19.5117 4.5 19.5804 4.52845 19.631 4.57908C19.6817 4.62972 19.7101 4.69839 19.7101 4.77V9.84V11.59C20.1279 11.5255 20.5088 11.3136 20.784 10.9926C21.0591 10.6716 21.2103 10.2628 21.2101 9.84V4.77C21.2101 4.30057 21.0236 3.85036 20.6917 3.51842C20.3597 3.18648 19.9095 3 19.4401 3V3Z" />
    </g>
    <defs>
      <clipPath id="clip0_1070_1347">
        <rect width={18.21} height={18.21} fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgQr;
