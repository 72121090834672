export function isArray(input: unknown): input is any[] {
  return Array.isArray(input);
}

export const isEmptyArray = <Type>(arr: Array<Type>): boolean => {
  return isArray(arr) && arr.length === 0;
};

export const isNotEmptyArray = <Type>(arr: Array<Type>): boolean => {
  return isArray(arr) && !isEmptyArray(arr);
};

export const isArrayLength = <Type>(arr: Array<Type>, length: number): boolean => {
  return isArray(arr) && arr.length === length;
};

export const splitArray = <Type>(arr: Array<Type>, index: number): Array<Type>[] => {
  return [arr.slice(0, index), arr.slice(index)];
};

export function definedFilter<T>(array?: (T | undefined | null)[]): T[] {
  function isDefined(e: T | undefined | null): e is T {
    return !!e;
  }
  return array?.filter(isDefined) ?? [];
}

export const getUniqueValues = <T extends string | boolean | number>(array: Array<T>): Array<T> => {
  return array.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
};

export function shiftArrayValue<T>(selectedIndex: number, newIndex: number, array: T[]): T[] {
  if (
    selectedIndex < 0 ||
    selectedIndex >= array.length ||
    newIndex < 0 ||
    newIndex >= array.length
  ) {
    // Invalid index, return the original array
    return array;
  }
  const updatedArray = array.slice();
  updatedArray.splice(selectedIndex, 1);
  updatedArray.splice(newIndex, 0, array[selectedIndex]);

  return updatedArray;
}
