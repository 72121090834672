import { IconName } from '@firi/icons/web';
import clsx from 'clsx';
import React from 'react';
import type {
  MultiValueGenericProps,
  OptionProps,
  Props,
  SingleValueProps,
} from 'react-select';
import Select from 'react-select';

import * as components from './inputSelectComponents';

type InputSingleSelectType = false;
type InputMultipleSelectType = true;

export type InputSelectItemProps<
  IsMulti,
  Option extends InputSelectBaseOption = InputSelectBaseOption
> = IsMulti extends true
  ? MultiValueGenericProps<Option, true>
  : SingleValueProps<Option, false>;

interface InputSelectBaseOption {
  value: string;
  label: string;
  disabled?: boolean;
}

interface InputSelectExtraProps<
  Option extends InputSelectBaseOption = InputSelectBaseOption,
  IsMulti extends boolean = boolean
> {
  /** Add Icon on the left side */
  icon?: IconName;
  /** Hide the clear indicator */
  hideClearIndicator?: boolean;
  /** Hide the indicator separator */
  hideIndicatorSeparator?: boolean;
  /** Hide value remove button */
  hideValueRemove?: boolean;
  /** Invalid select */
  invalid?: boolean;
  /** Modify item component */
  item?: IsMulti extends true
    ? React.FunctionComponent<InputSelectItemProps<true, Option>>
    : React.FunctionComponent<InputSelectItemProps<false, Option>>;
  /** Modify menu item component */
  menuItem?: React.FunctionComponent<OptionProps<Option, IsMulti>>;
  label?: string;
}

type InputSelectProps<
  IsMulti extends boolean = boolean,
  Option extends InputSelectBaseOption = InputSelectBaseOption
> = Props<Option, IsMulti> & InputSelectExtraProps<Option, IsMulti>;

/** Style proxy removes all the styles included from react-select */
const styleProxy = new Proxy(
  {},
  {
    get: () => () => {},
  }
);

function InputSelect<
  IsMulti extends boolean = false,
  Option extends InputSelectBaseOption = InputSelectBaseOption
>({
  isSearchable = false,
  hideClearIndicator = true,
  hideIndicatorSeparator = true,
  className,
  ...props
}: InputSelectProps<IsMulti, Option>) {
  return (
    <Select
      className={clsx('text-text-light-1 dark:text-white', className)}
      isOptionDisabled={(option) => option.disabled ?? false}
      components={{ ...components, LoadingIndicator: undefined }}
      hideClearIndicator={hideClearIndicator}
      hideIndicatorSeparator={hideIndicatorSeparator}
      styles={styleProxy}
      isSearchable={isSearchable}
      {...props}
    />
  );
}

export type {
  InputSelectProps,
  InputSelectBaseOption,
  InputSelectExtraProps,
  InputSingleSelectType,
  InputMultipleSelectType,
  OptionProps as InputSelectMenuItemProps,
};
export { InputSelect };
