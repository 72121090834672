import { gql, useQuery, WatchQueryFetchPolicy } from '@apollo/client';

import { allMiscTypes } from '../../constants';
import { filter } from '../../jest.config';
import {
  TradeFragment,
  TransactionMiscFragment,
  TransferDepositFragment,
  TransferWithdrawFragment,
} from '../fragments';
import {
  SearchEntity,
  SearchInputFilter,
  SearchInputNextPage,
  TransactionQuery,
  TransactionsMiscTypes,
} from '../gqlTypes';

/**
 * TODO return account id for trades
 */
const QUERY = gql`
  query Transaction(
    $entities: [SearchEntity!]!
    $count: Int!
    $nextPage: [SearchInputNextPage!]
    $filter: SearchInputFilter
    $accountGroupId: [String!]
  ) {
    search: search_v3(
      entities: $entities
      count: $count
      nextPage: $nextPage
      filter: $filter
      accountGroupId: $accountGroupId
    ) {
      hasMoreData
      nextPage {
        entity
        afterId
      }
      results {
        ... on Trade {
          ...TradeFragment
          tradeDetails {
            ...TradeFragment
          }
        }
        ... on TransactionMisc {
          ...TransactionMiscFragment
        }
        ... on TransferWithdraw {
          ...TransferWithdrawFragment
        }
        ... on TransferDeposit {
          ...TransferDepositFragment
        }
      }
    }
  }
  ${TransferWithdrawFragment}
  ${TradeFragment}
  ${TransferDepositFragment}
  ${TransactionMiscFragment}
`;

export const useTransactionSearch = (
  input?: {
    entities?: SearchEntity[];
    count: number;
    nextPage?: SearchInputNextPage[];
    filter?: SearchInputFilter;
    accountGroupId?: string[];
  },
  options?: {
    skip?: boolean;
    fetchPolicy?: WatchQueryFetchPolicy;
    notifyOnNetworkStatusChange?: boolean;
  },
) => {
  const miscFilter = (input?.filter?.misc?.transactionTypes || allMiscTypes).filter(
    (t) => t !== TransactionsMiscTypes.DepositFee,
  );

  const filter = input?.filter
    ? { ...input.filter, misc: { transactionTypes: miscFilter } }
    : { misc: { transactionTypes: miscFilter } };

  const variables = {
    ...input,
    filter,
    entities: input?.entities ?? [
      SearchEntity.Deposit,
      SearchEntity.Misc,
      SearchEntity.Trade,
      SearchEntity.Withdraw,
    ],
  };

  const { fetchMore, ...result } = useQuery<TransactionQuery>(QUERY, {
    variables,
    skip: options?.skip,
    fetchPolicy: options?.fetchPolicy,
    notifyOnNetworkStatusChange: options?.notifyOnNetworkStatusChange,
  });

  const loadMore = () => {
    return fetchMore({
      variables: {
        ...variables,
        nextPage: result.data?.search?.nextPage.map((p) => ({
          entity: p.entity,
          afterId: p.afterId,
        })),
      },
    })?.catch((e) => {
      // TODO log to sentry
      console.log(e);
    });
  };

  return { ...result, loadMore };
};
