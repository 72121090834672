import type { CheckboxProps } from '@radix-ui/react-checkbox';
import { Checkbox, CheckboxIndicator } from '@radix-ui/react-checkbox';
import clsx from 'clsx';
import React from 'react';

import { WebTypography } from '../../01-atoms';

// Reference for API https://www.radix-ui.com/docs/primitives/components/checkbox#root

function CheckIcon() {
  return (
    <svg
      viewBox="0 0 16 11"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-current w-full h-full text-white w-4 h-[10px]"
    >
      <path
        d="M4.57143 9.81714L0 5.24571L1.24571 4.06857L5.81714 8.64L14.4343 0L15.6457 1.14286L6.99429 9.81714C6.66824 10.1273 6.23271 10.2957 5.78286 10.2857C5.33386 10.2909 4.9001 10.1231 4.57143 9.81714Z"
        fill="currentColor"
      />
    </svg>
  );
}

export type InputCheckboxProps = CheckboxProps & {
  variant?: 'rounded' | 'squared';
};
function InputCheckbox({
  id,
  className,
  variant = 'squared',
  ...props
}: InputCheckboxProps) {
  return (
    <Checkbox
      id={id}
      className={clsx(
        'w-6 h-6 flex items-center justify-center border focus:shadow-md relative overflow-hidden',
        props.disabled ? 'border-black-150' : ' border-firi-blue',
        variant === 'squared' ? 'rounded-[4px] ' : 'rounded-full',
        className
      )}
      {...props}
    >
      <CheckboxIndicator className="absolute -inset-px bg-firi-blue flex items-center justify-center">
        {props.checked === 'indeterminate' ? (
          <WebTypography color="white">--</WebTypography>
        ) : (
          <CheckIcon />
        )}
      </CheckboxIndicator>
    </Checkbox>
  );
}

export { InputCheckbox };
