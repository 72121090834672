import * as React from 'react';
import { SVGProps } from 'react';
const SvgSeBankId = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.4114 16.2803L11.7898 7.59194C11.2468 7.59194 10.3174 7.59194 10.3174 7.59194C9.62817 7.59194 8.74053 7.20556 8.47946 6.49545C8.39592 6.25527 8.19751 5.43029 9.33577 4.6262C9.74304 4.34425 10.0041 4.03096 10.0563 3.79078C10.1085 3.54015 10.0459 3.32085 9.86835 3.15377C9.61773 2.91359 9.12692 2.77783 8.50035 2.77783C7.44563 2.77783 6.7042 3.38351 6.6311 3.82211C6.57888 4.14583 6.82951 4.4069 7.04881 4.57399C7.7067 5.0648 7.86334 5.7749 7.45607 6.44324C7.03836 7.13246 6.12984 7.5815 5.15867 7.59194C5.15867 7.59194 4.19793 7.59194 3.65491 7.59194C3.5296 8.43781 1.48281 21.4077 1.32617 22.4311H9.45064C9.52374 21.9821 9.89968 19.528 10.4114 16.2803Z"
      fill="white"
    />
    <path
      d="M0.887635 25.4385H4.21888C5.63909 25.4385 5.9837 26.159 5.87927 26.8169C5.79573 27.3495 5.43024 27.7463 4.80367 28.0074C5.59732 28.3102 5.9106 28.7802 5.79573 29.5216C5.64953 30.451 4.84544 31.1402 3.79072 31.1402H0L0.887635 25.4385ZM3.09106 27.7985C3.73851 27.7985 4.04135 27.4539 4.104 27.0467C4.16666 26.6081 3.96825 26.3052 3.3208 26.3052H2.74645L2.5167 27.7985H3.09106ZM2.736 30.2526C3.40434 30.2526 3.79072 29.9811 3.88471 29.4276C3.95781 28.9472 3.68629 28.6653 3.03884 28.6653H2.39139L2.14077 30.263H2.736V30.2526Z"
      fill="white"
    />
    <path
      d="M10.4636 31.1821C9.59687 31.2447 9.17916 31.1507 8.9703 30.7748C8.51082 31.0567 7.99912 31.2029 7.4561 31.2029C6.47448 31.2029 6.12987 30.6913 6.22385 30.1273C6.26562 29.8558 6.42227 29.5948 6.67289 29.3755C7.21592 28.9055 8.55259 28.8429 9.07473 28.4878C9.1165 28.091 8.95986 27.9448 8.46905 27.9448C7.8947 27.9448 7.41433 28.1328 6.58935 28.6967L6.78776 27.4018C7.49787 26.8901 8.18709 26.6499 8.98074 26.6499C9.99369 26.6499 10.8918 27.0676 10.7247 28.1745L10.5263 29.4277C10.4532 29.8663 10.4741 30.002 10.9649 30.0125L10.4636 31.1821ZM8.95986 29.2084C8.50038 29.5008 7.64407 29.4486 7.55008 30.0542C7.50831 30.3362 7.68584 30.5451 7.96779 30.5451C8.23931 30.5451 8.57348 30.4302 8.84499 30.2422C8.8241 30.1378 8.83454 30.0334 8.86587 29.8349L8.95986 29.2084Z"
      fill="white"
    />
    <path
      d="M12.0821 26.723H13.8156L13.7216 27.2974C14.2751 26.8274 14.6928 26.6499 15.2358 26.6499C16.207 26.6499 16.656 27.2451 16.4994 28.2163L16.0504 31.1298H14.3169L14.6928 28.7176C14.7659 28.279 14.6301 28.0701 14.296 28.0701C14.0245 28.0701 13.7738 28.2163 13.5336 28.54L13.1368 31.1194H11.4033L12.0821 26.723Z"
      fill="white"
    />
    <path
      d="M17.8574 25.4385H19.5909L19.1523 28.2371L20.8127 26.7229H22.9534L20.8231 28.6026L22.5357 31.1298H20.3532L19.0374 29.083H19.0165L18.7032 31.1193H16.9697L17.8574 25.4385Z"
      fill="white"
    />
    <path d="M23.9875 25.4385H25.9821L25.1049 31.1298H23.1104L23.9875 25.4385Z" fill="white" />
    <path
      d="M26.9531 25.4385H29.8039C32.0074 25.4385 32.6444 27.0362 32.4355 28.3625C32.2371 29.6574 31.2137 31.1298 29.2818 31.1298H26.0654L26.9531 25.4385ZM28.8014 29.7722C29.7726 29.7722 30.3052 29.2919 30.4618 28.2789C30.5767 27.527 30.347 26.7856 29.2714 26.7856H28.7388L28.2793 29.7722H28.8014Z"
      fill="white"
    />
    <path
      d="M21.3347 0H13.0327L11.9258 7.02798H13.3356C14.1083 7.02798 14.8393 6.67292 15.1526 6.16123C15.257 5.99415 15.2988 5.84795 15.2988 5.71219C15.2988 5.41979 15.1004 5.20049 14.902 5.0543C14.3589 4.64703 14.2441 4.21888 14.2441 3.91604C14.2441 3.85338 14.2441 3.80116 14.2545 3.74895C14.3694 3.00751 15.3719 2.20342 16.6981 2.20342C17.4918 2.20342 18.0975 2.39139 18.463 2.736C18.7867 3.03884 18.912 3.467 18.818 3.91604C18.7031 4.44862 18.1706 4.88721 17.8677 5.10651C17.0636 5.67042 17.168 6.16123 17.2203 6.30743C17.3873 6.74602 18.0244 7.02798 18.5152 7.02798H20.6664C20.6664 7.02798 20.6664 7.02798 20.6664 7.03842C23.5903 7.05931 25.1568 8.40642 24.666 11.5393C24.2065 14.4528 21.9717 15.7059 19.3088 15.7268L18.2541 22.4519H19.8101C26.3786 22.4519 31.7461 18.2331 32.8009 11.5706C34.1062 3.29991 28.8535 0 21.3347 0Z"
      fill="white"
    />
  </svg>
);
export default SvgSeBankId;
