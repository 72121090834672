import { gql, useQuery } from '@apollo/client';
import { isAfter, parse } from 'date-fns';

import { DonationQuery } from '../gqlTypes';
import { Donation as DonationId } from './../../types';

export const DONATION_QUERY = gql`
  query Donation($organization: String!) {
    donation(organization: $organization) {
      name
      short_name
      start
      end
      active
      currencies {
        currency
        amount
        fiat {
          currency
          value
        }
      }
      total_donation {
        currency
        value
        users
      }
    }
  }
`;

export const useDonation = (donationId: DonationId) => {
  const res = useQuery<DonationQuery>(DONATION_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { organization: donationId },
  });

  const end = parse(res.data?.donation?.end ?? '', 'dd-MM-yyyy HH:mm:ss', new Date());

  return {
    ...res,
    // TODO remove once active state is handled by the backend
    inactive: res.data?.donation && res.data.donation.end && isAfter(new Date(), end),
  };
};
