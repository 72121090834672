import { gql, useMutation } from '@apollo/client';

import { PlaceOrderMutation, PlaceOrderMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation PlaceOrder($input: PlaceOrderInput!) {
    placeOrder(input: $input) {
      ok
      error {
        path
        message
      }
    }
  }
`;

export const usePlaceOrder = () => {
  return useMutation<PlaceOrderMutation, PlaceOrderMutationVariables>(mutation, {
    refetchQueries: ['BuyOrders', 'SellOrders', 'MarketOrders', 'Accounts', 'MarketHistory'],
  });
};
