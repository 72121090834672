import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { ShouldUpdateSavingsDepositQuery } from '../gqlTypes';

const query = gql`
  query ShouldUpdateSavingsDeposit {
    shouldUpdateSavingsDeposit
  }
`;

export const useShouldUpdateSavingsDeposit = (
  options?: QueryHookOptions<ShouldUpdateSavingsDepositQuery>,
) => useQuery<ShouldUpdateSavingsDepositQuery>(query, options);
