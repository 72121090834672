import * as React from 'react';
import { SVGProps } from 'react';
const SvgHandelsbanken = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={20} cy={20} r={20} fill="#003399" />
    <path
      d="M3.71477 19.887V21.4215H3V18H3.71477V19.2962H5.04905V18H5.76386V21.4215H5.04905V19.887H3.71477Z"
      fill="white"
    />
    <path
      d="M8.41801 20.9262C8.41801 21.2264 8.48474 21.2931 8.56574 21.3217V21.4218H7.84618C7.80805 21.3026 7.79853 21.2645 7.78421 21.1216C7.63172 21.2788 7.41731 21.4885 6.9646 21.4885C6.58337 21.4885 6.19263 21.2741 6.19263 20.7499C6.19263 20.2543 6.50713 20.0017 6.93123 19.9398L7.53163 19.8492C7.63648 19.8349 7.76513 19.792 7.76513 19.6491C7.76513 19.3679 7.49827 19.3537 7.32674 19.3537C6.99316 19.3537 6.93599 19.5586 6.91691 19.7063H6.27363C6.34988 18.9296 6.88835 18.8199 7.40779 18.8199C7.76517 18.8199 8.41801 18.9296 8.41801 19.5538V20.9262ZM7.76517 20.1876C7.69845 20.24 7.58888 20.2828 7.31246 20.3257C7.06464 20.3686 6.85975 20.4306 6.85975 20.707C6.85975 20.9404 7.04084 21.012 7.16953 21.012C7.46495 21.012 7.76517 20.8214 7.76517 20.5117V20.1876Z"
      fill="white"
    />
    <path
      d="M11.2582 21.4218H10.591V19.8826C10.591 19.6968 10.5815 19.3823 10.1574 19.3823C9.86194 19.3823 9.63325 19.5824 9.63325 19.9684V21.4218H8.96613V18.8867H9.60465V19.2584H9.61421C9.70473 19.1059 9.89535 18.8199 10.3766 18.8199C10.8722 18.8199 11.2582 19.1106 11.2582 19.6682V21.4218H11.2582Z"
      fill="white"
    />
    <path
      d="M14.1458 21.4215H13.493V21.1022H13.4835C13.3215 21.3881 13.026 21.4881 12.7496 21.4881C11.9967 21.4881 11.6822 20.8211 11.6822 20.1253C11.6822 19.2485 12.1778 18.8196 12.7353 18.8196C13.1642 18.8196 13.3643 19.0532 13.4692 19.2151H13.4787V18H14.1459V21.4215H14.1458ZM12.9164 20.9402C13.3405 20.9402 13.5073 20.5875 13.5073 20.2111C13.5073 19.8061 13.3929 19.3963 12.9354 19.3963C12.5113 19.3963 12.3684 19.7727 12.3684 20.173C12.3684 20.4732 12.4875 20.9402 12.9164 20.9402Z"
      fill="white"
    />
    <path
      d="M16.8716 20.6784C16.6953 21.2883 16.1902 21.4885 15.7565 21.4885C15.0418 21.4885 14.4938 21.1454 14.4938 20.1018C14.4938 19.7968 14.5986 18.8199 15.7089 18.8199C16.2092 18.8199 16.9002 19.0582 16.9002 20.2162V20.3353H15.1609C15.18 20.5259 15.2181 20.9548 15.7566 20.9548C15.9424 20.9548 16.133 20.8595 16.1854 20.6784H16.8716V20.6784ZM16.214 19.9016C16.1759 19.4918 15.9138 19.3679 15.6994 19.3679C15.3849 19.3679 15.2181 19.568 15.1799 19.9016H16.214Z"
      fill="white"
    />
    <path d="M17.9819 21.4215H17.3148V18H17.9819V21.4215Z" fill="white" />
    <path
      d="M20.0071 19.6586C19.9785 19.4966 19.9213 19.3251 19.5591 19.3251C19.197 19.3251 19.1493 19.4632 19.1493 19.5728C19.1493 19.9731 20.7361 19.6824 20.7361 20.6165C20.7361 21.355 20.0214 21.4885 19.6259 21.4885C19.0493 21.4885 18.425 21.2883 18.4059 20.6117H19.0921C19.0921 20.8261 19.2256 20.9834 19.6449 20.9834C19.988 20.9834 20.0691 20.8166 20.0691 20.7213C20.0691 20.2972 18.4965 20.6165 18.4965 19.6682C18.4965 19.2965 18.73 18.8199 19.521 18.8199C20.0166 18.8199 20.6075 18.9438 20.6599 19.6586H20.0071V19.6586Z"
      fill="white"
    />
    <path
      d="M21.8227 19.2151H21.8322C21.9371 19.0531 22.1372 18.8196 22.5661 18.8196C23.1236 18.8196 23.6192 19.2485 23.6192 20.1253C23.6192 20.8211 23.3047 21.4881 22.5518 21.4881C22.2754 21.4881 21.9799 21.3881 21.8179 21.1022H21.8084V21.4215H21.1555V18H21.8227V19.2151ZM22.3659 19.3962C21.9084 19.3962 21.7941 19.806 21.7941 20.2111C21.7941 20.5875 21.9609 20.9401 22.385 20.9401C22.8138 20.9401 22.933 20.4732 22.933 20.1729C22.933 19.7727 22.79 19.3962 22.3659 19.3962Z"
      fill="white"
    />
    <path
      d="M26.0923 20.9262C26.0923 21.2264 26.159 21.2931 26.24 21.3217V21.4218H25.5204C25.4824 21.3026 25.4728 21.2645 25.4585 21.1216C25.306 21.2788 25.0916 21.4885 24.6389 21.4885C24.2577 21.4885 23.8669 21.2741 23.8669 20.7499C23.8669 20.2543 24.1814 20.0017 24.6055 19.9398L25.206 19.8492C25.3108 19.8349 25.4394 19.792 25.4394 19.6491C25.4394 19.3679 25.1726 19.3537 25.0011 19.3537C24.6675 19.3537 24.6104 19.5586 24.5912 19.7063H23.9479C24.0242 18.9296 24.5626 18.8199 25.0821 18.8199C25.4394 18.8199 26.0923 18.9296 26.0923 19.5538V20.9262H26.0923ZM25.4394 20.1876C25.3727 20.24 25.2631 20.2828 24.9867 20.3257C24.7389 20.3686 24.534 20.4306 24.534 20.707C24.534 20.9404 24.7152 21.012 24.8438 21.012C25.1392 21.012 25.4394 20.8214 25.4394 20.5117V20.1876H25.4394Z"
      fill="white"
    />
    <path
      d="M28.9324 21.4218H28.2653V19.8826C28.2653 19.6968 28.2558 19.3823 27.8317 19.3823C27.5362 19.3823 27.3075 19.5824 27.3075 19.9684V21.4218H26.6404V18.8867H27.2789V19.2584H27.2885C27.379 19.1059 27.5696 18.8199 28.0509 18.8199C28.5464 18.8199 28.9324 19.1106 28.9324 19.6682V21.4218Z"
      fill="white"
    />
    <path
      d="M30.1904 21.4215H29.5233V18H30.1904V19.8489L31.0291 18.8863H31.8487L30.9528 19.868L31.8725 21.4215H31.072L30.4669 20.335L30.1904 20.6209V21.4215Z"
      fill="white"
    />
    <path
      d="M34.2838 20.6784C34.1075 21.2883 33.6024 21.4885 33.1688 21.4885C32.454 21.4885 31.906 21.1454 31.906 20.1018C31.906 19.7968 32.0109 18.8199 33.1212 18.8199C33.6215 18.8199 34.3125 19.0582 34.3125 20.2162V20.3353H32.5731C32.5922 20.5259 32.6303 20.9548 33.1688 20.9548C33.3546 20.9548 33.5452 20.8595 33.5977 20.6784H34.2838V20.6784ZM33.6262 19.9016C33.5881 19.4918 33.3261 19.3679 33.1116 19.3679C32.7971 19.3679 32.6303 19.568 32.5922 19.9016H33.6262Z"
      fill="white"
    />
    <path
      d="M37 21.4218H36.3329V19.8826C36.3329 19.6968 36.3233 19.3823 35.8993 19.3823C35.6038 19.3823 35.3751 19.5824 35.3751 19.9684V21.4218H34.7079V18.8867H35.3465V19.2584H35.356C35.4466 19.1059 35.6371 18.8199 36.1184 18.8199C36.614 18.8199 37 19.1106 37 19.6682V21.4218Z"
      fill="white"
    />
  </svg>
);
export default SvgHandelsbanken;
