import * as React from 'react';
import { SVGProps } from 'react';
const SvgSteps = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16 8.25H16.75V7.5V3.75H20.25V20.25H3.75V16.75H7.5H8.25V16V12.75H12H12.75V12V8.25H16Z"
      stroke="#12123B"
      strokeWidth={1.5}
    />
  </svg>
);
export default SvgSteps;
