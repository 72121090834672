import * as React from 'react';
import { SVGProps } from 'react';
const SvgPhoneCall = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 4.5H4.5V16H12H13.5H15V20C15 20.5523 14.5523 21 14 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H14C14.5523 3 15 3.44772 15 4V8H13.5V4.5ZM10 18.4307C10 18.983 9.55228 19.4307 9 19.4307C8.44772 19.4307 8 18.983 8 18.4307C8 17.8784 8.44772 17.4307 9 17.4307C9.55228 17.4307 10 17.8784 10 18.4307Z"
      fill="#12123B"
    />
    <path d="M17 14L17 16L21 12L17 8L17 10L9.5 10L9.5 14L17 14Z" fill="#474AEE" />
  </svg>
);
export default SvgPhoneCall;
