import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import {
  ReferralOwnerQuery as ReferralOwnerQueryType,
  ReferralOwnerQueryVariables,
} from '../gqlTypes';

export const ReferralOwnerQuery = gql`
  query ReferralOwner($code: String!) {
    referralOwner: referralOwner_v3(code: $code) {
      name
      validTo
      welcomeBonus
      welcomeBonusCurrency
    }
  }
`;

export const useReferralOwner = (
  options?: QueryHookOptions<ReferralOwnerQueryType, ReferralOwnerQueryVariables>,
) => useQuery<ReferralOwnerQueryType, ReferralOwnerQueryVariables>(ReferralOwnerQuery, options);
