import { gql } from '@apollo/client';

export const TransferDepositFragment = gql`
  fragment TransferDepositFragment on TransferDeposit {
    id
    amount
    currency
    createdAt
    address
    status
    confirmations
    required
    isFiat
    txid
    depositType
    accountGroupId
    transactionId
    fee
    fiatValue
  }
`;
