import * as React from 'react';
import { SVGProps } from 'react';
const SvgEmailSent = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14 6.5H16L12 2L8 6.5H10L10 10.5H14L14 6.5Z" fill="#474AEE" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99999 7.21073L3.49997 7.21069C2.67272 7.21069 1.99997 7.88344 1.99997 8.71069L1.99999 20.3389C1.99999 21.1662 2.67274 21.8389 3.49999 21.8389L20.5 21.8391C21.3272 21.8391 22 21.1663 22 20.3391L22 8.71085C22 7.8836 21.3272 7.21085 20.5 7.21085L17 7.21082V8.71083L19.1355 8.71085L12 14.0996L4.86445 8.71069L6.99999 8.71072V7.21073ZM12 15.9998L20.5 9.54989L20.5 20.3391L3.49999 20.3389L3.49997 9.54973L12 15.9998Z"
      fill="#12123B"
    />
  </svg>
);
export default SvgEmailSent;
