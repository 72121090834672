import * as React from 'react';
import { SVGProps } from 'react';
const SvgPresent = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0871 7.00002H20C20.5523 7.00002 21 7.44773 21 8.00002V11C21 11.5523 20.5523 12 20 12V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21L4 12C3.44772 12 3 11.5523 3 11V8.00002C3 7.44773 3.44772 7.00002 4 7.00002H6.65551C6.61118 6.97018 6.56723 6.93987 6.52367 6.9091C5.18614 5.96422 4.15726 4.53647 4.00008 2.81503C3.96242 2.40253 4.26628 2.0376 4.67878 1.99994C6.32465 1.84966 8.03353 2.36158 9.36966 3.30546C10.6023 4.17623 11.5728 5.45711 11.8405 7.00002H11.9849C12.3913 5.40857 13.4335 3.97613 14.757 3.03199C16.0902 2.08096 17.7774 1.58059 19.4544 2.00002C19.8562 2.10052 20.1005 2.50775 20 2.90958C19.599 4.51291 18.5521 5.95731 17.2203 6.90733C17.1763 6.93872 17.1319 6.96962 17.0871 7.00002ZM16.3492 5.68619C15.5337 6.2679 14.6148 6.59552 13.7113 6.58807C14.1177 5.67238 14.7987 4.84485 15.6281 4.25313C16.4436 3.67141 17.3625 3.3438 18.266 3.35124C17.8596 4.26694 17.1787 5.09447 16.3492 5.68619ZM5.66765 3.49119C5.95864 4.3466 6.57101 5.10601 7.38915 5.68397C8.22135 6.27186 9.22718 6.64176 10.2257 6.72337C9.93469 5.86796 9.32232 5.10855 8.50418 4.53059C7.67198 3.9427 6.66615 3.5728 5.66765 3.49119ZM4.5 8.50002H9.5V10.5H4.5V8.50002ZM11 10.5V8.50002H13V10.5H11ZM13 12H11V20.5H13V12ZM14.5 20.5V12H18.5V20.5H14.5ZM14.5 10.5V8.50002H19.5V10.5H14.5ZM5.5 12H9.5V20.5H5.5V12Z"
    />
  </svg>
);
export default SvgPresent;
