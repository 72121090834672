import { gql, QueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';

import { VideoIdVerificationUrlQuery, VideoIdVerificationUrlQueryVariables } from '../gqlTypes';

export const VIDEO_ID_VERIFICATION_QUERY = gql`
  query VideoIdVerificationUrl($input: IdVerificationInput!) {
    idVerificationUrl(input: $input) {
      url
    }
  }
`;

export const useLazyVideoIdVerificationUrl = (
  variables?: VideoIdVerificationUrlQueryVariables,
  options?: QueryHookOptions<VideoIdVerificationUrlQuery, VideoIdVerificationUrlQueryVariables>,
) =>
  useLazyQuery<VideoIdVerificationUrlQuery, VideoIdVerificationUrlQueryVariables>(
    VIDEO_ID_VERIFICATION_QUERY,

    { variables, fetchPolicy: 'network-only', ...options },
  );

export const useVideoIdVerificationUrl = (
  variables: VideoIdVerificationUrlQueryVariables,
  options?: QueryHookOptions<VideoIdVerificationUrlQuery, VideoIdVerificationUrlQueryVariables>,
) =>
  useQuery<VideoIdVerificationUrlQuery, VideoIdVerificationUrlQueryVariables>(
    VIDEO_ID_VERIFICATION_QUERY,
    {
      variables: variables,
      ...options,
    },
  );
