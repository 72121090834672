import Fingerprint2, { Options } from 'fingerprintjs2';
import lscache from 'lscache';

const KEY = 'fingerprint';

// Number of minutes the tokens lives
const TTL = 15; // 15 minutes

export const getCached = () => lscache.get(KEY);

export const setCached = (fingerprint: string) => {
  lscache.set(KEY, fingerprint, TTL);
};

const options: Options = {
  excludes: { fonts: true, audio: true, webgl: true, canvas: true },
};

export const generate = (): Promise<{
  fingerprint: string;
  components: Fingerprint2.Component[];
}> =>
  new Promise((resolve) => {
    Fingerprint2.get(options, (result) => {
      const fingerprint = Fingerprint2.x64hash128(result.map((pair) => pair.value).join(), 27);
      resolve({ fingerprint, components: result });
    });
  });

export const fingerprint = {
  getCached,
  setCached,
  generate,
};
