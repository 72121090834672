import { gql, useMutation } from '@apollo/client';

import { VerifyEmailCodeMutation, VerifyEmailCodeMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation VerifyEmailCode($code: String!) {
    verifyEmailCode(code: $code) {
      success
      message
    }
  }
`;

export const useVerifyEmailCode = () =>
  useMutation<VerifyEmailCodeMutation, VerifyEmailCodeMutationVariables>(mutation);
