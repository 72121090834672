import * as React from 'react';
import { SVGProps } from 'react';
const SvgAave = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 800 800"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <rect id="path-1" x={0} y={0} width={800} height={800} />
      <linearGradient
        x1="87.2792784%"
        y1="18.632268%"
        x2="12.8792784%"
        y2="81.232268%"
        id="linearGradient-3"
      >
        <stop stopColor="#B6509E" offset="0%" />
        <stop stopColor="#2EBAC6" offset="100%" />
      </linearGradient>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="aave">
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-1" />
        </mask>
        <g id="SVGID_1_" />
        <g id="b" mask="url(#mask-2)" fillRule="nonzero">
          <g transform="translate(12, 12)">
            <circle id="Oval" fill="url(#linearGradient-3)" cx={388} cy={388} r={388} />
            <path
              d="M557.8,542.6 L426.6,225.4 C419.2,209 408.2,201 393.7,201 L382.1,201 C367.6,201 356.6,209 349.2,225.4 L292.1,363.6 L248.9,363.6 C236,363.7 225.5,374.1 225.4,387.1 L225.4,387.4 C225.5,400.3 236,410.8 248.9,410.9 L272.1,410.9 L217.6,542.6 C216.6,545.5 216,548.5 216,551.6 C216,559 218.3,564.8 222.4,569.3 C226.5,573.8 232.4,576 239.8,576 C244.7,575.9 249.4,574.4 253.3,571.5 C257.5,568.6 260.4,564.4 262.7,559.6 L322.7,410.8 L364.3,410.8 C377.2,410.7 387.7,400.3 387.8,387.3 L387.8,386.7 C387.7,373.8 377.2,363.3 364.3,363.2 L342.1,363.2 L387.9,249.1 L512.7,559.5 C515,564.3 517.9,568.5 522.1,571.4 C526,574.3 530.8,575.8 535.6,575.9 C543,575.9 548.8,573.7 553,569.2 C557.2,564.7 559.4,558.9 559.4,551.5 C559.5,548.5 559,545.4 557.8,542.6 Z"
              id="Path"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default SvgAave;
