import axios from 'axios';
import Fingerprint2 from 'fingerprintjs2';

import { fingerprint as fp } from '../../utils/fingerprint';
import { getAuthBackendUrl } from '../getAuthBackendUrl';
import { Client, Environment } from '../types';

/**
 * Vipps
 */
export interface VippsInputParameters {
  client: Client;
  environment: Environment;
  uuid: string;
  redirectUrl?: string;
  session?: string;
  components?: Fingerprint2.Component[];
}
interface VippsResponse {
  url?: string;
}

interface VippsRegisterWithStateResponse {
  session: string;
  sessionExpiration: string;
}

export const vippsLogin = async (params: VippsInputParameters) => {
  const fingerprint = await fp.generate();
  const components = params.components || fingerprint.components;

  try {
    const res = await axios.request<VippsResponse>({
      method: 'get',
      url: getAuthBackendUrl(params.environment) + '/v2/vipps/login',
      headers: {
        'miraiex-client': params.client,
        'miraiex-fingerprint': fingerprint.fingerprint,
        components: JSON.stringify(components),
        'miraiex-frontend-env': params.environment,
        'firi-client-uuid': params.uuid,
      },
      params: { redirectUrl: params.redirectUrl },
    });
    return {
      url: res.data.url,
    };
  } catch (e) {
    return {
      error: 'Something went wrong',
    };
  }
};

export interface VippsRegisterInput extends VippsInputParameters {
  newsletter: boolean;
  privacy: boolean;
  affiliate: string;
  stateUuid?: string | undefined;
}

export const vippsRegister = async (params: VippsRegisterInput) => {
  const fingerprint = await fp.generate();
  const components = params.components || fingerprint.components;

  try {
    const res = await axios.request<VippsResponse | VippsRegisterWithStateResponse>({
      method: 'post',
      url: getAuthBackendUrl(params.environment) + '/v2/vipps/create',
      headers: {
        'miraiex-client': params.client,
        'miraiex-fingerprint': fingerprint.fingerprint,
        components: JSON.stringify(components),
        'miraiex-frontend-env': params.environment,
        'firi-client-uuid': params.uuid,
      },
      withCredentials: true,
      data: { stateUuid: params.stateUuid, affiliate: params.affiliate },
      params: { redirectUrl: params.redirectUrl },
    });

    if (typeof res.data === 'object' && 'session' in res.data) {
      return {
        sessionToken: res.data.session,
        sessionExpiration: res.data.sessionExpiration,
      };
    }

    return {
      url: res?.data?.url ?? '',
    };
  } catch (e: any) {
    if (e.response?.data.name === 'ReferralNotValid') {
      return {
        error: e.response.data.name,
      };
    }

    return {
      error: 'Something went wrong',
    };
  }
};

export const vippsLinking = async (params: VippsInputParameters) => {
  const fingerprint = await fp.generate();
  const components = params.components || fingerprint.components;

  try {
    const res = await axios.request<VippsResponse>({
      method: 'get',
      url: getAuthBackendUrl(params.environment) + '/v2/vipps/link',
      headers: {
        'miraiex-client': params.client,
        'miraiex-fingerprint': fingerprint.fingerprint,
        components: JSON.stringify(components),
        'miraiex-frontend-env': params.environment,
        'firi-client-uuid': params.uuid,
        ...(params.client === 'app' ? { Authorization: `Bearer ${params.session}` } : {}),
      },
      params: { redirectUrl: params.redirectUrl },
      withCredentials: true,
    });
    return {
      url: res.data.url,
    };
  } catch (e) {
    return {
      error: 'Something went wrong',
    };
  }
};
