import * as React from 'react';
import { SVGProps } from 'react';
const SvgSparebank1Dark = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={20} cy={20} r={20} fill="white" />
    <path
      d="M3.77412 21.9154C3.07119 21.9154 2.51422 21.7531 2.41143 21.7207C2.35729 21.6991 2.3303 21.6558 2.3303 21.6073V21.5803L2.4006 21.2233C2.41143 21.1693 2.44925 21.1529 2.49243 21.1529C2.50325 21.1529 2.51408 21.1529 2.53025 21.1583C2.64387 21.1799 3.19535 21.3369 3.7145 21.3369C4.15786 21.3369 4.5527 21.2071 4.5527 20.7474C4.5527 20.2824 4.14717 20.2338 3.62268 20.158C3.03871 20.0713 2.28699 19.9147 2.28699 18.9954C2.28699 18.0492 2.98992 17.8003 3.82263 17.8003C4.45539 17.8003 4.97988 17.9517 5.09871 17.9842C5.16367 18.0004 5.19615 18.0437 5.19615 18.0978V18.1248L5.1205 18.4762C5.11502 18.514 5.07719 18.5519 5.03402 18.5519C5.02854 18.5519 5.02319 18.5465 5.01771 18.5465C4.75814 18.4924 4.35795 18.3843 3.88211 18.3843C3.48206 18.3843 3.06022 18.4601 3.06022 18.9142C3.06022 19.3631 3.51441 19.428 3.99573 19.4982C4.62835 19.5955 5.34759 19.6875 5.34759 20.6933C5.34759 21.6396 4.57983 21.9154 3.77412 21.9154Z"
      fill="#002776"
    />
    <path
      d="M10.6446 21.8506C10.4554 21.8937 10.1958 21.9262 9.89305 21.9262C9.30908 21.9262 8.58984 21.8668 8.58984 21.007C8.58984 20.3095 9.02786 20.2338 9.46039 20.1635L10.4229 20.0067V19.8338C10.4229 19.6823 10.4066 19.5742 10.2714 19.5147C10.185 19.4769 10.0552 19.4606 9.86057 19.4606C9.49287 19.4606 9.09268 19.5687 8.93055 19.6119C8.91424 19.6119 8.90355 19.6173 8.89272 19.6173C8.86572 19.6173 8.83859 19.6065 8.83324 19.5687L8.75759 19.2335V19.2118C8.75759 19.1632 8.79542 19.1415 8.82255 19.1307C8.95234 19.082 9.44983 18.9469 10.0013 18.9469C10.3852 18.9469 10.6448 19.001 10.8179 19.0983C11.0936 19.2496 11.1477 19.5039 11.1477 19.7958V21.2883C11.1476 21.6938 11.0123 21.7641 10.6446 21.8506ZM10.423 20.4772L9.75244 20.5853C9.53069 20.6177 9.32525 20.6502 9.32525 21.0016C9.32525 21.4234 9.66596 21.4557 9.92553 21.4557C10.1365 21.4557 10.3094 21.418 10.423 21.3799V20.4772Z"
      fill="#002776"
    />
    <path
      d="M13.0767 19.5037C12.7468 19.5037 12.4656 19.5362 12.3143 19.5741V21.7749C12.3143 21.8236 12.2818 21.8561 12.2332 21.8561H11.6817C11.6492 21.8561 11.5897 21.8237 11.5897 21.7749V19.4389C11.5897 19.228 11.714 19.1632 11.9736 19.0875C12.2115 19.0119 12.5955 18.9577 12.9036 18.9524H12.9956C13.0712 18.9524 13.0712 18.9524 13.1092 18.9686C13.1309 18.9794 13.1578 19.001 13.1578 19.055V19.4064C13.1578 19.4606 13.1525 19.5037 13.0767 19.5037Z"
      fill="#002776"
    />
    <path
      d="M15.9069 20.6448C15.9069 20.7043 15.8744 20.7691 15.7933 20.7691H13.9819C13.9819 21.0178 14.0522 21.1531 14.1981 21.2666C14.3388 21.3801 14.5442 21.4289 14.8363 21.4289C15.1715 21.4289 15.4256 21.3477 15.5932 21.2991C15.604 21.2937 15.6095 21.2937 15.6202 21.2937C15.6527 21.2937 15.6797 21.3154 15.696 21.3695L15.7663 21.6453C15.7663 21.6506 15.7718 21.6616 15.7718 21.6723C15.7718 21.7156 15.7448 21.7372 15.7068 21.7534C15.523 21.8292 15.1552 21.9265 14.7281 21.9265C13.6683 21.9265 13.252 21.5101 13.252 20.4503C13.252 19.4337 13.5332 18.947 14.5876 18.947C15.1338 18.947 15.469 19.0876 15.6636 19.3472C15.8474 19.5905 15.9069 19.9365 15.9069 20.3854V20.6448ZM15.1445 19.8391C15.0742 19.5417 14.9119 19.4444 14.593 19.4444C14.2306 19.4444 14.0792 19.5525 14.0197 19.8229C13.9819 19.9743 13.9819 20.1311 13.9765 20.3096L15.1878 20.3041C15.1878 20.1311 15.1823 19.985 15.1445 19.8391Z"
      fill="#002776"
    />
    <path
      d="M22.0582 21.8506C21.8689 21.8937 21.6094 21.9262 21.3066 21.9262C20.7225 21.9262 20.0034 21.8668 20.0034 21.007C20.0034 20.3095 20.4414 20.2338 20.8741 20.1635L21.8366 20.0067V19.8338C21.8366 19.6823 21.8203 19.5742 21.6852 19.5147C21.5987 19.4769 21.4689 19.4606 21.2743 19.4606C20.9064 19.4606 20.5063 19.5687 20.3441 19.6119C20.328 19.6119 20.3171 19.6173 20.3064 19.6173C20.2794 19.6173 20.2523 19.6065 20.2468 19.5687L20.1712 19.2335V19.2118C20.1712 19.1632 20.2089 19.1415 20.236 19.1307C20.3658 19.082 20.8633 18.9469 21.4149 18.9469C21.7988 18.9469 22.0583 19.001 22.2314 19.0983C22.5073 19.2496 22.5613 19.5039 22.5613 19.7958V21.2883C22.5612 21.6938 22.4259 21.7641 22.0582 21.8506ZM21.8366 20.4772L21.166 20.5853C20.9443 20.6177 20.7388 20.6502 20.7388 21.0016C20.7388 21.4234 21.0794 21.4557 21.339 21.4557C21.5499 21.4557 21.723 21.418 21.8366 21.3799V20.4772Z"
      fill="#002776"
    />
    <path
      d="M25.4529 21.856H24.8959C24.8473 21.856 24.8095 21.8236 24.8095 21.7748V19.866C24.8095 19.5523 24.6905 19.4441 24.4039 19.4441C24.0795 19.4441 23.728 19.5361 23.728 19.5361V21.7747C23.728 21.8233 23.6955 21.8558 23.6469 21.8558H23.0952C23.0411 21.8558 23.0034 21.8235 23.0034 21.7747V19.4441C23.0034 19.2387 23.1061 19.1684 23.4089 19.0873C23.6847 19.0116 24.1389 18.9467 24.4851 18.9467C25.161 18.9467 25.534 19.1197 25.534 19.7685V21.7747C25.534 21.8235 25.5016 21.856 25.4529 21.856Z"
      fill="#002776"
    />
    <path
      d="M28.5885 21.8072C28.5885 21.8288 28.567 21.8558 28.5344 21.8558H27.8098C27.7828 21.8558 27.7556 21.8558 27.7286 21.8235L26.8906 20.6824H26.7067V21.7748C26.7067 21.8235 26.6797 21.856 26.6255 21.856H26.0685C26.02 21.856 25.9819 21.8236 25.9819 21.7748V17.995C25.9819 17.9302 26.0089 17.8977 26.074 17.8815L26.6037 17.7896H26.6254C26.674 17.7896 26.7065 17.822 26.7065 17.8815V20.1957H26.8904L27.7448 19.0548C27.7663 19.0223 27.7881 19.017 27.8151 19.017L28.4749 19.0331C28.5127 19.0331 28.529 19.0601 28.529 19.0818C28.529 19.0926 28.5237 19.0981 28.5182 19.1088L27.4962 20.3958L28.5778 21.78C28.5831 21.7857 28.5885 21.7965 28.5885 21.8072Z"
      fill="#002776"
    />
    <path
      d="M7.18284 18.9469C6.82048 18.9469 6.40412 19.0064 6.07972 19.12C5.83632 19.1956 5.73901 19.2768 5.73901 19.558V22.9052C5.73901 22.9593 5.78136 23.0034 5.85934 22.9895C5.93733 22.9753 6.37164 22.8952 6.37164 22.8952C6.4366 22.8843 6.4636 22.852 6.4636 22.787V21.8443C6.6556 21.891 6.8975 21.9265 7.16653 21.9265C8.17234 21.9265 8.3616 21.2939 8.3616 20.4394C8.3616 19.5795 8.22647 18.9469 7.18284 18.9469ZM6.9774 21.4287C6.68548 21.4287 6.46373 21.3639 6.46373 21.3639V19.5417C6.46373 19.5417 6.78279 19.4552 7.03153 19.4552C7.44255 19.4552 7.62099 19.547 7.62099 20.4232C7.62085 21.245 7.46941 21.4287 6.9774 21.4287Z"
      fill="#002776"
    />
    <path
      d="M18.2835 21.856H16.4828C16.4342 21.856 16.3909 21.8181 16.3909 21.7695V17.9463C16.3909 17.8977 16.434 17.8599 16.4828 17.8599H18.2889C19.2031 17.8599 19.5758 18.2184 19.5758 18.8677C19.5758 19.4409 19.2574 19.646 18.7647 19.7871C19.3117 19.8772 19.711 20.1742 19.711 20.8122C19.711 21.5368 19.2027 21.856 18.2835 21.856ZM18.2131 18.4168H17.1695V19.5739L18.1753 19.5308C18.5863 19.5146 18.7971 19.3685 18.7971 18.9576C18.7972 18.579 18.6133 18.4168 18.2131 18.4168ZM18.1861 20.0984L17.1696 20.0661V21.2989H18.1861C18.7322 21.2989 18.9107 21.0611 18.9107 20.7311C18.9107 20.2878 18.7647 20.1146 18.1861 20.0984Z"
      fill="#002776"
    />
    <path
      d="M33.1953 15.9561C32.7934 15.9561 32.4063 16.0188 32.0426 16.1344C33.5733 15.8023 35.0905 16.4329 35.637 17.7243C36.2687 19.217 35.3644 21.0266 33.6173 21.766C32.05 22.4292 30.3376 22.0054 29.5454 20.8338C30.0088 22.4122 31.467 23.5651 33.1953 23.5651C35.2964 23.5651 36.9998 21.8618 36.9998 19.7606C36.9998 17.6593 35.2964 15.9561 33.1953 15.9561Z"
      fill="#E60000"
    />
    <path
      d="M33.6174 21.766C35.3646 21.0266 36.2689 19.2171 35.6371 17.7242C35.0905 16.4327 33.5732 15.8022 32.0427 16.1343C30.5051 16.6226 29.3909 18.0612 29.3909 19.7605C29.3909 20.1333 29.4454 20.4933 29.5455 20.8337C30.3376 22.0054 32.0501 22.4291 33.6174 21.766Z"
      fill="#AF0000"
    />
    <path
      d="M34.1706 21.4796V17.5732C34.1706 17.4957 34.1073 17.4325 34.0296 17.4325H33.145C33.0819 17.4325 33.0464 17.4465 32.9899 17.4677L31.8485 18.0553C31.7782 18.0836 31.75 18.1257 31.75 18.1963V18.8364C31.75 18.9117 31.811 18.9724 31.8861 18.9724H32.6166V22.0435C32.95 21.9972 33.2869 21.9057 33.6173 21.7657C33.8126 21.6831 33.9973 21.587 34.1706 21.4796Z"
      fill="white"
    />
  </svg>
);
export default SvgSparebank1Dark;
