import { t } from '@lingui/macro';

import { ClientCountry, SupportedLanguage } from '../constants';
import { FiatCurrency } from './../gql/gqlTypes';

type ManualTransfer = {
  recipientName: string;
  address: string;
  postalCode: string;
};

type SavingsPayment = {
  recipientName: string;
};

// TODO: Update DK TBD information
export const useCountryInfo = () => {
  const countryInfo: Record<
    ClientCountry,
    {
      name: string;
      currency: FiatCurrency;
      manualTransfer: ManualTransfer;
      savingsPayment: SavingsPayment;
      defaultLanguage: SupportedLanguage;
      adtractionUrl: string; // url for request towards Adtraction to confirm conversion
    }
  > = {
    NO: {
      name: t`Norway`,
      currency: FiatCurrency.Nok,
      manualTransfer: {
        recipientName: t`Firi AS`,
        address: 'Grundingen 2',
        postalCode: '0250 Oslo',
      },
      savingsPayment: {
        recipientName: t`Firi AS`,
      },
      defaultLanguage: 'no',
      adtractionUrl:
        'https://cnv.adt662.net/t/t?t=3&tk=1&am=0&c=NOK&ti=[user_id]&tp=1664264385&trt=4&ss=1&ap=1664263246&at_gd=[at_gd]',
    },
    DK: {
      name: t`Denmark`,
      currency: FiatCurrency.Dkk,
      manualTransfer: {
        recipientName: t`Firi AS`,
        address: 'Grundingen 2',
        postalCode: '0250 Oslo',
      },
      savingsPayment: {
        recipientName: t`Firi AS`,
      },
      defaultLanguage: 'da',
      adtractionUrl:
        'https://cnv.adt611.com/t/t?t=4&tk=1&am=0&c=DKK&ti=[user_id]&tp=1741945703&trt=4&ss=1&ap=1733049551&at_gd=[at_gd]',
    },
    SE: {
      name: t`Sweden`,
      currency: FiatCurrency.Sek,
      manualTransfer: {
        recipientName: t`Firi AS`,
        address: 'Grundingen 2',
        postalCode: '0250 Oslo',
      },
      savingsPayment: {
        recipientName: t`Firi AS`,
      },
      defaultLanguage: 'sv',
      adtractionUrl: '',
    },
  };

  return countryInfo;
};
