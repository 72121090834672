import * as React from 'react';
import { SVGProps } from 'react';
const SvgDashboard = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.77778 10.3611H1.11111C0.914214 10.3611 0.75 10.1969 0.75 10V1.11111C0.75 0.914214 0.914214 0.75 1.11111 0.75H7.77778C7.97468 0.75 8.13889 0.914214 8.13889 1.11111V10C8.13889 10.1969 7.97468 10.3611 7.77778 10.3611ZM7.77778 19.25H1.11111C0.914213 19.25 0.75 19.0858 0.75 18.8889V14.4444C0.75 14.2475 0.914213 14.0833 1.11111 14.0833H7.77778C7.97468 14.0833 8.13889 14.2475 8.13889 14.4444V18.8889C8.13889 19.0858 7.97468 19.25 7.77778 19.25ZM18.8889 19.25H12.2222C12.0253 19.25 11.8611 19.0858 11.8611 18.8889V10C11.8611 9.8031 12.0253 9.63889 12.2222 9.63889H18.8889C19.0858 9.63889 19.25 9.8031 19.25 10V18.8889C19.25 19.0858 19.0858 19.25 18.8889 19.25ZM11.8611 5.55556V1.11111C11.8611 0.914214 12.0253 0.75 12.2222 0.75H18.8889C19.0858 0.75 19.25 0.914213 19.25 1.11111V5.55556C19.25 5.75245 19.0858 5.91667 18.8889 5.91667H12.2222C12.0253 5.91667 11.8611 5.75245 11.8611 5.55556Z"
      stroke="currentColor"
      strokeWidth={1.5}
    />
  </svg>
);
export default SvgDashboard;
