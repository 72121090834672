import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { UserFragment } from '../fragments';
import { UserQuery } from '../gqlTypes';

export const CURRENT_USER_QUERY = gql`
  query User {
    currentUser {
      ...UserFragment
    }
  }
  ${UserFragment}
`;

export const useCurrentUser = (options?: QueryHookOptions<UserQuery>) =>
  useQuery<UserQuery>(CURRENT_USER_QUERY, options);
