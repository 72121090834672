import * as React from 'react';
import { SVGProps } from 'react';
const SvgAge50 = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.02 16.1497C6.51333 16.1497 6.05667 16.0531 5.65 15.8597C5.25 15.6597 4.92333 15.3797 4.67 15.0197C4.42333 14.6531 4.27333 14.2297 4.22 13.7497H5.83C6.00333 14.3897 6.40667 14.7097 7.04 14.7097C7.4 14.7097 7.68333 14.5897 7.89 14.3497C8.10333 14.1097 8.21 13.7864 8.21 13.3797C8.21 12.9931 8.1 12.6797 7.88 12.4397C7.66 12.1931 7.38 12.0697 7.04 12.0697C6.50667 12.0697 6.11667 12.3164 5.87 12.8097H4.33L5.16 8.36973H9.48V9.81973H6.33L6.06 11.2297C6.22667 11.0831 6.43 10.9631 6.67 10.8697C6.91 10.7764 7.15333 10.7297 7.4 10.7297C7.88 10.7297 8.30333 10.8397 8.67 11.0597C9.03667 11.2797 9.32 11.5931 9.52 11.9997C9.72 12.3997 9.82 12.8597 9.82 13.3797C9.82 13.9264 9.70333 14.4097 9.47 14.8297C9.24333 15.2497 8.91667 15.5764 8.49 15.8097C8.07 16.0364 7.58 16.1497 7.02 16.1497Z"
      fill="#DA2D43"
    />
    <path
      d="M13.683 16.1497C12.763 16.1497 12.0563 15.8331 11.563 15.1997C11.0696 14.5664 10.823 13.5531 10.823 12.1597C10.823 10.7864 11.0663 9.78639 11.553 9.15973C12.0396 8.53306 12.7363 8.21973 13.643 8.21973C14.5563 8.21973 15.2596 8.53639 15.753 9.16973C16.2463 9.80306 16.493 10.8164 16.493 12.2097C16.493 13.5897 16.2496 14.5931 15.763 15.2197C15.283 15.8397 14.5896 16.1497 13.683 16.1497ZM13.643 14.7097C14.043 14.7097 14.3463 14.5297 14.553 14.1697C14.7663 13.8097 14.873 13.1497 14.873 12.1897C14.873 11.2297 14.7663 10.5697 14.553 10.2097C14.3396 9.84973 14.0296 9.66973 13.623 9.66973C13.363 9.66973 13.1463 9.74306 12.973 9.88973C12.7996 10.0297 12.6663 10.2831 12.573 10.6497C12.4796 11.0164 12.433 11.5264 12.433 12.1797C12.433 12.8397 12.4796 13.3564 12.573 13.7297C12.673 14.0964 12.8096 14.3531 12.983 14.4997C13.163 14.6397 13.383 14.7097 13.643 14.7097Z"
      fill="#DA2D43"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.716 3.42469C12.8801 2.68005 10.8537 2.54449 8.93508 3.03796C7.01642 3.53144 5.30687 4.62788 4.05813 6.16586C2.80938 7.70385 2.0874 9.60213 1.99849 11.5812C1.90957 13.5604 2.45841 15.5157 3.56421 17.1595C4.67001 18.8033 6.27434 20.0486 8.14102 20.7121C10.0077 21.3757 12.0381 21.4224 13.9333 20.8454C15.8285 20.2683 17.4884 19.0981 18.6686 17.5069L19.8734 18.4005C18.5018 20.2497 16.5727 21.6097 14.3702 22.2803C12.1677 22.9509 9.808 22.8966 7.63862 22.1255C5.46923 21.3544 3.60474 19.9071 2.31962 17.9968C1.03451 16.0864 0.396665 13.814 0.5 11.5139C0.603335 9.21387 1.44239 7.00776 2.89363 5.22037C4.34488 3.43298 6.33164 2.15874 8.56144 1.58524C10.7912 1.01174 13.1462 1.16929 15.2798 2.03468C17.4133 2.90008 19.2127 4.4276 20.4129 6.39237L19.1328 7.17433C18.1001 5.48371 16.5518 4.16933 14.716 3.42469Z"
      fill="#12123B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.5 12.6817H17.5V11.1817H23.5V12.6817Z"
      fill="#12123B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7647 14.9028L19.7647 8.90283H21.2647V14.9028H19.7647Z"
      fill="#12123B"
    />
  </svg>
);
export default SvgAge50;
