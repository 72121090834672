import { gql, useMutation } from '@apollo/client';

import { ActivateStakingMutation, ActivateStakingMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation ActivateStaking($input: ActivateStakingInput!) {
    activateStaking(input: $input) {
      success
    }
  }
`;

export const useActivateStaking = () =>
  useMutation<ActivateStakingMutation, ActivateStakingMutationVariables>(mutation, {
    refetchQueries: 'active',
  });
