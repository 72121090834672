import { gql, useQuery } from '@apollo/client';

import { ReferralBonusQuery, ReferralBonusQueryVariables } from '../gqlTypes';

const QUERY = gql`
  query ReferralBonus($code: String) {
    referralBonus(code: $code) {
      amount
      payoutCurrency
      welcomeBonus
      welcomeBonusCurrency
      code
    }
  }
`;

export const useReferralBonus = (params?: { code?: string }) => {
  return useQuery<ReferralBonusQuery, ReferralBonusQueryVariables>(QUERY, {
    variables: { code: params?.code },
  });
};
