import { gql, useMutation } from '@apollo/client';

import { RemoveBankAccountMutation, RemoveBankAccountMutationVariables } from '../gqlTypes';

const MUTATION = gql`
  mutation RemoveBankAccount($input: RemoveBankAccountInput!) {
    removeBankAccount(input: $input) {
      success
    }
  }
`;

export const useRemoveBankAccount = () =>
  useMutation<RemoveBankAccountMutation, RemoveBankAccountMutationVariables>(MUTATION, {
    refetchQueries: ['BankAccounts'],
  });
