import { DEVICE_ID } from '@firi/shared/cookies';
import { WebConfigManager } from 'constants/config';
import { getCookie, setCookies } from 'cookies-next';
import { checkForAnyCookieAttributions } from 'helpers/trackingAttributions';
import { useEffect } from 'react';

import { useWebStore } from './useWebStore';

export let amplitude: any;
export let uuid: any;

export const useInitializeAmplitude = () => {
  const setDeviceId = useWebStore((state) => state.setDeviceId);
  const sixMonths = new Date();
  sixMonths.setMonth(sixMonths.getMonth() + 6);

  const cookieOptions = {
    domain: process.env.NEXT_PUBLIC_DOMAIN || 'localhost',
    path: '/',
    expires: sixMonths,
    sameSite: 'lax' as any,
  };

  useEffect(() => {
    const initAmplitude = async () => {
      let currentDeviceID = getCookie(DEVICE_ID);
      if (!currentDeviceID) {
        if (!uuid) {
          uuid = await import('uuid');
        }
        currentDeviceID = uuid.v4();
        const attributeExist = checkForAnyCookieAttributions();
        if (attributeExist) {
          setCookies(DEVICE_ID, currentDeviceID, cookieOptions);
        }
      }
      setDeviceId(currentDeviceID?.toString());

      amplitude = await import('@amplitude/analytics-browser');
      amplitude.init(WebConfigManager.amplitude.apiKey, undefined, {
        deviceId: currentDeviceID,
        serverUrl: WebConfigManager.amplitude.proxyServer,
        defaultTracking: {
          sessions: true,
          formInteractions: false,
          pageViews: { trackHistoryChanges: 'pathOnly', eventType: 'Viewed firi.com' },
        },
        minIdLength: 1,
        trackingOptions: {
          ipAddress: false,
        },
      });
    };
    initAmplitude();
  }, []);
};
