import { gql, useQuery } from '@apollo/client';

import { StakingAssetsAttainableQuery } from './../gqlTypes';

const query = gql`
  query StakingAssetsAttainable {
    staking {
      notStakedCurrencies {
        currency
        fiatCurrency
        name
        type
        minimumStaked
        lockPeriod
        rewardDelay
        epochLength
        apy
        price
      }
    }
  }
`;

export const useStakingAssetsAttainable = () => useQuery<StakingAssetsAttainableQuery>(query);
