import { gql, useMutation } from '@apollo/client';

import { PasswordResetCodeMutation, PasswordResetCodeMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation PasswordResetCode($code: String!) {
    requestPasswordResetCode: requestPasswordResetCode_v2(input: { code: $code }) {
      code
      email
      success
      message
    }
  }
`;

export const usePasswordResetCodeQuery = () => {
  return useMutation<PasswordResetCodeMutation, PasswordResetCodeMutationVariables>(mutation);
};
