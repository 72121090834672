import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { CryptocurrenciesQuery, CryptoCurrency } from '../gqlTypes';

export const CRYPTOCURRENCIES_QUERY = gql`
  query Cryptocurrencies {
    account {
      cryptoCurrencies {
        id
        name
        hasTag
      }
    }
  }
`;

export const useCryptoCurrencies = (opts?: QueryHookOptions<CryptocurrenciesQuery>) => {
  const res = useQuery<CryptocurrenciesQuery>(CRYPTOCURRENCIES_QUERY, opts);

  const getName = (currency: CryptoCurrency | null | undefined) =>
    currency ? res.data?.account?.cryptoCurrencies?.find((c) => c.id === currency)?.name || '' : '';

  const isCrypto = (currency: CryptoCurrency | null | undefined) =>
    currency ? !!res.data?.account?.cryptoCurrencies?.find((c) => c.id === currency) : false;

  return { ...res, getName, isCrypto };
};
