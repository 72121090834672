import clsx from 'clsx';
import React from 'react';

import { Icon, IconName } from '../../01-atoms/icon/Icon';

export type ButtonSkins =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'outlined'
  | 'alert'
  | 'alert-outlined'
  | 'success'
  | 'success-outlined'
  | 'white-outlined'
  | 'white-primary';

export type ButtonSize = 'sm' | 'md' | 'lg';
export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  size?: ButtonSize;
  skin?: ButtonSkins;
  full?: boolean;
  iconPosition?: 'left' | 'right';
  icon?: IconName;
  as?: 'button' | 'div';
}

const rootClasses = [
  'rounded-full',
  'disabled:opacity-50',
  'flex flex-row justify-center items-center',
];

const sizes = {
  lg: 'py-2 px-6 gap-2 text-web-sm min-w-[119px]',
  md: 'py-1 px-3 gap-2 text-web-sm',
  sm: 'py-1 px-3 gap-2 text-web-xs',
};

const iconButtonSizes = {
  lg: 'p-3  h-11 w-11',
  md: 'p-1  h-8 w-8',
  sm: 'p-1  h-6 w-6',
};

const bg = {
  primary:
    'bg-firi-blue hover:bg-blue-900 dark:hover:bg-blue-600 dark:bg-blue-500',
  secondary: 'bg-transparent',
  tertiary: 'bg-blue-200 hover:bg-blue-300 dark:hover:bg-blue-100',
  outlined: 'bg-transparent hover:bg-blue-100 dark:hover:bg-background-dark-5',
  'white-outlined':
    'bg-transparent hover:bg-white dark:hover:bg-background-dark-5',
  alert: 'bg-red hover:bg-red-600 dark:bg-red-600',
  'alert-outlined': 'bg-transparent',
  success: 'bg-green hover:bg-green-600 dark:bg-green-600',
  'success-outlined': 'bg-transparent',
  'white-primary': 'bg-white hover:bg-black-100',
};

const border = {
  primary: '',
  secondary: '',
  tertiary: '',
  outlined:
    'border-firi-blue border hover:border-blue-900 dark:border-blue-300',
  'white-outlined':
    'border-white border hover:border-blue-100 dark:border-background-dark-5',
  alert: '',
  'alert-outlined':
    'border-red border hover:border-red-600 dark:border-red-600',
  success: '',
  'success-outlined':
    'border-green border hover:border-green-600 dark:border-green-600',
  'white-primary': '',
};

const fontColor = {
  primary: 'text-white fill-white',
  secondary:
    'text-blue-700 fill-blue-700 hover:text-blue-900 dark:text-blue-300 dark:fill-blue-300 dark:hover:fill-black-100 dark:hover:text-black-100',
  tertiary:
    'text-blue-700 fill-blue-700 dark:text-black-900 dark:fill-black-900 ',
  outlined:
    'text-blue-700 fill-blue-700 hover:text-blue-700 dark:text-blue-300 dark:fill-blue-300 dark:hover:text-dark-text-5 dark:hover:fill-text-dark-text-5',
  'white-outlined':
    'text-white fill-white hover:text-blue-700 group-hover:fill-blue-700 dark:group-hover:fill-white dark:text-white dark:fill-white',
  alert: 'text-white',
  'alert-outlined':
    'text-red fill-red hover:text-red-600 hover:fill-red-600  dark:text-red-600 dark:fill-red-600',
  success: 'text-white',
  'success-outlined':
    'text-green fill-green hover:text-green-600 hover:fill-green-600  dark:text-green-600 dark:fill-green-600',
  'white-primary': 'text-firi-blue dark:text-dark-1',
};

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size = 'lg',
      skin = 'primary',
      full = false,
      disabled = false,
      iconPosition,
      icon,
      onClick,
      type = 'button',
      className,
      children,
      as = 'button',
      ...restProps
    }: ButtonProps,
    ref
  ) => {
    const classNames = clsx(
      'font-medium',
      rootClasses,
      bg[skin],
      border[skin],
      fontColor[skin],
      !children ? iconButtonSizes[size] : sizes[size],
      className,
      {
        'flex-row-reverse': icon && iconPosition === 'right',
        'w-full': full,
      },
      skin === 'white-outlined' && 'group'
    );

    if (as === 'div') {
      return (
        <div className={classNames}>
          {icon && (
            <Icon
              size={size !== 'sm' ? 'md' : 'sm'}
              icon={icon}
              color="none"
              className={fontColor[skin]}
            />
          )}
          <span>{children}</span>
        </div>
      );
    }

    return (
      <button
        ref={ref}
        className={classNames}
        disabled={disabled}
        onClick={onClick}
        type={type}
        {...restProps}
      >
        {icon && (
          <Icon
            size={size !== 'sm' ? 'md' : 'sm'}
            icon={icon}
            color={'none'}
            className={fontColor[skin]}
          />
        )}
        <span>{children}</span>
      </button>
    );
  }
);
