import { gql, useMutation } from '@apollo/client';

import { AddBankAccountMutation, AddBankAccountMutationVariables } from '../gqlTypes';

const MUTATION = gql`
  mutation AddBankAccount($input: BankAccountInput!) {
    createBankAccount(input: $input) {
      id
      iban
      swiftbic
      displayName
      accountNumber
      routingNumber
    }
  }
`;

/**
 * Query for adding bank accounts
 */
export const useAddBankAccount = () =>
  useMutation<AddBankAccountMutation, AddBankAccountMutationVariables>(MUTATION, {
    refetchQueries: ['BankAccounts'],
  });
