import * as React from 'react';
import { SVGProps } from 'react';
const SvgDkk = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 23.9998C18.6274 23.9998 24 18.6272 24 11.9999C24 5.37253 18.6274 0 12 0C5.37258 0 0 5.37253 0 11.9999C0 18.6272 5.37258 23.9998 12 23.9998Z"
      fill="#E31836"
    />
    <path
      d="M23.8984 10.4348H9.39136L9.39131 0.285339C8.28234 0.531243 7.23098 0.929817 6.26086 1.45917V10.4347L0.101578 10.4347C0.0347813 10.9471 0 11.4695 0 12C0 12.5305 0.0347813 13.0528 0.101578 13.5652H6.26081L6.26086 22.5407C7.23098 23.0701 8.28234 23.4687 9.39131 23.7146V13.5653L23.8984 13.5652C23.9651 13.0528 24 12.5305 24 12C24 11.4695 23.9651 10.9471 23.8984 10.4348Z"
      fill="white"
    />
  </svg>
);
export default SvgDkk;
