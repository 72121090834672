import { gql, useMutation } from '@apollo/client';

import {
  RequestLightningWithdrawMutation,
  RequestLightningWithdrawMutationVariables,
} from './../gqlTypes';

const mutation = gql`
  mutation RequestLightningWithdraw($input: RequestWithdrawLightningInput!) {
    requestWithdrawLightning(input: $input) {
      url
      invoice
      state
    }
  }
`;

export const useRequestLightningWithdraw = () =>
  useMutation<RequestLightningWithdrawMutation, RequestLightningWithdrawMutationVariables>(
    mutation,
  );
