import { gql, QueryResult, useQuery } from '@apollo/client';

import { StakingAssetsQuery } from './../gqlTypes';

const query = gql`
  query StakingAssets {
    staking {
      currencies {
        currency
        fiatCurrency
        name
        type
        minimumStaked
        lockPeriod
        rewardDelay
        epochLength
        apy
        price
      }
    }
  }
`;

export const useStakingAssets = (options?: {
  skip?: boolean;
}): QueryResult<StakingAssetsQuery> & { maxApy: number | undefined } => {
  const r = useQuery<StakingAssetsQuery>(query, options);
  const maxApy = r.data?.staking.currencies?.reduce(
    (apy, currency) => Math.max(apy, currency.apy || 0),
    0,
  );

  return {
    ...r,
    maxApy,
  };
};
