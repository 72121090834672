import { gql, useQuery } from '@apollo/client';

import { PaymentStatusQuery, PaymentStatusQueryVariables } from '../gqlTypes';

const QUERY = gql`
  query PaymentStatus($id: String!) {
    payment {
      paymentStatus(id: $id)
    }
  }
`;

export const usePaymentStatus = (params: { paymentId: string; pollInterval?: number }) =>
  useQuery<PaymentStatusQuery, PaymentStatusQueryVariables>(QUERY, {
    variables: {
      id: params.paymentId,
    },
    fetchPolicy: 'network-only',
    pollInterval: params.pollInterval,
  });
