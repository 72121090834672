import * as React from 'react';
import { SVGProps } from 'react';
const SvgNordea = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={20} cy={20} r={20} fill="#0000A0" />
    <path
      d="M33.1886 18.3254C31.8897 18.4199 30.8506 19.4826 30.7562 20.7814C30.6617 22.3165 31.8661 23.5917 33.3775 23.5917C33.9207 23.5917 34.6527 23.3083 34.9834 22.7415V23.5208H35.9988V21.0176C36.0461 19.3645 34.7236 18.2073 33.1886 18.3254ZM34.9125 21.0176C34.8653 21.8914 34.1096 22.5526 33.2122 22.4345C32.5273 22.3401 31.9842 21.7969 31.8897 21.1121C31.7953 20.191 32.5037 19.4117 33.4011 19.4117C34.2041 19.4117 34.8653 20.0494 34.9125 20.8523V21.0176Z"
      fill="white"
    />
    <path
      d="M27.6154 18.3253C26.222 18.3725 25.0413 19.4352 25.0413 20.9938C25.0413 22.4108 26.1984 23.6152 27.6862 23.6152C28.7253 23.6152 29.7408 23.0012 30.1422 21.9857L29.1268 21.6787C28.9378 22.1038 28.4891 22.458 27.9932 22.5525C27.2847 22.6942 26.6235 22.2455 26.3637 21.7732L30.3075 20.6868C30.1895 19.6478 29.2921 18.2781 27.6154 18.3253ZM26.1512 20.8285C26.1512 20.2854 26.4818 19.6478 27.1903 19.388C27.9932 19.081 28.7017 19.4824 29.0087 20.0492L26.1512 20.8285Z"
      fill="white"
    />
    <path
      d="M18.9484 19.388V18.3017C17.9565 18.3017 17.6259 18.7976 17.4606 19.0574V18.467H16.327V20.8758V23.4971H17.4842C17.4842 21.9385 17.4842 21.9621 17.4842 21.0175C17.4842 19.8839 18.1454 19.4352 18.9484 19.388Z"
      fill="white"
    />
    <path
      d="M8.53393 21.4897L5.08605 17.4279H3.90527V23.4971H5.13328V19.4352L8.62839 23.5207H9.69109V17.4279H8.53393V21.4897Z"
      fill="white"
    />
    <path
      d="M24.3563 16.8848H23.27V18.8685C23.0102 18.5379 22.2073 18.2545 21.5224 18.3253C20.2236 18.4198 19.1845 19.4825 19.09 20.7813C18.9956 22.3163 20.2 23.5916 21.7114 23.5916C22.2781 23.5916 23.0575 23.3318 23.3172 22.8359V23.4971H24.3327V20.9939C24.3327 20.9703 24.3327 20.9703 24.3327 20.9466C24.3327 20.923 24.3327 20.923 24.3327 20.8994L24.3563 16.8848ZM23.2228 21.0175C23.1755 21.8913 22.4198 22.5525 21.5224 22.4344C20.8376 22.34 20.2944 21.7968 20.2 21.112C20.1055 20.1909 20.814 19.4116 21.7114 19.4116C22.5143 19.4116 23.1755 20.0493 23.2228 20.8522V21.0175Z"
      fill="white"
    />
    <path
      d="M13.0209 18.3017C11.5568 18.3017 10.376 19.4825 10.376 20.9466C10.376 22.4108 11.5568 23.5916 13.0209 23.5916C14.4851 23.5916 15.6659 22.4108 15.6659 20.9466C15.6423 19.4825 14.4615 18.3017 13.0209 18.3017ZM13.0209 22.458C12.1944 22.458 11.5095 21.7732 11.5095 20.9466C11.5095 20.1201 12.1944 19.4352 13.0209 19.4352C13.8475 19.4352 14.5323 20.1201 14.5323 20.9466C14.5323 21.7968 13.8475 22.458 13.0209 22.458Z"
      fill="white"
    />
  </svg>
);
export default SvgNordea;
