import { gql, useMutation } from '@apollo/client';

import { DeleteApiKeyMutation, DeleteApiKeyMutationVariables } from '../gqlTypes';

const MUTATION = gql`
  mutation DeleteApiKey($apiKey: String!) {
    deleteApiKey(apiKey: $apiKey)
  }
`;

export const useDeleteApiKey = () => {
  return useMutation<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>(MUTATION, {
    refetchQueries: ['ApiKeys'],
  });
};
