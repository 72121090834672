import * as React from 'react';
import { SVGProps } from 'react';
const SvgBlogFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#474AEE" {...props}>
    <path d="M15 18C13.7745 18 12.6848 18.591 12 19.5022C11.3153 18.5902 10.2255 18 9 18L3 18.0007C3 18.0007 3 18.0008 3 18H1.5C1.5 18.8273 2.17275 19.5 3 19.5H9C10.2405 19.5 11.25 20.5095 11.25 21.75H12.75C12.75 20.5095 13.7595 19.5 15 19.5H21C21.8273 19.5 22.5 18.8273 22.5 18H15Z" />
    <path d="M11.25 17.2515C10.623 16.7798 9.84375 16.5 9 16.5H2.25C1.836 16.5 1.5 16.164 1.5 15.75V4.5C1.5 3.67125 2.17125 3 3 3H9C10.2428 3 11.25 4.00725 11.25 5.25V17.2515Z" />
    <path d="M22.5 15.75C22.5 16.164 22.164 16.5 21.75 16.5H15C14.1562 16.5 13.377 16.7798 12.75 17.2515V5.25C12.75 4.00725 13.7573 3 15 3H21C21.8288 3 22.5 3.67125 22.5 4.5V15.75Z" />
  </svg>
);
export default SvgBlogFilled;
