import { gql, useQuery } from '@apollo/client';

import { RewardsQuery } from './../gqlTypes';

const QUERY = gql`
  query Rewards {
    affiliate {
      rewards {
        uuid
        userID
        type
        level
        description
        metric
        metricGoal
        metricName
        completed
        fiatValue
        amount
        currency
        transactionType
        paidAt
        updatedAt
        completedAt
        fiatCurrency
      }
    }
  }
`;

export const useRewards = () => {
  return useQuery<RewardsQuery>(QUERY, {
    fetchPolicy: 'cache-and-network',
  });
};
