import * as React from 'react';
import { SVGProps } from 'react';
const SvgSettingsFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4135 21.4993C13.9725 21.4993 13.5345 21.3696 13.1557 21.1153L11.4217 19.9498C11.1652 19.7781 10.8345 19.7773 10.5787 19.9498L8.84473 21.1153C8.21623 21.5376 7.42273 21.6171 6.72298 21.3276C6.02323 21.0381 5.51848 20.4208 5.37223 19.6776L4.97023 17.6271C4.91098 17.3241 4.67698 17.0901 4.37398 17.0308L2.32348 16.6288C1.58023 16.4833 0.96298 15.9786 0.67348 15.2781C0.38323 14.5783 0.46273 13.7848 0.88573 13.1563L2.05123 11.4223C2.22298 11.1658 2.22373 10.8351 2.05123 10.5793L0.88573 8.84531C0.46348 8.21681 0.38398 7.42331 0.67348 6.72356C0.96373 6.02381 1.58023 5.51906 2.32348 5.37281L4.37398 4.97081C4.67698 4.91156 4.91098 4.67756 4.97023 4.37456L5.37223 2.32406C5.51773 1.58081 6.02248 0.963558 6.72298 0.674058C7.42273 0.384557 8.21623 0.463307 8.84473 0.885558L10.5787 2.05106C10.8352 2.22356 11.166 2.22356 11.4217 2.05106L13.1557 0.885558C13.7842 0.463307 14.5777 0.383807 15.2775 0.673308C15.9772 0.963558 16.482 1.58006 16.6275 2.32331L17.0295 4.37381C17.0887 4.67681 17.3227 4.91081 17.6257 4.97006L19.6762 5.37206C20.4195 5.51756 21.0367 6.02231 21.3262 6.72281C21.6157 7.42331 21.537 8.21606 21.1147 8.84456L19.9492 10.5786C19.7767 10.8351 19.7775 11.1658 19.9492 11.4216L21.1147 13.1556C21.537 13.7841 21.6165 14.5776 21.327 15.2773C21.0375 15.9771 20.4202 16.4818 19.677 16.6281L17.6265 17.0301C17.3235 17.0893 17.0895 17.3233 17.0302 17.6263L16.6282 19.6768C16.4827 20.4201 15.978 21.0373 15.2775 21.3268C14.9992 21.4423 14.706 21.4993 14.4135 21.4993ZM11.0002 15.5C8.51924 15.5 6.50024 13.481 6.50024 11C6.50024 8.519 8.51924 6.5 11.0002 6.5C13.4812 6.5 15.5002 8.519 15.5002 11C15.5002 13.481 13.4812 15.5 11.0002 15.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSettingsFilled;
