import * as React from 'react';
import { SVGProps } from 'react';
const SvgDot = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
      fill="#E6007A"
    />
    <g clipPath="url(#clip0_2949_33122)">
      <path
        d="M11.9991 17.8051C13.9055 17.8051 15.4509 18.7442 15.4509 19.9026C15.4509 21.061 13.9055 22 11.9991 22C10.0928 22 8.54736 21.061 8.54736 19.9026C8.54736 18.7442 10.0928 17.8051 11.9991 17.8051Z"
        fill="white"
      />
      <path
        d="M11.9991 2.00144C13.9055 2.00144 15.4509 2.94049 15.4509 4.09886C15.4509 5.25724 13.9055 6.19629 11.9991 6.19629C10.0928 6.19629 8.54736 5.25724 8.54736 4.09886C8.54736 2.94049 10.0928 2.00144 11.9991 2.00144Z"
        fill="white"
      />
      <path
        d="M7.18918 14.9018C8.14237 16.6266 8.13618 18.4947 7.17537 19.0743C6.21455 19.6539 4.66296 18.7255 3.70977 17.0006C2.75659 15.2758 2.76278 13.4077 3.72359 12.8281C4.6844 12.2486 6.236 13.177 7.18918 14.9018Z"
        fill="white"
      />
      <path
        d="M20.2874 7.00087C21.2406 8.7257 21.2349 10.5935 20.2748 11.1727C19.3146 11.7518 17.7635 10.8231 16.8104 9.09829C15.8572 7.37347 15.8628 5.5057 16.823 4.92651C17.7832 4.34732 19.3342 5.27604 20.2874 7.00087Z"
        fill="white"
      />
      <path
        d="M7.17586 4.92699C8.13667 5.50657 8.14286 7.37466 7.18968 9.09949C6.23649 10.8243 4.68489 11.7527 3.72408 11.1731C2.76327 10.5936 2.75708 8.72547 3.71026 7.00064C4.66345 5.27581 6.21505 4.34741 7.17586 4.92699Z"
        fill="white"
      />
      <path
        d="M20.2758 12.8277C21.2359 13.4069 21.2416 15.2747 20.2884 16.9995C19.3352 18.7243 17.7841 19.653 16.824 19.0739C15.8638 18.4947 15.8582 16.6269 16.8113 14.9021C17.7645 13.1772 19.3156 12.2485 20.2758 12.8277Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2949_33122">
        <rect width={18} height={20} fill="white" transform="matrix(1 0 0 -1 3 22)" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDot;
