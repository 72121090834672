import { gql, useQuery } from '@apollo/client';

import { ContactsQuery, ContactsQueryVariables, CryptoCurrency } from './../gqlTypes';

const query = gql`
  query Contacts($currency: CryptoCurrency) {
    currentUser {
      id
      contacts(currency: $currency) {
        name
        address
        currency
        tag
      }
    }
  }
`;

export const useContacts = (currency?: CryptoCurrency) =>
  useQuery<ContactsQuery, ContactsQueryVariables>(query, {
    variables: { currency },
    fetchPolicy: 'cache-and-network',
  });
