import { gql, useMutation } from '@apollo/client';

import { ValidateBankIdSessionMutation, ValidateBankIdSessionMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation ValidateBankIdSession($input: InvalidateBankIdSessionInput!) {
    validateBankIdSession(input: $input) {
      success
    }
  }
`;

export const useTestBankIdSession = () => {
  return useMutation<ValidateBankIdSessionMutation, ValidateBankIdSessionMutationVariables>(
    mutation,
  );
};
