import { gql, useMutation } from '@apollo/client';

import { SubmitUserNinMutation, SubmitUserNinMutationVariables } from './../gqlTypes';

const MUTATION = gql`
  mutation SubmitUserNin($nin: String!) {
    submitUserNin(nin: $nin) {
      success
      message
    }
  }
`;

export const useSubmitUserNin = () => {
  return useMutation<SubmitUserNinMutation, SubmitUserNinMutationVariables>(MUTATION);
};
