import * as React from 'react';
import { SVGProps } from 'react';
const Svg2Fa = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="#474AEE" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3.5 15.5V5.5H18.5V7L20 7V5C20 4.44772 19.5523 4 19 4H3C2.44772 4 2 4.44772 2 5V16C2 16.5523 2.44772 17 3 17H10.5V18H7.5V19.5H12V15.5H3.5Z" />
    <path d="M17.6758 18.9462C18.2281 18.9462 18.6758 18.4985 18.6758 17.9462C18.6758 17.3939 18.2281 16.9462 17.6758 16.9462C17.1235 16.9462 16.6758 17.3939 16.6758 17.9462C16.6758 18.4985 17.1235 18.9462 17.6758 18.9462Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 9C22 8.44772 21.5523 8 21 8L14 8C13.4477 8 13 8.44771 13 9L13 20C13 20.5523 13.4477 21 14 21H21C21.5523 21 22 20.5523 22 20V9ZM20.5 9.5L14.5 9.5L14.5 19.5H20.5V9.5Z"
    />
  </svg>
);
export default Svg2Fa;
