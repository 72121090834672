import * as React from 'react';
import { SVGProps } from 'react';
const SvgSortMin = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="#12123B" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.33325 2.66674L4.33325 13.3333L3.33325 13.3333L3.33325 2.66674L4.33325 2.66674Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99999 5.33339L6.99999 13.3333L6 13.3333L6 5.33339L6.99999 5.33339Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.6665 8.00004L9.6665 13.3333L8.6665 13.3333L8.6665 8.00004L9.6665 8.00004Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3332 10.6667L12.3332 13.3333L11.3333 13.3333L11.3333 10.6667L12.3332 10.6667Z"
      fill="#474AEE"
    />
  </svg>
);
export default SvgSortMin;
