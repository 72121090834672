import * as React from 'react';
import { SVGProps } from 'react';
const SvgApplePay = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 26 11" fill="none" {...props}>
    <path
      d="M5.28176 1.39951C5.57288 1.03148 5.77043 0.537319 5.71832 0.0323486C5.29217 0.0537662 4.77213 0.316514 4.47105 0.684835C4.20071 1.00024 3.96144 1.51509 4.02382 1.9989C4.5022 2.04084 4.98014 1.75721 5.28176 1.39951Z"
      fill="currentColor"
    />
    <path
      d="M5.7129 2.09338C5.01818 2.05155 4.42749 2.4919 4.09572 2.4919C3.76377 2.4919 3.25573 2.11445 2.70624 2.12463C1.99104 2.13525 1.32742 2.54396 0.964489 3.19401C0.218 4.49443 0.767491 6.42341 1.49341 7.48253C1.84594 8.00651 2.27079 8.58345 2.83059 8.56273C3.35952 8.54175 3.56683 8.21656 4.20976 8.21656C4.85222 8.21656 5.03897 8.56273 5.59887 8.55224C6.17949 8.54175 6.54249 8.028 6.89501 7.50351C7.29942 6.9062 7.46497 6.32945 7.47538 6.29778C7.46497 6.2873 6.35577 5.85722 6.34548 4.5676C6.33501 3.48779 7.21633 2.97418 7.25781 2.94232C6.76012 2.19834 5.98249 2.11445 5.7129 2.09338Z"
      fill="currentColor"
    />
    <path
      d="M11.762 0.632021C13.272 0.632021 14.3235 1.68403 14.3235 3.21567C14.3235 4.75277 13.2504 5.81024 11.7242 5.81024H10.0523V8.49745H8.84445V0.632019H11.762V0.632021ZM10.0523 4.78547H11.4383C12.49 4.78547 13.0885 4.21321 13.0885 3.22113C13.0885 2.22916 12.49 1.66226 11.4437 1.66226H10.0523V4.78547V4.78547Z"
      fill="currentColor"
    />
    <path
      d="M14.6391 6.86775C14.6391 5.86474 15.3995 5.24884 16.7478 5.17251L18.3008 5.07989V4.63841C18.3008 4.00065 17.8748 3.61911 17.163 3.61911C16.4888 3.61911 16.0681 3.94609 15.9658 4.45852H14.8656C14.9303 3.42282 15.8039 2.65973 17.2061 2.65973C18.5813 2.65973 19.4603 3.39559 19.4603 4.54568V8.49748H18.3439V7.5545H18.3171C17.9881 8.19227 17.2708 8.59557 16.5266 8.59557C15.4157 8.59557 14.6391 7.89788 14.6391 6.86775ZM18.3008 6.34995V5.89754L16.904 5.9847C16.2083 6.0338 15.8147 6.34448 15.8147 6.83505C15.8147 7.33645 16.2246 7.66353 16.8501 7.66353C17.6644 7.66353 18.3008 7.09663 18.3008 6.34995Z"
      fill="currentColor"
    />
    <path
      d="M20.5142 10.6069V9.653C20.6003 9.67477 20.7944 9.67476 20.8916 9.67476C21.4308 9.67476 21.7221 9.44588 21.9 8.85722C21.9 8.84628 22.0025 8.50837 22.0025 8.5029L19.9533 2.76324H21.2151L22.6498 7.42913H22.6712L24.1058 2.76324H25.3354L23.2104 8.79718C22.7253 10.1872 22.1644 10.6341 20.9887 10.6341C20.8916 10.6341 20.6003 10.6232 20.5142 10.6069Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgApplePay;
