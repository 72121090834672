import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { CryptoCurrency, FiatCurrency, Symbol, WithdrawLimitQuery } from '../gqlTypes';

const QUERY = gql`
  query WithdrawLimit($currency: Symbol) {
    withdraw {
      withdrawLimit(currency: $currency) {
        currency
        usage
        remaining
        index
        limit
        maxLimit
        locked
        lockedAmount
        fee
        minLimit(currency: $currency) {
          source
          limit
        }
      }
    }
  }
`;

export const useWithdrawLimit = (
  params: { currency?: Symbol | CryptoCurrency | FiatCurrency },
  options?: QueryHookOptions,
) => {
  return useQuery<WithdrawLimitQuery>(QUERY, {
    ...options,
    variables: { currency: params.currency },
    fetchPolicy: options?.fetchPolicy || 'cache-and-network',
    skip: options?.skip || !params.currency,
    pollInterval: options?.pollInterval,
  });
};
