import * as React from 'react';
import { SVGProps } from 'react';
const SvgMobilePay = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.86674 23.5562C8.06093 23.5562 7.33437 23.0711 7.02564 22.3267L0.151987 5.73326C-0.268544 4.71795 0.21353 3.55397 1.22878 3.13331L8.42823 0.151555C8.91575 -0.0504745 9.46356 -0.0505199 9.9511 0.151428C10.4386 0.353377 10.826 0.740769 11.0278 1.22835L17.9015 17.8225C18.3213 18.8375 17.8394 20.0008 16.8247 20.4214L9.62524 23.4046C9.38483 23.5045 9.12708 23.556 8.86674 23.5562ZM9.18981 1.07057C9.06916 1.07077 8.94974 1.09479 8.83839 1.14126L1.6386 4.12302C1.41354 4.21624 1.23475 4.39506 1.14155 4.62013C1.04836 4.8452 1.04841 5.09807 1.14169 5.3231L8.01534 21.9176C8.20991 22.3858 8.74672 22.6082 9.21542 22.4148L16.4149 19.431C16.6399 19.3378 16.8187 19.159 16.9119 18.9339C17.0051 18.7089 17.0051 18.456 16.9118 18.231L10.0381 1.63816C9.89585 1.29508 9.56123 1.07119 9.18981 1.07057Z"
      fill="white"
    />
    <path
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.5}
      d="M11.4817 2.32262L10.4919 2.7326L13.2135 9.30295L14.2032 8.89297L11.4817 2.32262Z"
      fill="url(#paint0_linear_201_64)"
    />
    <path
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.5}
      d="M16.4546 17.129L17.4443 16.7191L14.3637 9.28167L13.3739 9.69165L16.4546 17.129Z"
      fill="url(#paint1_linear_201_64)"
    />
    <path
      d="M15.2047 12.1991C13.1457 13.0529 11.4265 14.2298 10.3009 15.6484L7.39868 8.63918C8.72249 7.10115 10.4075 5.91592 12.3025 5.18989C14.3615 4.3361 16.4416 3.92287 18.209 4.16364L21.1118 11.1725C19.0881 11.0201 17.0583 11.3729 15.2047 12.1991Z"
      fill="white"
    />
    <g
      style={{
        mixBlendMode: 'multiply',
      }}
      opacity={0.25}
    >
      <path
        style={{
          mixBlendMode: 'multiply',
        }}
        opacity={0.9}
        d="M15.2047 12.1991C13.1457 13.0529 11.4265 14.2298 10.3009 15.6484L7.39868 8.63918C8.72249 7.10115 10.4075 5.91592 12.3025 5.18989C14.3615 4.3361 16.4416 3.92287 18.209 4.16364L21.1118 11.1725C19.0881 11.0201 17.0583 11.3729 15.2047 12.1991Z"
        fill="url(#paint2_linear_201_64)"
      />
      <path
        style={{
          mixBlendMode: 'multiply',
        }}
        opacity={0.7}
        d="M15.2047 12.1991C13.1457 13.0529 11.4265 14.2298 10.3009 15.6484L7.39868 8.63918C8.72249 7.10115 10.4075 5.91592 12.3025 5.18989C14.3615 4.3361 16.4416 3.92287 18.209 4.16364L21.1118 11.1725C19.0881 11.0201 17.0583 11.3729 15.2047 12.1991Z"
        fill="url(#paint3_linear_201_64)"
      />
    </g>
    <path
      d="M16.1502 14.336C13.9215 14.336 11.8813 14.7667 10.3 15.6457V8.55863C12.1116 7.64448 14.1218 7.19445 16.1502 7.24892C18.3789 7.24892 20.4591 7.66216 22 8.56034V15.6474C20.1888 14.7325 18.1786 14.2819 16.1502 14.336Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_201_64"
        x1={13.0428}
        y1={7.52636}
        x2={12.5984}
        y2={2.342}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#504678" />
        <stop offset={0.302} stopColor="#504678" stopOpacity={0.616} />
        <stop offset={0.608} stopColor="#504678" stopOpacity={0.283} />
        <stop offset={0.852} stopColor="#504678" stopOpacity={0.076} />
        <stop offset={1} stopColor="#504678" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint1_linear_201_64"
        x1={14.6205}
        y1={11.2663}
        x2={14.8992}
        y2={17.0753}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#504678" />
        <stop offset={0.302} stopColor="#504678" stopOpacity={0.616} />
        <stop offset={0.608} stopColor="#504678" stopOpacity={0.283} />
        <stop offset={0.852} stopColor="#504678" stopOpacity={0.076} />
        <stop offset={1} stopColor="#504678" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint2_linear_201_64"
        x1={9.27766}
        y1={11.7599}
        x2={14.7159}
        y2={8.05257}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#504678" />
        <stop offset={0.179} stopColor="#504678" stopOpacity={0.872} />
        <stop offset={0.526} stopColor="#504678" stopOpacity={0.536} />
        <stop offset={1} stopColor="#504678" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint3_linear_201_64"
        x1={13.9431}
        y1={8.13584}
        x2={13.7019}
        y2={6.20649}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#504678" />
        <stop offset={0.643} stopColor="#504678" stopOpacity={0.332} />
        <stop offset={1} stopColor="#504678" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgMobilePay;
