import { gql, useQuery } from '@apollo/client';

import {
  AvailableCryptoCurrenciesQuery,
  AvailableCryptoCurrenciesQueryVariables,
} from '../gqlTypes';

const QUERY = gql`
  query AvailableCryptoCurrencies($accountGroupId: String) {
    account {
      availableCryptoCurrencies(accountGroupId: $accountGroupId) {
        id
        name
      }
    }
  }
`;

export const useAvailableCryptoCurrencies = (params?: {
  accountGroupId?: string;
  skip?: boolean;
}) =>
  useQuery<AvailableCryptoCurrenciesQuery, AvailableCryptoCurrenciesQueryVariables>(QUERY, {
    variables: { accountGroupId: params?.accountGroupId },
    skip: params?.skip,
  });
