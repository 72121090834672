import * as React from 'react';
import { SVGProps } from 'react';
const SvgXlmDark = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_395_862)">
      <circle cx={12} cy={12} r={12} fill="white" />
    </g>
    <path
      d="M17.898 6.7917L15.9495 7.78442L6.54066 12.5768C6.50885 12.3342 6.49284 12.0897 6.49274 11.845C6.49403 10.8685 6.7504 9.90925 7.23647 9.06232C7.72254 8.2154 8.42143 7.51015 9.26393 7.01645C10.1064 6.52274 11.0633 6.2577 12.0397 6.24758C13.0162 6.23746 13.9783 6.48261 14.8309 6.95875L15.9461 6.39051L16.1125 6.30562C15.0908 5.56408 13.884 5.11947 12.6255 5.0209C11.367 4.92234 10.1057 5.17366 8.98104 5.74711C7.8564 6.32055 6.91221 7.19377 6.25283 8.27027C5.59344 9.34677 5.24453 10.5846 5.24466 11.847C5.24466 12.0214 5.25128 12.195 5.26451 12.368C5.28305 12.613 5.22872 12.858 5.10836 13.0721C4.988 13.2863 4.80699 13.4601 4.5881 13.5716L4 13.8715V15.2702L5.73143 14.3877L6.29214 14.1015L6.84464 13.8201L16.7636 8.76618L17.8781 8.19862L20.1819 7.02448V5.62646L17.898 6.7917Z"
      fill="#12123B"
    />
    <path
      d="M20.1819 8.42383L7.40809 14.9278L6.29351 15.4967L4 16.6654V18.0627L6.27777 16.9023L8.22622 15.9096L17.6447 11.1103C17.6765 11.3546 17.6925 11.6007 17.6926 11.847C17.692 12.8246 17.4357 13.7851 16.9492 14.633C16.4626 15.481 15.7628 16.1869 14.9191 16.6808C14.0753 17.1747 13.1171 17.4393 12.1395 17.4483C11.162 17.4574 10.199 17.2106 9.34628 16.7325L9.27782 16.7688L8.06876 17.385C9.09018 18.1265 10.2967 18.5713 11.5551 18.6701C12.8134 18.7689 14.0746 18.5179 15.1992 17.9448C16.3238 17.3718 17.2682 16.499 17.9278 15.4229C18.5875 14.3467 18.9369 13.1092 18.9373 11.847C18.9373 11.671 18.9304 11.4951 18.9174 11.3212C18.8989 11.0763 18.9532 10.8314 19.0734 10.6173C19.1936 10.4031 19.3744 10.2293 19.5931 10.1176L20.1819 9.81774V8.42383Z"
      fill="#12123B"
    />
    <defs>
      <clipPath id="clip0_395_862">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgXlmDark;
