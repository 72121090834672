import { gql, useMutation } from '@apollo/client';

import { WithdrawCryptoBankIdMutation, WithdrawCryptoBankIdMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation WithdrawCryptoBankId($input: WithdrawCryptoBankIdInput!) {
    withdrawCryptoBankId(input: $input) {
      success
      message
    }
  }
`;

export const useWithdrawCryptoBankId = () =>
  useMutation<WithdrawCryptoBankIdMutation, WithdrawCryptoBankIdMutationVariables>(mutation, {
    refetchQueries: ['Transaction'],
  });
