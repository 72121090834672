import { gql, useMutation } from '@apollo/client';

import { UpdateProfileMutation, UpdateProfileMutationVariables } from '../gqlTypes';

const mutation = gql`
  mutation UpdateProfile($input: UpdateUserProfileInput_v2!) {
    updateUserProfile_v2(input: $input) {
      id
      address
      city
      country
      nationality
      email
      phone
      firstName
      lastName
      postalArea
      securityLevel
      twoFactor
    }
  }
`;

export const useUpdateProfile = () =>
  useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(mutation);
