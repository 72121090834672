import { gql, useMutation } from '@apollo/client';

import { RequestPasswordResetMutation, RequestPasswordResetMutationVariables } from '../gqlTypes';

const MUTATION = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset: requestPasswordReset_v2(input: { email: $email }) {
      success
    }
  }
`;

export const usePasswordResetRequest = () =>
  useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(MUTATION);
