import { gql, useQuery, WatchQueryFetchPolicy } from '@apollo/client';

import { PnlInput, PnlYieldQuery, PnlYieldQueryVariables } from './../gqlTypes';

const query = gql`
  query PNLYield($input: PNLInput!) {
    pnl {
      yield(input: $input) {
        data {
          date
          total {
            totalBalanceWithFiat
            fiatBalance
            fiatAmount
            percent
          }
          currencies {
            cryptoCurrency
            fiatBalance
            fiatAmount
            percent
          }
        }
      }
    }
  }
`;

export const usePNLYield = (
  params: PnlInput,
  apolloOpts: {
    fetchPolicy?: WatchQueryFetchPolicy;
    nextFetchPolicy?: WatchQueryFetchPolicy;
    skip?: boolean;
  },
) => {
  return useQuery<PnlYieldQuery, PnlYieldQueryVariables>(query, {
    variables: {
      input: {
        period: params.period,
        accountGroupId: params.accountGroupId,
      },
    },
    fetchPolicy: apolloOpts.fetchPolicy,
    nextFetchPolicy: apolloOpts.nextFetchPolicy,
    skip: apolloOpts.skip,
  });
};
