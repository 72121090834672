import * as React from 'react';
import { SVGProps } from 'react';
const SvgVippsWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1208 6.02235C10.8834 6.02235 11.537 5.44339 11.537 4.61121H11.5371C11.5371 3.77884 10.8834 3.20007 10.1208 3.20007C9.35834 3.20007 8.70494 3.77884 8.70494 4.61121C8.70494 5.44339 9.35834 6.02235 10.1208 6.02235ZM11.9726 8.30239C11.0283 9.53243 10.0299 10.3829 8.2687 10.3829H8.26892C6.4721 10.3829 5.07386 9.29728 3.98466 7.7052C3.5488 7.05374 2.87707 6.90908 2.38682 7.25284C1.93302 7.5786 1.82436 8.26611 2.24166 8.8633C3.7484 11.1611 5.83609 12.4998 8.2687 12.4998C10.502 12.4998 12.2449 11.4144 13.6063 9.60498C14.1145 8.93563 14.0964 8.24811 13.6063 7.86808C13.1523 7.50588 12.4806 7.63309 11.9726 8.30239Z"
      fill="white"
    />
  </svg>
);
export default SvgVippsWhite;
