import * as React from 'react';
import { SVGProps } from 'react';
const SvgBankId = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.09091 7.63636H3.81818C3.38182 7.63636 3 7.25455 3 6.81818C3 6.38182 3.38182 6 3.81818 6H7.09091C7.52727 6 7.90909 6.38182 7.90909 6.81818C7.90909 7.25455 7.52727 7.63636 7.09091 7.63636Z"
      fill="#39134C"
    />
    <path
      d="M7.09091 14.1818H3.81818C3.38182 14.1818 3 13.8 3 13.3636C3 12.9273 3.38182 12.5454 3.81818 12.5454H7.09091C7.52727 12.5454 7.90909 12.9273 7.90909 13.3636C7.90909 13.8 7.52727 14.1818 7.09091 14.1818Z"
      fill="#39134C"
    />
    <path
      d="M7.09091 17.4546H3.81818C3.38182 17.4546 3 17.0728 3 16.6364C3 16.2001 3.38182 15.8183 3.81818 15.8183H7.09091C7.52727 15.8183 7.90909 16.2001 7.90909 16.6364C7.90909 17.0728 7.52727 17.4546 7.09091 17.4546Z"
      fill="#39134C"
    />
    <path
      d="M13.6363 10.9092H10.3636C9.92723 10.9092 9.54541 10.5274 9.54541 10.091C9.54541 9.65465 9.92723 9.27283 10.3636 9.27283H13.6363C14.0727 9.27283 14.4545 9.65465 14.4545 10.091C14.4545 10.5274 14.0727 10.9092 13.6363 10.9092Z"
      fill="#39134C"
    />
    <path
      d="M13.6363 14.1818H10.3636C9.92723 14.1818 9.54541 13.8 9.54541 13.3636C9.54541 12.9273 9.92723 12.5454 10.3636 12.5454H13.6363C14.0727 12.5454 14.4545 12.9273 14.4545 13.3636C14.4545 13.8 14.0727 14.1818 13.6363 14.1818Z"
      fill="#39134C"
    />
    <path
      d="M20.1817 7.63636H16.909C16.4726 7.63636 16.0908 7.25455 16.0908 6.81818C16.0908 6.38182 16.4726 6 16.909 6H20.1817C20.6181 6 20.9999 6.38182 20.9999 6.81818C20.9999 7.25455 20.6181 7.63636 20.1817 7.63636Z"
      fill="#39134C"
    />
    <path
      d="M20.1817 10.9092H16.909C16.4726 10.9092 16.0908 10.5274 16.0908 10.091C16.0908 9.65465 16.4726 9.27283 16.909 9.27283H20.1817C20.6181 9.27283 20.9999 9.65465 20.9999 10.091C20.9999 10.5274 20.6181 10.9092 20.1817 10.9092Z"
      fill="#39134C"
    />
    <path
      d="M20.1817 17.4546H16.909C16.4726 17.4546 16.0908 17.0728 16.0908 16.6364C16.0908 16.2001 16.4726 15.8183 16.909 15.8183H20.1817C20.6181 15.8183 20.9999 16.2001 20.9999 16.6364C20.9999 17.0728 20.6181 17.4546 20.1817 17.4546Z"
      fill="#39134C"
    />
  </svg>
);
export default SvgBankId;
