import * as React from 'react';
import { SVGProps } from 'react';
const SvgSolDark = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={12} cy={12} r={12} fill="white" />
    <path
      d="M18.9278 15.4611L16.6168 17.8366C16.5666 17.8882 16.5058 17.9293 16.4382 17.9574C16.3707 17.9855 16.2978 18.0001 16.2242 18H5.2683C5.21603 18 5.16489 17.9854 5.12118 17.9579C5.07746 17.9304 5.04307 17.8913 5.02224 17.8454C5.0014 17.7994 4.99502 17.7486 5.00389 17.6993C5.01275 17.6499 5.03647 17.6041 5.07214 17.5674L7.38501 15.192C7.43511 15.1405 7.4957 15.0995 7.56303 15.0714C7.63037 15.0433 7.703 15.0287 7.77645 15.0286H18.7317C18.784 15.0286 18.8351 15.0432 18.8788 15.0707C18.9225 15.0982 18.957 15.1373 18.9778 15.1832C18.9986 15.2291 19.005 15.2799 18.9961 15.3293C18.9872 15.3787 18.9635 15.4245 18.9278 15.4611ZM16.6168 10.6777C16.5666 10.6261 16.5058 10.585 16.4382 10.5569C16.3707 10.5287 16.2978 10.5143 16.2242 10.5143H5.2683C5.21603 10.5143 5.16489 10.5289 5.12118 10.5564C5.07746 10.5839 5.04307 10.623 5.02224 10.6689C5.0014 10.7149 4.99502 10.7657 5.00389 10.815C5.01275 10.8644 5.03647 10.9102 5.07214 10.9469L7.38501 13.3223C7.43511 13.3738 7.4957 13.4148 7.56303 13.4429C7.63037 13.471 7.703 13.4856 7.77645 13.4857H18.7317C18.784 13.4857 18.8351 13.4711 18.8788 13.4436C18.9225 13.4161 18.957 13.377 18.9778 13.3311C18.9986 13.2851 19.005 13.2343 18.9961 13.185C18.9872 13.1356 18.9635 13.0898 18.9278 13.0531L16.6168 10.6777ZM5.2683 8.97143H16.2242C16.2978 8.97146 16.3707 8.95698 16.4382 8.92886C16.5058 8.90074 16.5666 8.8596 16.6168 8.808L18.9278 6.43257C18.9635 6.39594 18.9872 6.35012 18.9961 6.30075C19.005 6.25138 18.9986 6.2006 18.9778 6.15465C18.957 6.1087 18.9225 6.06959 18.8788 6.04211C18.8351 6.01464 18.784 6 18.7317 6H7.77645C7.703 6.00012 7.63037 6.01469 7.56303 6.0428C7.4957 6.07091 7.43511 6.11197 7.38501 6.16343L5.07273 8.53886C5.0371 8.57545 5.01339 8.62122 5.00451 8.67054C4.99562 8.71986 5.00195 8.77059 5.02271 8.81652C5.04348 8.86245 5.07777 8.90157 5.1214 8.92908C5.16502 8.9566 5.21608 8.97131 5.2683 8.97143Z"
      fill="url(#paint0_linear_19_140)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_19_140"
        x1={6.18176}
        y1={18.286}
        x2={17.1272}
        y2={5.41144}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.08} stopColor="#9945FF" />
        <stop offset={0.3} stopColor="#8752F3" />
        <stop offset={0.5} stopColor="#5497D5" />
        <stop offset={0.6} stopColor="#43B4CA" />
        <stop offset={0.72} stopColor="#28E0B9" />
        <stop offset={0.97} stopColor="#19FB9B" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgSolDark;
