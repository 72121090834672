import { PLATFORM_URL } from '../constants';
import { CryptoCurrency } from '../gql/gqlTypes';

export type PlatformLink = 'login' | 'signup' | 'vipps-signup' | 'email-signup';

export const getPlatformLink = (
  params:
    | { link: PlatformLink }
    | { link: 'advanced'; currency: CryptoCurrency; locale: string | undefined },
) => {
  switch (params.link) {
    case 'login':
      return PLATFORM_URL;

    case 'signup':
      return PLATFORM_URL + '/sign-up';

    case 'email-signup':
      return PLATFORM_URL + '/sign-up/email';

    case 'vipps-signup':
      return PLATFORM_URL + '/sign-up/vipps';

    case 'advanced':
      return PLATFORM_URL + `/advanced/${params.currency}${params.locale === 'dk' ? 'DKK' : 'NOK'}`;
  }
};
