import { ApolloClientParams, createApolloClient } from '@firi/shared';
import { PROD_GRAPHQL_API, TEST_GRAPHQL_API } from '@firi/shared/constants';
import React from 'react';

const isDevelopment = process.env.NODE_ENV === 'development';

export const sharedApolloClientProps: ApolloClientParams = {
  client: 'web',
  locale: 'no',
  getToken: () => null,
  getUserId: () => null,
  gqlEndpoint: isDevelopment ? TEST_GRAPHQL_API : PROD_GRAPHQL_API,
  environment: isDevelopment ? 'test' : 'prod',
  onUnauthorized: () => null,
};

// Explicit serverside apolloeclient because it cannot be shared with the clientside one
export const serverSideApolloClient = createApolloClient(sharedApolloClientProps);
