import { gql, useQuery } from '@apollo/client';

import { VoucherQuery, VoucherQueryVariables } from '../gqlTypes';

export const VOUCHER_QUERY = gql`
  query Voucher($id: String!) {
    voucher {
      voucher(id: $id) {
        id
        amount
        currency
        createdAt
        from
        to
        voucherType
      }
    }
  }
`;

export const useVoucher = (voucherId?: string) => {
  return useQuery<VoucherQuery, VoucherQueryVariables>(VOUCHER_QUERY, {
    variables: {
      id: voucherId || '',
    },
    skip: !voucherId,
  });
};
