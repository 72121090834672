import * as React from 'react';
import { SVGProps } from 'react';
const SvgEmail = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.33331 2.66667H13.6666C14.2181 2.66667 14.6666 3.11517 14.6666 3.66667L14.6666 12.3333C14.6666 12.8848 14.2181 13.3333 13.6666 13.3333H2.33331C1.78181 13.3333 1.33331 12.8848 1.33331 12.3333V3.66667C1.33331 3.11517 1.78181 2.66667 2.33331 2.66667ZM3.24296 3.66667L7.99998 7.36657L12.757 3.66667L3.24296 3.66667ZM13.6666 4.22603L7.99998 8.63343L2.33331 4.22602L2.33331 12.3333H13.6666L13.6666 4.22603Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEmail;
