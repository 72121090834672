import { gql, useQuery } from '@apollo/client';

import { StakingStatsQuery } from './../gqlTypes';

const query = gql`
  query StakingStats {
    staking {
      stats {
        fiatValue
        fiatCurrency
        apy
      }
    }
  }
`;

export const useStakingStats = () => useQuery<StakingStatsQuery>(query);
